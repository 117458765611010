import { Toast } from '@capacitor/toast';
import { IonBackButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonLoading, IonPage, IonRow, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alterarDetaquePortfolio, assinaUrl, enviarArquivoPortfolio, removerArquivoPortfolioCategoria, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import GridImagens from '../Portfolio/GridImagens';
import './PortfolioVisualizar.css';


type Props = {
  abrirMenu: boolean;
};

const PortfolioVisualizar: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [showModalIncluirCategorias, setShowModalIncluirCategorias] = useState(false);
  const [fileDataURL, setFileDataURL] = useState<any>(null);
  const imageMimeType = /image\/(png|jpg|jpeg)/i;
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [destaquePortfolio, setDestaquePortfolio] = useState(false);
  const [arrayCategorias, setArrayCategorias] = useState<any>([]);
  const [fileArquivos, setFileArquivos] = useState<any>();
  const [descricaoArquivo, setDescricaoArquivo] = React.useState<any>();
  const [hiddenCategoria, setHiddenCategoria] = useState(false);
  const [stateCategoria, setStateCategoria] = useState<any>();
  const [stateCategoriaDescricao, setStateCategoriaDescricao] = useState<any>();
  const [stateListaImagens, setStateListaImagens] = useState<[]>([]);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregaCategoria();
  });

  // Busca as categorias cadastradas para a empresa
  async function carregaCategoria() {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      const resp: Response = await fetch(urlBase.url + "/portfolio",
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      setShowLoadingPage(true);

      await resp.json().then(async (res) => {

        if (resp.status === 400 || resp.status === 401) {
          setShowLoadingPage(false);
          history.replace("/login/");
        }

        if (res) {

          let lista = res.Items.sort((a: any, b: any) => a.destaque - b.destaque).reverse();

          //let lista = (res.Items.sort((a: any, b: any) => (a.descricao.toLowerCase() > b.descricao.toLowerCase()) ? 1 : -1));


          setArrayCategorias(lista);
        }

      }).finally(() => {
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  // Efetua o registro da imagem no sistema
  async function enviarArquivo(url: any, fileName: any, fileType: any) {

    let objRequest;
    objRequest = {
      "url": url,
      "nome": fileName,
      "tipo": fileType,
      "descricao": descricaoArquivo,
      "destaque": destaquePortfolio,
      "opcao": "categoria"
    };

    requestService(enviarArquivoPortfolio.url, {
      method: enviarArquivoPortfolio.method,
      headers: [
        ["token", window.localStorage.getItem("token") || ""]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response.message) {
          Toast.show({
            text: "Enviado com sucesso",
            position: "center",
            duration: "long"
          });

          //carregaGaleria(categoriaPortfolio);
          carregaCategoria();
          DismissModalIncluirCategorias();
        }
      },
      (error: any) => {
        console.log(error);
        Toast.show({
          text: error.message,
          position: "center",
          duration: "long"
        });
      })
  }

  // Efetua a exclusao da Categoria
  async function removerCategoria(categoria: any) {

    const remover = async () => {

      setShowLoadingPage(true);

      const resp: Response = await fetch(removerArquivoPortfolioCategoria.url.replace("{id}", categoria.replaceAll(/#/g, "%23")),
        {
          method: removerArquivoPortfolioCategoria.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });

      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          Toast.show({
            text: res.message,
            position: "center",
            duration: "long"
          });
        }
        else {
          if (res) {
            Toast.show({
              text: res.message,
              position: "center",
              duration: "long"
            });

            carregaCategoria();
          }
        }
      }).finally(() => {
        setShowLoadingPage(false);
      });
    }

    remover();
  }

  // Valida se tamanho do arquivo está dentro dos limites estabelecidos e efetua o processo de inclusão da categoria
  const handleUpload = async (file: any) => {

    if (file.size < 5000000) {
      try {

        await AssinaUploadS3Arquivo(file);

      } catch (error) {
        console.log("error", error);
        Toast.show({
          text: "Ocorreu um erro ao adicionar um arquivo, tente novamente.",
          position: "center",
          duration: "long"
        });
      }
    }
    else {
      Toast.show({
        text: "Somente arquivos até 5 MB.",
        position: "center",
        duration: "long"
      });
    }
  }

  // Método que assina url para envio do arquivo e efetua a inclusao da categoria 
  async function AssinaUploadS3Arquivo(file: any) {
    const atualizar = async () => {
      // Split the filename to get the name and type
      let fileParts = file.name.split('.');
      let fileNameArquivo = fileParts[0];
      let fileTypeArquivo = fileParts[1];

      let params = {
        nome: fileNameArquivo,
        tipo: fileTypeArquivo
      }

      try {
        setShowLoadingPage(true);

        //Assina URL
        const resp = await fetch(assinaUrl.url.concat("?pasta=portfolio"),
          {
            method: assinaUrl.method,
            headers: [
              ["token", localStorage.getItem("token") || ""]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        // Assinado com sucesso, insere arquivo no S3
        if (response.Item) {
          const uploadToS3 = await fetch(response.Item.urlAssinado, {
            method: "PUT",
            headers: {
              "Content-Type": fileTypeArquivo,
            },
            body: file,
          });

          // Inserido com sucesso no S3, registra categoria no sistema
          if (uploadToS3.status == 200) {
            await enviarArquivo(response.Item.key, response.Item.fileName, fileTypeArquivo);
          }
          else {
            Toast.show({
              text: "Ocorreu um erro ao fazer o upload do arquivo.",
              position: "center",
              duration: "long"
            });
          }
        }

        //setShowLoadingPage(false);

      } catch (error) {
        console.log("erro", error);
        //setShowLoadingPage(false);
      }
    }

    atualizar().finally(() => { setShowLoadingPage(false) });
  };

  // Método que efetua a alteração se e a categoria é destaque 
  async function alterarDestaque(categoria: any) {

    const consultar = async () => {

      setShowLoadingPage(true);

      const resp: Response = await fetch(alterarDetaquePortfolio.url.replace("{id}", categoria.replaceAll(/#/g, "%23")),
        {
          method: alterarDetaquePortfolio.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });

      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          history.replace("/login/");
        }

        if (res) {
          carregaCategoria();
        }
      }).finally(() => {
        setShowLoadingPage(false);
      });
    }

    consultar();

  }

  // Metodo chamado quando é selecionado um arquivo para subida
  function ChangeHandlerInputFile(e: any) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match(imageMimeType)) {
        alert("Tipo da imagem inválido");
        return;
      }
      setFileArquivos(file);
    }
    else {
      alert("Arquivo não selecionado");
      return;
    }
  }

  // Utilizado para exibir a imagem na tela quando o arquivo é selecionado
  useEffect(() => {
    let fileReader: FileReader, isCancel = false;
    if (fileArquivos) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const result = e.target;
        if (result && !isCancel) {
          setFileDataURL(result.result);
        }
      }
      fileReader.readAsDataURL(fileArquivos);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [fileArquivos]);

  // Utilizado no dismiss do modal de incluir categorias
  function DismissModalIncluirCategorias() {
    //carregaCategoria();
    setShowModalIncluirCategorias(false);
    setFileArquivos(null);
    setFileDataURL(null);
    setDescricaoArquivo('');
    setDestaquePortfolio(false);
  }

  // Chamado pelo botao cadastrar do modal de cadastro de categoria
  function CadastrarCategoria() {
    if (!fileArquivos || !descricaoArquivo || descricaoArquivo.trim() == "") {
      Toast.show({
        text: "Todos os campos são obrigatórios.",
        position: "center",
        duration: "long"
      });

      return null;
    }
    else {
      handleUpload(fileArquivos).catch((e) => { console.log("cancelado", e); });
    }
  }

  // Utilizado para carregas as imagens da tela referente a categoria selecionada na tela anterior
  async function servicoBuscaGaleria(sk: any) {
    const consultar = async () => {

      setShowLoadingPage(true);

      const resp: Response = await fetch(urlBase.url + "/portfolio/" + (sk).replaceAll(/#/g, "%23"),
        {
          method: urlBase.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {

        if (resp.status === 400 || resp.status === 401) {
          history.replace("/login/");
        }

        if (res) {
          setStateListaImagens(res.Items);
          //history.push("galeria", { categoria: btoa(categoria) });
        }

      }).finally(() => {
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  return (
    <IonPage className="Portfolio">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          {/* <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={images} />
            </IonButton>
          </IonButtons> */}
          <IonTitle>Visualizar Portfólio</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <div className="mobile" style={{ margin: 10 }} hidden={hiddenCategoria}>

          <div className="phone">
            <div className="phone-mirror">

              <div className="topWrapper" style={{ zIndex: 998 }}>
                <div className="camera"></div>
                <div className="line-rec"></div>
              </div>
              <div style={{ position: "absolute", height: "596px", overflow: "auto", marginTop: '2px' }}>

                <div style={{ padding: '5px', paddingTop: '20px' }}>
                  <IonGrid >
                    <IonRow>
                      {
                        arrayCategorias.map((value: any) => (
                          <IonCol size={value.destaque ? "12" : "6"} size-md={value.destaque ? "12" : "6"} size-lg={value.destaque ? "12" : "6"}>
                            <IonCard onClick={() => { setStateCategoria(btoa(value.sk)); setStateCategoriaDescricao(value.descricao); servicoBuscaGaleria(value.sk); setHiddenCategoria(!hiddenCategoria) }}
                              style={{ margin: 0, maxWidth: '1024px', height: value.destaque ? "350px" : "250px" }}>
                              <img style={{ objectFit: 'cover', width: '100%', height: value.destaque ? "350px" : "250px" }} src={value.urlFile}></img>
                              <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}>
                                <IonCardContent style={{ textAlign: 'end', height: value.destaque ? "350px" : "250px", width: '100%', display: 'flex', alignItems: 'end', paddingBottom: '50px', justifyContent: 'right', color: '#000000', fontFamily: 'Open Sans', fontSize: '20px', fontWeight: 'bold', textShadow: '1px 1px 10px #fff' }}>{value.descricao}</IonCardContent>
                              </div>
                            </IonCard>
                          </IonCol>
                        ))
                      }
                    </IonRow>
                  </IonGrid>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="mobile" style={{ margin: 10 }} hidden={!hiddenCategoria}>

          <div className="phone">
            <div className="phone-mirror">

              <div className="topWrapper" style={{ zIndex: 998 }}>
                <div className="camera"></div>
                <div className="line-rec"></div>
              </div>
              <IonButtons style={{ zIndex: 999, position: "absolute", paddingTop: 14 }} onClick={() => setHiddenCategoria(!hiddenCategoria)}>
                <IonIcon color='primary' slot="icon-only" icon={arrowBack} />
              </IonButtons>
              <div style={{ position: "absolute", height: "596px", overflow: "auto", padding: 10, marginTop: '2px' }}>

                <div style={{ padding: '5px', paddingTop: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '100px', paddingBottom: '70px' }}>
                    <IonLabel style={{ width: '80%', textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
                      {stateCategoriaDescricao || ""}
                    </IonLabel>
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ maxWidth: '1024px', width: '100%' }}>
                      {(!stateListaImagens || stateListaImagens.length == 0) ? (
                        <IonLabel style={{ fontSize: '18px', fontWeight: 'bold', display: 'flex', width: '100%', justifyContent: 'center' }}>
                          Ainda não há imagens cadastradas.
                        </IonLabel>
                      ) : (
                        <IonLabel style={{ fontSize: '18px', fontWeight: 'bold' }}>
                          {stateListaImagens.length}{stateListaImagens.length < 2 ? ' imagem' : ' imagens'}
                        </IonLabel>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '50px' }}>
                    <div id='container' style={{ maxWidth: '1024px' }}>
                      <GridImagens lista={stateListaImagens} history={history} excluirFoto={null} inibirExcluir={true}></GridImagens>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />
      </IonContent>
    </IonPage>
  );
};

export default PortfolioVisualizar;