import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonReorder, IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import * as icons from 'ionicons/icons';
import { add, arrowBack, checkmark, close, pencil, reorderTwo, trash } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { alterarStatusEmpresa, empresaCarregar, inserirStatusEmpresa, ordernarStatusEmpresa, removerStatusEmpresa, requestService } from '../../../../../Utils/Services';
import AppTypeaheadIcon from '../../../../../components/AppTypeaheadIcon';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./EmpresaWorkflowCadastro.css";

type IconType = typeof import('ionicons/icons/index');

type Props = {
  abrirMenu: boolean;
  onDismiss: () => void;
};

export interface Opcao {
  descricao: string;
}

const EmpresaWorkflowCadastro: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [configWorkflow, setConfigWorkflow] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const [showDetalhesIcones, setShowDetalhesIcones] = useState(false);
  const [indice, setIndice] = useState("");
  const [status, setStatus] = useState("");
  const [icone, setIcone] = useState("");
  const [exibirInformacoes, setExibirInformacoes] = useState("");
  const [exibirFotos, setExibirFotos] = useState("");
  const [exibirArquivos, setExibirArquivos] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [isDisabled, setIsDisabled] = useState(true);


  const [listaIcones, setListaIcones] = useState<any[]>([...Object.keys(icons)]);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [mostartAlerta] = useIonAlert();

  const inputRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarConta();
  });


  function carregarConta() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setConfigWorkflow(response.Item.configWorkflow || []);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }



  function deletarStatus(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delStatus = async () => {
      const resp = await fetch(removerStatusEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerStatusEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Item removido com sucesso.");
        setShowToast(true);
        setConfigWorkflow(retorno.Item?.Attributes?.configWorkflow || []);
        window.localStorage.setItem("configWorkflow", JSON.stringify(retorno.Item?.Attributes?.configWorkflow));
      }
      else {
        setTextToast("Ocorreu um erro ao efetuar a exclusão.");
        setShowToast(true);
      }
    }

    delStatus();

  }


  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    // console.log('Before complete', configWorkflow);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setConfigWorkflow(event.detail.complete(configWorkflow));

    // After complete is called the items will be in the new order
    // console.log('After complete', configWorkflow);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordStatus = async () => {
      const resp = await fetch(ordernarStatusEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: ordernarStatusEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'configWorkflow': configWorkflow }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setConfigWorkflow(retorno.Item?.Attributes?.configWorkflow || []);
        window.localStorage.setItem("configWorkflow", JSON.stringify(retorno.Item?.Attributes?.configWorkflow));
      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordStatus();

  }

  const loadStatus = () => {

    if (configWorkflow == undefined) return;

    if (configWorkflow.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }



    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {configWorkflow.map((value: any, index: any) => (
            <IonItem lines="none" key={"config" + index}>

              <IonLabel style={{ fontSize: "12px" }}>{value.status}</IonLabel>
              <IonLabel><IonIcon style={{ margin: "0px", padding: "0px", fontSize: "20px", "color": "#333333" }} icon={icons[value.icone as keyof IconType]} ></IonIcon></IonLabel>
              <IonLabel>
                <IonButton style={{ margin: "0px", padding: "0px" }} fill="clear" onClick={() => {
                  if (value.exibirInformacoes == "true") {
                    setTextToast("Sim");
                  }
                  else {
                    setTextToast("Não");
                  }
                  setShowToast(true);
                }}>
                  <IonIcon color="primary" icon={value.exibirInformacoes == "true" ? checkmark : close}></IonIcon>
                </IonButton>

              </IonLabel>

              <IonLabel>
                <IonButton style={{ margin: "0px", padding: "0px" }} fill="clear" onClick={() => {
                  if (value.exibirFotos == "true") {
                    setTextToast("Sim");
                  }
                  else {
                    setTextToast("Não");
                  }
                  setShowToast(true);
                }}>
                  <IonIcon style={{ margin: "0px", padding: "0px", fontSize: "20px", "color": "#333333" }} color="primary" icon={value.exibirFotos == "true" ? checkmark : close}></IonIcon>
                </IonButton>

              </IonLabel>

              <IonLabel>
                <IonButton style={{ margin: "0px", padding: "0px" }} fill="clear" onClick={() => {
                  if (value.exibirArquivos == "true") {
                    setTextToast("Sim");
                  }
                  else {
                    setTextToast("Não");
                  }
                  setShowToast(true);
                }}>
                  <IonIcon color="primary" icon={value.exibirArquivos == "true" ? checkmark : close}></IonIcon>
                </IonButton>

              </IonLabel>
              <IonLabel >  </IonLabel>


              <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonReorder slot="end"></IonReorder>
              </div>
              <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonButtons >





                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      setIndice(value.indice);
                      setStatus(value.status);
                      setIcone(value.icone);
                      setExibirInformacoes(value.exibirInformacoes);
                      setExibirFotos(value.exibirFotos);
                      setExibirArquivos(value.exibirArquivos);
                      setShowDetalhes(true);

                    }}>
                    <IonIcon color="primary" icon={pencil} />
                  </IonButton>

                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      mostartAlerta({
                        backdropDismiss: false,
                        header: 'Remover item',
                        message: 'Deseja <strong>remover</strong>?',
                        buttons: [
                          {
                            text: 'Cancelar',
                            role: 'cancel',
                          },
                          {
                            text: 'Confirmar',
                            cssClass: 'primary',
                            handler: () => {
                              deletarStatus(value.indice);
                            }
                          }
                        ],
                        onDidDismiss: (e) => console.log('did dismiss'),
                      })
                    }}>
                    <IonIcon color="primary" icon={trash} />
                  </IonButton>

                </IonButtons>

              </div>
            </IonItem>

          ))}
        </IonReorderGroup>
      </>
    );
  }

  function cadastrar() {
    if (!status || status.trim() == ""
      || !icone || icone.trim() == ""
      || !exibirInformacoes || exibirInformacoes.trim() == ""
      || !exibirFotos || exibirFotos.trim() == ""
      || !exibirArquivos || exibirArquivos.trim() == "") {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
      return false;
    }
    else {



      console.log("indice", indice);
      if (indice != "") {
        const objectRequest = {
          status: status,
          icone: icone,
          exibirInformacoes: exibirInformacoes,
          exibirFotos: exibirFotos,
          exibirArquivos: exibirArquivos,
          indice: indice
        }
        console.log("objectRequest", objectRequest);

        let id = loja;

        console.log("id", id);

        requestService(
          alterarStatusEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
          {
            method: alterarStatusEmpresa.method,
            body: JSON.stringify(objectRequest),
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
          history,
          (response: any) => {
            console.log(response);
            if (response) {
              setTextToast("Alteração realizada com sucesso.");
              setShowToast(true);

              setConfigWorkflow(response.message.Attributes.configWorkflow || []);
              window.localStorage.setItem("configWorkflow", JSON.stringify(response.message.Attributes.configWorkflow));
              setShowDetalhes(false);

            } else if (response.status === 400) {
              console.log(response);
              setTextToast("Ocorreu um erro ao efetuar a alteração.");
              setShowToast(true);

            }
            setShowLoading(false);
          },
          (error: any) => {
            console.log(error);
            setTextToast("Ocorreu um erro ao efetuar a alteração.");
            setShowToast(true);
            setShowLoading(false);
          })
      } else {
        const objectRequest = {
          status: status,
          icone: icone,
          exibirInformacoes: exibirInformacoes,
          exibirFotos: exibirFotos,
          exibirArquivos: exibirArquivos
        }
        console.log("objectRequest", objectRequest);

        let id = loja;

        console.log("id", id);

        requestService(
          inserirStatusEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
          {
            method: inserirStatusEmpresa.method,
            body: JSON.stringify(objectRequest),
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
          history,
          (response: any) => {
            console.log(response);
            if (response) {
              setTextToast("Cadastro realizado com sucesso.");
              setShowToast(true);

              setConfigWorkflow(response.message.Attributes.configWorkflow || []);
              window.localStorage.setItem("configWorkflow", JSON.stringify(response.message.Attributes.configWorkflow));
              setShowDetalhes(false);

            } else if (response.status === 400) {
              console.log(response);
              setTextToast("Ocorreu um erro ao efetuar o cadastro.");
              setShowToast(true);

            }
            setShowLoading(false);
          },
          (error: any) => {
            console.log(error);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);
            setShowLoading(false);
          })
      }
    }

  }

  const filtroSelectionChanged = (filtroProjetos: string) => {

    if (filtroProjetos != "") {
      setIcone(filtroProjetos);
    }
    else {
      setIcone("");
    }
    setShowDetalhesIcones(false);
  };

  return (

    <IonPage className="EmpresaWorkflowCadastro">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Configurações {window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo"}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setIndice("");
            setStatus("");
            setIcone("");
            setExibirInformacoes("");
            setExibirFotos("");
            setExibirArquivos("");
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>



        <IonList>
          <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>

            <IonLabel style={{ fontSize: "12px" }}>Status</IonLabel>
            <IonLabel style={{ fontSize: "12px" }}>Ícone</IonLabel>
            <IonLabel style={{ fontSize: "12px" }}>Informações</IonLabel>
            <IonLabel style={{ fontSize: "12px" }}>Fotos</IonLabel>
            <IonLabel style={{ fontSize: "12px" }}>Arquivos</IonLabel>
            <IonLabel style={{ textAlign: "right" }}>
              <IonBadge hidden={acessos?.configuracoes != 'edicao'}
                onClick={() => setIsDisabled((current) => !current)}>
                <IonIcon color="medium" icon={reorderTwo}></IonIcon>
              </IonBadge>
            </IonLabel>
          </IonItem>
          {loadStatus()}





          {/* <IonFab horizontal="end" edge style={{ "top": "0px", "right": "-4px" }} hidden={acessos?.configuracoes != 'edicao'}>
            <IonFabButton size="small" color="tertiary" 
              <IonIcon color="light" icon={menu}></IonIcon>
            </IonFabButton>
          </IonFab> */}
        </IonList>


        {/* <IonButton slot="end" >Ordenar</IonButton> */}



        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Novo item</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList>

                      <IonItem lines="none">
                        <IonLabel>Status*</IonLabel>
                        <IonInput placeholder="Digite o status" value={status} ref={(ref) => inputRef.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setStatus(e.target.value)}></IonInput>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>Ícone*</IonLabel>



                        {/* <IonInput placeholder="Digite o status" value={icone} required onIonInput={(e: any) => setIcone(e.target.value)}></IonInput> */}
                        <IonIcon icon={icons[icone as keyof IconType]} ></IonIcon>
                        <IonButton slot="end" onClick={() => setShowDetalhesIcones(true)} expand="block" type="submit">Selecionar</IonButton>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>Exibir Informações*</IonLabel>
                        <IonSelect slot="end" value={exibirInformacoes} placeholder="Selecione" onIonChange={e => setExibirInformacoes(e.detail.value)}>
                          <IonSelectOption slot="start" value="true">Sim</IonSelectOption>
                          <IonSelectOption slot="start" value="false">Não</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>Exibir Fotos*</IonLabel>
                        <IonSelect slot="end" value={exibirFotos} placeholder="Selecione" onIonChange={e => setExibirFotos(e.detail.value)}>
                          <IonSelectOption slot="start" value="true">Sim</IonSelectOption>
                          <IonSelectOption slot="start" value="false">Não</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>Exibir Arquivos*</IonLabel>
                        <IonSelect slot="end" value={exibirArquivos} placeholder="Selecione" onIonChange={e => setExibirArquivos(e.detail.value)}>
                          <IonSelectOption slot="start" value="true">Sim</IonSelectOption>
                          <IonSelectOption slot="start" value="false">Não</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>
            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>






        <IonModal isOpen={showDetalhesIcones} onDidDismiss={() => setShowDetalhesIcones(false)} >
          <AppTypeaheadIcon
            title="Ícones"
            items={listaIcones}
            selectedItem={icone}
            onSelectionCancel={() => setShowDetalhesIcones(false)}
            onSelectionChange={filtroSelectionChanged}
          />
        </IonModal>



        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default EmpresaWorkflowCadastro;