import { Toast } from '@capacitor/toast';
import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonFab, IonFabButton, IonFooter, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import imageCompression from 'browser-image-compression';
import { add, arrowBack, close } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { assinaUrl, enviarArquivoPortfolio, removerArquivoPortfolioGaleria, requestService, urlBase } from '../../../../../Utils/Services';
import './Galeria.css';
import GridImagens from './GridImagens';
import imgDefaultImage from './imgs/defaultImage.png';


type Props = {
  abrirMenu: boolean;
};

const Galeria: React.FC<Props> = (props: Props) => {

  const location = useLocation();
  const history = useHistory();
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [stateListaImagens, setStateListaImagens] = useState<[]>([]);
  const imageMimeType = /image\/(png|jpg|jpeg)/i;
  const [fileDataURL, setFileDataURL] = useState<any>(null);
  const [fileArquivos, setFileArquivos] = useState<any>();
  const [descricaoArquivo, setDescricaoArquivo] = React.useState<any>();
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "");
  const [categoriaPortfolio, setCategoriaPortfolio] = useState<any>();
  const [showModalIncluirFotos, setShowModalIncluirFotos] = useState(false);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const descricaoInputRef = useRef<any>(null);

  useEffect(() => {
    servicoBuscaGaleria();
    setCategoriaPortfolio((location.state ? atob((location.state as { categoria: string, descricao: string }).categoria) : ""));
  }, []);

  // Utilizado para carregas as imagens da tela referente a categoria selecionada na tela anterior
  async function servicoBuscaGaleria() {
    const consultar = async () => {

      setShowLoadingPage(true);

      const resp: Response = await fetch(urlBase.url + "/portfolio/" + (location.state ? atob((location.state as { categoria: string, descricao: string }).categoria) : "").replaceAll(/#/g, "%23"),
        {
          method: urlBase.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {

        if (resp.status === 400 || resp.status === 401) {
          history.replace("/login/");
        }

        if (res) {
          setStateListaImagens(res.Items);
          //history.push("galeria", { categoria: btoa(categoria) });
        }

      }).finally(() => {
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  // Utilizado no dismiss do modal de incluir fotos
  function DismissModalIncluirFotos() {
    //carregaCategoria();
    setShowModalIncluirFotos(false);
    setFileArquivos(null);
    setFileDataURL(null);
    setDescricaoArquivo('');
  }

  // Metodo chamado quando é selecionado um arquivo para subida
  async function ChangeHandlerInputFile(e: any) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match(imageMimeType)) {
        alert("Tipo da imagem inválido");
        return;
      }
      //setFileArquivos(file);
      setFileArquivos(await ReduzirFoto(file));
    }
    else {
      alert("Arquivo não selecionado");
      return;
    }
  }

  // Metodo para reduzir tamanho de imagens
  async function ReduzirFoto(file: File) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
      initialQuality: 1
    }

    return await imageCompression(file, options);
  }

  // Utilizado para exibir a imagem na tela quando o arquivo é selecionado
  useEffect(() => {
    let fileReader: FileReader, isCancel = false;
    if (fileArquivos) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const result = e.target;
        if (result && !isCancel) {
          setFileDataURL(result.result);
        }
      }
      fileReader.readAsDataURL(fileArquivos);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [fileArquivos]);

  // Chamado pelo botao cadastrar do modal de cadastro de imagem
  function CadastrarImagem() {
    if (!fileArquivos || !descricaoArquivo || descricaoArquivo.trim() == "") {
      Toast.show({
        text: "Todos os campos são obrigatórios.",
        position: "center",
        duration: "long"
      });

      return null;
    }
    else {
      handleUpload(fileArquivos).catch((e) => { console.log("cancelado", e); });
    }
  }

  // Valida se tamanho do arquivo está dentro dos limites estabelecidos e efetua o processo de inclusão da imagem
  const handleUpload = async (file: any) => {

    if (file.size < 5000000) {
      try {

        await AssinaUploadS3Arquivo(file);

      } catch (error) {
        console.log("error", error);
        Toast.show({
          text: "Ocorreu um erro ao adicionar um arquivo, tente novamente.",
          position: "center",
          duration: "long"
        });
      }
    }
    else {
      Toast.show({
        text: "Somente arquivos até 5 MB.",
        position: "center",
        duration: "long"
      });
    }
  }

  // Método que assina url para envio do arquivo e efetua a inclusao da categoria 
  async function AssinaUploadS3Arquivo(file: any) {
    const atualizar = async () => {
      // Split the filename to get the name and type
      let fileParts = file.name.split('.');
      let fileNameArquivo = fileParts[0];
      let fileTypeArquivo = fileParts[1];

      let params = {
        nome: fileNameArquivo,
        tipo: fileTypeArquivo
      }

      try {
        setShowLoadingPage(true);

        //Assina URL
        const resp = await fetch(assinaUrl.url.concat("?pasta=portfolio"),
          {
            method: assinaUrl.method,
            headers: [
              ["token", localStorage.getItem("token") || ""]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        // Assinado com sucesso, insere arquivo no S3
        if (response.Item) {
          const uploadToS3 = await fetch(response.Item.urlAssinado, {
            method: "PUT",
            headers: {
              "Content-Type": fileTypeArquivo,
            },
            body: file,
          });

          // Inserido com sucesso no S3, registra categoria no sistema
          if (uploadToS3.status == 200) {
            await enviarArquivo(response.Item.key, response.Item.fileName, fileTypeArquivo);
          }
          else {
            Toast.show({
              text: "Ocorreu um erro ao fazer o upload do arquivo.",
              position: "center",
              duration: "long"
            });
          }
        }
      } catch (error) {
        console.log("erro", error);
      }
    }
    atualizar().finally(() => { setShowLoadingPage(false) });
  };

  // Efetua o registro da imagem no sistema
  async function enviarArquivo(url: any, fileName: any, fileType: any) {

    let objRequest;
    objRequest = {
      "empresaid": lojas,
      "url": url,
      "nome": fileName,
      "tipo": fileType,
      "descricao": descricaoArquivo,
      "categoria": categoriaPortfolio,
      "opcao": "galeria"
    };

    requestService(enviarArquivoPortfolio.url, {
      method: enviarArquivoPortfolio.method,
      headers: [
        ["token", window.localStorage.getItem("token") || ""]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response.message) {
          Toast.show({
            text: "Enviado com sucesso",
            position: "center",
            duration: "long"
          });

          //carregaGaleria(categoriaPortfolio);
          servicoBuscaGaleria();
          DismissModalIncluirFotos();
        }
      },
      (error: any) => {
        console.log(error);
        Toast.show({
          text: error.message,
          position: "center",
          duration: "long"
        });
      })
  }

  // Servico que efetua a exclusao da imagem na galeria
  async function removerFotoGaleria(galeria: any) {
    const remover = async () => {

      setShowLoadingPage(true);

      const resp: Response = await fetch(removerArquivoPortfolioGaleria.url.replace("{id}", galeria.replaceAll(/#/g, "%23")),
        {
          method: removerArquivoPortfolioGaleria.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {

        if (resp.status === 400 || resp.status === 401) {
          Toast.show({
            text: res.message,
            position: "center",
            duration: "long"
          });
        }
        else {
          if (res) {
            Toast.show({
              text: res.message,
              position: "center",
              duration: "long"
            });
            servicoBuscaGaleria();
          }
        }
      }).finally(() => {
        setShowLoadingPage(false);
      });
    }

    remover();

  }

  return (
    <IonPage>
      <div id="galeriaFab">
        <IonFab slot="fixed" vertical="top" horizontal="start" style={{ paddingTop: '40px' }}><IonFabButton size='small' translucent={true} onClick={() => history.goBack()}><IonIcon color='primary' icon={arrowBack}></IonIcon></IonFabButton></IonFab>
        <IonFab slot="fixed" vertical="bottom" horizontal="center" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button"
            onClick={() => setShowModalIncluirFotos(true)}>
            <IonIcon color='light' icon={add} />
          </IonFabButton>
        </IonFab>
      </div>

      <IonContent style={{ background: '#eeeeee' }} fullscreen class='ion-padding'>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '100px', paddingBottom: '70px' }}>
          <IonLabel style={{ width: '80%', textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
            {location.state ? (location.state as { categoria: string, descricao: string }).descricao : ""}
          </IonLabel>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ maxWidth: '1024px', width: '100%' }}>
            {(!stateListaImagens || stateListaImagens.length == 0) ? (
              <IonLabel style={{ fontSize: '18px', fontWeight: 'bold', display: 'flex', width: '100%', justifyContent: 'center' }}>
                Ainda não há imagens cadastradas.
              </IonLabel>
            ) : (
              <IonLabel style={{ fontSize: '18px', fontWeight: 'bold' }}>
                {stateListaImagens.length}{stateListaImagens.length < 2 ? ' imagem' : ' imagens'}
              </IonLabel>
            )}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', paddingBottom: '50px' }}>
          <div id='container' style={{ width: '100%', maxWidth: '1024px' }}>
            <GridImagens lista={stateListaImagens} history={history} excluirFoto={removerFotoGaleria} inibirExcluir={acessos?.configuracoes != 'edicao'}></GridImagens>
          </div>
        </div>
      </IonContent>

      {/*MODAL PARA CADASTRO DE FOTOS*/}
      <IonModal onDidPresent={() => {
        if (descricaoInputRef.current) {
          descricaoInputRef.current.setFocus();
        }
      }} isOpen={showModalIncluirFotos} onDidDismiss={() => { DismissModalIncluirFotos() }}>
        <IonContent>
          <IonToolbar style={{ paddingTop: '40px', height: "90px" }} >
            <IonButtons slot="start">
              <IonButton onClick={() => { DismissModalIncluirFotos() }}>
                <IonIcon color='primary' slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary">Inclusão de Imagem</IonTitle>
          </IonToolbar>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
            <IonCard style={{ maxWidth: '350px', width: '100%', minHeight: '100px' }}>
              <IonCardContent>
                <IonItem lines='full'>
                  <IonLabel position='stacked'>Descrição</IonLabel>
                  <IonInput value={descricaoArquivo} ref={(ref) => descricaoInputRef.current = ref} maxlength={255} required autoCorrect='true' autoCapitalize='true' onIonInput={(e: any) => setDescricaoArquivo(e.target.value)} placeholder='Descrição da imagem'></IonInput>
                </IonItem>
                <IonItem lines='none'>
                  <input onChange={ChangeHandlerInputFile} type="file" accept="image/*" />
                </IonItem>
                <img style={{ objectFit: 'cover', height: '250px', width: '100%', borderRadius: '15px' }} src={fileDataURL || imgDefaultImage}></img>
              </IonCardContent>
            </IonCard>
          </div>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton expand='block' onClick={() => CadastrarImagem()}>Cadastrar</IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoadingPage}
        onDidDismiss={() => { setShowLoadingPage(false); }}
        spinner='circles'
      />
    </IonPage>
  );

};

export default Galeria;
