import { IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonModal, IonPage, IonPopover, IonSelect, IonSelectOption, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { Buffer } from 'buffer';
import { chatbubbles, closeOutline, ellipsisVertical, folderOutline, notifications } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { urlBase } from '../../../../../Utils/Services';
import Notificacoes from '../Notificacoes/Notificacoes';
import './Caixa.css';


type Props = {
  abrirMenu: boolean;
};

const Caixa: React.FC<Props> = (props: Props) => {
  const btnref = useRef<HTMLIonButtonElement>(null);
  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [cliente, setCliente] = useState<any>();
  const [listaCliente, setListaCliente] = useState<any>([]);
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const { loja, setLoja } = useContext(MessageContext);
  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);
  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const [showModalResponsaveis, setShowModalResponsaveis] = useState(false);
  const [responsaveis, setResponsaveis] = useState<any>([]);
  const [selectedResponsaveis, setSelectedResponsaveis] = useState<any>([]);
  const [selectedResponsaveisText, setSelectedResponsaveisText] = useState<string>('0 Responsáveis');
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);


  const [showModalCliente, setShowModalCliente] = useState(false);
  // const [clienteText, setClienteText] = useState<any>("");

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);


  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };



  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("Caixa");
    setCliente("");
    setListaCliente([]);
    // setClienteText("Selecione a " + (window.localStorage.getItem("MesaOuComandaOuCaixa") || "Mesa"));
    carregarProjetosAbertosInternos();
  })



  async function carregarProjetosAbertosInternos() {
    const consultar = async () => {
      setShowLoadingPage(true);

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let urlFinal = urlBase.url + "/projetos/listacomandas?campo=data" +
        "&filtro=Ativo" +
        "&lojas=" + Buffer.from((loja).replaceAll(/#/g, '%23')).toString('base64');

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      let res = await resp.json();



      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }

      console.log("setListaCliente", res);

      if (res && Array.isArray(res) && res.length > 0) {
        let lista = (res.sort((a: any, b: any) => (a.clienteNome.toLowerCase() > b.clienteNome.toLowerCase()) ? 1 : -1));
        setListaCliente(lista);
      }

      setShowLoadingPage(false);

    };
    return await consultar();
  }

  // const consultarCliente = async () => {

  //   let token: string = "";
  //   const tkn = localStorage.getItem("token");
  //   if (typeof (tkn) === "string") {
  //     token = tkn;
  //   }

  //   let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

  //   const resp = await fetch(montarUrl.replace("{perfil}", "comanda"),
  //     {
  //       method: "GET",
  //       headers: [
  //         ["token", token]
  //       ],
  //       credentials: 'include'
  //     });
  //   let equipe = await resp.json();
  //   if (resp.status === 400 || resp.status === 401) {
  //     history.replace("/login/");
  //   }
  //   else {
  //     if (equipe.Items) {
  //       let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
  //       setListaCliente(lista);
  //     }
  //   }
  // }

  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("");
  });


  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }


  function validarCamposComanda() {

    if (cliente.length == 0) {
      setTextToast("Todos os campos com * são obrigatórios.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  function validarCampos() {

    if (cliente.length == 0) {
      setTextToast("Selecione a " + (window.localStorage.getItem("MesaOuComandaOuCaixa") || "Mesa"));
      setShowToast(true);
      return false;
    }
    return true;
  }



  const formatData = (data: string[]) => {
    if (data.length === 1) {
      const responsavel = responsaveis.find((convidado: any) => convidado.pk === data[0])!;
      return responsavel.nome;
    }

    return `${data.length} Responsáveis`;
  };

  const responsavelSelectionChanged = (responsaveis: any[]) => {

    if (responsaveis.length > 0) {
      setSelectedResponsaveis(responsaveis);
      setSelectedResponsaveisText(formatData(responsaveis));
    }
    else {
      setSelectedResponsaveis([]);
      setSelectedResponsaveisText("0 Responsáveis");
    }
    setShowModalResponsaveis(false);
  };

  // const clienteSelectionChanged = (pkClienteSelecionado: string) => {
  //   console.log("pkClienteSelecionado", pkClienteSelecionado);

  //   const selecionado = listaCliente.find((cliente: any) => cliente.pk === pkClienteSelecionado);

  //   console.log("clienteSelecionado", selecionado);

  //   if (selecionado) {
  //     setCliente(selecionado.pk);
  //     setClienteText(selecionado.nome);
  //   }
  //   setShowModalCliente(false);
  // };


  function readProjetosAbertos() {
    return listaCliente.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"cliente" + index} value={value.clienteid}>{value.clienteNome}</IonSelectOption>
    )
    )
  };

  return (
    <IonPage className="Caixa">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          {/* <IonButtons slot="start">
           
              {
                ((!isPlatform('mobileweb') && isPlatform('mobile'))) ?
                  (
                    < IonButton onClick={() => {
                      history.goBack();
                    }}>
                      <IonIcon slot="icon-only" icon={arrowBack} />
                    </IonButton>
                  ) :
                  (
                    <IonButtons slot="start">
                      <IonMenuButton></IonMenuButton>
                    </IonButtons>
                  )
              }
            </IonButtons> */}
          {/* <IonTitle hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))}>Caixa</IonTitle> */}

          <IonButtons slot="end">
            <IonButton onClick={() => { setShowNotificacoes(true); }}>
              <IonIcon slot="icon-only" icon={notifications} />
              <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
            <IonButton onClick={() => { history.push("./chat"); }}>
              <IonIcon slot="icon-only" icon={chatbubbles} />
              <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>


              <IonItem lines="none" button hidden={acessos?.caixa != 'leitura' && acessos?.caixa != 'edicao'}
                onClick={() => {
                  history.push("comandas/comandashistorico"); closePopover();
                }}>
                <IonIcon icon={folderOutline} slot="start" />
                <IonLabel>Histórico</IonLabel>
              </IonItem>

              <IonItem lines="none" button onClick={closePopover}>
                <IonIcon icon={closeOutline} slot="start" />
                <IonLabel>Cancelar</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>

        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <div className="button-container">

          <IonItem lines="none" className="cliente-item" >
            <IonSelect slot="end" value={cliente} placeholder="Selecione" onIonChange={e => { setCliente(e.detail.value); }}>
              {readProjetosAbertos()}
            </IonSelect>
          </IonItem>

          {/* <IonItem slot="end" lines="none" button={true} detail={false} className="cliente-item" onClick={e => { setShowModalCliente(true); }}>
              {clienteText}
            </IonItem>
            <IonModal isOpen={showModalCliente} onDidDismiss={() => setShowModalCliente(false)} >
              <AppTypeaheadItem
                title="Comandas"
                camposFiltro="nome,telefone"
                campo="nome"
                items={listaCliente}
                autoFocus={true}
                selectedItem={cliente}
                onSelectionCancel={() => setShowModalCliente(false)}
                onSelectionChange={clienteSelectionChanged}
              />
            </IonModal> */}

          <IonButton
            disabled={acessos?.caixa != 'edicao'}
            expand="block"
            size="large"
            color="primary"
            className="custom-button"
            onClick={() => {
              console.log("cliente", cliente);
              if (validarCampos()) {

                history.push("caixa/caixafechar", { clienteid: cliente.replaceAll(/#/g, '%23') });
              }
            }}>

            FECHAR
          </IonButton>

        </div>

        <div className="content">
          <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
            <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
          </IonModal>
        </div>


        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />


        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />
      </IonContent>
    </IonPage>
  );
};

export default Caixa;