// import '@capacitor-community/http';
import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack, notifications } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './MenuCampanha.css';

type Props = {
    abrirMenu: boolean;
}

const MenuCampanha: React.FC<Props> = (props: Props) => {

    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [textToast, setTextToast] = useState("");
    const [showToast2, setShowToast2] = useState(false);
    const [showPerfil, setShowPerfil] = useState(false);

    const [perfil, setPerfil] = useState<any>("clientes");
    const { loja, setLoja } = useContext(MessageContext);

    const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
    const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

    useIonViewDidEnter(() => {
        setEsconderMenu(true);
    setNomeMenu("");
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=''></IonBackButton>
                    </IonButtons>
                    {/* <IonButtons slot="start">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={notifications} />
                        </IonButton>
                    </IonButtons> */}
                    <IonTitle slot="start">Campanha</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent className="conta">

                <div hidden={acessos?.campanha != 'leitura' && acessos?.campanha != 'edicao'}>
                    {/* <IonListHeader lines="full" >Campanha</IonListHeader> */}

                    <IonItem lines="none" button onClick={() => {
                        console.log("notificacoes")
                        setShowPerfil(true);
                    }} detail >
                        <IonIcon color='medium' slot="start" icon={notifications} />
                        <IonLabel>Enviar Notificações</IonLabel>
                    </IonItem>
                </div>

                <div className="content">
                    <IonModal isOpen={showPerfil} onDidDismiss={() => setShowPerfil(false)} >
                        <IonHeader>
                            <IonToolbar color="primary">
                                <IonTitle>Enviar Notificações</IonTitle>
                                <IonButtons slot="start" onClick={() => setShowPerfil(false)}>
                                    <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent class="ion-padding">
                            <IonList lines="full" class="ion-no-margin">
                                <IonListHeader lines="full">
                                    <IonLabel>
                                        Qual perfil você deseja enviar notificações?
                                    </IonLabel>
                                </IonListHeader>
                                <IonItem lines="none" >
                                    <IonLabel>Perfil</IonLabel>
                                    <IonSelect name='Perfil' value={perfil} multiple={false} placeholder="Selecione" cancelText="Cancel" okText="Ok" onIonChange={e => {
                                        setPerfil(e.detail.value);
                                    }} slot="end">

                                        <IonSelectOption value="clientes">Clientes</IonSelectOption>
                                        <IonSelectOption value="arquitetos">Arquitetos</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonList>


                        </IonContent>
                        <IonFooter className="ion-no-border" >
                            <IonToolbar>
                                <IonButton expand="block" size='large' color="primary" onClick={() => {


                                    if (perfil) {
                                        if (perfil == "clientes") {
                                            console.log("1", perfil);
                                            history.push("/empresa/enviarnotificacaoclientes");
                                        }
                                        else {
                                            console.log("2", perfil);
                                            history.push("/empresa/enviarnotificacaoarquitetos");
                                        }
                                        setShowPerfil(false);
                                    }
                                    else {
                                        setTextToast("Precisa selecionar um perfil para enviar notificações.");
                                        setShowToast(true);

                                    }
                                }}>Avançar</IonButton>
                            </IonToolbar>
                        </IonFooter>
                    </IonModal>
                </div>

                <IonToast
                    isOpen={showToast}
                    position="middle" //top, bottom, middle
                    onDidDismiss={() => setShowToast(false)}
                    message={textToast}
                    duration={3000}
                />
            </IonContent>

        </IonPage>
    );
};

export default MenuCampanha;
