import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonPopover, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { addCircle, alarmOutline, appsOutline, arrowBack, cardOutline, checkmarkCircle, closeOutline, constructOutline, cubeOutline, ellipsisVertical, informationCircleOutline, locationOutline, navigateOutline, notificationsOutline, personOutline, phonePortraitOutline, powerOutline, swapHorizontalOutline, trash } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { agendamentoRegistrarAnotacao, finalizarAgendamento, removerAnotacaoAgendamento, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./AgendamentosDetalhes.css";
import maps from './imgs/maps.png';
import waze from './imgs/waze.png';

type Props = {
  abrirMenu: boolean;
  agendamentoid: string;
  esconderProjeto?: boolean;
  onDismiss: () => void;
};

const AgendamentosDetalhes: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();


  const [idAgendamento, setIdAgendamento] = useState("");
  const [dadosAgendamento, setDadosAgendamento] = useState<any>([]);
  const [temHoraFim, setTemHoraFim] = useState(false);
  // const [showActionSheet, setShowActionSheet] = useState(false);
  const [alertConfirmar, setAlertConfirmar] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const { recarregarAgendamento, setRecarregarAgendamento } = useContext(MessageContext);
  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);


  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };


  useEffect(() => {

    console.log("idAgendamento", idAgendamento);

    if (idAgendamento != "") {
      getAgendamento();
    }
  }, [idAgendamento]);



  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    if (params.agendamentoid) {
      setIdAgendamento(params.agendamentoid);
    }

  });

  let params: any = {
    agendamentoid: "",
    esconderProjeto: false,
  }
  params = useParams();

  if (location && location.state && location.state.agendamentoid) {
    params.agendamentoid = location.state.agendamentoid;
    params.esconderProjeto = location.state.esconderProjeto;
  }


  if (props.agendamentoid) {
    params = {
      agendamentoid: props.agendamentoid,
      esconderProjeto: props.esconderProjeto,
    }
  }

  const getAgendamento = async () => {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("agendamentoid", params.agendamentoid);

    setShowLoadingPage(true);

    const resp = await fetch(urlBase.url + "/agendamentos/" + params.agendamentoid.replaceAll(/#/g, '%23'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const operacao = await resp.json();
    //if (resp.status === 400 || resp.status === 401) history.replace("/login/");
    if (operacao.Item !== undefined) {

      setDadosAgendamento(operacao.Item);
      setTemHoraFim(operacao.Item.dataEncerramento ? true : false);
    }
    setShowLoadingPage(false);
  }

  function encerrar2() {
    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    return fetch(finalizarAgendamento.url.replace("{id}", dadosAgendamento.pk.replaceAll(/#/g, "%23")),
      {
        method: finalizarAgendamento.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });
  }


  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  function formatarData(data: Date) {
    return (adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + "/" + data.getFullYear().toString().substr(2));
  }

  // const loadConvidados = () => {

  //   if ((!dadosAgendamento.convidados) || dadosAgendamento.convidados?.length == 0) {
  //     return (
  //       <IonItem lines="none">

  //         <IonLabel className="ion-text-wrap">
  //           <h3>Não existe nenhum convidado</h3>
  //         </IonLabel>
  //       </IonItem>
  //     );
  //   }

  //   return dadosAgendamento.convidados?.map((value: any) => (

  //     <IonItem lines="none">
  //       <IonIcon src={person} slot="start" />
  //       <IonLabel className="ion-text-wrap">
  //         <h3>{value.nome}</h3>
  //       </IonLabel>
  //     </IonItem>
  //   ))
  // }

  async function funcRemoverLoja(pk: string, data: string) {

    let resp = await fetch(removerAnotacaoAgendamento.url.replace("{id}", pk.replace(/#/g, "%23")).replace("{anotacao}", data.replace(/#/g, "%23")),
      {
        method: removerAnotacaoAgendamento.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });

    let retorno = await resp.json();

    console.log("retorno", retorno);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      console.log("dadosProjeto.anotacoes", dadosAgendamento.anotacoes);

      var res = dadosAgendamento.anotacoes.filter((p: any, i: any) => {
        if (p.data == data) {
          dadosAgendamento.anotacoes.splice(i, 1); //remove the mached object from the original array
          return p;
        }
      });
      console.log("res", res);

      //dadosProjeto.anotacoes.splice(retorno.data, 1);
    }
    return resp;
  }

  const loadAnotacoes = () => {

    return dadosAgendamento.anotacoes?.sort(function (a: any, b: any) {
      if (a.data < b.data) {
        return 1;
      }
      if (a.data > b.data) {
        return -1;
      }
      return 0;
    }).slice(0).map((value: any) => (

      <IonItem lines="none">

        <IonLabel className="ion-text-wrap">
          <h3 style={{ "verticalAlign": "middle" }}><strong>{new Date(value.data).toLocaleDateString() + " " + new Date(value.data).toLocaleTimeString()}</strong></h3>
          <h3>{value.anotacao + (value.pessoa ? (' - ' + value.pessoa) : "")}</h3>
        </IonLabel>
        <IonIcon hidden={acessos?.agenda != 'edicao'} icon={trash} color="danger" style={{ fontSize: "20px" }} onClick={() =>
          mostartAlerta({
            backdropDismiss: false,
            header: 'Remover Anotação',
            message: 'Realizar <strong>remoção</strong>?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Remover',
                cssClass: 'primary',
                handler: () => {
                  setShowLoadingPage(true);
                  let retorno = funcRemoverLoja(dadosAgendamento.pk, value.data);
                  if (retorno) {
                    retorno?.then(res => {
                      if (res.status === 200) {
                        console.log("res", res)
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Anotação removida com sucesso.'
                        });
                      }
                      else {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro ao tentar remover.'
                        });
                      }
                    }).catch(() => {
                      mostrarToast({
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Erro ao tentar remover.'
                      });
                    }).finally(() => {
                      setShowLoadingPage(false);
                    });
                  }
                  else {
                    mostrarToast({
                      position: 'middle',
                      buttons: [{ text: 'ok', handler: () => fecharToast() }],
                      message: 'Erro ao tentar remover.'
                    });
                    setShowLoadingPage(false);
                  }
                  return true;
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })
        }>
        </IonIcon>
      </IonItem>
    ))
  }

  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }


  function getButtons() {

    let resultado: any = [];
    if (acessos?.agenda == 'edicao') {
      resultado.push(
        {
          text: "Encerrar",
          disabled: dadosAgendamento.status === "Agendado" ? false : true,
          icon: powerOutline,
          handler: () => {
            setAlertConfirmar(true);
          }
        }
      );
    }

    resultado.push(
      {
        text: 'Cancelar',
        disabled: false,
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });

    return resultado;
  }

  function carregarDetalhesProjeto(projetoid: any, abrirInserirAgendamento: boolean) {
    history.push("../projetos/projetosdetalhes", { projetoid: projetoid, abrirInserirAgendamento: abrirInserirAgendamento, esconderAgendamentos: true })
  }

  function registrarAnotacao(pk: any, mensagem: any) {
    if (!mensagem || mensagem.trim() == "") {
      return null;
    }

    let params = {
      mensagem: mensagem
    }
    return fetch(agendamentoRegistrarAnotacao.url.replaceAll("{pk}", pk.replaceAll(/#/g, "%23")),
      {
        method: agendamentoRegistrarAnotacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  return (

    <IonPage className="AgendamentosDetalhes">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>

          </IonButtons>
          <IonTitle color="dark" className="ion-text-wrap" style={{ "padding-left": "20px", "padding-top": "0px", "padding-bottom": "0px", "text-align": "left" }}>
            {dadosAgendamento ? (window.localStorage.getItem("servicoTipo") || "Projeto") + " - " + dadosAgendamento.descricaoAgendamento : ""}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>
          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>
              {getButtons().map((button: any, index: number) => (
                <IonItem key={index} lines="none" disabled={button.disabled} button onClick={() => {
                  if (button.handler) button.handler();
                  closePopover();
                }}>
                  <IonIcon icon={button.icon} slot="start" />
                  <IonLabel>{button.text}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonPopover>




        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>


        <div className="header">
          <div className="info">
            {/* {dadosAgendamento.interno == true ? <IonIcon color="danger" icon={lockClosed} size="small" /> : <IonIcon color="success" icon={lockOpen} size="small" />}
            {dadosAgendamento.confirmado == true ? <IonIcon color="success" icon={checkmarkCircle} size="small" /> : <IonIcon color="danger" icon={checkmarkCircle} size="small" />} */}
            <IonIcon src={alarmOutline}></IonIcon>
            <div>
              <IonLabel>{dadosAgendamento && dadosAgendamento.dataAgendamento ? formatarData(new Date(dadosAgendamento.dataAgendamento)) : ""}</IonLabel>
              <IonLabel>{dadosAgendamento && dadosAgendamento.horaAgendamento ? dadosAgendamento.horaAgendamento + (dadosAgendamento.horaAgendamento2 ? " - " + dadosAgendamento.horaAgendamento2 : "") : ""}</IonLabel>
            </div>
          </div>
        </div>
        <IonList>
          <IonListHeader lines="full">
            <IonLabel>Agendamento</IonLabel>
          </IonListHeader>

          <IonItem lines="none">
            <IonIcon icon={cubeOutline} slot="start" />
            <div className="contentList">
              <h1>Descrição</h1>
              <h2>{dadosAgendamento && dadosAgendamento.descricaoAgendamento ? dadosAgendamento.descricaoAgendamento : "Sem descrição"}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={swapHorizontalOutline} slot="start" />
            <div className="contentList">
              <h1>Tipo</h1>
              <h2>{dadosAgendamento && dadosAgendamento.tipoAgendamento ? dadosAgendamento.tipoAgendamento : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Status</h1>
              <h2>{dadosAgendamento ? dadosAgendamento.status : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={locationOutline} slot="start" />
            <div className="contentList">
              <h1>Endereço</h1>
              <h2>{dadosAgendamento && dadosAgendamento.endereco ? [dadosAgendamento.endereco, dadosAgendamento.complemento]
                .filter(Boolean)
                .join(', ') : "não identificado"}</h2>
            </div>
          </IonItem>


          <IonItem lines="none">
            <IonIcon src={navigateOutline} slot="start" />
            <div className="contentList">
              {(dadosAgendamento && dadosAgendamento.enderecoLat && dadosAgendamento.enderecoLng) ? (
                <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                  <a target='_blank' href={"https://www.google.com/maps/search/?api=1&query=" + (dadosAgendamento ? dadosAgendamento.enderecoLat + "," + dadosAgendamento.enderecoLng + "&query_place_id=" : "")}><img src={maps} alt="maps" /></a>
                  <a target='_blank' href={"https://waze.com/ul?ll=" + (dadosAgendamento ? dadosAgendamento.enderecoLat + "%2C" + dadosAgendamento.enderecoLng : "") + "&navigate=yes"}><img src={waze} alt="waze" /></a>
                </div>
              ) : (
                <h2 style={{ color: "red" }}><b>Sem Localização</b></h2>
              )}
            </div>
          </IonItem>

          {/* <IonItem lines="none">
            {dadosAgendamento.interno == true ? <IonIcon color="danger" icon={lockClosed} slot="start" /> : <IonIcon color="success" icon={lockOpen} slot="start" />}
            <div className="contentList">
              <h1>Bloqueado</h1>
              <h2>{dadosAgendamento ? (dadosAgendamento.interno ? "sim" : "não") : ""}</h2>
            </div>
          </IonItem> */}

          <IonItem lines="none">
            {dadosAgendamento.confirmado == true ? <IonIcon color="success" icon={checkmarkCircle} slot="start" /> : <IonIcon color="danger" icon={checkmarkCircle} slot="start" />}
            <div className="contentList">
              <h1>Confirmado</h1>
              <h2>{dadosAgendamento ? (dadosAgendamento.confirmado ? "sim" : "não") : ""}</h2>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Projeto</IonLabel>
          </IonListHeader>

          <IonItem disabled={params.esconderProjeto} lines="none" detail onClick={(e) => {

            carregarDetalhesProjeto(dadosAgendamento.projetoid.replaceAll(/#/g, '%23'), false);
          }}>
            <IonIcon icon={appsOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosAgendamento && dadosAgendamento.nomeProjeto ? dadosAgendamento.nomeProjeto : "Sem descrição"}</h2>
            </div>
          </IonItem>



          <IonListHeader lines="full">
            <IonLabel>Empresa</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosAgendamento && dadosAgendamento.empresaNome ? dadosAgendamento.empresaNome : ""}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div>
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosAgendamento ? dadosAgendamento.empresaTelefone : "")}><h2>{dadosAgendamento && dadosAgendamento.empresaTelefone ? formataTelefone(dadosAgendamento.empresaTelefone) : ""}</h2></a>
              </div>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Cliente</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosAgendamento && dadosAgendamento.clienteNome ? dadosAgendamento.clienteNome : ""}</h2>
            </div>
          </IonItem>
          {/* <IonItem lines="none">
            <IonIcon src={card} slot="start" />
            <div className="contentList">
              <h1>CPF</h1>
              <h2>{dadosAgendamento && dadosAgendamento.clienteCpf ? cpf_mask(dadosAgendamento.clienteCpf) : ""}</h2>
            </div>
          </IonItem> */}
          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosAgendamento ? dadosAgendamento.clienteTelefone : "")}><h2>{dadosAgendamento && dadosAgendamento.clienteTelefone ? formataTelefone(dadosAgendamento.clienteTelefone) : ""}</h2></a>
              </div>
            </div>
          </IonItem>


          <IonItem lines="none">
            <IonIcon src={notificationsOutline} slot="start" />
            <div className="contentList">
              <h1>Enviar notificação?</h1>
              <h2>{dadosAgendamento && dadosAgendamento.enviarMsgCliente ? dadosAgendamento.enviarMsgCliente : ""}</h2>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Prestador</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosAgendamento && dadosAgendamento.prestadorNome ? dadosAgendamento.prestadorNome : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={cardOutline} slot="start" />
            <div className="contentList">
              <h1>CPF</h1>
              <h2>{dadosAgendamento && dadosAgendamento.prestadorCpf ? cpf_mask(dadosAgendamento.prestadorCpf) : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosAgendamento ? dadosAgendamento.prestadorTelefone : "")}><h2>{dadosAgendamento && dadosAgendamento.prestadorTelefone ? formataTelefone(dadosAgendamento.prestadorTelefone) : ""}</h2></a>
              </div>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={constructOutline} slot="start" />
            <div className="contentList">
              <h1>Atividades</h1>
              <IonTextarea readonly={true} style={{ "--background": "#eeeeee" }} rows={6} value={dadosAgendamento && dadosAgendamento.atividadesPrestador ? dadosAgendamento.atividadesPrestador : "Não informado"}></IonTextarea>
            </div>
          </IonItem>

          <IonListHeader lines="full" hidden={!(dadosAgendamento && dadosAgendamento.responsavelNome)}>
            <IonLabel>Arquiteto</IonLabel>
          </IonListHeader>
          <IonItem lines="none" hidden={!(dadosAgendamento && dadosAgendamento.responsavelNome)}>
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosAgendamento && dadosAgendamento.responsavelNome ? dadosAgendamento.responsavelNome : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none" hidden={!(dadosAgendamento && dadosAgendamento.responsavelNome)}>
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosAgendamento ? dadosAgendamento.responsavelTelefone : "")}><h2>{dadosAgendamento && dadosAgendamento.responsavelTelefone ? formataTelefone(dadosAgendamento.responsavelTelefone) : ""}</h2></a>
              </div>

            </div>
          </IonItem>
          <IonItem lines="none" hidden={!(dadosAgendamento && dadosAgendamento.responsavelNome)}>
            <IonIcon src={notificationsOutline} slot="start" />
            <div className="contentList">
              <h1>Enviar notificação?</h1>
              <h2>{dadosAgendamento && dadosAgendamento.enviarMsgResposavel ? dadosAgendamento.enviarMsgResposavel : ""}</h2>
            </div>
          </IonItem>

          {/* <IonListHeader lines="full">
            <IonLabel>Convidados</IonLabel>
          </IonListHeader>

          {loadConvidados()} */}

          <IonListHeader lines="full">
            <IonLabel>Anotações</IonLabel>
            <IonItem hidden={acessos?.agenda != 'edicao'} lines="none" >
              <IonIcon src={addCircle} color="primary" onClick={async () => {



                mostartAlerta({
                  backdropDismiss: false,
                  header: 'Registrar anotação no agendamento',
                  message: 'Digite a anotação abaixo',
                  inputs: [
                    {
                      name: 'mensagem',
                      type: 'textarea',
                      id: 'name2-id',
                      placeholder: 'Digite aqui a anotação',
                    }],
                  buttons: ['Cancel', {
                    text: 'Incluir', handler: (d) => {
                      setShowLoadingPage(true);
                      let retorno = registrarAnotacao(dadosAgendamento.pk.replace(/#/g, '%23'), d.mensagem);
                      if (retorno) {
                        retorno?.then(async e => {
                          let body = await e.json();
                          if (e.status === 200) {
                            if (dadosAgendamento.anotacoes) {
                              dadosAgendamento.anotacoes?.push({ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa });
                            }
                            else {
                              dadosAgendamento.anotacoes = [{ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa }];
                            }

                            mostrarToast({
                              duration: 2000,
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Sucesso na inclusão!'
                            });
                          }
                          else {
                            mostrarToast({
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro na inclusão!'
                            });
                          }
                        }).catch((e) => {
                          console.log(e);
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro na inclusão!'
                          });
                        }).finally(() => {
                          setShowLoadingPage(false);
                        });
                        return true;
                      }
                      else {
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Digite uma anotação para incluir.'
                        });
                        setShowLoadingPage(false);
                        return false;
                      }
                    }
                  }],
                  onDidDismiss: (e) => { console.log('did dismiss'); }
                })



              }}></IonIcon>
            </IonItem>
          </IonListHeader>

          {loadAnotacoes()}


        </IonList>


        {/* <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={getButtons()}
        /> */}
        <IonAlert isOpen={alertConfirmar}
          onDidDismiss={() => setAlertConfirmar(false)}
          header={'Encerrar agendamento'}
          message={'Deseja <strong>encerrar</strong>?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: async () => {
                setShowLoadingPage(true);
                await encerrar2().then(res => {
                  console.log(res);
                  setRecarregarAgendamento(true);
                  history.goBack();
                }).catch(() => console.log("Erro na chamada"))
                  .finally(() => {
                    setShowLoadingPage(false);
                  });
              }
            }
          ]}
        />


        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>
      {/* <IonFooter className="ion-no-border" hidden={(temHoraFim)} >
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheet(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter> */}
    </IonPage>
  );


};

export default AgendamentosDetalhes;