import { IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { addCircle, arrowBack, removeCircle, trash } from 'ionicons/icons';
import QRCode from 'qrcode';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { incluirProjeto, listaEquipePorPerfil, projetoGerarCupom, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Endereco from '../Endereco/Endereco';
import ProjetosProdutosComporNovoMemory from '../ProjetosProdutosComporNovoMemory/ProjetosProdutosComporNovoMemory';
import './ProjetosNovoPedidoVenda.css';

type Props = {
  closeAction: Function;
  closeActionSimple: Function;
}

const ProjetosNovoPedidoVenda: React.FC<Props> = (props: Props) => {
  // Navigation
  const history = useHistory();
  const location = useLocation<any>();

  // Alert and Toast
  const [mostartAlerta] = useIonAlert();
  const [textToast, setTextToast] = useState("");
  const [showToast, setShowToast] = useState(false);

  // Loading State
  const [showLoading, setShowLoading] = useState(false);

  // Contexts
  const { loja, setLoja } = useContext(MessageContext);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { pkUsuario, setPkUsuario } = useContext(MessageContext);

  // State: Loja
  const [lojas, setLojas] = useState<string>(loja);

  // State: Filtro e Listagem
  const [textoFiltro, setTextoFiltro] = useState<any>();
  const [listaItemProdutos, setListaItemProdutos] = useState<any>([]);

  // State: Detalhes do Endereço
  const [showDetalhesEndereco, setShowDetalhesEndereco] = useState(false);
  const inputRefEndereco = useRef<any>(null);
  const [endereco, setEndereco] = useState("");
  const [enderecoLat, setEnderecoLat] = useState("");
  const [enderecoLng, setEnderecoLng] = useState("");
  const [complemento, setComplemento] = useState("");

  // State: Cliente
  const [pkCliente, setPkCliente] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");

  // State: Valores e Pagamento
  const [troco, setTroco] = useState<any>();
  const [valorTotal, setValorTotal] = useState<any>();
  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();
  const [formaPagamento, setFormaPagamento] = useState('');
  const [taxaEntrega, setTaxaEntrega] = useState<any>();
  const [desconto, setDesconto] = useState<any>();
  const [taxaServico, setTaxaServico] = useState<any>();

  // Refs
  const campoTelefone = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const contentCatalogoRef = useRef<any>(null);

  // Dados da Empresa
  // const [dadosEmpresa, setDadosEmpresa] = useState<any>([]);

  const [listaComanda, setListaComanda] = useState<any>([]);
  const [selectedButton, setSelectedButton] = useState<string>(window.localStorage.getItem("opcaoCriarPedido") || "Balcão");

  const opcoesPagamento = [
    'Dinheiro',
    'Cartão de Crédito',
    'Cartão de Débito',
    'Pix',
    'Alelo',
    'Sodexo',
    'Vale Alimentação',
    'Boleto',
    'Transferência Bancária'
  ];

  const opcoesTaxaEntrega = [
    { label: 'Grátis', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 6,00', value: '6,00' },
    { label: 'R$ 7,00', value: '7,00' },
    { label: 'R$ 8,00', value: '8,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 12,00', value: '12,00' },
    { label: 'R$ 15,00', value: '15,00' },
  ];

  const opcoesDesconto = [
    { label: 'R$ 0,00', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 15,00', value: '15,00' },
    { label: 'R$ 20,00', value: '20,00' },
    { label: 'R$ 25,00', value: '25,00' }
  ];

  const opcoesTaxaServico = [
    { label: '0%', value: '0' },
    { label: '10%', value: '10' },
    { label: '12%', value: '12' },
    { label: '15%', value: '15' }
  ];




  useEffect(() => {

    try {
      if (selectedButton) {
        if (selectedButton == "Comanda") {
          buscarTipo("comanda");
        }
        else if (selectedButton == "Balcão") {
          buscarTipo("balcao");
        }
        else {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }

        setPkCliente("");
        setTextoFiltro("");
        window.localStorage.setItem("opcaoCriarPedido", selectedButton);
      }

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [selectedButton]);

  useEffect(() => {

    try {
      let taxaServicoFloat = parseFloat(taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
      let descontoFloat = parseFloat(desconto?.replace(/\./g, '').replace(/\,/g, '.'));
      let taxaEntregaFloat = parseFloat(taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

      let valorTotalRecalculado = 0;
      if (valorTotalProdutos) {
        valorTotalRecalculado = parseFloat(valorTotalProdutos.replace(/\./g, '').replace(/\,/g, '.'));
      }

      if (taxaEntrega) {
        valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
      }

      if (desconto) {
        valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
      }
      if (taxaServico) {
        valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
      }

      setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [taxaEntrega, taxaServico, desconto, valorTotalProdutos]);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    // console.log("********dadosEmpresa", dadosEmpresa);
    // carregarEmpresa();

  })

  useEffect(() => {

    if (listaComanda && Array.isArray(listaComanda) && listaComanda.length == 1) {
      setPkCliente(listaComanda[0].pk);
    }

  }, [listaComanda])

  const buscarTipo = async (perfil: any) => {
    try {
      setShowLoading(true);
      let listaComandas = await buscarTipoDynamoDb(perfil);

      if (Array.isArray(listaComandas) && listaComandas.length > 0) {
        let lista = (listaComandas.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))

        // console.log("lista", lista);
        setListaComanda(lista);

      }
      else {
        setListaComanda([]);
      }
    } catch (error: any) {
      console.error("Ocorreu um problema inesperado. Por favor, tente novamente mais tarde. Detalhes do erro: ", error);
      setTextToast(error);
      setShowToast(true);
    } finally {
      setShowLoading(false);
    }

  }

  const buscarTipoDynamoDb = async (perfil: any) => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", perfil),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let comandas = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (comandas.Items) {
        return comandas.Items;
      }
    }
  }


  function readListaComandas() {

    let listaFiltrada = textoFiltro
      ? listaComanda.filter((value: any) =>
        value.nome.toLowerCase().includes(textoFiltro?.toLowerCase())
      )
      : listaComanda;



    return listaFiltrada.map((value: any, index: any) => (
      <>

        <IonCol size="3">
          <IonButton



            color={pkCliente == value.pk ? "danger" : 'light'}
            className="flat-button-balcao-comanda"
            onClick={() => {
              setPkCliente(value.pk);
            }}>
            <IonLabel className="ion-text-wrap">
              <b>{value.nome}</b>
            </IonLabel>
            {/* <IonIcon icon={pizzaOutline} style={{ fontSize: '80px' }} /> */}
          </IonButton>
        </IonCol>

      </>

    ));
  }

  function validarCampos() {

    if (!pkCliente) {
      setTextToast((window.localStorage.getItem("MesaOuComandaOuCaixa") || "Mesa") + " não selecionada.");
      setShowToast(true);
      return;
    }
    else if (listaItemProdutos.length == 0) {
      setTextToast("Adicione pelo menos um item.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  function validarCamposDelivery() {

    if (nome.length == 0) {
      setTextToast("Campo Nome é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (telefone.length != 15 && telefone.length != 14) {
      setTextToast("Campo Telefone é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (endereco.length == 0) {
      setTextToast("Campo Endereço é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (listaItemProdutos.length == 0) {
      setTextToast("Adicione pelo menos um item.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  const openNewTabWithHTML = async (projeto: any) => {
    let newWindow: any;
    newWindow = window.open('', '_blank');

    console.log(projeto);
    // Gera o conteúdo HTML dinamicamente
    const htmlContentTab = await montarCupomHtml(projeto);

    // Escreve o conteúdo HTML na nova aba
    newWindow.document.open();
    newWindow.document.write(htmlContentTab);
    newWindow.document.close();

    // Chama a função de impressão na nova aba
    setTimeout(function () {
      newWindow.print();
      newWindow.close();
    }, 300);
  };

  async function montarCupomHtml(projeto: any) {

    function removerAcentosECedilha(texto: any) {
      return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ç/g, 'c').replace(/Ç/g, 'C');
    }

    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, ''); // Remove tudo que não é dígito

      if (texto.length === 11) {
        // Máscara para CPF
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        // Máscara para CNPJ
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }

      return texto; // Retorna o texto sem máscara se não tiver 11 ou 14 dígitos
    }

    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';
      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });
      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }
      return linhas;
    }
    let dataCupom: any;
    if (projeto.dataCupom) {
      dataCupom = new Date(projeto.dataCupom);
      //dataCupom.setHours(dataCupom.getHours() - 3);
    }
    let html = `
      <!DOCTYPE html>
      <html lang="pt-BR">
         <head>
            <meta charset="UTF-8">
            <meta nomeProjeto="viewport" content="width=device-width, initial-scale=1.0">
            <title>CUPOM NÃO FISCAL</title>
            <style>
               body {
               font-family: 'Courier New', Courier, monospace;
               margin: 0;
               padding: 0;
               }
            <style>
               @media print {
               body {
               width: 370px; /* Define a largura da página como a largura do papel da impressora térmica */
               margin: 0;
               }
               .no-print {
               display: none; /* Esconde elementos que não devem aparecer na impressão */
               }
               }
               .bold {
               font-weight: bold;
               }
               .regular {
               font-size: 12pt;
               }
               .regular-itens {
               font-size: 12pt;
               }
               .table-container {
               width: 100%;
               max-width: 370px;
               margin: 0 auto;
               text-align: left;
               }
               table {
               width: 100%;
               border-collapse: collapse;
               }
               th, td {
               border: none;
               padding: 5px;
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               th {
               background-color: #f2f2f2;
               }
               .header, .footer {
               margin-top: 20px;
               }
               .footer {
               margin-top: 40px;
               }
               .separator {
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               /* CSS for the giant button */
               .print-button {
               display: block;
               width: 100%;
               padding: 20px;
               margin: 20px 0;
               background-color: #4CAF50; /* Green background */
               color: white;
               font-size: 24px;
               font-weight: bold;
               text-align: center;
               border: none;
               border-radius: 10px;
               cursor: pointer;
               }
               .print-button:hover {
               background-color: #45a049; /* Darker green on hover */
               }
               .close-button {
               position: absolute;
               top: 10px;
               right: 10px;
               padding: 10px 20px;
               background-color: #f44336; /* Red background */
               color: white;
               font-size: 16px;
               font-weight: bold;
               border: none;
               border-radius: 5px;
               cursor: pointer;
               }
               .close-button:hover {
               background-color: #d32f2f; /* Darker red on hover */
               }
            </style>
            <div class="table-container">
               `;
    if ((projeto.interno)) {
      html += `
               <div class="header bold">
                  <div>${projeto.cliente.nome}</div>
               </div>
               `;
    }
    else {
      html += `
               <div class="header bold">
                  <div>${projeto.empresa.empresaNome}</div>
                  <div class="regular">CNPJ: ${aplicarMascaraCPFouCNPJ(projeto.empresa.empresaCnpj)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${projeto.empresa.empresaEndereco}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
                  <div class="regular">Tel: ${formataTelefone(projeto.empresa.empresaTelefone)}</div>
                  <div class="regular">
                     ${(dataCupom)
          ? `Data: ${dataCupom.toLocaleDateString('pt-BR')} Hora: ${dataCupom.toLocaleTimeString('pt-BR')}`
          : ""}
                  </div>
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">
                  <div>${projeto.cliente.nome}</div>
               </div>
               <div class="header bold">
                  <div class="regular">Tel: ${formataTelefone(projeto.cliente.telefone)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${[projeto.endereco, projeto.complemento]
            .filter(Boolean)
            .join(', ') || ""}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">CUPOM NÃO FISCAL</div>
               `;
    }
    html += `
               <div class="separator">======================================</div>
               <div class="regular">${projeto.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}</div>
               <div class="separator">======================================</div>
               `;
    if (projeto.produtos && projeto.produtos.length > 0) {
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td colspan="2">QTDE&nbsp|&nbspDESCRIÇÃO</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        ${projeto.produtos.map((iv: any, index: any) => `
                        ${(Array.isArray(iv.descricao)) ? (
          iv.descricao.map((itemDesc: any, indexLinha: any) => `
                        ${indexLinha === 0 ?
              quebrarTextoEmLinhas(iv.tipo === "pizzas" ? `${iv.tamanho} ${iv.quantidadeSabores} SABORES (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})` : `${iv.categoria} (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha2) => `
                        <tr>
                           ${indexLinha2 === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
              : ''}
                        ${(itemDesc.descricao) && (
              quebrarTextoEmLinhas(`- ${itemDesc.descricao} (R$ ${itemDesc.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha) => `
                        <tr>
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')
            )}
                        `).join('')
        ) : (
          quebrarTextoEmLinhas(`${iv.descricao} (R$ ${iv.valor.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })})`, 30)
            .map((linha, indexLinha) => `
                        <tr>
                           ${indexLinha === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
        )}
                        <tr>
                           <td colspan="2" class="regular-itens" style="text-align: left;">VALOR: R$ ${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        `).join('')}
                     </tbody>
                  </table>
               </div>
               `;
      if (!(projeto.interno)) {
        html += `
               <div class="bold">TOTAL: R$ ${projeto.valorTotalProdutos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      }
    }
    if (projeto.servicos && projeto.servicos.length > 0) {
      if (projeto.produtos && projeto.produtos.length > 0) {
        html += `
               <div class="separator">**************************************</div>
               `;
      }
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td>#</td>
                           <td>DESCRIÇÃO</td>
                           <td>QTD</td>
                           <td>VALOR</td>
                        </tr>
                        ${projeto.servicos.map((iv: any, index: any) => `
                        <tr>
                           <td class="regular-itens">${quebrarTextoEmLinhas(`${iv.descricao} x ${iv.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 30)
          .map((linha, indexLinha) => `
                        <tr>
                           <td class="regular-itens">${indexLinha == 0 ? String(index + 1).padStart(2, '0') : "&nbsp"}</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')}</td> 
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td class="regular-itens" style="text-align: right;">${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>`).join('')}
                     </tbody>
                  </table>
               </div>
               <div class="separator">--------------------------------------</div>
               <div class="bold">TOTAL: R$ ${projeto.valorTotalServicos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.formaPagamento) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}</div>
               `;
    }
    if (projeto.troco) {
      html += `
               <div class="bold">TROCO: R$ ${projeto.troco?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.taxaEntrega && !projeto.interno) {
      html += `            
               <div class="bold">TAXA DE ENTREGA: +R$ ${projeto.taxaEntrega?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.desconto) {
      html += `            
               <div class="bold">DESCONTO: -R$ ${projeto.desconto?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.interno && projeto.taxaServico) {
      html += `            
               <div class="bold">TAXA: +R$ ${projeto.taxaServico?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
               `;
    }
    if (projeto.valorTotal && !(projeto.interno)) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">TOTAL ${projeto.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${projeto.valorTotal?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      const qrData = await QRCode.toDataURL(projeto.pk);
      html += `
               <div class="separator">.</div>
               <div>
                  <img src="${qrData}" alt="QR Code" style="width: 150px; height: 150px;"/>
               </div>
               `;
    }
    html += `
               <div class="separator">.</div>
               <div class="separator">.</div>
               <div class="separator">.</div>
            </div>
            </body>
      </html>
      `;
    return removerAcentosECedilha(html);
  }


  function avancar(pularEndereco: boolean = false) {

    if (selectedButton == "Balcão") {
      avancarBalcao();
    }
    else if (selectedButton == "Comanda") {
      avancarComanda();
    }
    else {
      avancarDelivery(pularEndereco);
    }



  }

  function avancarBalcao() {

    if (!validarCampos())
      return;

    setShowLoading(true);

    let objRequest = {
      "nomeCliente": "",
      "telefoneCliente": "",
      lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
        return lojas.includes(value.empresaLojaId);
      }),
      "endereco": "",
      "complemento": "",
      "enderecoLat": "",
      "enderecoLng": "",
      "clienteid": pkCliente.replaceAll(/#/g, '%23'),
      "responsavelid": "",
      "empresaid": loja || "",
      "responsaveis": [pkUsuario.replaceAll(/#/g, '%23')],
      "interno": false,
      "produtos": listaItemProdutos,
      "formaPagamento": formaPagamento,
      "troco": troco,
      "taxaEntrega": taxaEntrega,
      "desconto": desconto,
      "taxaServico": taxaServico

    };

    requestService(incluirProjeto.url, {
      method: incluirProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {


        if (response.message) {


          if (response.message?.pk) {


            // if (!isPlatform('mobileweb') && isPlatform('mobile')) {
            gerarCupom(response.message.pk);
            history.goBack();
            //   }
            //   else {




            //     mostartAlerta({
            //       backdropDismiss: false,
            //       header: 'Cadastro realizado com sucesso',
            //       message: 'Deseja imprimir?',

            //       buttons: [{
            //         text: 'Cancelar',
            //         role: 'cancel',
            //         handler: () => {
            //           history.goBack();
            //         }
            //       },
            //       {
            //         text: 'Confirmar',
            //         handler: async () => {

            //           await openNewTabWithHTML(response.message.vo);
            //           history.goBack();
            //         }
            //       }],
            //       onDidDismiss: () => { console.log('did dismiss'); }
            //     })

            //   }




          }

        }
      },
      (error: any) => {
        console.log(error);




        mostartAlerta({
          backdropDismiss: false,
          header: 'Endereço com problema',
          message: error.message,

          buttons: [{
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            handler: () => {
              avancar(true);
            }
          }],
          onDidDismiss: () => { console.log('did dismiss'); }
        })
      }).finally(() => {
        setShowLoading(false);
      });




  }

  function avancarComanda(pularEndereco: boolean = false) {

    if (!validarCampos())
      return;

    setShowLoading(true);

    let objRequest = {
      "nomeCliente": "",
      "telefoneCliente": "",
      lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
        return lojas.includes(value.empresaLojaId);
      }),
      "endereco": "",
      "complemento": "",
      "enderecoLat": "",
      "enderecoLng": "",
      "clienteid": pkCliente.replaceAll(/#/g, '%23'),
      "responsavelid": "",
      "empresaid": loja || "",
      "responsaveis": [pkUsuario.replaceAll(/#/g, '%23')],
      "interno": true,
      "produtos": listaItemProdutos,
      "formaPagamento": formaPagamento,
      "troco": troco,
      "taxaEntrega": taxaEntrega,
      "desconto": desconto,
      "taxaServico": taxaServico

    };

    requestService(incluirProjeto.url, {
      method: incluirProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {


        if (response.message) {


          if (response.message?.pk) {


            // if (!isPlatform('mobileweb') && isPlatform('mobile')) {
            gerarCupom(response.message.pk);
            history.goBack();
            // }
            // else {




            //   mostartAlerta({
            //     backdropDismiss: false,
            //     header: 'Cadastro realizado com sucesso',
            //     message: 'Deseja imprimir?',

            //     buttons: [{
            //       text: 'Cancelar',
            //       role: 'cancel',
            //       handler: () => {
            //         history.goBack();
            //       }
            //     },
            //     {
            //       text: 'Confirmar',
            //       handler: async () => {

            //         await openNewTabWithHTML(response.message.vo);
            //         history.goBack();
            //       }
            //     }],
            //     onDidDismiss: () => { console.log('did dismiss'); }
            //   })

            // }




          }

        }
      },
      (error: any) => {
        console.log(error);




        mostartAlerta({
          backdropDismiss: false,
          header: 'Endereço com problema',
          message: error.message,

          buttons: [{
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            handler: () => {
              avancar(true);
            }
          }],
          onDidDismiss: () => { console.log('did dismiss'); }
        })
      }).finally(() => {
        setShowLoading(false);
      });




  }


  function avancarDelivery(pularEndereco: boolean = false) {

    if (!validarCamposDelivery())
      return;

    setShowLoading(true);

    let objRequest = {
      "nomeCliente": nome,
      "telefoneCliente": "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
      lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
        return lojas.includes(value.empresaLojaId);
      }),
      "pularEndereco": pularEndereco,
      "endereco": endereco || "",
      "complemento": complemento || "",
      "enderecoLat": enderecoLat,
      "enderecoLng": enderecoLng,
      "clienteid": pkCliente.replaceAll(/#/g, '%23'),
      "responsavelid": "",
      "empresaid": loja || "",
      "responsaveis": [pkUsuario.replaceAll(/#/g, '%23')],
      "interno": false,
      "produtos": listaItemProdutos,
      "formaPagamento": formaPagamento,
      "troco": troco,
      "taxaEntrega": taxaEntrega,
      "desconto": desconto,
      "taxaServico": taxaServico

    };

    requestService(incluirProjeto.url, {
      method: incluirProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {


        if (response.message) {


          if (response.message?.pk) {


            // if (!isPlatform('mobileweb') && isPlatform('mobile')) {
            gerarCupom(response.message.pk);
            history.goBack();
            // }
            // else {




            //   mostartAlerta({
            //     backdropDismiss: false,
            //     header: 'Cadastro realizado com sucesso',
            //     message: 'Deseja imprimir?',

            //     buttons: [{
            //       text: 'Cancelar',
            //       role: 'cancel',
            //       handler: () => {
            //         history.goBack();
            //       }
            //     },
            //     {
            //       text: 'Confirmar',
            //       handler: async () => {

            //         await openNewTabWithHTML(response.message.vo);
            //         history.goBack();
            //       }
            //     }],
            //     onDidDismiss: () => { console.log('did dismiss'); }
            //   })

            // }




          }

        }
      },
      (error: any) => {
        console.log(error);




        mostartAlerta({
          backdropDismiss: false,
          header: 'Endereço com problema',
          message: error.message,

          buttons: [{
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            handler: () => {
              avancar(true);
            }
          }],
          onDidDismiss: () => { console.log('did dismiss'); }
        })
      }).finally(() => {
        setShowLoading(false);
      });




  }


  async function gerarCupom(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    setShowLoading(true);
    const resp = await fetch(projetoGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarCupom.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoading(false);

  }

  const handleSelectItem = (item: any) => {

    setNome(item.nome);
    setTelefone(formataTelefone(item.telefone));
    setEndereco(item.endereco);
    setEnderecoLat(item.enderecoLat);
    setEnderecoLng(item.enderecoLng);
    setComplemento(item.complemento);
    setPkCliente(item.pk);
  };

  const buscarCliente = async (clienteTelefone: any) => {

    if (clienteTelefone && (clienteTelefone.length == 11 || clienteTelefone.length == 10)) {
      let listaClientes = await buscarClienteDynamoDb(clienteTelefone);

      if (Array.isArray(listaClientes) && listaClientes.length > 0) {
        handleSelectItem(listaClientes[0])
      }
      else {
        mostartAlerta({
          backdropDismiss: false,
          header: 'Cliente não cadastrado',
          message: 'O cliente não está cadastrado. Deseja criar um novo cadastro?',

          buttons: [{
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar', handler: () => {
              setTelefone(formataTelefone("55" + textoFiltro));
              setNome("");
              setEndereco("");
              setEnderecoLat("");
              setEnderecoLng("");
              setComplemento("");
              setPkCliente("");
            }
          }],
          onDidDismiss: () => { console.log('did dismiss'); }
        })

      }
    }

  }

  const buscarClienteDynamoDb = async (clienteTelefone: any) => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    const filtro = "?status=ativo#55" + clienteTelefone
    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja).concat(filtro).replaceAll(/#/g, "%23");

    const resp = await fetch(montarUrl.replace("{perfil}", "cliente"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        return equipe.Items;
      }
    }
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  async function closeModalEndereco(enderecoSelecionado: any, latitudeSelecionado: any, enderecoLngSelecionado: any) {
    console.log("enderecoSelecionado", enderecoSelecionado);
    console.log("latitudeSelecionado", latitudeSelecionado);
    console.log("enderecoLngSelecionado", enderecoLngSelecionado);

    setEndereco(enderecoSelecionado);
    setEnderecoLat(latitudeSelecionado);
    setEnderecoLng(enderecoLngSelecionado);

    setShowDetalhesEndereco(false);
  }

  async function closeModalSimpleEndereco() {
    setShowDetalhesEndereco(false);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();



    let val = e.target.value;

    if (val.length > 15) {
      val = val.substring(0, 15);
      setTelefone(val.substring(0, 15));
      return;
    }
    if (val.length < 15) {

      setTelefone(val
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})(\d+?)$/, "$1"));
    }
    else {

      setTelefone(val
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})(\d+?)$/, "$1"));
    }


  }

  function deletarItemProdutos(index: number) {
    // Filtra a lista de produtos, removendo o item com o índice fornecido
    const updatedList = listaItemProdutos.filter((_: any, i: any) => i !== index);

    // Atualiza a lista de produtos com o item removido
    setListaItemProdutos(updatedList);

    // Calcula a nova soma dos valores após a remoção
    const totalValor = updatedList.reduce((total: any, item: any) => {
      const itemValorNumerico = parseFloat(
        item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
      return total + itemValorNumerico;
    }, 0);

    // Exemplo: você pode armazenar a nova soma em outro estado ou usar conforme necessário
    console.log("Nova soma dos valores:", totalValor);

    setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));

  }

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setTroco(v);
  }





  const loadItemProdutos2 = () => {

    if (listaItemProdutos == undefined) return;

    if (listaItemProdutos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>

        <IonGrid>
          {listaItemProdutos.map((value: any, index: any) => (
            <>

              <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                <IonCol size="9">


                  {(value.descricao && Array.isArray(value.descricao)) ? (

                    <>
                      {(value.tipo === "pizzas") ? (
                        <div>{value.tamanho} {value.quantidadeSabores} SABORES</div>
                      ) : (
                        <div>{value.categoria}</div>
                      )}
                      {value.descricao.map((item: any, index: number) => (
                        <div key={"desc" + index}>
                          - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</IonLabel>)
                        </div>
                      ))}
                    </>

                  ) : (
                    <div key={"desc" + index}>
                      {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</IonLabel>)

                    </div>
                  )}



                </IonCol>


                <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                  <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                </IonCol>
                <IonCol sizeSm='6'>
                  <IonLabel style={{ textAlign: "right", fontSize: "12px" }} >
                    <IonChip style={{ margin: 0, padding: '5px' }}>
                      <IonIcon size='large' color='danger' icon={removeCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata a quantidade atual
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        if (quantidadeFormatada > 1) {
                          // Decrementa a quantidade
                          let quantidadeNova = quantidadeFormatada - 1;

                          // Formata o valor e calcula o novo valor com base na nova quantidade
                          const valorFormatado = parseFloat(
                            itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );

                          // Atualiza a quantidade e o valor do item
                          itemToUpdate.quantidade = quantidadeNova;
                          const valorFloat = quantidadeNova * valorFormatado;

                          itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });

                          // Substitui o item atualizado na lista copiada
                          updatedList[index] = itemToUpdate;

                          let totalValor = updatedList.reduce((total: any, item: any) => {
                            const itemValorNumerico = parseFloat(
                              item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                            );
                            return total + itemValorNumerico;
                          }, 0);

                          setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }));

                          // Atualiza o estado com a nova lista
                          setListaItemProdutos(updatedList);

                        }
                        // else {
                        //   // Se a quantidade for 1, pode-se optar por remover o item completamente ou manter o valor como está.
                        //   // Se preferir remover o item:
                        //   updatedList.splice(index, 1);
                        //   setListaItemProdutos(updatedList);
                        // }

                      }}></IonIcon>
                      <IonLabel style={{ fontSize: '20px' }}>{value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}</IonLabel>
                      <IonIcon size='large' color='success' icon={addCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata e incrementa a quantidade
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        let quantidadeNova = quantidadeFormatada + 1;

                        // Formata o valor e calcula o novo valor com base na nova quantidade
                        const valorFormatado = parseFloat(
                          itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        // Atualiza a quantidade e o valor do item
                        itemToUpdate.quantidade = quantidadeNova;
                        const valorFloat = quantidadeNova * valorFormatado;

                        itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        });

                        // Substitui o item atualizado na lista copiada
                        updatedList[index] = itemToUpdate;

                        let totalValor = updatedList.reduce((total: any, item: any) => {
                          const itemValorNumerico = parseFloat(
                            item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );
                          return total + itemValorNumerico;
                        }, 0);

                        setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));

                        // Atualiza o estado com a nova lista
                        setListaItemProdutos(updatedList);


                      }}></IonIcon>
                    </IonChip>
                  </IonLabel>
                </IonCol>
                <IonCol style={{ display: "flex", justifyContent: "flex-end" }} sizeSm='6'>
                  <IonButtons>
                    <IonButton
                      onClick={() => {
                        mostartAlerta({
                          backdropDismiss: false,
                          header: 'Remover item',
                          message: 'Deseja <strong>remover</strong>?',
                          buttons: [
                            {
                              text: 'Cancelar',
                              role: 'cancel',
                            },
                            {
                              text: 'Confirmar',
                              cssClass: 'primary',
                              handler: () => {
                                deletarItemProdutos(index);
                              }
                            }
                          ],
                          onDidDismiss: (e) => console.log('did dismiss'),
                        });
                      }}
                    >
                      <IonIcon color="primary" icon={trash} />
                    </IonButton>
                  </IonButtons>
                </IonCol>

              </IonRow>
            </>
          ))}
        </IonGrid>
      </>
    );
  }


  async function closeModalProdutosCompor() {
    // setShowDetalhesCompor(false);
  }

  const scrollToCatalogo = () => {
    contentCatalogoRef.current && contentCatalogoRef.current.scrollIntoView();
  };

  async function adicionarItem(produto: any) {

    if (produto) {

      let updatedList = [...listaItemProdutos, produto];

      setListaItemProdutos(updatedList);

      let totalValor = updatedList.reduce((total: any, item: any) => {
        const itemValorNumerico = parseFloat(
          item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
        );
        return total + itemValorNumerico;
      }, 0);

      setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));


    }
    scrollToCatalogo();
  }



  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName);
  };

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
              if (listaItemProdutos.length > 0) {
                mostartAlerta({
                  backdropDismiss: false,
                  header: 'Descartar ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
                  message: "Itens adicionados. Deseja descardar " + (window.localStorage.getItem("servicoTipo") || "Projeto") + "?",

                  buttons: [{
                    text: 'Cancelar',
                    role: 'cancel'
                  },
                  {
                    text: 'Confirmar',
                    handler: async () => {
                      history.goBack();
                    }
                  }],
                  onDidDismiss: () => { console.log('did dismiss'); }
                })
              }
              else { history.goBack(); }

            }
            } />
          </IonButtons>
          <IonTitle>Criar {(window.localStorage.getItem("servicoTipo") || "Projeto")}</IonTitle>

        </IonToolbar>


        {/* {listaProjetosNovoPedidoVendas?.length > 0 && (
          <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: listaProjetosNovoPedidoVendas.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
            {listaProjetosNovoPedidoVendas.map((item: any, index: any) => (
              <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItem(item)}>
                <IonLabel>{item.nome + " - " + formataTelefone(item.telefone)}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )} */}
      </IonHeader>
      <IonContent>

        {/* <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { setShowDetalhesCompor(true); }}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab> */}



        <div>


          <IonCard>
            <IonCardContent>
              <IonGrid style={{ padding: "0px", margin: "0px" }}>
                <IonRow>
                  <IonCol size="12" sizeMd="2">
                    <IonGrid style={{ padding: "0px", margin: "0px" }}>

                      <IonRow hidden={!(window.localStorage.getItem("servicoPrincipal") == "Pedidos")}>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12">
                          <IonButton
                            expand="block"
                            color={selectedButton === 'Balcão' ? 'danger' : 'light'}
                            onClick={() => handleButtonClick('Balcão')}
                          >
                            Balcão
                          </IonButton>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12">
                          <IonButton
                            expand="block"
                            color={selectedButton === 'Comanda' ? 'danger' : 'light'}
                            onClick={() => handleButtonClick('Comanda')}
                          >
                            {window.localStorage.getItem("MesaOuComandaOuCaixa") || "Mesa"}
                          </IonButton>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12">
                          <IonButton
                            expand="block"
                            color={selectedButton === 'Delivery' ? 'danger' : 'light'}
                            onClick={() => {
                              handleButtonClick('Delivery');
                            }}
                          >
                            Delivery
                          </IonButton>
                        </IonCol>
                      </IonRow>


                    </IonGrid>
                  </IonCol>


                  <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="10" hidden={selectedButton == "Delivery"}>

                    <IonSearchbar
                      ref={inputRef}
                      value={textoFiltro}
                      onIonChange={(e) => {
                        setTextoFiltro(e.detail.value);
                      }}
                      enterkeyhint="search"
                      onKeyUp={(e: any) => {
                        if (e.key === "Enter") {
                          buscarCliente(e.target.value);
                        }
                      }}
                      placeholder="Buscar"
                    />

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                      {/* <IonRow className="ion-justify-content-center"> */}
                      <IonRow>
                        {readListaComandas()}
                      </IonRow>
                    </IonGrid>
                  </IonCol>


                  <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="10" hidden={selectedButton != "Delivery"}>

                    <IonSearchbar
                      ref={inputRef}
                      value={textoFiltro}
                      onIonChange={(e) => {
                        setTextoFiltro(e.detail.value);
                      }}
                      enterkeyhint="search"
                      onKeyUp={(e: any) => {
                        if (e.key === "Enter") {
                          buscarCliente(e.target.value);
                        }
                      }}
                      placeholder="Buscar"
                    />




                    <IonGrid>
                      <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12">
                          <IonLabel><b>Cliente</b></IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                      <IonRow>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="4">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Telefone*</IonLabel>
                            <IonInput
                              ref={(ref) => campoTelefone.current = ref}
                              type="tel"
                              maxlength={15}
                              placeholder="(__) _____-____"
                              required
                              value={telefone}
                              onKeyUp={onKeyUpTelefone}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="8">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Nome*</IonLabel>
                            <IonInput
                              placeholder="Digite o nome completo"
                              value={nome}
                              autoCorrect='true'
                              autoCapitalize='true'
                              required
                              onIonInput={(e: any) => setNome(e.target.value)}
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="8">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Endereço*</IonLabel>
                            <IonInput
                              value={endereco}
                              autoCorrect='true'
                              autoCapitalize='true'
                              placeholder="Ex: Avenida Paulista, 111, São Paulo, SP"
                              onIonChange={() => { setEnderecoLat(""); setEnderecoLng(""); }}
                              onIonInput={(e: any) => setEndereco(e.target.value)}
                            />
                            <IonButton slot="end" style={{ textAlign: 'right' }} onClick={() => { setShowDetalhesEndereco(true); }}>Buscar</IonButton>
                          </IonItem>
                        </IonCol>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="4">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Complemento</IonLabel>
                            <IonInput
                              value={complemento}
                              autoCorrect='true'
                              autoCapitalize='true'
                              placeholder="Digite o complemento"
                              onIonInput={(e: any) => setComplemento(e.target.value)}
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>

        </div>

        <IonGrid style={{ padding: "0px", margin: "0px" }} ref={contentCatalogoRef}>
          <IonRow>

            <IonCol style={{ padding: "0px", margin: "0px" }} sizeMd="8" size="12">

              <ProjetosProdutosComporNovoMemory
                empresa={dadosEmpresa}
                disabled={false}
                adicionarItem={adicionarItem}
              />

            </IonCol>


            <IonCol style={{ padding: "0px", margin: "0px" }} sizeMd="4" size="12">
              <IonCard>
                <IonCardContent>

                  <IonGrid style={{ padding: "0px", margin: "0px" }}>

                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="8"><IonLabel><b>Lista de itens</b></IonLabel></IonCol>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="4"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotalProdutos}</b></IonLabel></IonCol>

                    </IonRow>

                  </IonGrid>

                  {loadItemProdutos2()}

                  <IonGrid style={{ margin: "0px" }} hidden={selectedButton == "Comanda"}>
                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="12"><IonLabel><b>Pagamento</b></IonLabel></IonCol>
                    </IonRow>
                  </IonGrid>


                  <IonGrid style={{ padding: "0px", margin: "0px" }} hidden={selectedButton == "Comanda"}>
                    <IonRow>
                      <IonCol>
                        <IonList>
                          <IonItem lines="none">
                            <IonLabel position="stacked">Forma de pagamento</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={formaPagamento}
                              onIonChange={(e) => {
                                setFormaPagamento(e.detail.value);
                              }}
                            >
                              {opcoesPagamento.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao}>
                                  {opcao}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>

                          <IonItem lines="none" hidden={formaPagamento != "Dinheiro"}>
                            <IonLabel position="stacked">Troco</IonLabel>
                            <IonInput
                              type="tel"
                              maxlength={14}
                              placeholder="Digite o valor"
                              value={troco}
                              onKeyUp={onChangeValor}
                            />
                          </IonItem>

                          <IonItem lines="none">
                            <IonLabel position="stacked">Taxa de entrega</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={taxaEntrega}
                              onIonChange={(e) => setTaxaEntrega(e.detail.value)}
                            >
                              {opcoesTaxaEntrega.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao.value}>
                                  {opcao.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>

                          <IonItem lines="none">
                            <IonLabel position="stacked">Desconto</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={desconto}
                              onIonChange={(e) => setDesconto(e.detail.value)}
                            >
                              {opcoesDesconto.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao.value}>
                                  {opcao.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonList>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonGrid style={{ padding: "0px", margin: "0px" }}>
                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderTopStyle: 'solid' }}>
                      <IonCol><IonLabel><b>Total</b></IonLabel></IonCol>
                      <IonCol><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotal}</b></IonLabel></IonCol>
                    </IonRow>
                  </IonGrid>


                  <IonToolbar>
                    <IonButton
                      onClick={async () => {
                        avancar();
                      }}
                      expand="block"
                      type="submit"
                    >
                      Registrar
                    </IonButton>
                  </IonToolbar>


                </IonCardContent>
              </IonCard>
            </IonCol>

          </IonRow>
        </IonGrid>



        {/* <IonModal
          isOpen={showDetalhesCompor} onDidDismiss={() => { setShowDetalhesCompor(false); }}>


          <ProjetosProdutosComporNovoMemoryModal empresa={dadosEmpresa} closeAction={adicionarItem} closeActionSimple={closeModalProdutosCompor}></ProjetosProdutosComporNovoMemoryModal>

        </IonModal> */}


        <IonModal
          onDidPresent={() => {
            if (inputRefEndereco.current) {
              inputRefEndereco.current.setFocus();
            }
          }} isOpen={showDetalhesEndereco} onDidDismiss={() => { setShowDetalhesEndereco(false); }}>
          <Endereco endereco={endereco} inputRef={inputRefEndereco} closeAction={closeModalEndereco} closeActionSimple={closeModalSimpleEndereco}></Endereco>
        </IonModal>


      </IonContent>



      <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
      />


      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { setShowLoading(false); }}
        spinner='circles'
      />

    </IonPage >
  );
};

export default ProjetosNovoPedidoVenda;