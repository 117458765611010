import { IonButton, IonButtons, IonContent, IonDatetime, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPopover, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import React, { useContext, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import "./LembretesCad.css";

import { Toast } from '@capacitor/toast';
import { arrowBack } from 'ionicons/icons';
import 'moment/locale/pt-br';
import { incluirLembrete, listaEquipePorPerfil, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';

type Props = {
  lembrete: any;
  closeAction: Function;
  closeActionSimple: Function;
};

const LembretesCad: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [descricao, setDescricao] = useState<any>();
  const [perfil, setPerfil] = useState<string>("");
  const [showModalLembrete, setShowModalLembrete] = useState(false);
  const [dataLancamento, setDataLancamento] = useState<any>();
  const [dataLancamentoBr, setDataLancamentoBr] = useState<any>();
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [listaPessoa, setListaPessoa] = useState<any>([]);
  const [showModalDataCad, setShowModalDataCad] = useState(false);
  const popover1 = useRef<HTMLIonPopoverElement>(null);
  const { loja, setLoja } = useContext(MessageContext);
  const [checkedEnviarMensagem, setCheckedEnviarMensagem] = useState(false);
  const [pessoa, setPessoa] = useState<string>("");

  let params: any = {
    lembrete: ""
  }

  params = useParams();

  if (location && location.state && location.state.lembrete) {
    params.lembrete = location.state.lembrete;
  }

  if (props.lembrete) {
    params = {
      lembrete: props.lembrete
    }
  }

  async function avancarLembrete() {
    setDescricao("");

    let data = new Date().toString();
    const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
      Date.parse(data)
    );
    setDataLancamentoBr(dataFormatada);
    setDataLancamento(data);
    setCheckedEnviarMensagem(false);

    if (perfil != "") {
      await consultarPessoa();
    }
    else {
      setPessoa("");
    }

    setShowModalLembrete(true);

  }

  function validarCamposLancamento() {

    if (perfil != "" && pessoa == "") {
      setTextToast("Campo " + perfil + " é obrigatório.");
      setShowToast(true);
      return false;
    }

    if (descricao == "") {
      setTextToast("Campo Descrição é obrigatório.");
      setShowToast(true);
      return false;
    }

    return true;
  }

  function iniciarLancamento() {

    if (!validarCamposLancamento())
      return;
    if (botaoAtivo) {
      setShowLoading(true);
      setBotaoAtivo(false);

      let found;

      found = listaPessoa.find((obj: any) => {
        return obj.pk === pessoa;
      });

      console.log("found", found);

      let objRequest = {
        "descricao": descricao,
        "data": new Date(dataLancamento),
        "clienteid": found?.pk,
        "perfil": perfil || "",
        "empresaid": loja || "",
        "enviarMensagem": checkedEnviarMensagem
      };

      console.log("objRequest", objRequest);

      requestService(incluirLembrete.url, {
        method: incluirLembrete.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {

          console.log("response_incluirLembrete", response);
          if (response.message) {
            props.closeAction();
            Toast.show({
              text: "Lembrete criado com sucesso!",
              position: "center",
              duration: "long"
            });
          }
        },
        (error: any) => {
          console.log(error);
          setShowLoading(false);
          Toast.show({
            text: error.message,
            position: "center",
            duration: "long"
          });
        })

    }

    setShowLoading(false);
    setBotaoAtivo(true);
  }

  const openPopoverCad = (e: any) => {
    popover1.current!.event = e;
    setShowModalDataCad(true);
  };

  function readPessoa() {
    return listaPessoa.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"pessoa" + value.pk} value={value.pk}>{value.nome}</IonSelectOption>
    )
    )
  };

  const consultarPessoa = async (empresaid?: any) => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", empresaid ? empresaid.replaceAll(/#/g, "%23") : loja.replaceAll(/#/g, "%23"));

    setShowLoading(true);
    const resp = await fetch(montarUrl.replace("{perfil}", perfil),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();

    setShowLoading(false);

    console.log("equipe", equipe);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
        setListaPessoa(lista);
      }
    }
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { props.closeActionSimple() }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Novo Lembrete</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>



        <IonList lines="full" class="ion-no-margin">
          <IonListHeader lines="full">
            <IonLabel>
              Para qual perfil você deseja enviar um lembrete?
            </IonLabel>
          </IonListHeader>
          <IonItem lines="none" >
            <IonLabel>Perfil*</IonLabel>
            <IonSelect name='Perfil' value={perfil} multiple={false} placeholder="Selecione" cancelText="Cancel" okText="Ok" onIonChange={e => {
              setPerfil(e.detail.value);
            }} slot="end">
              <IonSelectOption value="">Nenhum</IonSelectOption>
              <IonSelectOption value="usuario">Usuários</IonSelectOption>
              {/* {(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") && (
                <IonSelectOption value="comanda">Comandas</IonSelectOption>
              )} */}
              <IonSelectOption value="cliente">Clientes</IonSelectOption>
              {window.localStorage.getItem("servicoPrincipal") == "Obras" && (
                <IonSelectOption value="arquiteto">Arquitetos</IonSelectOption>
              )}

              <IonSelectOption value="prestador">Prestadores</IonSelectOption>
              {(acessos?.vendas == 'leitura' || acessos?.vendas == 'edicao') && (
                <IonSelectOption value="prospect">Prospects</IonSelectOption>
              )}
            </IonSelect>
          </IonItem>
        </IonList>




        <div className="content">


          <IonModal isOpen={showModalLembrete} onDidDismiss={() => setShowModalLembrete(false)} >
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>Novo Lembrete</IonTitle>
                <IonButtons slot="start" onClick={() => { setShowModalLembrete(false); }}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
              <IonList lines="full" class="ion-no-margin">

                <IonItem lines="none" hidden={perfil == ""}>

                  <IonLabel style={{ textTransform: 'capitalize' }}>{perfil}*</IonLabel>
                  <IonSelect slot="end" multiple={false} value={pessoa} placeholder="Selecione" onIonChange={e => { setPessoa(e.detail.value); }}>
                    {readPessoa()}
                  </IonSelect>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Data*</IonLabel>
                  <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverCad(e); }}>{dataLancamentoBr}</IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Descrição*</IonLabel>
                  <IonTextarea style={{ "--background": "#eeeeee", marginLeft: "10px" }} rows={6} value={descricao} maxlength={250} placeholder="" onIonInput={(e: any) => setDescricao(e.target.value)}></IonTextarea>
                </IonItem>

                <IonItem lines="none" hidden={pessoa == "" || perfil != "cliente"}>
                  <IonLabel>Enviar mensagem</IonLabel>
                  <IonToggle slot='end' checked={checkedEnviarMensagem} onIonChange={e => {
                    setCheckedEnviarMensagem(e.detail.checked);
                  }} />

                </IonItem>


              </IonList>
            </IonContent>
            <IonFooter className="ion-no-border" >
              <IonToolbar>
                <IonButton expand="block" size='large' color="primary" onClick={() => { iniciarLancamento(); }}>Registrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>
        </div>


        <IonPopover ref={popover1}
          isOpen={showModalDataCad}
          onDidDismiss={() => setShowModalDataCad(false)} >
          <IonContent>
            <IonDatetime

              presentation='date'

              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataLancamentoBr(dataFormatada);
                  setDataLancamento(data);
                }
                //setShowModalDataCad(false);
              }}></IonDatetime>
          </IonContent>


        </IonPopover >

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton expand="block" size='large' color="primary" onClick={() => {
            avancarLembrete();
          }}>Avançar</IonButton>
        </IonToolbar>
      </IonFooter>



    </>
  );


};

export default ({ lembrete, closeAction, closeActionSimple }: { lembrete: any, closeAction: Function, closeActionSimple: Function }) => (
  <LembretesCad lembrete={lembrete} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </LembretesCad>
)