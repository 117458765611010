import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonSegment, IonSegmentButton, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToolbar, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { add, arrowBack, close, informationCircle, notifications, refresh, search, trash } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { removerLembrete, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import LembretesCad from '../LembretesCad/LembretesCad';
import './Lembretes.css';

type Props = {
  abrirMenu: boolean;
  onDismiss: () => void;
};

const Lembretes: React.FC<Props> = (props: Props) => {

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const history = useHistory();

  const [mostartAlerta] = useIonAlert();

  const [arrayLembretes, setArrayLembretes] = useState<any>([]);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [lembretespk, setLembretespk] = useState("");
  const [lembretesFunilk, setLembretesk] = useState("");
  const [lembretesgsi2sk, setLembretesgsi2sk] = useState("");
  const [showCopiado, setShowCopiado] = useState(false);
  const [listaProjeto, setListaProjeto] = useState<any>([]);
  const [filtroSegment, setFiltroSegment] = useState<string>("");
  const [pesquisaData, setPesquisaData] = useState<any>("Data");
  // const [pesquisaNome, setPesquisaNome] = useState<any>("Pesquisar");
  const [pesquisa, setPesquisa] = useState<any>("");

  const [lembretes, setLembretes] = useState<any>();

  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [mostrarFiltroData, setMostrarFiltroData] = useState(true);
  const [campo, setCampo] = useState<any>("data");
  const [showModalData, setShowModalData] = useState(false);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [showDetalhesLembretes, setShowDetalhesLembretes] = useState(false);
  const [showDetalhesLembretesAlterar, setShowDetalhesLembretesAlterar] = useState(false);
  const { loja, setLoja } = useContext(MessageContext);

  const [exibirInfo, setExibirInfo] = useState(false);

  const [dataCadastro, setDataCadastro] = useState<any>("");
  const [cadastradoPor, setCadastradoPor] = useState<any>("");

  const [descricao, setDescricao] = useState<any>("");
  const [cliente, setCliente] = useState<any>("");
  const [perfil, setPerfil] = useState<any>("");

  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useEffect(() => {
    // primeira vez q entra e quando troca de loja
    console.log("entrou useEffect mudou loja - lembretes", loja);
    console.log("entrou useEffect mudou loja - lembretes", filtroSegment);
    setCampo("data");
    setMostrarFiltroData(true);
    setPesquisaData('Data');
    setFiltroSegment("");
    carregarLembretesSegmentadaData();
  }, [loja]
  );

  useEffect(() => {
    console.log("entrou useEffect mudou loja - campo, pesquisa, filtroSegment", campo);
    console.log("entrou useEffect mudou loja - campo, pesquisa, filtroSegment", pesquisa);
    console.log("entrou useEffect mudou loja - campo, pesquisa, filtroSegment", filtroSegment);
    if (pesquisa != "" || filtroSegment != "") {
      carregarLembretesSegmentadaData();
    }
  }, [pesquisa, filtroSegment]
  );

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
  });

  useIonViewDidEnter(() => {
    console.log("filtroSegment", filtroSegment);
  });

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  function doRefresh(event: any) {
    carregarLembretesSegmentadaData();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  function carregarDetalhesProjeto(projetoid: any, empresaid: any, abrirInserirAgendamento: boolean) {
    if (projetoid) {
      history.push("projetos/projetosdetalhes", { projetoid: projetoid, empresaid: empresaid, abrirInserirAgendamento: abrirInserirAgendamento, esconderOperacoes: true })
    }
  }


  function preencheCard(value: any, indice: any) {
    return (
      <IonItem lines="none" className={"cardLembretes"} key={"lembretes" + indice + value.valor + Math.random()}>
        <IonGrid style={{ padding: "0px" }}>

          <IonRow key={"lembretesrow1" + indice + value.valor + Math.random()} >

            <IonCol style={{ textAlign: "center" }} size="2" sizeMd="1" sizeLg="1">
              <IonLabel style={{ fontSize: "14px" }}>{value.data ?

                new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                  Date.parse(value.data)
                ).substring(0, 5)

                : ""}</IonLabel>
            </IonCol>

            <IonCol size="4" sizeMd="5" sizeLg="5">
              <IonLabel style={{ fontSize: "14px" }}>{value.descricao}</IonLabel>
            </IonCol>

            <IonCol size="3" sizeMd="4" sizeLg="4">
              <IonLabel style={{ fontSize: "14px" }}>{value.clienteNome}</IonLabel>
            </IonCol>

            {/* <IonCol size="2" sizeMd="2" sizeLg="2">
              <IonLabel style={{ fontSize: "14px" }}>{value.perfil}</IonLabel>
            </IonCol> */}

            <IonCol size="3" sizeMd="2" sizeLg="2">
              <IonLabel style={{ fontSize: "14px" }}></IonLabel>
            </IonCol>


            <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>

              <IonButtons slot='start'>


                <IonButton hidden={value.perfil != "cliente"}
                  onClick={() => {
                    if (value.enviarMensagem) {
                      setTextToast("Cliente vai ser avisado pelo sistema sobre esse lembrete.");
                    }
                    else {
                      setTextToast("Cliente não vai receber esse lembrete.");
                    }
                    setShowToast(true);
                  }}>
                  <IonIcon size="small" color={value.enviarMensagem == true ? "success" : "danger"} icon={notifications}></IonIcon>
                </IonButton>



                <IonButton
                  onClick={() => {

                    setCadastradoPor(value.cadastradoPor);
                    setDataCadastro(value.dataCadastro);

                    setPerfil(value.perfil);
                    setDescricao(value.descricao);
                    setCliente(value.clienteNome);
                    setExibirInfo(!exibirInfo)
                  }}>
                  <IonIcon icon={informationCircle} />
                </IonButton>

                <IonButton
                  onClick={() => {




                    mostartAlerta({
                      backdropDismiss: false,
                      header: 'Remover lembrete',
                      message: 'Deseja <strong>remover</strong>?',
                      buttons: [
                        {
                          text: 'Cancelar',
                          role: 'cancel',
                        },
                        {
                          text: 'Confirmar',
                          cssClass: 'primary',
                          handler: () => {
                            deletarLembrete(value.pk)
                          }
                        }
                      ],
                      onDidDismiss: (e) => console.log('did dismiss'),
                    })








                  }}>
                  <IonIcon icon={trash} />
                </IonButton>

              </IonButtons>
            </div>



          </IonRow>
        </IonGrid>
      </IonItem >
    )
  }

  const loadItens = () => {

    if (arrayLembretes === undefined || arrayLembretes.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader key="primeiraheader" >
            <IonCardTitle>Neste momento não existe nenhum lançamento.</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      )
    }

    return arrayLembretes.map((value: any, index: any, arr: any) =>

      (!arr[index - 1]) ?
        (<div key={"divlembretespai" + index}>
          <IonCard className={"card"} key={"lembretes" + index}>
            <IonCardHeader key={"headerlembretes" + index}>
              <IonCardTitle key={"titulolembretes" + index}>
                <div key={"divlembretes" + index} className={"cabecalho"} >{
                  new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(value.data)
                  )
                }</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          {preencheCard(value, index)}
        </div>)
        :
        (value.data.substring(0, 10) !== arr[index - 1].data.substring(0, 10)) ?
          (<div key={"divlembretespai" + index}>
            <IonCard className={"card"} key={"lembretes" + index}>
              <IonCardHeader key={"headerlembretes" + index}>
                <IonCardTitle key={"titulolembretes" + index}>
                  <div key={"divlembretes" + index} className={"cabecalho"} >{
                    new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                      Date.parse(value.data)
                    )
                  }</div>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
            {preencheCard(value, index)}
          </div>
          ) :
          (
            <div key={"divlembretespai" + index}>
              {preencheCard(value, index)}
            </div>
          )
    );
  }

  async function carregarLembretesSegmentadaData() {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let data = pesquisa;

      let valor = data;
      if (data) {
        valor = data.replaceAll(/#/g, "%23");
      }

      setShowLoadingPage(true);
      const resp: Response = await fetch(urlBase.url + "/lembretes?campo=" + campo +
        "&filtro=" + filtroSegment +
        "&valor=" + valor +
        "&lojas=" + Buffer.from(loja).toString('base64'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          setShowLoadingPage(false);
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setLembretespk(res.LastEvaluatedKey.pk);
          setLembretesk(res.LastEvaluatedKey.sk);
          setLembretesgsi2sk(res.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        setArrayLembretes(res.Items);
        setShowLoadingPage(false);
      }).finally(() => {
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  async function carregarMaisLembretes($event: CustomEvent<void>) {
    console.log("carregarMaisLembretes",);
    if (lembretesgsi2sk.includes(filtroSegment)) {
      await carregarMaisLembretesChamada().then(() => {
      });
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisLembretesChamada() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let valor = pesquisa;
    if (pesquisa) {
      valor = pesquisa.replaceAll(/#/g, "%23");
    }


    console.log(valor);

    const res: Response = await fetch(urlBase.url + "/lembretes?campo=" + campo +
      "&valor=" + valor +
      // "&filtro=" + filtroSegment +
      "&isk=" + lembretesgsi2sk +
      "&pk=" + lembretespk +
      "&sk=" + lembretesFunilk +
      "&lojas=" + Buffer.from(loja).toString('base64'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (lembretesFunil) => {

        if (lembretesFunil.Items) {
          for (let i = 0; i < lembretesFunil.Items.length; i++) {
            arrayLembretes.push(lembretesFunil.Items[i]);
          }

          if (lembretesFunil.LastEvaluatedKey) {
            setLembretespk(lembretesFunil.LastEvaluatedKey.pk);
            setLembretesk(lembretesFunil.LastEvaluatedKey.sk);
            setLembretesgsi2sk(lembretesFunil.LastEvaluatedKey.gsi2sk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setLembretespk("");
            setLembretesk("");
            setLembretesgsi2sk("");
            setDesabilitarInfiniteScroll(true);
          }
        }

      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");

  }


  function avancarLembretes() {
    setShowDetalhesLembretes(true);
  }

  async function handleSegmentChange(e: any) {
    setFiltroSegment(e.detail.value);
  }

  function readProjetosFiltro() {
    return listaProjeto.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"lembretesfiltro" + value.pk} value={value.pk}>{value.nomeProjeto}</IonSelectOption>
    )
    )
  };

  const openPopover = (e: any) => {

    setFiltroSegment("");

    popover.current!.event = e;
    setShowModalData(true);
  };

  async function closeModalLembretes() {
    setShowDetalhesLembretes(false);
    carregarLembretesSegmentadaData();
  }

  async function closeModalSimpleLembretes() {
    setShowDetalhesLembretes(false);
  }


  async function closeModalLembretesAlterar() {
    setShowDetalhesLembretesAlterar(false);
    carregarLembretesSegmentadaData();
  }

  async function closeModalSimpleLembretesAlterar() {
    setShowDetalhesLembretesAlterar(false);
  }


  function deletarLembrete(pk: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delLembrete = async () => {
      const resp = await fetch(removerLembrete.url.replace("{id}", pk.replace(/#/g, "%23")),
        {
          method: removerLembrete.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno", retorno);
      setTextToast("Lembrete removido com sucesso.");
      setShowToast(true);
      carregarLembretesSegmentadaData();
    }

    delLembrete();

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Lembretes</IonTitle>


          {/* <IonSelect style={{ width: '100%', background: "#FFFFFF", paddingRight: "20px" }} color='primary' title="Projetos" slot="" hidden={mostrarFiltroData} value={pesquisaNome} placeholder="Pesquisar" onIonChange={e => {
            setPesquisaNome(e.detail.value);
            setPesquisa(e.detail.value);
            carregarOperacoesSegmentada(statusOperacao, e.detail.value);
          }}>
            {readClientesFiltro()}
          </IonSelect> */}

          {/* <IonSelect interface='popover' style={{ fontSize: "14px" }} name='Filtros' title='Filtros' value={campo} multiple={false} cancelText="Cancelar" okText="Ok" onIonChange={e => { trocarCampoFiltro(e); }} slot="end">
              <IonSelectOption value="data">Data</IonSelectOption>
              <IonSelectOption value="nome">Projetos</IonSelectOption>
            </IonSelect> */}

          {/* <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={mostrarFiltroData} onClick={e => { setShowModalFiltro(true); }}>
              <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={pesquisa != ""}></IonIcon>
              <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaNome} </IonLabel>
            </IonButton> */}

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={!mostrarFiltroData} onClick={e => { openPopover(e); }} >
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={pesquisa != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaData} </IonLabel>
          </IonButton>

          {/* <IonModal isOpen={showModalFiltro} onDidDismiss={() => setShowModalFiltro(false)} >
              <AppTypeaheadItem
                title="Projetos"
                items={listaProjeto}
                selectedItem={pesquisa}
                onSelectionCancel={() => setShowModalFiltro(false)}
                onSelectionChange={filtroSelectionChanged}
              />
            </IonModal> */}

          {/* <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
              <IonButton onClick={() => { history.push("./chat"); }}>
                <IonIcon slot="icon-only" icon={chatbubbles} />
                <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
              </IonButton>
            </IonButtons> */}
        </IonToolbar>
        <IonToolbar style={{ height: "48px" }}>
          <IonSegment value={filtroSegment} onIonChange={(e) => { scrollToTop(); handleSegmentChange(e); }}>
            <IonSegmentButton style={{ height: "30px" }} value="01" >
              <IonLabel style={{ fontSize: "10px" }}>JAN</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="02" >
              <IonLabel style={{ fontSize: "10px" }}>FEV</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="03" >
              <IonLabel style={{ fontSize: "10px" }}>MAR</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="04" >
              <IonLabel style={{ fontSize: "10px" }}>ABR</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="05" >
              <IonLabel style={{ fontSize: "10px" }}>MAI</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="06" >
              <IonLabel style={{ fontSize: "10px" }}>JUN</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="07" >
              <IonLabel style={{ fontSize: "10px" }}>JUL</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="08" >
              <IonLabel style={{ fontSize: "10px" }}>AGO</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="09" >
              <IonLabel style={{ fontSize: "10px" }}>SET</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="10" >
              <IonLabel style={{ fontSize: "10px" }}>OUT</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="11" >
              <IonLabel style={{ fontSize: "10px" }}>NOV</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="12" >
              <IonLabel style={{ fontSize: "10px" }}>DEZ</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

      </IonHeader>


      <IonContent fullscreen scrollEvents={true} ref={contentRef} color='warning'>



        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.lembretes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            avancarLembretes();
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab vertical="top" horizontal="end" slot="fixed" edge hidden={isPlatform("android") || isPlatform("ios")}>
          <IonFabButton color="tertiary" onClick={() => { carregarLembretesSegmentadaData(); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>




        <div className='LembretesSlides'>


          <div style={{ position: "absolute", right: "14px", zIndex: 9999999, paddingTop: "10px", backgroundColor: "#ffffff", borderColor: "#000000", borderRadius: "10px 10px 10px 10px", textAlign: "right", padding: "10px 10px 10px 10px", marginTop: "16px", border: "1px solid #e0e0e0" }} hidden={!exibirInfo}>
            <div>
              <div style={{ position: "relative", top: "0px", left: "0px", textAlign: "left" }}>
                {/* <IonButton fill="clear" slot="start" size="small" color="tertiary" onClick={() => { setExibirInfo(false); }} > */}
                <IonIcon size="large" slot="icon-only" icon={close} onClick={() => { setExibirInfo(false); }}></IonIcon>
                {/* </IonButton> */}
              </div>
              <IonList style={{ paddingTop: "0px" }}>

                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px", paddingInlineStart: "0px" }} lines="none">
                  <IonLabel style={{ textTransform: 'capitalize' }}>Nome: </IonLabel>
                  {cliente}
                </IonItem>
                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px", paddingInlineStart: "0px" }} lines="none">
                  <IonLabel>Perfil: </IonLabel>
                  {perfil}
                </IonItem>

                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px", paddingInlineStart: "0px" }} lines="none">
                  <IonLabel>Cadastrado por: </IonLabel>
                  {cadastradoPor}
                </IonItem>


                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px", paddingInlineStart: "0px" }} lines="none">
                  <IonLabel>Data Cadastro: </IonLabel>

                  {dataCadastro ?

                    new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                      Date.parse(dataCadastro)
                    )

                    : ""}



                </IonItem>


                <IonItem style={{ fontSize: "12px" }} lines="none">
                  <IonLabel>Descrição: </IonLabel>
                  <IonTextarea disabled={true} style={{ "--background": "#eeeeee", marginLeft: "10px" }} rows={6} value={descricao} maxlength={250} placeholder="" onIonInput={(e: any) => setDescricao(e.target.value)}></IonTextarea>
                </IonItem>



              </IonList>

            </div>

          </div>

          {loadItens()}





        </div>

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisLembretes(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais lançamentos..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonToast
          isOpen={showCopiado}
          onDidDismiss={() => setShowCopiado(false)}
          message="Campo copiado."
          duration={3000}
        />

        <div className="content">
          <IonModal isOpen={showDetalhesLembretes} onDidDismiss={() => { setShowDetalhesLembretes(false); }}>
            <LembretesCad lembrete={null} closeAction={closeModalLembretes} closeActionSimple={closeModalSimpleLembretes}></LembretesCad>
          </IonModal>

        </div>

        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />



      </IonContent>

      <IonPopover ref={popover}
        isOpen={showModalData}
        onDidDismiss={() => setShowModalData(false)} >
        <IonDatetime
          presentation='date'
          onIonChange={e => {
            if (e.detail.value) {
              let data = e.detail.value.toString().substring(0, 10);
              const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                Date.parse(data)
              );
              setPesquisaData(dataFormatada);
              setPesquisa(data);
            }
            //setShowModalData(false);
          }}></IonDatetime>

      </IonPopover >
    </IonPage>
  );
};

export default Lembretes;
