import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPage, IonRow, IonSegment, IonSegmentButton, IonSlide, IonSlides, IonTextarea, IonTitle, IonToggle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import "./EmpresaWorkflow.css";

import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { Toast } from '@capacitor/toast';
import imageCompression from 'browser-image-compression';
import * as icons from 'ionicons/icons';
import { addCircle, alertCircle, arrowBack, camera, create, informationCircleOutline, logoUsd, receipt } from 'ionicons/icons';
import { empresaRegistrarInformacao, empresaWorkflowCarregar, removerArquivoEmpresa, removerFotoEmpresa, requestService, urlBase } from '../../../../../Utils/Services';
import { UserPhoto } from '../../../../../components/usePhotoGallery';

import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { MessageContext } from '../../../../../contexts/MessageContext';

type IconType = typeof import('ionicons/icons/index');


type Props = {
  abrirMenu: boolean;
  empresaid: string;
  onDismiss: () => void;
};

const EmpresaWorkflow: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();

  const [dadosProjeto, setDadosProjeto] = useState<any>([]);
  const [operacaoCarregada, setOperacaoCarregada] = useState(false);
  const [temHoraFim, setTemHoraFim] = useState(false);

  const [showModalInformacoes, setShowModalInformacoes] = useState(false);
  const [tituloInformacoes, setTituloInformacoes] = React.useState<string>();
  const [textoInformacoes, setTextoInformacoes] = React.useState<string>();


  const [showModalValores, setShowModalValores] = useState(false);
  const [tituloValores, setTituloValores] = React.useState<string>();
  const [textoValores, setTextoValores] = React.useState<string>();

  const [showModalArquivos, setShowModalArquivos] = useState(false);
  const [tituloArquivos, setTituloArquivos] = React.useState<string>();
  const [fileArquivos, setFileArquivos] = useState<any>();
  const [textoArquivos, setTextoArquivos] = React.useState<string>();

  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);

  const [selectedTab, setSelectedTab] = useState<string>("0");
  const sliderRef = useRef<HTMLIonSlidesElement>(null);

  const [informacoes, setInformacoes] = useState<any>([]);
  const [valores, setValores] = useState<any>([]);
  const [fotos, setFotos] = useState<any>([]);
  const [arquivos, setArquivos] = useState<any>([]);
  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  const [showLoadingPage, setShowLoadingPage] = useState(false);

  const [checkedAssinarDocumento, setCheckedAssinarDocumento] = useState(false);

  const [caminhoParaExcluir, setCaminhoParaExcluir] = React.useState<string>();
  const [caminhoIndiceParaExcluir, setCaminhoIndiceParaExcluir] = React.useState<string>();
  const [showAlertConfirmarExclusao, setShowAlertConfirmarExclusao] = useState(false);
  const [showAlertConfirmarExclusaoFoto, setShowAlertConfirmarExclusaoFoto] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const inputRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarEmpresa();
  });

  let params: any = {
    empresaid: "",
  }
  params = useParams();

  if (location && location.state && location.state.empresaid) {
    params.empresaid = location.state.empresaid;
  }


  if (props.empresaid) {
    params = {
      empresaid: props.empresaid
    }
  }

  const takePhoto = async (loja: string, indice: string) => {

    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Prompt, //CameraSource.Camera || CameraSource.Photos,//.Prompt,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
      //quality: 80,
      //preserveAspectRatio: true,

      //saveToGallery: true,
      //width: 600,
      //height: 600
    });

    var binary_string = window.atob(cameraPhoto.base64String || '');
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes.buffer)], {
      type: `image/${cameraPhoto.format}`,
    });

    const file = new File([blob], "Name", {
      lastModified: new Date().getTime(),
      type: blob.type,
    });

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1102,
      useWebWorker: true,
      initialQuality: 0.5
    }

    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB


      let base64 = await getBase64(compressedFile);
      await incluirFoto(loja, indice, base64, "", "Fotos");

    } catch (error) {
      console.log(error);
    }

  };

  async function getBase64(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }

  async function incluirAquivo(projetoId: string, indice: string, file: any, fileTypeArquivo: any, nomeArquivo: string, tipoArquivo: string) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let params = {
        nome: nomeArquivo,
        campo: tipoArquivo,
        mensagem: fileTypeArquivo,
        indice: indice,
        assinarDocumento: checkedAssinarDocumento
      }

      try {
        setShowLoadingPage(true);

        console.log("params", params);
        const resp = await fetch(empresaRegistrarInformacao.url.replaceAll("{pk}", projetoId.replaceAll(/#/g, "%23")),
          {
            method: empresaRegistrarInformacao.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        console.log("response", response);
        if (response.Item) {



          console.log("file.type", file.type);
          const uploadToS3 = await fetch(response.Item, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadToS3.status == 200) {

            Toast.show({
              text: "Arquivo foi adicionado com sucesso.",
              position: "center",
              duration: "long"
            });

            setTextoArquivos("");
            setFileArquivos("");
          }
          else {
            Toast.show({
              text: "Ocorreu um erro ao fazer o upload do arquivo.",
              position: "center",
              duration: "long"
            });

          }

          setShowModalInformacoes(false);
          setShowModalValores(false);
        }
        setShowLoadingPage(false);

      } catch (error) {
        console.log("erro", error);
        setShowLoadingPage(false);
      }
    }

    atualizar();

  };


  async function incluirFoto(projetoId: string, indice: string, base64: any, nomeArquivo: string, tipoArquivo: string) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let params = {
        nome: nomeArquivo,
        campo: tipoArquivo,
        mensagem: base64,
        indice: indice
      }

      try {
        setShowLoadingPage(true);

        const resp = await fetch(empresaRegistrarInformacao.url.replaceAll("{pk}", projetoId.replaceAll(/#/g, "%23")),
          {
            method: empresaRegistrarInformacao.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        if (response.Item.Attributes) {


          setShowModalInformacoes(false);
          setShowModalValores(false);
          preencherDadosOperacao(response.Item.Attributes, false);

          setTextoArquivos("");
          setFileArquivos("");
          console.log("retornoobjetocheio", response);
        }
        setShowLoadingPage(false);


      } catch (error) {
        console.log("erro", error);
        setShowLoadingPage(false);
      }
    }

    atualizar();

  };

  function carregarEmpresa() {
    if (!operacaoCarregada) {
      setOperacaoCarregada(true);

      const getoperacao = async () => {
        var token: string = "";
        var tkn = localStorage.getItem("token");
        if (typeof (tkn) === "string") { token = tkn; }
        setShowLoadingPage(true);
        const resp = await fetch(empresaWorkflowCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')),
          {
            method: empresaWorkflowCarregar.method,
            headers: [
              ["token", token]
            ],
            credentials: 'include'
          });
        const operacao = await resp.json();
        if (operacao.Item !== undefined) {


          preencherDadosOperacao(operacao.Item, true);


          console.log("abrirInserirAgendamento", params.abrirInserirAgendamento);
          setTemHoraFim(operacao.Item.dataEncerramento ? true : false);

        }
        setShowLoadingPage(false);
      }

      getoperacao();
    }
    //}
    return "";
  }

  function carregarEmpresaSemTrocarAba() {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    const getoperacao = async () => {
      const resp = await fetch(urlBase.url + "/projetos/" + loja.replaceAll(/#/g, '%23'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      const operacao = await resp.json();
      //if (resp.status === 400 || resp.status === 401) history.replace("/login/");
      if (operacao.Item !== undefined) {


        preencherDadosOperacao(operacao.Item, false);


        console.log("abrirInserirAgendamento", params.abrirInserirAgendamento);
        setTemHoraFim(operacao.Item.dataEncerramento ? true : false);

      }
    }

    getoperacao();

    return "";
  }

  function preencherDadosOperacao(projeto: any, slideDoStatus: Boolean) {

    console.log("operacao", projeto);
    setDadosProjeto(projeto);


    let preencherInformacoes: any[] = [];
    let preencherValores: any[] = [];
    let preencherFotos: any[] = [];
    let preencherArquivos: any[] = [];
    let newPhotosProjeto: UserPhoto[] = [];
    let newArquivosProjeto: UserPhoto[] = [];

    console.log("contar", projeto.configWorkflow.length);
    projeto.configWorkflow.map((value: any, index: any) => {
      console.log("preencherDadosOperacao_slideDoStatus", slideDoStatus);
      if (slideDoStatus && value.status === projeto.statusWorkflow) {
        sliderRef.current?.slideTo(index);
      }
      preencherInformacoes.push(value.campoInformacoes);
      preencherValores.push(value.campoValores);


      if (value.campoFotos) {
        let _photos: string[] = value.campoFotos;


        _photos.map(async (valorPath: any, indexPath: any) => {
          newPhotosProjeto.push({ filepath: valorPath, webviewpath: valorPath });
        })

        preencherFotos.push(newPhotosProjeto);
        newPhotosProjeto = [];

      }
      else {
        preencherFotos.push("");
      }

      if (value.campoArquivos) {
        let _photos: string[] = value.campoArquivos;


        _photos.map(async (valorPath: any, indexPath: any) => {
          newArquivosProjeto.push({ filepath: valorPath, webviewpath: valorPath });
        })

        preencherArquivos.push(newArquivosProjeto);
        newArquivosProjeto = [];

      }
      else {
        preencherArquivos.push("");
      }





    });


    console.log("preencherInformacoes", preencherInformacoes);
    setInformacoes(preencherInformacoes);
    setValores(preencherValores);
    setFotos(preencherFotos);
    console.log("preencherFotos", preencherFotos);
    console.log("preencherArquivos", preencherArquivos);
    setArquivos(preencherArquivos);
  }

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  function onTabChange(value: string) {
    console.log(value);
    if (value === "" || value == null || value === undefined) return;

    sliderRef.current?.slideTo(parseInt(value));
  }


  async function onSlideChange() {
    let slideIndex = await sliderRef?.current?.getActiveIndex();

    console.log(slideIndex);

    if (slideIndex == null || slideIndex === undefined)
      return;

    setSelectedTab(slideIndex.toString());

    document.getElementById("tab-" + slideIndex)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }


  function deletarFoto(url: any, indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    setShowLoadingPage(true);
    const delFoto = async () => {
      const resp = await fetch(removerFotoEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerFotoEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'photo': url, 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();


      console.log("retorno", retorno);


      if (retorno.Item && retorno.Item.Attributes && retorno.Item.Attributes) {


        preencherDadosOperacao(retorno.Item.Attributes, false);


      }
      setShowLoadingPage(false);
    }

    delFoto();


  }

  function deletarArquivo(url: any, indice: any) {




    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    setShowLoadingPage(true);
    const delFoto = async () => {
      const resp = await fetch(removerArquivoEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerArquivoEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'arquivo': url, 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();

      console.log("retorno", retorno);


      if (retorno.Item && retorno.Item.Attributes && retorno.Item.Attributes) {


        preencherDadosOperacao(retorno.Item.Attributes, false);


      }
      setShowLoadingPage(false);
    }

    delFoto();


  }


  function preencheFotos(value: any, indice: any) {

    return (
      (fotos[indice]) ?
        (<>
          {fotos[indice].map((photo: any, index: any) => (
            <IonCol size="6" key={index} >
              <div>

                <a target='_blank' href={photo.webviewpath?.replace("small", "big")}>

                  <IonImg src={photo.webviewpath} />
                </a>

                <IonButton fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                  setCaminhoParaExcluir(photo.webviewpath); setCaminhoIndiceParaExcluir(indice); setShowAlertConfirmarExclusaoFoto(true);
                }}>Excluir</IonButton>


              </div>
            </IonCol>
          ))
          }

        </>) :
        (
          <>
            {/* {preencheCard(value, index)} */}
          </>
        )
    )
  }

  function preencheArquivos(value: any, indice: any) {

    return (
      (arquivos[indice]) ?
        (<>
          {arquivos[indice].map((arq: any, index: any) => (
            <IonCol size="6" key={index} >
              <div>
                <a target='_blank' href={arq.webviewpath?.split(";")[1]}>
                  {arq.webviewpath?.split(";")[0].split("|")[0]}
                </a>

                <IonButton style={{ margin: '10px 0 10px 0', fontWeight: 'normal', width: '100%', "font-size": "12px", "font-weight": "bold" }} hidden={arq.webviewpath?.split(";")[0].split("|")[1] != "cont"} fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                  Toast.show({
                    text: "O Cliente ainda não assinou o documento.",
                    position: "center",
                    duration: "long"
                  });
                }}>Não Assinado</IonButton>

                <IonButton style={{ margin: '10px 0 10px 0', fontWeight: 'normal', width: '100%' }} fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                  setCaminhoParaExcluir(arq.webviewpath); setCaminhoIndiceParaExcluir(indice); setShowAlertConfirmarExclusao(true);
                }}>Excluir</IonButton>
              </div>
            </IonCol>
          ))}

        </>) :
        (
          <>
            {/* {preencheCard(value, index)} */}
          </>
        )
    )
  }


  function preencheCard(value: any, index: any) {


    return (
      (<>
        <IonList style={{ "margin": "0px" }}>
          <IonItem style={{ "margin": "0px" }} lines="none" hidden={value.exibirInformacoes === "false" || (window.localStorage.getItem("perfil") == "usuario" && value.somenteAdministrador == "true")}>
            <IonIcon style={{ "margin-right": "6px" }} icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              {/* <h1>Informações</h1> */}

              <IonListHeader hidden={false} lines="full">
                <IonLabel>Informações</IonLabel>
                <IonItem lines="none" hidden={acessos?.configuracoes != 'edicao'}>
                  <IonIcon src={create} color="primary" onClick={async () => { setTituloInformacoes(value.status); setTextoInformacoes(informacoes[index]); setShowModalInformacoes(true); }}></IonIcon>
                </IonItem>
              </IonListHeader>
              <div dangerouslySetInnerHTML={{ __html: informacoes[index] }} style={{ whiteSpace: "pre-wrap", paddingTop: "6px" }} >
              </div>
            </div>
          </IonItem>
          <IonItem style={{ "margin": "0px" }} lines="none" hidden={value.exibirValor === "false" || (window.localStorage.getItem("perfil") == "usuario" && value.somenteAdministrador == "true")}>
            <IonIcon style={{ "margin-right": "6px" }} icon={logoUsd} slot="start" />
            <div className="contentList">


              <IonListHeader hidden={false} lines="full">
                <IonLabel>Valores</IonLabel>
                <IonItem lines="none" hidden={acessos?.configuracoes != 'edicao'}>
                  <IonIcon src={create} color="primary" onClick={async () => { setTituloValores(value.status); setTextoValores(valores[index]); setShowModalValores(true); }}></IonIcon>
                </IonItem>
              </IonListHeader>

              <div dangerouslySetInnerHTML={{ __html: valores[index] }} style={{ whiteSpace: "pre-wrap", paddingTop: "6px" }} >
              </div>
            </div>
          </IonItem>


          <IonItem style={{ "margin": "0px" }} lines="none" hidden={value.exibirFotos === "false" || (window.localStorage.getItem("perfil") == "usuario" && value.somenteAdministrador == "true")}>
            <IonIcon style={{ "margin-right": "6px" }} icon={camera} slot="start" />
            <div className="contentList">
              {/* <h1>Fotos</h1> */}


              <IonListHeader hidden={false} lines="full">
                <IonLabel>Fotos</IonLabel>
                <IonItem lines="none" hidden={acessos?.configuracoes != 'edicao'}>
                  <IonIcon src={addCircle} color="primary" onClick={async () => { await takePhoto(loja, index.toString()).catch((e) => { console.log("cancelado"); }); }}></IonIcon>
                </IonItem>
              </IonListHeader>




              <IonList lines="full" class="ion-no-margin">
                <IonGrid>
                  <IonRow>

                    {preencheFotos(value, index)}
                  </IonRow>
                </IonGrid>
              </IonList>
            </div>
          </IonItem>

          <IonItem style={{ "margin": "0px" }} lines="none" hidden={value.exibirArquivos === "false" || (window.localStorage.getItem("perfil") == "usuario" && value.somenteAdministrador == "true")}>
            <IonIcon style={{ "margin-right": "6px" }} icon={receipt} slot="start" />
            <div className="contentList">

              <IonListHeader hidden={false} lines="full">
                <IonLabel>Arquivos</IonLabel>
                <IonItem lines="none" hidden={acessos?.configuracoes != 'edicao'}>
                  <IonIcon src={addCircle} color="primary" onClick={async () => { setTituloArquivos(value.status); setShowModalArquivos(true) }}></IonIcon>
                </IonItem>
              </IonListHeader>




              <IonList lines="full" class="ion-no-margin">
                <IonGrid>
                  <IonRow>

                    {preencheArquivos(value, index)}
                  </IonRow>
                </IonGrid>
              </IonList>
            </div>
          </IonItem>
        </IonList>


      </>)
    )
  }

  function loadItensMenu(value: any, index: any) {

    let nomeIcone: string = value.icone;
    return (

      <IonSegmentButton value={index} id={"tab-" + index}>

        <p><IonIcon icon={icons[nomeIcone as keyof IconType]} ></IonIcon></p>
      </IonSegmentButton>

    )
  }


  function registrarInformacao(pk: any, campo: any, mensagem: any, indice: any) {
    console.log("pk", pk);
    console.log("mensagem", mensagem);
    console.log("indice", indice);

    if (!mensagem || mensagem.replace(/<p><br><\/p>/g, "").trim() == "") {
      setAlertaPreenchimento(true);
      return null;
    }

    let params = {
      campo: campo,
      mensagem: mensagem,
      indice: indice
    };
    console.log("params", params);
    requestService(empresaRegistrarInformacao.url.replaceAll("{pk}", pk.replaceAll(/#/g, "%23")), {
      method: empresaRegistrarInformacao.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(params),
      credentials: 'include'
    },
      history,
      (response: any) => {
        console.log(response);

        setShowModalInformacoes(false);
        setShowModalValores(false);
        preencherDadosOperacao(response.message.Attributes, false);

      },
      (error: any) => {
        console.log(error);
      });



  }



  const handleUpload = async (loja: string, indice: string) => {

    if (!fileArquivos || !textoArquivos || textoArquivos.trim() == "") {
      setAlertaPreenchimento(true);
      return null;
    }

    console.log("fileArquivos", fileArquivos);


    let file = fileArquivos.files[0];
    console.log("file", file);

    if (file.size < 15000000) {
      let fileParts = file.name.split('.');
      let fileNameArquivo = fileParts[0];
      let fileTypeArquivo = fileParts[1];
      console.log("Preparing the upload");
      console.log(fileParts);

      try {
        incluirAquivo(loja, indice, file, fileTypeArquivo, textoArquivos || "", "Arquivos");

      } catch (error) {
        console.log("error", error);
        Toast.show({
          text: "Ocorreu um erro ao adicionar um arquivo, tente novamente.",
          position: "center",
          duration: "long"
        });
      }



    }
    else {
      Toast.show({
        text: "Somente arquivos até 15 MB.",
        position: "center",
        duration: "long"
      });
    }
  }

  const TOOLBAR_OPTIONS = [
    ["bold", "italic", "underline", "strike"],
    [{ color: ["black", "red", "blue", "green", "gray", "orange"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ['link', 'video'],

    ["clean"],

  ];

  return (

    <IonPage className="EmpresaWorkflow">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}><IonIcon slot="icon-only" icon={arrowBack} /></IonButton>
          </IonButtons>
          {/* <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={albums} />
            </IonButton>
          </IonButtons> */}

          <IonTitle slot="start">
            {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
              valor.empresaLojaId == loja ?
                valor.empresaLojaNome
                :
                ""
            ))}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>

        <div className="header">
          <div className="info" >
            <IonToolbar>
              <IonSegment scrollable value={selectedTab} onIonChange={(e) => { scrollToTop(); onTabChange(e.detail.value ?? "") }}>
                {JSON.parse(window.localStorage.getItem("configWorkflow") || "[]").map((valor: any, index: any) => (
                  loadItensMenu(valor, index)
                ))}
              </IonSegment>
            </IonToolbar>
          </div>
        </div>
        <div>
          <IonSlides className={"ItinerarioSlides"} ref={sliderRef} onIonSlideDidChange={() => { onSlideChange(); }}>
            {JSON.parse(window.localStorage.getItem("configWorkflow") || "[]").map((value: any, index: any) => (
              <IonSlide>
                <IonList>
                  <IonListHeader lines="full">
                    <IonLabel>{value.status}</IonLabel>
                  </IonListHeader>
                  {preencheCard(value, index)}
                </IonList>
              </IonSlide >
            ))}

          </IonSlides>
        </div>







        <IonModal isOpen={showModalInformacoes} onDidDismiss={() => { setShowModalInformacoes(false); setTextoInformacoes(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Informações</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalInformacoes(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem lines="none">
                <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
                <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
              </IonItem>
              <IonItem lines="none">
                <IonLabel >Informações referente a etapa de <b>{tituloInformacoes}</b>:</IonLabel>




              </IonItem>
              <div style={{ "padding": "4px" }}>
                <ReactQuill
                  theme="snow"
                  value={textoInformacoes}
                  onChange={e => setTextoInformacoes(e)}
                  modules={{
                    toolbar: {
                      container: TOOLBAR_OPTIONS
                    }
                  }}
                />
              </div>
            </IonList>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' onClick={() => { registrarInformacao(loja.replaceAll(/#/g, '%23'), "Informações", (textoInformacoes || ""), selectedTab) }}>Inserir informações</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>





        <IonModal isOpen={showModalValores} onDidDismiss={() => { setShowModalValores(false); setTextoValores(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Valores</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalValores(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonList>
              <IonItem lines="none">
                <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
                <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
              </IonItem>
              <IonItem lines="none" >
                <IonLabel >Valores referente a etapa de <b>{tituloValores}</b>:</IonLabel>
              </IonItem>
              <div style={{ "padding": "4px" }}>
                <ReactQuill
                  theme="snow"
                  value={textoValores}
                  onChange={e => setTextoValores(e)}
                  modules={{
                    toolbar: {
                      container: TOOLBAR_OPTIONS
                    }
                  }}
                />
              </div>
            </IonList>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' onClick={() => { registrarInformacao(loja.replaceAll(/#/g, '%23'), "Valores", (textoValores || ""), selectedTab) }}>Inserir informações</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showModalArquivos} onDidDismiss={() => { carregarEmpresaSemTrocarAba(); setShowModalArquivos(false); setTextoArquivos(''); setFileArquivos(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Adicionar Arquivos</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalArquivos(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonItem lines="none">
              <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
              <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
            </IonItem>
            <IonItem lines="none" >
              <IonLabel className="ion-text-wrap" position="stacked">Arquivo referente a etapa de <b>{tituloArquivos}</b>:</IonLabel>
              <IonTextarea rows={1} maxlength={20} value={textoArquivos} ref={(ref) => inputRef.current = ref} autoCorrect='true' autoCapitalize='true' placeholder="Nome do arquivo" onIonChange={e => setTextoArquivos(e.detail.value!)}></IonTextarea>
            </IonItem>

            <IonItem lines="none">
              <IonLabel>Assinar documento?</IonLabel>
              <IonToggle checked={checkedAssinarDocumento} onIonChange={e => {
                setCheckedAssinarDocumento(e.detail.checked);
              }} />
            </IonItem>

            <IonItem lines="none" >
              <input ref={(ref) => { setFileArquivos(ref); }} type="file" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*" />
            </IonItem>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' onClick={() => { handleUpload(loja, selectedTab.toString()).catch((e) => { console.log("cancelado"); }); }}>Adicionar Arquivo</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>





        <IonAlert isOpen={showAlertConfirmarExclusao}
          onDidDismiss={() => setShowAlertConfirmarExclusao(false)}
          header={'Confirmar Exclusão'}
          message={'Você <strong>confirma</strong> excluir esse arquivo?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                deletarArquivo(caminhoParaExcluir, caminhoIndiceParaExcluir);
                setShowAlertConfirmarExclusao(false);
              }

            }
          ]}
        />

        <IonAlert isOpen={showAlertConfirmarExclusaoFoto}
          onDidDismiss={() => setShowAlertConfirmarExclusaoFoto(false)}
          header={'Confirmar Exclusão'}
          message={'Você <strong>confirma</strong> excluir essa imagem?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                deletarFoto(caminhoParaExcluir, caminhoIndiceParaExcluir);
                setShowAlertConfirmarExclusaoFoto(false);
              }

            }
          ]}
        />


        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>
    </IonPage>
  );


};

export default EmpresaWorkflow;