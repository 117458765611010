import { IonContent, IonPage, useIonViewDidEnter } from "@ionic/react";
import QRCode from 'qrcode';
import { useContext, useState } from "react";
import { useLocation, useParams } from "react-router";
import { MessageContext } from "../../../../../contexts/MessageContext";

type Props = {
  dadosProjeto: any;
};


const ProjetosCupomHtml: React.FC<Props> = (props: Props) => {

  const location = useLocation<any>();
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const [htmlContent, setHtmlContent] = useState<any>();


  let params: any = {
    dadosProjeto: "",
    esconderAgendamentos: false,
    esconderOperacoes: false,
  }
  params = useParams();

  if (location && location.state && location.state.dadosProjeto) {
    params.dadosProjeto = location.state.dadosProjeto;
    params.esconderAgendamentos = location.state.esconderAgendamentos;
    params.esconderOperacoes = location.state.esconderOperacoes;
  }



  if (props.dadosProjeto) {
    params = {
      dadosProjeto: props.dadosProjeto
    }
  }

  useIonViewDidEnter(() => {

    console.log("projetoscupomhtml");
    setEsconderMenu(true);
    setNomeMenu("");
    if (params.dadosProjeto) {

      console.log("params.dadosProjeto", params.dadosProjeto);
      setHtmlContent(montarCupomHtml(params.dadosProjeto));
    }
  });

  const generateHTMLContent = () => {
    // Aqui você pode gerar o conteúdo HTML dinamicamente
    return `
      <html>
        <head>
          <title>Documento para Impressão</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
            }
            h1 {
              color: #333;
            }
            .content {
              margin-top: 20px;
            }
          </style>
        </head>
        <body>
          <h1>Este é o Título</h1>
          <div class="content">
            <p>Este é um conteúdo de exemplo gerado dinamicamente.</p>
            <p>Você pode adicionar mais elementos HTML conforme necessário.</p>
          </div>
        </body>
      </html>
    `;
  };

  const openNewTabWithHTML = () => {
    let newWindow: any;
    newWindow = window.open('', '_blank');

    // Abre uma nova aba com o conteúdo gerado
    newWindow.document.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close();
  };

  async function montarCupomHtml(projeto: any) {

    function removerAcentosECedilha(texto: any) {
      return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ç/g, 'c').replace(/Ç/g, 'C');
    }

    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, ''); // Remove tudo que não é dígito

      if (texto.length === 11) {
        // Máscara para CPF
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        // Máscara para CNPJ
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }

      return texto; // Retorna o texto sem máscara se não tiver 11 ou 14 dígitos
    }

    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';
      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });
      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }
      return linhas;
    }
    let dataCupom: any;
    if (projeto.dataCupom) {
      dataCupom = new Date(projeto.dataCupom);
      //dataCupom.setHours(dataCupom.getHours() - 3);
    }
    let html = `
      <!DOCTYPE html>
      <html lang="pt-BR">
         <head>
            <meta charset="UTF-8">
            <meta nomeProjeto="viewport" content="width=device-width, initial-scale=1.0">
            <title>CUPOM NÃO FISCAL</title>
            <style>
               body {
               font-family: 'Courier New', Courier, monospace;
               margin: 0;
               padding: 0;
               }
            <style>
               @media print {
               body {
               width: 370px; /* Define a largura da página como a largura do papel da impressora térmica */
               margin: 0;
               }
               .no-print {
               display: none; /* Esconde elementos que não devem aparecer na impressão */
               }
               }
               .bold {
               font-weight: bold;
               }
               .regular {
               font-size: 12pt;
               }
               .regular-itens {
               font-size: 12pt;
               }
               .table-container {
               width: 100%;
               max-width: 370px;
               margin: 0 auto;
               text-align: left;
               }
               table {
               width: 100%;
               border-collapse: collapse;
               }
               th, td {
               border: none;
               padding: 5px;
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               th {
               background-color: #f2f2f2;
               }
               .header, .footer {
               margin-top: 20px;
               }
               .footer {
               margin-top: 40px;
               }
               .separator {
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               /* CSS for the giant button */
               .print-button {
               display: block;
               width: 100%;
               padding: 20px;
               margin: 20px 0;
               background-color: #4CAF50; /* Green background */
               color: white;
               font-size: 24px;
               font-weight: bold;
               text-align: center;
               border: none;
               border-radius: 10px;
               cursor: pointer;
               }
               .print-button:hover {
               background-color: #45a049; /* Darker green on hover */
               }
               .close-button {
               position: absolute;
               top: 10px;
               right: 10px;
               padding: 10px 20px;
               background-color: #f44336; /* Red background */
               color: white;
               font-size: 16px;
               font-weight: bold;
               border: none;
               border-radius: 5px;
               cursor: pointer;
               }
               .close-button:hover {
               background-color: #d32f2f; /* Darker red on hover */
               }
            </style>
            <div class="table-container">
               `;
    if ((projeto.interno)) {
      html += `
               <div class="header bold">
                  <div>${projeto.clienteNome}</div>
               </div>
               `;
    }
    else {
      html += `
               <div class="header bold">
                  <div>${projeto.empresaNome}</div>
                  <div class="regular">CNPJ: ${aplicarMascaraCPFouCNPJ(projeto.empresaCnpj)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${projeto.empresaEndereco}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
                  <div class="regular">Tel: ${formataTelefone(projeto.empresaTelefone)}</div>
                  <div class="regular">
                     ${(dataCupom)
          ? `Data: ${dataCupom.toLocaleDateString('pt-BR')} Hora: ${dataCupom.toLocaleTimeString('pt-BR')}`
          : ""}
                  </div>
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">
                  <div>${projeto.clienteNome}</div>
               </div>
               <div class="header bold">
                  <div class="regular">Tel: ${formataTelefone(projeto.clienteTelefone)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${[projeto.endereco, projeto.complemento]
            .filter(Boolean)
            .join(', ') || ""}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">CUPOM NÃO FISCAL</div>
               `;
    }
    html += `
               <div class="separator">======================================</div>
               <div class="regular">${projeto.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}</div>
               <div class="separator">======================================</div>
               `;
    if (projeto.produtos && projeto.produtos.length > 0) {
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td colspan="2">QTDE&nbsp|&nbspDESCRIÇÃO</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        ${projeto.produtos.map((iv: any, index: any) => `
                        ${(Array.isArray(iv.descricao)) ? (
          iv.descricao.map((itemDesc: any, indexLinha: any) => `
                        ${indexLinha === 0 ?
              quebrarTextoEmLinhas(iv.tipo === "pizzas" ? `${iv.tamanho} ${iv.quantidadeSabores} SABORES (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})` : `${iv.categoria} (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha2) => `
                        <tr>
                           ${indexLinha2 === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
              : ''}
                        ${(itemDesc.descricao) && (
              quebrarTextoEmLinhas(`- ${itemDesc.descricao} (R$ ${itemDesc.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha) => `
                        <tr>
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')
            )}
                        `).join('')
        ) : (
          quebrarTextoEmLinhas(`${iv.descricao} (R$ ${iv.valor.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })})`, 30)
            .map((linha, indexLinha) => `
                        <tr>
                           ${indexLinha === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
        )}
                        <tr>
                           <td colspan="2" class="regular-itens" style="text-align: left;">VALOR: R$ ${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        `).join('')}
                     </tbody>
                  </table>
               </div>
               `;
      if (!(projeto.interno)) {
        html += `
               <div class="bold">TOTAL: R$ ${projeto.valorTotalProdutos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      }
    }
    if (projeto.servicos && projeto.servicos.length > 0) {
      if (projeto.produtos && projeto.produtos.length > 0) {
        html += `
               <div class="separator">**************************************</div>
               `;
      }
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td>#</td>
                           <td>DESCRIÇÃO</td>
                           <td>QTD</td>
                           <td>VALOR</td>
                        </tr>
                        ${projeto.servicos.map((iv: any, index: any) => `
                        <tr>
                           <td class="regular-itens">${quebrarTextoEmLinhas(`${iv.descricao} x ${iv.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 30)
          .map((linha, indexLinha) => `
                        <tr>
                           <td class="regular-itens">${indexLinha == 0 ? String(index + 1).padStart(2, '0') : "&nbsp"}</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')}</td> 
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td class="regular-itens" style="text-align: right;">${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>`).join('')}
                     </tbody>
                  </table>
               </div>
               <div class="separator">--------------------------------------</div>
               <div class="bold">TOTAL: R$ ${projeto.valorTotalServicos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.formaPagamento) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}</div>
               `;
    }
    if (projeto.troco) {
      html += `
               <div class="bold">TROCO: R$ ${projeto.troco?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.taxaEntrega && !projeto.interno) {
      html += `            
               <div class="bold">TAXA DE ENTREGA: +R$ ${projeto.taxaEntrega?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.desconto) {
      html += `            
               <div class="bold">DESCONTO: -R$ ${projeto.desconto?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.interno && projeto.taxaServico) {
      html += `            
               <div class="bold">TAXA: +R$ ${projeto.taxaServico?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
               `;
    }
    if (projeto.valorTotal && !(projeto.interno)) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">TOTAL ${projeto.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${projeto.valorTotal?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      const qrData = await QRCode.toDataURL(projeto.pk);
      html += `
               <div class="separator">.</div>
               <div>
                  <img src="${qrData}" alt="QR Code" style="width: 150px; height: 150px;"/>
               </div>
               `;
    }
    html += `
               <div class="separator">.</div>
               <div class="separator">.</div>
               <div class="separator">.</div>
            </div>
            </body>
      </html>
      `;
    return removerAcentosECedilha(html);
  }

  function removerAcentosECedilha(texto: any) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ç/g, 'c').replace(/Ç/g, 'C');
  }


  return (

    <IonPage>
      <IonContent fullscreen={false}>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </IonContent>
    </IonPage>

  )
};

export default ProjetosCupomHtml;