import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonModal, IonRadio, IonRadioGroup, IonRow, IonSearchbar, IonTextarea, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { arrowBack, gridOutline, listOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import "./ProjetosProdutosComporNovoMemory.css";
import imgDefaultImage from './imgs/defaultImage.svg';

type MyModalProps = {
  empresa: any;
  disabled: any;
  adicionarItem: Function;
}

const ProjetosProdutosComporNovoMemory: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);

  const [showTamanhoSaboresPizza, setShowTamanhoSaboresPizza] = useState(false);
  const [showComplementos, setShowComplementos] = useState(false);

  const [valor, setValor] = useState<any>();
  const [subtotal, setSubtotal] = useState<any>();
  const [itemSelecionadoMassa, setItemSelecionadoMassa] = useState<any>();
  const [itemSelecionadoBorda, setItemSelecionadoBorda] = useState<any>();
  const [updatedItensSelecionadosComplementos, setUpdatedItensSelecionadosComplementos] = useState<any>([]);
  const [grupoComplementos, setGrupoComplementos] = useState<any>();
  const [observacao, setObservacao] = useState("");
  const [selectedCategoria, setSelectedCategoria] = useState<any>();
  const [selectedSize, setSelectedSize] = useState<any>();
  const [selectedCount, setSelectedCount] = useState<any>();
  const [selectedMassa, setSelectedMassa] = useState<any>();
  const [selectedBorda, setSelectedBorda] = useState<any>();
  const [selectedCategoriaObject, setSelectedCategoriaObject] = useState<any>([]);
  const [selectedSizeObject, setSelectedSizeObject] = useState<any>();
  const [etapa, setEtapa] = useState("1");
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [buscarCompor, setBuscarCompor] = useState("");
  const [listaItemCatalogoProdutosCompor, setListaItemCatalogoProdutosCompor] = useState<any>([]);
  const [filteredItemsCompor, setFilteredItemsCompor] = useState<any[]>([]);
  const [listaComporItemsSelecionados, setListaComporItemsSelecionados] = useState<any>([]);
  const inputRefSearchCompor2 = useRef<any>(null);
  const [selectedProdutos, setSelectedProdutos] = useState<string[]>([]);

  const [opcaoListagem, setOpcaoListagem] = useState<any>(window.localStorage.getItem("opcaoListagemAdicionarProdutos") || "list");

  const [dadosEmpresa, setDadosEmpresa] = useState<any>();

  const [categoriaExpandida, setCategoriaExpandida] = useState<string | null>(null);
  const [showTamanhoSaboresPizzaV2, setShowTamanhoSaboresPizzaV2] = useState(false);

  useEffect(() => {
    if (props.empresa) {
      setDadosEmpresa(props.empresa);
    }
  }, [props.empresa]);

  function cadastrarComporProduto() {

    if (selectedCategoriaObject.tipo == "pizzas") {
      if (selectedCount == 1 && listaComporItemsSelecionados.length != 1) {
        setTextToast("Selecione uma pizza inteira.");
        setShowToast(true);
        return false;
      }
      else if (selectedCount == 2 && listaComporItemsSelecionados.length != 2) {
        setTextToast("Selecione duas partes para compor a pizza.");
        setShowToast(true);
        return false;
      }
      else if (selectedCount == 3 && listaComporItemsSelecionados.length != 3) {
        setTextToast("Selecione três partes para compor a pizza.");
        setShowToast(true);
        return false;
      }
    }
    else {
      if (listaComporItemsSelecionados.length != 1) {
        setTextToast("Selecione somente um item para avançar.");
        setShowToast(true);
        return false;
      }
    }

    let isValid = true;
    let errorMessage = '';

    if (grupoComplementos) {
      grupoComplementos.forEach((grupo: any) => {
        if (grupo.obrigatoriedade === "obrigatorio" && !grupo.selectedItem) {
          isValid = false;
          errorMessage += `Selecione um item para o complemento ${grupo.name}.<br>`;
        }
      });


      if (!isValid) {
        setTextToast(errorMessage);
        setShowToast(true);
        return false;
      }
    }

    cadastrarDireto();
  }

  function cadastrarDireto() {

    const listaComporItemsSelecionadosComComplementos = [
      ...listaComporItemsSelecionados,
      ...updatedItensSelecionadosComplementos
    ];

    if (itemSelecionadoBorda) {
      listaComporItemsSelecionadosComComplementos.push(itemSelecionadoBorda);
    }

    if (itemSelecionadoMassa) {
      listaComporItemsSelecionadosComComplementos.push(itemSelecionadoMassa);
    }

    if (observacao) {
      const novoItem = {
        descricao: observacao,
        valor: "0,00",
        categoria: "Observação",
      };
      listaComporItemsSelecionadosComComplementos.push(novoItem);
    }


    const objectRequest = {
      tipo: selectedCategoriaObject?.tipo,
      descricao: listaComporItemsSelecionadosComComplementos,
      categoria: selectedCategoria,
      tamanho: selectedSize,
      quantidadeSabores: selectedCount,
      quantidade: "1",
      largura: "",
      comprimento: "",
      unidadeMedida: "unid",
      valor: valor,
      desconto: "0",
      subtotal: subtotal,
    }

    props.adicionarItem(objectRequest);

    setEtapa("1");
    setShowComplementos(false);
    limpar();
  }

  async function deletarItemCompor(index: any) {
    const updatedList = listaComporItemsSelecionados.filter((_: any, i: any) => i !== index);
    setListaComporItemsSelecionados(updatedList);

    const valorFinal = calcularValorTotal(
      updatedItensSelecionadosComplementos,
      updatedList,
      itemSelecionadoMassa,
      itemSelecionadoBorda
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);
  }

  const handleCategoriaClick = async (item: any) => {
    if (item.tipo == "pizzas") {
      setShowTamanhoSaboresPizzaV2(false);
      setCategoriaExpandida(null);
    }
    console.log("handleCategoriaClick", item);
    limpar();

    setSelectedCategoria(item.nome);
    setSelectedCategoriaObject(item);

    setSelectedSize(item.tamanho);
    setSelectedCount(item.sabores);

    await avancar(item, item.tamanho, item.sabores);

  };

  const handleSizeClick = async (size: any) => {
    console.log("handleSizeClick");
    await avancar(selectedCategoriaObject, size, selectedCount);
    setSelectedSize(size.name);
    setSelectedSizeObject(size);
  };

  const handleCountClick = async (count: any) => {
    console.log("handleCountClick");
    if (!(await avancar(selectedCategoriaObject, selectedSizeObject, count))) {
      setTextToast("Selecione as opções para avançar.");
      setShowToast(true);
      return;
    }
    setSelectedCount(count);
  };

  const addItemsPizzas = (items: any[], categoria: any, size: any, count: any) => {

    console.log("categoria", categoria);
    console.log("size", size);
    console.log("count", count);

    const countFloat = parseFloat(count.replace(',', '.'));

    return items
      .filter(item =>
        item.categoria === categoria.nomeCategoria && item.valores && item.valores.some((valor: any) => valor.name === size)
      )
      .map(item => {
        const selectedValor = item.valores.find((valor: any) => valor.name === size);

        // console.log("selectedValor", selectedValor);
        return {
          ...item,
          nome: (countFloat == 1 ? `${item.nome}` : `1/${count} ${item.nome}`),
          // tipo: item.tipo,
          valor: (parseFloat(selectedValor.valor.replace(',', '.')) / countFloat).toFixed(2).replace('.', ','),
        };
      });
  };

  const addItemsPizzasAgrupado = (items: any[], categoria: any, size: any, count: any) => {

    const countFloat = parseFloat(count.replace(',', '.'));

    return items
      .filter(item =>
        item.categoria === categoria.nome && item.valores && item.valores.some((valor: any) => valor.name === selectedSize)
      )
      .map(item => {
        const selectedValor = item.valores.find((valor: any) => valor.name === size.name);

        return {
          ...item,
          nome: (countFloat == 1 ? `${item.nome}` : `1/${count} ${item.nome}`),
          // tipo: item.tipo,
          valor: (parseFloat(selectedValor.valor.replace(',', '.')) / countFloat).toFixed(2).replace('.', ','),
        };
      });
  };

  const addItems = (items: any[], categoria: any) => {

    return items
      .filter(item =>
        item.categoria === categoria.nome
      );

  };

  const handleSearchCompor = (e: any) => {
    setBuscarCompor(e.target.value);
    const searchTerm = e.target.value?.toLowerCase();
    if (searchTerm === "") {
      setFilteredItemsCompor([]);
      return;
    }

    const isInteger = /^\d+$/.test(searchTerm);

    const filtered = listaItemCatalogoProdutosCompor.filter((item: any) =>
      isInteger ? item.codigo === searchTerm : item.codigo.includes(searchTerm) || item.nome.toLowerCase().includes(searchTerm)
    );
    setFilteredItemsCompor(filtered);
  };

  const handleSelectItemCompor = (item: any) => {

    if (selectedCategoriaObject.tipo == "pizzas") {
      if (selectedCount == 1 && listaComporItemsSelecionados.length >= 1) {
        setTextToast("Selecione uma pizza inteira.");
        setShowToast(true);
        return false;
      }
      else if (selectedCount == 2 && listaComporItemsSelecionados.length >= 2) {
        setTextToast("Selecione duas partes para compor a pizza.");
        setShowToast(true);
        return false;
      }
      else if (selectedCount == 3 && listaComporItemsSelecionados.length >= 3) {
        setTextToast("Selecione três partes para compor a pizza.");
        setShowToast(true);
        return false;
      }
    }
    else {
      if (listaComporItemsSelecionados.length >= 1) {
        setTextToast("Selecione somente um item para avançar.");
        setShowToast(true);
        return false;
      }
    }

    setBuscarCompor("");
    setFilteredItemsCompor([]);

    setGrupoComplementos(item.grupoComplementos);

    const novoItem = {
      descricao: item.nome,
      valor: item.valor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      categoria: item.categoria,
    };

    let updatedList = [...listaComporItemsSelecionados, novoItem];
    setListaComporItemsSelecionados(updatedList);


    const valorFinal = calcularValorTotal(
      updatedItensSelecionadosComplementos,
      updatedList,
      itemSelecionadoMassa,
      itemSelecionadoBorda
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);

  };

  function gerarItensPizzas(categoriaProdutos: any[]) {
    const novasCategorias: any[] = []; // Array para armazenar todas as categorias modificadas

    categoriaProdutos.forEach((cat: any) => {
      // Verifica se a categoria é "pizzas" e se tem tamanhos definidos
      if (cat.tipo === "pizzas" && Array.isArray(cat.tamanhos)) {
        const novosItens: any[] = [];

        // Itera sobre os tamanhos disponíveis
        cat.tamanhos.forEach((tamanho: any) => {
          const { name, sabores } = tamanho;

          // Para cada tamanho, cria opções com base no número de sabores
          for (let count = 1; count <= sabores; count++) {
            // console.log(`Adicionando item: ${name} com ${count} sabores`);

            // Adiciona o novo item à lista de novos itens
            novosItens.push({
              nome: `${cat.nome} ${name} com ${count} sabores`,//cat.nome,
              nomeCategoria: cat.nome,
              tamanho: name,
              sabores: count.toString(),
              tipo: cat.tipo,
              urlImagem: cat.urlImagem, // Usa a imagem original
              bordas: cat.bordas,
              massas: cat.massas,
              indice: cat.indices
            });
          }
        });

        // Adiciona os novos itens de pizzas à lista final de categorias
        novasCategorias.push(...novosItens);
      } else {
        // Se não for pizzas, mantém a categoria original
        novasCategorias.push(cat);
      }
    });

    return novasCategorias;
  }


  const agruparPorTamanho = (categorias: any) => {
    const agrupado: any = {};

    categorias.forEach((item: any) => {
      if (!agrupado[item.tamanho]) {
        agrupado[item.tamanho] = [];
      }
      agrupado[item.tamanho].push(item);
    });

    return agrupado;
  };

  const agruparPorCategoriaETamanho = (categorias: any) => {
    const agrupado: any = {};

    categorias.forEach((item: any) => {
      // Verifica se a categoria já existe
      if (!agrupado[item.nomeCategoria]) {
        agrupado[item.nomeCategoria] = {};
      }

      // Agrupa dentro da categoria por tamanho
      if (!agrupado[item.nomeCategoria][item.tamanho]) {
        agrupado[item.nomeCategoria][item.tamanho] = [];
      }

      // Adiciona o item dentro da categoria e do tamanho correspondente
      agrupado[item.nomeCategoria][item.tamanho].push(item);
    });

    return agrupado;
  };


  const toggleCategoriaExpandida = (categoria: any) => {

    if (props.disabled) {
      return;
    }

    if (categoria.tipo === "pizzas") {
      setCategoriaExpandida(categoriaExpandida === categoria.nome ? null : categoria.nome);
      // setCategoriaExpandida(categoria.nomeCategoria);
      setShowTamanhoSaboresPizzaV2(true); // Abre o modal
    } else {
      handleCategoriaClick(categoria);
      // For other categories, implement respective action (e.g., navigate or open modal)
    }
  };


  const closeModal = () => {
    setShowTamanhoSaboresPizzaV2(false); // Fecha o modal
    setCategoriaExpandida(null); // Limpa a categoria expandida ao fechar
  };


  const renderCategoriaButtons = () => {

    if (dadosEmpresa && dadosEmpresa.categoriaProdutos) {

      // console.log("renderCategoriaButtons");

      // Gera itens de pizzas dinâmicos
      const categorias = gerarItensPizzas(dadosEmpresa.categoriaProdutos);

      // Agrupa as categorias por tamanho
      //const categoriasAgrupadas = agruparPorTamanho(categorias);

      // if (Array.isArray(categorias) && categorias.length > 1) {
      //   handleCategoriaClick(categorias[0]);
      // }

      //usado para agrupar
      //const categorias = dadosEmpresa.categoriaProdutos;

      // Agrupa por categoria e tamanho
      const categoriasAgrupadas = agruparPorCategoriaETamanho(categorias);

      console.log("dadosEmpresa.categoriaProdutos", dadosEmpresa.categoriaProdutos);







      return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          {/* Categories row */}
          <div style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', width: '100%' }}>
            {dadosEmpresa.categoriaProdutos.map((item: any, index: any) => (
              <IonCol size="auto" key={index} style={{ flex: '0 0 auto', textAlign: 'center', maxWidth: '90px' }}
                // onClick={() => handleCategoriaClick(item)}
                onClick={() => { toggleCategoriaExpandida(item) }}>

                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', position: 'relative' }}>
                  <div
                    style={{
                      width: '70px',
                      height: '70px',
                      borderRadius: '50%', // Imagem circular
                      overflow: 'hidden',
                      border: selectedCategoria == item.nome
                        ? "2px solid #FF0000"
                        : "2px solid #ccc",
                      position: 'relative', // Necessário para posicionar os elementos sobrepostos
                    }}
                  >
                    <img
                      id={"img" + index}
                      alt={item.nome}
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                      }}
                      src={item.urlImagem || imgDefaultImage}
                    />

                    {/* Divisão de sabores */}
                    {item.sabores > 1 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          // background: `conic-gradient(transparent 0 ${360 / item.sabores}deg, #f1f1f1 0)`,
                          zIndex: 2,
                          pointerEvents: 'none', // Não interfere na usabilidade

                        }}
                      />
                    )}

                    {item.tamanho && (

                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)', // Centraliza o texto
                          zIndex: 3, // Coloca acima da divisão
                          color: '#fff', // Cor do texto (ajustável)
                          fontSize: '12px', // Tamanho grande para a letra
                          fontWeight: 'bold', // Negrito para dar destaque
                          pointerEvents: 'none', // Não interfere na usabilidade
                        }}
                      >

                        {item.tamanho} {item.sabores == "1" ? item.sabores + " sabor" : item.sabores + " sabores"}
                      </div>
                    )}
                  </div>
                </div>

                <IonLabel style={{ fontSize: '12px' }}>
                  {item.nomeCategoria || item.nome}
                </IonLabel>
              </IonCol>
            ))}


          </div>

          {/* IonModal para exibir a categoria expandida */}
          <IonModal isOpen={showTamanhoSaboresPizzaV2} onDidDismiss={closeModal}>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
                    closeModal();
                  }} />
                </IonButtons>
                <IonTitle>{categoriaExpandida}</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              {/* Tamanho e sabores expandidos dentro do modal */}
              {categoriaExpandida && (
                <div style={{
                  justifyContent: "center", alignItems: "center", height: "90%", display: 'flex', flexDirection: 'column', paddingTop: '10px', position: 'relative', width: '100%'
                }}>
                  {Object.keys(categoriasAgrupadas[categoriaExpandida]).map((tamanho, tamIndex) => (
                    <div key={tamIndex} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexShrink: 0, width: '100%' }}>

                      {/* Renderiza o nome do tamanho */}
                      {tamanho !== "undefined" && (
                        <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '16px', width: '100%', paddingTop: '10px' }}>
                          {tamanho}
                        </div>
                      )}

                      {/* Renderiza as pizzas associadas ao tamanho */}
                      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}>
                        {categoriasAgrupadas[categoriaExpandida][tamanho].map((item: any, index: any) => (
                          <IonCol
                            size="auto"
                            key={index}
                            style={{ flex: '0 0 auto', textAlign: 'center', maxWidth: '90px', marginLeft: '5px', marginRight: '5px' }}
                            onClick={() => handleCategoriaClick(item)}
                          >
                            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                              <div
                                style={{
                                  width: '80px',
                                  height: '80px',
                                  borderRadius: '50%',
                                  overflow: 'hidden',
                                  border: selectedCategoria == item.nome ? "2px solid #FF0000" : "2px solid #ccc",
                                  position: 'relative',
                                }}
                              >
                                <img
                                  id={"img" + index}
                                  alt={item.nome}
                                  style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                  }}
                                  src={item.urlImagem || imgDefaultImage}
                                />

                                {/* Se tiver mais de 1 sabor, renderiza a divisão */}

                                <div
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    zIndex: 2,
                                    pointerEvents: 'none',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo escuro semitransparente para contraste

                                  }}
                                >


                                  {/* Renderiza a quantidade de sabores */}
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      zIndex: 3,
                                      color: '#fff',
                                      fontSize: '14px', // Aumentado para destaque
                                      fontWeight: 'bold',
                                      pointerEvents: 'none',
                                    }}
                                  >
                                    {item.sabores === "1" ? `${item.sabores} sabor` : `${item.sabores} sabores`}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <IonLabel style={{ fontSize: '12px' }}>
                              {item.tipo === "simples" && item.nome}
                            </IonLabel>
                          </IonCol>
                        ))}
                      </div>



                    </div>
                  ))}
                </div>
              )}
            </IonContent>
          </IonModal>
        </div>
      );

    }
  };

  // Função para renderizar os botões de tamanho dinamicamente
  const renderSizeButtons = () => {

    if (dadosEmpresa && dadosEmpresa.categoriaProdutos) {

      // console.log("renderSizeButtons");

      if (selectedCategoriaObject && selectedCategoriaObject.tamanhos) {
        return selectedCategoriaObject?.tamanhos
          .map((val: any, index: any) => (
            <IonCol size="auto" key={index}>
              <IonButton
                onClick={() => handleSizeClick(val)}
                color={selectedSize === val.name ? 'danger' : 'light'}
                className="custom-flat-button-size"
              >
                <div style={{ textAlign: 'center' }}>
                  <div style={{ marginTop: '6px', textAlign: 'center' }}>
                    <div style={{ fontWeight: 'bold' }}>{val.name}</div>
                  </div>
                </div>
              </IonButton>
            </IonCol>
          ));
      }
    }
  };

  // Função para renderizar os botões de quantidade dinamicamente
  const renderCountButtons = () => {

    if (dadosEmpresa && dadosEmpresa.categoriaProdutos) {

      // console.log("renderCountButtons");;

      let counts: string[] = [];
      if (selectedSizeObject && selectedSizeObject.sabores) {
        counts = Array.from({ length: selectedSizeObject.sabores }, (_, i) => (i + 1).toString());
      }
      return counts
        .map((count: any, index: any) => (
          <IonCol size="auto" key={index}>
            <IonButton
              onClick={() => handleCountClick(count)}
              color={selectedCount === count ? 'danger' : 'light'}
              shape="round"
              className="custom-flat-button"
            >
              <IonLabel>{count}</IonLabel>
            </IonButton>
          </IonCol>
        ));
    }
  };

  const handleSelectItemComporMassa = (item: any) => {
    const novoItem = {
      descricao: item.name,  // Corrigido de item.nome para item.name
      valor: item.price.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      categoria: "Massa",
    };

    setItemSelecionadoMassa(novoItem);

    const valorFinal = calcularValorTotal(
      updatedItensSelecionadosComplementos,
      listaComporItemsSelecionados,
      novoItem,
      itemSelecionadoBorda
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);
  };

  const handleSelectItemComporBorda = (item: any) => {
    const novoItem = {
      descricao: item.name,  // Corrigido de item.nome para item.name
      valor: item.price.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      categoria: "Borda",
    };

    setItemSelecionadoBorda(novoItem);

    const valorFinal = calcularValorTotal(
      updatedItensSelecionadosComplementos,
      listaComporItemsSelecionados,
      itemSelecionadoMassa,
      novoItem
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);
  };

  const updateGrupoComplementos = (groupIndex: number, field: string, value: any) => {
    const updatedGrupos = [...grupoComplementos];
    updatedGrupos[groupIndex][field] = value;

    // Atualiza o grupo de complementos no estado
    setGrupoComplementos(updatedGrupos);

    // Identificar o item selecionado dentro do grupo específico
    const selectedItem = updatedGrupos[groupIndex].itens.find((item: any) => item.name === value);

    if (selectedItem) {
      const novoItem = {
        descricao: selectedItem.name,
        valor: selectedItem.price.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        categoria: grupoComplementos[groupIndex].name,  // Nome do grupo como categoria
      };

      // Crie uma cópia do array de itens selecionados
      let updatedItensSelecionados = [...updatedItensSelecionadosComplementos];

      // Verificar se o grupo já tem um item selecionado no array
      const existingItemIndex = updatedItensSelecionados.findIndex(
        (item) => item.categoria === grupoComplementos[groupIndex].name
      );

      if (existingItemIndex !== -1) {
        // Se o item já existe, atualize-o
        updatedItensSelecionados[existingItemIndex] = novoItem;
      } else {
        // Se o item não existe, adicione-o
        updatedItensSelecionados.push(novoItem);
      }

      // Atualiza o estado com os itens selecionados
      setUpdatedItensSelecionadosComplementos(updatedItensSelecionados);

      const valorFinal = calcularValorTotal(
        updatedItensSelecionados,
        listaComporItemsSelecionados,
        itemSelecionadoMassa,
        itemSelecionadoBorda
      );

      setSubtotal(valorFinal);
      setValor(valorFinal);
    }
  };

  const avancar = async (categoria: any, size: any = "", count: any = "") => {
    // Faça a validação aqui, por exemplo, verificar se todos os campos obrigatórios foram preenchidos
    let isValid = true;

    if (categoria && categoria.tipo == "pizzas") {
      isValid = await adicionarItensPizzas(categoria, size, count);

      // usado quando for agrupar
      // isValid = await adicionarItensPizzas(categoria, size, count);
      // if (isValid) {
      //   setShowTamanhoSaboresPizza(false);
      // }
      // else {
      //   setShowTamanhoSaboresPizza(true);
      // }
    }
    else {
      isValid = await adicionarItensSimples(categoria);
    }

    if (isValid) {
      setEtapa("2");
    }

    return isValid;
  };

  async function adicionarItensPizzas(categoria: any, size: any, count: any) {

    if (count && size && categoria) {
      let todosItems: any[];
      todosItems = addItemsPizzas(dadosEmpresa.catalogoProdutos || [], categoria, size, count);
      setListaItemCatalogoProdutosCompor(todosItems || []);
    }
    else {
      return false;
    }
    return true;
  }

  async function adicionarItensSimples(categoria: any) {

    if (categoria) {
      let todosItems: any[];
      todosItems = addItems(dadosEmpresa.catalogoProdutos || [], categoria);
      setListaItemCatalogoProdutosCompor(todosItems || []);
    }
    else {
      setTextToast("Selecione a categoria.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  const limpar = () => {
    setEtapa("1");
    setSelectedCategoria("");
    setSelectedCategoriaObject([]);
    setSelectedSize("");
    setSelectedSizeObject([]);
    setSelectedCount("");

    clearSelectionsComplementos();

    setSelectedProdutos([]);
    setListaItemCatalogoProdutosCompor([]);
    setListaComporItemsSelecionados([]);

    setUpdatedItensSelecionadosComplementos([]);

    setSelectedBorda("");
    setSelectedMassa("");
    setItemSelecionadoBorda("");
    setItemSelecionadoMassa("");
    setObservacao("");

    const valorFinal = calcularValorTotal(
      [],
      [],
      "",
      ""
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);
  }

  const clearSelectionsComplementos = () => {
    const updatedGrupoComplementos = grupoComplementos?.map((grupo: any) => ({
      ...grupo,
      selectedItem: null,
    }));
    setGrupoComplementos(updatedGrupoComplementos); // Supondo que você tenha um setGrupoComplementos para atualizar o estado
  };

  const handleProdutoClick = async (item: any) => {
    const alreadySelected = selectedProdutos.includes(item.nome);

    if (alreadySelected) {
      // Se o item já estiver selecionado, encontrar o índice na listaComporItemsSelecionados
      const indexToRemove = listaComporItemsSelecionados.findIndex(
        (produtoSelecionado: any) => produtoSelecionado.descricao === item.nome
      );

      if (indexToRemove !== -1) {
        const updatedProdutos = selectedProdutos.filter(
          (produto) => produto !== item.nome
        );
        setSelectedProdutos(updatedProdutos);

        // Chamar a função para deletar o item em listaComporItemsSelecionados
        await deletarItemCompor(indexToRemove);

        if (selectedProdutos.length == 1) {
          const limparGrupoComplementos = () => {
            if (grupoComplementos) {
              const gruposLimpos = grupoComplementos.map((grupo: any) => ({
                ...grupo,
                itens: grupo.itens.map((item: any) => ({
                  ...item,
                  selecionado: false, // Adicione uma propriedade para controlar a seleção, se necessário
                })),
                selectedItem: null, // Limpe a seleção atual
              }));

              setGrupoComplementos(gruposLimpos);
            }
          };

          limparGrupoComplementos();

          setSelectedProdutos([]);
          setListaComporItemsSelecionados([]);
          setGrupoComplementos([]);

          setUpdatedItensSelecionadosComplementos([]);
        }
      }
    } else {
      console.log("selectedProdutos.length", selectedProdutos.length);
      console.log("selectedCount", selectedCount);

      if (selectedCategoriaObject.tipo === "pizzas") {
        if (selectedProdutos.length >= selectedCount) {
          return;
        }
        if (selectedProdutos.length >= selectedCount - 1) {
          setShowComplementos(true);
        }
      } else {
        if (selectedProdutos.length === 1) {
          return;
        }
        if (selectedProdutos.length === 0) {
          setShowComplementos(true);
        }
      }

      const itensProdutos = [...selectedProdutos, item.nome];
      setSelectedProdutos(itensProdutos);
      handleSelectItemCompor(item);
    }
  };

  const renderProdutosButtonsList = () => {
    return (
      <IonList style={{ width: "100%" }}>
        {listaItemCatalogoProdutosCompor.map((item: any, index: any) => (
          <IonItem
            lines="none"
            key={index}
            button
            detail={true}
            onClick={() => handleProdutoClick(item)}
            color={selectedProdutos.includes(item.nome) ? 'danger' : 'light'}
          >
            <IonLabel>{item.codigo && item.codigo + " - "} {item.nome}</IonLabel>
          </IonItem>
        ))}
      </IonList>
    );
  };

  const renderProdutosButtonsGrid = () => {
    return (
      <IonRow style={{ width: "100%" }}>
        {listaItemCatalogoProdutosCompor.map((item: any, index: any) => (
          <IonCol size="6" size-sm="6" size-md="4" size-lg="3" size-xl="2" key={index} style={{ padding: '0px' }}>
            <IonCard
              button
              onClick={() => handleProdutoClick(item)}
              style={{
                textAlign: 'center',
                // backgroundColor: selectedProdutos.includes(item.nome)
                //   ? "rgba(var(--ion-color-danger-rgb), 0.1)"
                //   : "var(--ion-color-light-rgb)",
                border: selectedProdutos.includes(item.nome)
                  ? "2px solid #FF0000"
                  : "2px solid #ccc",
              }}
            >

              <img id={"img" + index} alt={item.nome} style={{ objectFit: 'cover', width: "100%", height: "120px", borderRadius: '5px 5px 0 0' }} src={item.urlImagem || imgDefaultImage}></img>


              <IonCardContent style={{ padding: "4px" }}>
                <IonLabel>
                  <b>{item.nome}</b>
                  <p>R$ {item.valor}</p>
                </IonLabel>
              </IonCardContent>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    );
  };

  function calcularValorTotal(
    updatedItensSelecionadosComplementos: any[],
    listaComporItemsSelecionados: any[],
    itemSelecionadoMassa: any,
    itemSelecionadoBorda: any
  ): string {

    let totalValor = updatedItensSelecionadosComplementos.reduce((total: any, itemCompor: any) => {
      const itemValorNumerico = parseFloat(
        itemCompor.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
      return total + itemValorNumerico;
    }, 0);

    // Calcula o total de listaComporItemsSelecionados
    let totalValorListaSelecionados = listaComporItemsSelecionados.reduce((total: number, itemCompor: any) => {
      const itemValorNumerico = parseFloat(itemCompor.valor.toString().replace(/\./g, '').replace(/\,/g, '.'));
      return total + itemValorNumerico;
    }, 0);

    // Soma os totais calculados
    totalValor += totalValorListaSelecionados;

    // Adiciona o valor de itemSelecionadoMassa se existir
    if (itemSelecionadoMassa && itemSelecionadoMassa.valor) {
      totalValor += parseFloat(
        itemSelecionadoMassa.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
    }

    // Adiciona o valor de itemSelecionadoBorda se existir
    if (itemSelecionadoBorda && itemSelecionadoBorda.valor) {
      totalValor += parseFloat(
        itemSelecionadoBorda.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
    }

    // Formata o valor final
    const valorFinal = totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return valorFinal;
  }



  return (

    <>
      <IonCard>
        <IonCardContent>

          <IonGrid style={{ padding: "0px", margin: "0px" }}>
            <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
              <IonCol style={{ padding: "0px", margin: "0px" }}><IonLabel><b>Catálogo</b></IonLabel></IonCol>

            </IonRow>
          </IonGrid>
          <IonGrid>

            <IonRow>
              <IonCol size="12">


                <IonGrid style={{ padding: "0px", margin: "0px" }}>
                  {/* {(!isPlatform('mobileweb') && isPlatform('mobile')) ? (
          <IonRow className="ion-justify-content-center">
            {renderCategoriaButtons()}
          </IonRow>
        ) : ( */}
                  <IonRow style={{ opacity: props.disabled ? 0.5 : 1, overflowX: 'auto', flexWrap: 'nowrap' }}>
                    {renderCategoriaButtons()}
                  </IonRow>
                  {/* )} */}

                </IonGrid>

                <div hidden={etapa != "2"}>

                  <div style={{ display: 'flex', alignItems: 'center' }}>

                    <IonSearchbar
                      onIonInput={handleSearchCompor}
                      placeholder="Buscar"
                      value={buscarCompor} ref={inputRefSearchCompor2}
                    />
                    <IonRow>
                      <IonCol style={{ padding: "0px", margin: "0px", display: "flex", justifyContent: "flex-end" }} size='12'>
                        <IonButtons>
                          <IonButton
                            onClick={() => {
                              setOpcaoListagem("list");
                              window.localStorage.setItem("opcaoListagemAdicionarProdutos", "list");
                            }}
                          >
                            <IonIcon
                              color={opcaoListagem === "list" ? "primary" : "medium"}
                              icon={listOutline}
                            />
                          </IonButton>
                        </IonButtons>
                        <IonButtons>
                          <IonButton
                            onClick={() => {
                              setOpcaoListagem("grid");
                              window.localStorage.setItem("opcaoListagemAdicionarProdutos", "grid");
                            }}
                          >
                            <IonIcon
                              color={opcaoListagem === "grid" ? "primary" : "medium"}
                              icon={gridOutline}
                            />
                          </IonButton>
                        </IonButtons>
                      </IonCol>
                    </IonRow>

                  </div>


                  {filteredItemsCompor && Array.isArray(filteredItemsCompor) && filteredItemsCompor.length > 0 && (
                    <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: filteredItemsCompor.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
                      {filteredItemsCompor.map((item: any, index: any) => (
                        <IonItem lines="none" key={"filtro" + index} button onClick={() => {
                          handleProdutoClick(item)
                        }}>
                          <IonLabel>{item.nome} (R$ {item.valor})</IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  )}


                  {
                    opcaoListagem == "list" ?
                      renderProdutosButtonsList() :
                      renderProdutosButtonsGrid()
                  }

                </div>


              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>



      <IonModal isOpen={showTamanhoSaboresPizza} onDidDismiss={() => { setShowTamanhoSaboresPizza(false); }}>

        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
                setShowTamanhoSaboresPizza(false);
              }} />
            </IonButtons>
            <IonTitle>{selectedCategoria}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonGrid style={{ padding: "8px", width: "100%" }} hidden={selectedCategoriaObject?.tipo != "pizzas"}>

            <IonRow className="ion-justify-content-center" hidden={selectedCategoriaObject?.tipo != "pizzas"}>
              <IonCol size="auto">
                <div style={{ padding: '6px', textAlign: 'center' }}>
                  <IonLabel style={{ color: "black" }}>Tamanho</IonLabel>
                </div>
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center">
              {renderSizeButtons()}
            </IonRow>

            <IonRow className="ion-justify-content-center" hidden={!selectedSize}>
              <IonCol size="auto">

                <div style={{ padding: '6px', textAlign: 'center' }}>
                  <IonLabel style={{ color: "black" }}>Quantidade de sabores</IonLabel>
                </div>

              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center">
              {renderCountButtons()}
            </IonRow>
          </IonGrid>

        </IonContent>


      </IonModal>


      <IonModal isOpen={showComplementos} onDidDismiss={() => {
        clearSelectionsComplementos();

        setSelectedProdutos([]);
        setListaComporItemsSelecionados([]);
        setUpdatedItensSelecionadosComplementos([]);

        setSelectedBorda("");
        setSelectedMassa("");
        setItemSelecionadoBorda("");
        setItemSelecionadoMassa("");
        setObservacao("");

        setShowComplementos(false);
      }}>

        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
                clearSelectionsComplementos();

                setSelectedProdutos([]);
                setListaComporItemsSelecionados([]);
                setUpdatedItensSelecionadosComplementos([]);

                setSelectedBorda("");
                setSelectedMassa("");
                setItemSelecionadoBorda("");
                setItemSelecionadoMassa("");
                setObservacao("");

                setShowComplementos(false);
              }} />
            </IonButtons>
            <IonTitle>{selectedSize ? selectedSize + " - " + selectedCount + " sabor(es)" : "Complementos"}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonGrid style={{ padding: "8px", width: "100%" }} hidden={selectedCategoriaObject?.tipo != "pizzas"}>

            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonLabel style={{ fontWeight: 'bold', color: "black" }}>Massa</IonLabel>
                <IonRadioGroup
                  value={selectedMassa}
                  onIonChange={(e: any) => {
                    setSelectedMassa(e.detail.value);
                    const selectedMassaItem = selectedCategoriaObject?.massas?.find(
                      (massa: any) => massa.name === e.detail.value
                    );
                    if (selectedMassaItem) {
                      handleSelectItemComporMassa(selectedMassaItem);
                    }
                  }}
                >
                  {selectedCategoriaObject?.massas?.map((massa: any, index: any) => (
                    <IonItem key={index} lines="none" style={{ fontSize: "12px" }}>
                      <IonRadio slot="start" value={massa.name} />
                      <IonLabel>{massa.name} - {massa.price}</IonLabel>
                    </IonItem>
                  ))}
                </IonRadioGroup>
              </IonCol>

              <IonCol size="12" sizeMd="6">
                <IonLabel style={{ fontWeight: 'bold', color: "black" }}>Borda</IonLabel>
                <IonRadioGroup
                  value={selectedBorda}
                  onIonChange={(e: any) => {
                    setSelectedBorda(e.detail.value);
                    const selectedBordaItem = selectedCategoriaObject?.bordas?.find(
                      (borda: any) => borda.name === e.detail.value
                    );
                    if (selectedBordaItem) {
                      handleSelectItemComporBorda(selectedBordaItem);
                    }
                  }}
                >
                  {selectedCategoriaObject?.bordas?.map((borda: any, index: any) => (
                    <IonItem key={index} lines="none" style={{ fontSize: "12px" }}>
                      <IonRadio slot="start" value={borda.name} />
                      <IonLabel>{borda.name} - {borda.price}</IonLabel>
                    </IonItem>
                  ))}
                </IonRadioGroup>
              </IonCol>
            </IonRow>
          </IonGrid>

          {grupoComplementos && Array.isArray(grupoComplementos) && grupoComplementos.length > 0 && (
            <IonGrid style={{ padding: "8px", width: "100%" }}>

              <IonRow>
                {grupoComplementos?.map((grupo: any, groupIndex: number) => (

                  <IonCol key={groupIndex} size="12" sizeMd="6">
                    <IonLabel style={{ fontWeight: 'bold', color: "black" }}>{grupo.name} {grupo.obrigatoriedade == "obrigatorio" && "(Obrigatório)"}</IonLabel>
                    <IonRadioGroup
                      value={grupo.selectedItem}  // Supondo que você tenha uma chave para armazenar o item selecionado
                      onIonChange={(e: any) => {
                        const selectedValue = e.detail.value;
                        updateGrupoComplementos(groupIndex, 'selectedItem', selectedValue); // Atualiza o item selecionado no grupo
                      }}
                    >
                      {grupo.itens?.map((item: any, itemIndex: number) => (
                        <IonItem key={itemIndex} lines="none" style={{ fontSize: "12px" }}>
                          <IonRadio slot="start" value={item.name} />
                          <IonLabel>{item.name} - {item.price}</IonLabel>
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  </IonCol>

                ))}
              </IonRow>

            </IonGrid>
          )}


          <IonGrid style={{ padding: "8px", width: "100%" }}>
            <IonRow>
              <IonCol size="12">
                <IonLabel style={{ fontWeight: 'bold' }}>Observação ({observacao.length}/{100})</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">

                <IonTextarea
                  style={{ "--background": "#eeeeee" }}
                  rows={2}
                  autoCorrect='true' autoCapitalize='true'
                  value={observacao}
                  maxlength={100}
                  placeholder="Alguma observação?"
                  onIonInput={(e: any) => setObservacao(e.target.value)}
                ></IonTextarea>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" style={{ textAlign: 'right' }}>
                <IonLabel></IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter className="ion-no-border" style={{ borderTop: '1px solid #ccc' }}>

          <IonToolbar>

            <IonList style={{ padding: "0px", margin: "0px" }} >




              <IonItem style={{ padding: "0px", margin: "0px" }} lines="none">
                <IonLabel slot="start" style={{ fontWeight: 'bold' }}>Valor Total:</IonLabel>
                <IonLabel slot="end">{subtotal || "0"}</IonLabel>
              </IonItem>
            </IonList>

            <IonButton
              onClick={async () => {

                cadastrarComporProduto();
              }}
              expand="block"
              type="submit"
            >
              Adicionar
            </IonButton>
          </IonToolbar>
        </IonFooter>


      </IonModal>


      <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
      />

      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { setShowLoading(false); }}
        spinner='circles'
      />

    </>
  );


};

export default ({ empresa, disabled, adicionarItem }: { empresa: any, disabled: any, adicionarItem: Function }) => (
  <ProjetosProdutosComporNovoMemory empresa={empresa} disabled={disabled} adicionarItem={adicionarItem}>
  </ProjetosProdutosComporNovoMemory>
)