import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { addCircle, arrowBack, arrowForwardCircle, closeOutline, documentOutline, ellipsisVertical, powerOutline, printOutline, removeCircle } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { comandaGerarCupom, comandaGerarCupomPdf, encerrarComanda, requestService, unirComanda } from '../../../../../Utils/Services';
import "./CaixaFechar.css";

type Props = {
  clienteid: string;
  onDismiss: () => void;
};

const CaixaFechar: React.FC<Props> = (props: Props) => {


  const history = useHistory<any>();
  const location = useLocation<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [alertConfirmar, setAlertConfirmar] = useState(false);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [mostartAlerta] = useIonAlert();

  const [showModalCliente, setShowModalCliente] = useState(false);
  const [clienteText, setClienteText] = useState<any>();
  const [dataCupom, setDataCupom] = useState<any>();

  const [cliente, setCliente] = useState<any>();
  const [listaCliente, setListaCliente] = useState<any>([]);

  const [dadosListaProjetos, setDadosListaProjetos] = useState<any>();

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { loja, setLoja } = useContext(MessageContext);
  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const [clienteInterno, setClienteInterno] = useState(false);

  const [formaPagamento, setFormaPagamento] = useState('');
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);

  const [valorTotal, setValorTotal] = useState<any>();

  const [exibirCupom, setExibirCupom] = useState(false);


  const opcoesPagamento = [
    'Dinheiro',
    'Cartão de Crédito',
    'Cartão de Débito',
    'Pix',
    'Alelo',
    'Sodexo',
    'Vale Alimentação',
    'Boleto',
    'Transferência Bancária'
  ];

  const [taxaEntrega, setTaxaEntrega] = useState<any>();

  const opcoesTaxaEntrega = [
    { label: 'Grátis', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 6,00', value: '6,00' },
    { label: 'R$ 7,00', value: '7,00' },
    { label: 'R$ 8,00', value: '8,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 12,00', value: '12,00' },
    { label: 'R$ 15,00', value: '15,00' },
  ];

  const [desconto, setDesconto] = useState<any>();

  const opcoesDesconto = [
    { label: 'R$ 0,00', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 15,00', value: '15,00' },
    { label: 'R$ 20,00', value: '20,00' },
    { label: 'R$ 25,00', value: '25,00' }
  ];

  const [taxaServico, setTaxaServico] = useState<any>();

  const opcoesTaxaServico = [
    { label: '0%', value: '0' },
    { label: '10%', value: '10' },
    { label: '12%', value: '12' },
    { label: '15%', value: '15' }
  ];

  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };

  let params: any = {
    clienteid: ""
  }

  params = useParams();

  if (location && location.state && location.state.clienteid) {
    params.clienteid = location.state.clienteid;
  }

  if (props.clienteid) {
    params = {
      clienteid: props.clienteid
    }
  }

  useEffect(() => {

    try {
      let taxaServicoFloat = parseFloat(taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
      let descontoFloat = parseFloat(desconto?.replace(/\./g, '').replace(/\,/g, '.'));
      let taxaEntregaFloat = parseFloat(taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

      let valorTotalRecalculado = 0;
      if (dadosListaProjetos.valorTotal) {
        valorTotalRecalculado = parseFloat(dadosListaProjetos.valorTotal?.replace(/\./g, '').replace(/\,/g, '.'));
      }

      if (taxaEntrega) {
        valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
      }

      if (desconto) {
        valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
      }
      if (taxaServico) {
        valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
      }

      setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [taxaEntrega, taxaServico, desconto]);

  useIonViewDidEnter(() => {


    setEsconderMenu(true);
    if (params.clienteid) {
      console.log("aaaaa", params.clienteid);
      setCliente(params.clienteid);
      setExibirCupom(false);
      consultaComandaUnificada(params.clienteid);
    }
  })

  function consultaComandaUnificada(clienteid: any) {

    setShowLoading(true);


    setDadosListaProjetos([]);

    requestService(unirComanda.url.replaceAll("{id}", clienteid.replaceAll(/#/g, '%23')), {
      method: unirComanda.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response) {
          console.log("response", response);

          if (response && response.message) {
            setDadosListaProjetos(response.message);


            setValorTotal(response.message.valorTotal);

            try {

              if (response.message.valorTotal) {
                let taxaServicoFloat = parseFloat(taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
                let descontoFloat = parseFloat(desconto?.replace(/\./g, '').replace(/\,/g, '.'));
                let taxaEntregaFloat = parseFloat(taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

                let valorTotalRecalculado = parseFloat(response.message.valorTotal?.replace(/\./g, '').replace(/\,/g, '.'));

                if (taxaEntrega) {
                  valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
                }

                if (desconto) {
                  valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
                }
                if (taxaServico) {
                  valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
                }

                setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }));
              }

            } catch (error) {
              console.log("erro ao calcular", error);
            }


            setExibirCupom(true);

            // setShowModalInserirProjeto(false);
            // setTextToast("Comanda fechada com sucesso!");
            // setShowToast(true);
          }

        }
        //setBotaoAtivo(true);
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        //setBotaoAtivo(true);
        setShowLoading(false);
        setTextToast(error.message);
        setShowToast(true);
      })



  }


  function fecharComanda() {

    setShowLoading(true);
    //setBotaoAtivo(false);

    // const found = listaCliente.find((obj: any) => {
    //   return obj.pk === cliente;
    // });

    // console.log("found", found);

    let objRequest = {
      clienteid: cliente.replaceAll(/#/g, '%23'),
      formaPagamento: formaPagamento,
      desconto: desconto,
      taxaServico: taxaServico
    };

    return requestService(encerrarComanda.url.replaceAll("{id}", cliente.replaceAll(/#/g, '%23')), {
      method: encerrarComanda.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response) {
          console.log("response", response);
          setRecarregarProjeto(true);
          history.goBack();
        }
        //setBotaoAtivo(true);
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        //setBotaoAtivo(true);
        setShowLoading(false);
        setTextToast(error.message);
        setShowToast(true);
      })


  }

  const clienteSelectionChanged = async (pkClienteSelecionado: string) => {
    console.log("pkClienteSelecionado", pkClienteSelecionado);

    const selecionado = listaCliente.find((cliente: any) => cliente.pk === pkClienteSelecionado);

    console.log("clienteSelecionado", selecionado);

    if (selecionado) {
      setCliente(selecionado.pk);
      setClienteText(selecionado.nome);


    }
    setShowModalCliente(false);
  }


  async function gerarCupom(projetoid: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoading(true);
    let montaUrl = comandaGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23"));
    montaUrl += "?formapagamento=" + formaPagamento;

    if (taxaServico) {
      montaUrl += "&taxaservico=" + taxaServico;
    }
    if (desconto) {
      montaUrl += "&desconto=" + desconto;
    }

    const resp = await fetch(montaUrl,
      {
        method: comandaGerarCupom.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoading(false);

  }

  async function gerarCupomPdf(projetoid: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoading(true);
    let montaUrl = comandaGerarCupomPdf.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23"));
    montaUrl += "?formapagamento=" + formaPagamento;

    if (taxaServico) {
      montaUrl += "&taxaservico=" + taxaServico;
    }
    if (desconto) {
      montaUrl += "&desconto=" + desconto;
    }

    const resp = await fetch(montaUrl,
      {
        method: comandaGerarCupomPdf.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar PDF',
        message: '<strong>Cupom PDF</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoading(false);

  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  function aplicarMascaraCPFouCNPJ(texto: any) {
    if (!texto) return '';
    texto = texto.replace(/\D/g, ''); // Remove tudo que não é dígito

    if (texto.length === 11) {
      // Máscara para CPF
      return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (texto.length === 14) {
      // Máscara para CNPJ
      return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    return texto; // Retorna o texto sem máscara se não tiver 11 ou 14 dígitos
  }


  const loadItemProdutos2 = () => {


    console.log("listaItemProdutos", dadosListaProjetos);

    if (dadosListaProjetos == undefined) return;

    if (dadosListaProjetos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>

        <IonGrid>
          {dadosListaProjetos.map((value: any, index: any) => (
            <>

              <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                <IonCol size="9">


                  {(value.descricao && Array.isArray(value.descricao)) ? (

                    <>
                      {(value.tipo === "pizzas") ? (
                        <div>{value.tamanho} {value.quantidadeSabores} SABORES</div>
                      ) : (
                        <div>{value.categoria}</div>
                      )}
                      {value.descricao.map((item: any, index: number) => (
                        <div key={"desc" + index}>
                          - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</IonLabel>)
                        </div>
                      ))}
                    </>

                  ) : (
                    <div key={"desc" + index}>
                      {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</IonLabel>)

                    </div>
                  )}



                </IonCol>


                <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                  <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                </IonCol>



              </IonRow>
            </>
          ))}
        </IonGrid>
      </>
    );
  }

  return (

    <IonPage className="CaixaFechar">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Fechar</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>
              <IonItem lines="none" button onClick={() => {
                gerarCupom(cliente);
                closePopover();
              }}>
                <IonIcon icon={printOutline} slot="start" />
                <IonLabel>Imprimir TXT</IonLabel>
              </IonItem>

              <IonItem lines="none" button onClick={() => {
                gerarCupomPdf(cliente);
                closePopover();
              }}>
                <IonIcon icon={documentOutline} slot="start" />
                <IonLabel>Imprimir PDF</IonLabel>
              </IonItem>

              <IonItem lines="none" button onClick={() => {
                setAlertConfirmar(true);
                closePopover();
              }}>
                <IonIcon icon={powerOutline} slot="start" />
                <IonLabel>Encerrar</IonLabel>
              </IonItem>




              <IonItem lines="none" button onClick={closePopover}>
                <IonIcon icon={closeOutline} slot="start" />
                <IonLabel>Cancelar</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>


        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <IonGrid style={{ padding: "0px", margin: "0px" }}>
          <IonRow>

            <IonCol style={{ padding: "0px", margin: "0px" }} sizeMd="8" size="12">
              <IonCard>
                <IonCardContent>

                  <IonGrid style={{ padding: "0px", margin: "0px" }}>
                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                      <IonCol style={{ padding: "0px", margin: "0px" }}><IonLabel><b>Lista de itens</b></IonLabel></IonCol>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="4"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{dadosListaProjetos?.valorTotalProdutos}</b></IonLabel></IonCol>

                    </IonRow>
                  </IonGrid>
                  <IonGrid>

                    <IonRow>
                      <IonCol size="12">

                        {dadosListaProjetos && (
                          <IonGrid>




                            {dadosListaProjetos.listaProjetos && dadosListaProjetos.listaProjetos.length > 0 && (
                              dadosListaProjetos.listaProjetos.map((projeto: any, projetoIndex: any) => (
                                <div key={projetoIndex}>

                                  <IonRow style={{ fontSize: "20px", display: 'flex', alignItems: 'center' }}>
                                    <IonCol size="11" style={{ margin: "0px", padding: "0px" }}>
                                      <b>{dadosListaProjetos.servicoTipo || "Projeto"}: {projeto.nomeProjeto}</b>
                                    </IonCol>
                                    <IonCol size="1" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: "0px", padding: "0px" }}>
                                      <IonButtons>
                                        <IonButton
                                          onClick={() => { history.push("../projetos/projetosprodutos", { id: projeto.pk.replaceAll(/#/g, "%23"), fecharComanda: true }); }}
                                        >
                                          <IonIcon size='large' color="primary" icon={arrowForwardCircle} />
                                        </IonButton>
                                      </IonButtons>
                                    </IonCol>
                                  </IonRow>

                                  {/* <IonRow>
                                    <IonCol style={{ padding: '0 0 0 5px' }} size='1'>#</IonCol>
                                    <IonCol style={{ padding: '0 0 0 5px' }} size='7'>DESCRIÇÃO</IonCol>
                                    <IonCol style={{ padding: '0 0 0 5px' }}>QTDE</IonCol>
                                    <IonCol style={{ padding: '0 0 0 5px', textAlign: 'right', marginRight: '5px' }}>VALOR</IonCol>
                                  </IonRow> */}
                                  {projeto.produtos && projeto.produtos.length > 0 && (
                                    <>

                                      {projeto.produtos.map((value: any, index: any) => (
                                        <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                                          <IonCol size="9">


                                            {(value.descricao && Array.isArray(value.descricao)) ? (

                                              <>
                                                {(value.tipo === "pizzas") ? (
                                                  <div>{value.tamanho} {value.quantidadeSabores} SABORES</div>
                                                ) : (
                                                  <div>{value.categoria}</div>
                                                )}
                                                {value.descricao.map((item: any, index: number) => (
                                                  <div key={"desc" + index}>
                                                    - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2
                                                    })}</IonLabel>)
                                                  </div>
                                                ))}
                                              </>

                                            ) : (
                                              <div key={"desc" + index}>
                                                {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                                })}</IonLabel>)

                                              </div>
                                            )}



                                          </IonCol>


                                          <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                                            <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                                          </IonCol>

                                          <IonCol sizeSm='6'>
                                            <IonLabel style={{ textAlign: "right", fontSize: "12px" }} >
                                              <IonChip style={{ margin: 0, padding: '5px' }} disabled={true}>
                                                <IonIcon size='large' color='danger' icon={removeCircle} onClick={() => {

                                                  // // Faz uma cópia da lista atual de produtos
                                                  // let updatedList = [...listaItemProdutos];

                                                  // // Acessa o item que será atualizado
                                                  // let itemToUpdate = { ...updatedList[index] };

                                                  // // Formata a quantidade atual
                                                  // const quantidadeFormatada = parseFloat(
                                                  //   itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                                                  // );

                                                  // if (quantidadeFormatada > 1) {
                                                  //   // Decrementa a quantidade
                                                  //   let quantidadeNova = quantidadeFormatada - 1;

                                                  //   // Formata o valor e calcula o novo valor com base na nova quantidade
                                                  //   const valorFormatado = parseFloat(
                                                  //     itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                                                  //   );

                                                  //   // Atualiza a quantidade e o valor do item
                                                  //   itemToUpdate.quantidade = quantidadeNova;
                                                  //   const valorFloat = quantidadeNova * valorFormatado;

                                                  //   itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                                                  //     minimumFractionDigits: 2,
                                                  //     maximumFractionDigits: 2
                                                  //   });

                                                  //   // Substitui o item atualizado na lista copiada
                                                  //   updatedList[index] = itemToUpdate;

                                                  //   let totalValor = updatedList.reduce((total: any, item: any) => {
                                                  //     const itemValorNumerico = parseFloat(
                                                  //       item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                                                  //     );
                                                  //     return total + itemValorNumerico;
                                                  //   }, 0);

                                                  //   setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                                                  //     minimumFractionDigits: 2,
                                                  //     maximumFractionDigits: 2
                                                  //   }));

                                                  //   // Atualiza o estado com a nova lista
                                                  //   setListaItemProdutos(updatedList);

                                                  // }
                                                  // // else {
                                                  // //   // Se a quantidade for 1, pode-se optar por remover o item completamente ou manter o valor como está.
                                                  // //   // Se preferir remover o item:
                                                  // //   updatedList.splice(index, 1);
                                                  // //   setListaItemProdutos(updatedList);
                                                  // // }

                                                }}></IonIcon>
                                                <IonLabel style={{ fontSize: '20px' }}>{value.quantidade.toLocaleString('pt-br', {
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0
                                                })}</IonLabel>
                                                <IonIcon size='large' color='success' icon={addCircle} onClick={() => {

                                                  // // Faz uma cópia da lista atual de produtos
                                                  // let updatedList = [...listaItemProdutos];

                                                  // // Acessa o item que será atualizado
                                                  // let itemToUpdate = { ...updatedList[index] };

                                                  // // Formata e incrementa a quantidade
                                                  // const quantidadeFormatada = parseFloat(
                                                  //   itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                                                  // );

                                                  // let quantidadeNova = quantidadeFormatada + 1;

                                                  // // Formata o valor e calcula o novo valor com base na nova quantidade
                                                  // const valorFormatado = parseFloat(
                                                  //   itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                                                  // );

                                                  // // Atualiza a quantidade e o valor do item
                                                  // itemToUpdate.quantidade = quantidadeNova;
                                                  // const valorFloat = quantidadeNova * valorFormatado;

                                                  // itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                                                  //   minimumFractionDigits: 2,
                                                  //   maximumFractionDigits: 2
                                                  // });

                                                  // // Substitui o item atualizado na lista copiada
                                                  // updatedList[index] = itemToUpdate;

                                                  // let totalValor = updatedList.reduce((total: any, item: any) => {
                                                  //   const itemValorNumerico = parseFloat(
                                                  //     item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                                                  //   );
                                                  //   return total + itemValorNumerico;
                                                  // }, 0);

                                                  // setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                                                  //   minimumFractionDigits: 2,
                                                  //   maximumFractionDigits: 2
                                                  // }));

                                                  // // Atualiza o estado com a nova lista
                                                  // setListaItemProdutos(updatedList);


                                                }}></IonIcon>
                                              </IonChip>
                                            </IonLabel>
                                          </IonCol>
                                          <IonCol style={{ display: "flex", justifyContent: "flex-end" }} sizeSm='6'>
                                            <IonButtons>

                                              {/* <IonButton
                                                  onClick={() => { history.push("../projetos/projetosprodutos", { id: projeto.pk.replaceAll(/#/g, "%23"), fecharComanda: true }); }}
                                                >
                                                  <IonIcon size='large' color="primary" icon={arrowForwardCircle} />
                                                </IonButton> */}

                                              {/* <IonButton
                                                                onClick={() => {
                                                                  mostartAlerta({
                                                                    backdropDismiss: false,
                                                                    header: 'Remover item',
                                                                    message: 'Deseja <strong>remover</strong>?',
                                                                    buttons: [
                                                                      {
                                                                        text: 'Cancelar',
                                                                        role: 'cancel',
                                                                      },
                                                                      {
                                                                        text: 'Confirmar',
                                                                        cssClass: 'primary',
                                                                        handler: () => {
                                                                          deletarItemProdutos(index);
                                                                        }
                                                                      }
                                                                    ],
                                                                    onDidDismiss: (e) => console.log('did dismiss'),
                                                                  });
                                                                }}
                                                              >
                                                                <IonIcon color="primary" icon={trash} />
                                                              </IonButton> */}
                                            </IonButtons>
                                          </IonCol>

                                        </IonRow>
                                      ))}

                                    </>
                                  )}
                                </div>
                              ))
                            )}





                          </IonGrid>
                        )}



                        <IonAlert isOpen={alertConfirmar}
                          onDidDismiss={() => setAlertConfirmar(false)}
                          header={'Encerrar'}
                          message={'Deseja <strong>encerrar</strong>?'}
                          buttons={[
                            {
                              text: 'Cancelar',
                              role: 'cancel',
                            },
                            {
                              text: 'Confirmar',
                              cssClass: 'primary',
                              handler: async () => {
                                await fecharComanda();
                              }
                            }
                          ]}
                        />

                        <IonToast
                          isOpen={showToast}
                          position="middle"
                          onDidDismiss={() => setShowToast(false)}
                          message={textToast}
                          duration={3000}
                        />

                        <IonLoading
                          cssClass='my-custom-class'
                          isOpen={showLoading}
                          onDidDismiss={() => { setShowLoading(false); }}
                          spinner='circles'
                        />

                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>


            <IonCol style={{ padding: "0px", margin: "0px" }} sizeMd="4" size="12">
              <IonCard>
                <IonCardContent>

                  <IonGrid style={{ padding: "0px", margin: "0px" }}>

                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="8"><IonLabel><b>Pagamento</b></IonLabel></IonCol>
                      {/* <IonCol style={{ padding: "0px", margin: "0px" }} size="4"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotalProdutos}</b></IonLabel></IonCol> */}

                    </IonRow>

                  </IonGrid>







                  <IonGrid style={{ padding: "0px", margin: "0px" }}>
                    <IonRow>
                      <IonCol style={{ padding: "0px", margin: "0px" }}>
                        <IonList>
                          <IonItem lines="none">
                            <IonLabel position="stacked">Forma de pagamento</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={formaPagamento}
                              onIonChange={(e) => {
                                setFormaPagamento(e.detail.value);
                              }}
                            >
                              {opcoesPagamento.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao}>
                                  {opcao}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>

                          {/* <IonItem lines="none" hidden={formaPagamento != "Dinheiro"}>
                            <IonLabel position="stacked">Troco</IonLabel>
                            <IonInput
                              type="tel"
                              maxlength={14}
                              placeholder="Digite o valor"
                              value={troco}
                              onKeyUp={onChangeValor}
                            />
                          </IonItem> */}

                          <IonItem lines="none">
                            <IonLabel position="stacked">Taxa de serviço</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={taxaServico}
                              onIonChange={(e) => setTaxaServico(e.detail.value)}
                            >
                              {opcoesTaxaServico.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao.value}>
                                  {opcao.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>

                          <IonItem lines="none">
                            <IonLabel position="stacked">Desconto</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={desconto}
                              onIonChange={(e) => setDesconto(e.detail.value)}
                            >
                              {opcoesDesconto.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao.value}>
                                  {opcao.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonList>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonGrid style={{ padding: "0px", margin: "0px" }}>
                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderTopStyle: 'solid' }}>
                      <IonCol><IonLabel><b>Total</b></IonLabel></IonCol>
                      <IonCol><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotal}</b></IonLabel></IonCol>
                    </IonRow>
                  </IonGrid>


                  <IonToolbar>
                    <IonButton
                      onClick={async () => {
                        gerarCupom(cliente);
                      }}
                      expand="block"
                      type="submit"
                    >
                      Imprimir
                    </IonButton>

                    <IonButton
                      onClick={async () => {
                        setAlertConfirmar(true);
                      }}
                      expand="block"
                      type="submit"
                    >
                      Encerrar
                    </IonButton>
                  </IonToolbar>


                </IonCardContent>
              </IonCard>
            </IonCol>

          </IonRow>
        </IonGrid>
      </IonContent>
      {/* <IonFooter className="ion-no-border" hidden={!exibirCupom}>
        <IonToolbar>
          <IonButton expand="block" size='large' color="primary" onClick={() => {
            setAlertConfirmar(true);

          }}>Encerrar</IonButton>
        </IonToolbar>
      </IonFooter> */}
    </IonPage>
  );


};

export default CaixaFechar;