import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { enviarNotificacao, listaEquipePorPerfil, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './EnviarNotificacaoClientes.css';

type Props = {
  abrirMenu: boolean;
};

const EnviarNotificacaoClientes: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [listaCliente, setListaCliente] = useState<any>([]);
  const [cliente, setCliente] = useState<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [mensagem, setMensagem] = useState("");

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    consultarCliente();
  });


  function readCliente() {
    return listaCliente.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"cliente" + value.pk} value={value.pk}>{value.nome}</IonSelectOption>
    )
    )
  };

  const consultarCliente = async (empresaid?: any) => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", empresaid ? empresaid.replaceAll(/#/g, "%23") : loja.replaceAll(/#/g, "%23"));
    
    const resp = await fetch(montarUrl.replace("{perfil}", "cliente"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();

    console.log("equipe", equipe);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
        setListaCliente(lista);
      }
    }
  }

  function validarCampos() {

    if ((mensagem && mensagem.length > 0) && (cliente && cliente.length > 0)) {
      return true;
    }
    else {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
      return false;
    }

  }

  function enviar() {

    if (!validarCampos())
      return;

    console.log('listaCliente', listaCliente);

    if (botaoAtivo) {
      setShowLoading(true);
      setBotaoAtivo(false);

      let objRequest = {
        "mensagem": mensagem,
        "clientes": listaCliente,
        "empresaid": loja
      };

      requestService(enviarNotificacao.url, {
        method: enviarNotificacao.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {
          if (response.message) {
            setBotaoAtivo(true);
            setShowLoading(false);

            setCliente('');
            setMensagem('');

            setTextToast("Mensagens enviadas com sucesso.");
            setShowToast(true);
          }
        },
        (error: any) => {
          console.log(error);
          setBotaoAtivo(true);
          setShowLoading(false);
          setTextToast("Erro ao tentar enviar. Tente novamente mais tarde.");
          setShowToast(true);
        })

    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}><IonIcon slot="icon-only" icon={arrowBack} /></IonButton>
          </IonButtons>
          {/* <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={notifications} />
            </IonButton>
          </IonButtons> */}
          <IonTitle slot="start">Enviar Notificações</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        <div style={{ textAlign: "center" }}>

          <IonItem lines="none" >

            <IonLabel>Clientes</IonLabel>
            <IonSelect slot="end" multiple={true} value={cliente} placeholder="Selecione" onIonChange={e => { setCliente(e.detail.value); }}>
              {readCliente()}
            </IonSelect>
          </IonItem>

          <IonItem lines="none">
            <IonLabel position="stacked">Mensagem</IonLabel>
            <IonTextarea style={{ "--background": "#eeeeee" }} rows={6} value={mensagem} maxlength={250} placeholder="Mensagem a ser enviada por notificação" onIonInput={(e: any) => setMensagem(e.target.value)}></IonTextarea>
          </IonItem>
        </div>
        <div style={{ textAlign: "center" }} >

          <IonButton disabled={!botaoAtivo} hidden={acessos?.campanha != 'edicao'} onClick={() => {
            enviar();
          }} expand="block" type="button" fill='solid' color='tertiary'>Enviar</IonButton>
        </div>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
    </IonPage>
  );
};

export default EnviarNotificacaoClientes;