import { Toast } from '@capacitor/toast';
import { IonActionSheet, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonReorderGroup, IonRow, IonTitle, IonToast, IonToolbar, isPlatform, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { addCircle, arrowBack, bookOutline, closeOutline, ellipsisVertical, listOutline, removeCircle, trash } from 'ionicons/icons';
import QRCode from 'qrcode';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { alterarProjeto, alterarProjetosItemProdutosEmpresa, alterarStatusProjeto, inserirProjetosItemProdutosEmpresa, ordernarProjetosItemProdutosEmpresa, projetoGerarCupom, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import ProjetosLiberar from '../ProjetosLiberar/ProjetosLiberar';
import ProjetosProdutosAdicionar from '../ProjetosProdutosAdicionar/ProjetosProdutosAdicionar';
import ProjetosProdutosComporNovo from '../ProjetosProdutosComporNovo/ProjetosProdutosComporNovo';
import ProjetosProdutosComporNovoMemory from '../ProjetosProdutosComporNovoMemory/ProjetosProdutosComporNovoMemory';
import "./ProjetosProdutosMemory.css";

type Props = {
  id: any;
  nome: any;
  fecharComanda: boolean;
};

const ProjetosProdutosMemory: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();
  //const [id, setId] = useState("");
  const [fecharComanda, setFecharComanda] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [listaItemProdutos, setListaItemProdutos] = useState<any>([]);

  const [showDetalhesAdicionar, setShowDetalhesAdicionar] = useState(false);
  const [selecionado, setSelecionado] = useState("");
  const [quantidade, setQuantidade] = useState<any>();
  const [largura, setLargura] = useState<any>();
  const [comprimento, setComprimento] = useState<any>();
  const [unidadeMedida, setUnidadeMedida] = useState("");
  const [valor, setValor] = useState<any>();
  const [desconto, setDesconto] = useState<any>();
  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);
  const { recarregarCatalogo, setRecarregarCatalogo } = useContext(MessageContext);

  const [teveAlteracao, setTeveAlteracao] = useState(false);
  const [mostartAlerta] = useIonAlert();

  const [showDetalhesCompor, setShowDetalhesCompor] = useState(false);
  // const [showDetalhesComporV2, setShowDetalhesComporV2] = useState(false);
  const [area, setArea] = useState("");
  const [buscar, setBuscar] = useState("");
  // const [dadosEmpresa, setDadosEmpresa] = useState<any>([]);
  const {dadosEmpresa, setDadosEmpresa} =  useContext(MessageContext);

  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [showActionSheet, setShowActionSheet] = useState(false);

  const buscarInputRef = useRef<any>(null);
  const buscarAdicionarProdutoInputRef = useRef<any>(null);

  // const buscarComporProdutoInputRef = useRef<any>(null);
  // const inputProjetosAltRef = useRef<any>(null);

  // const [showAtualizarInformacoes, setShowAtualizarInformacoes] = useState(false);
  const [showLiberar, setShowLiberar] = useState(false);
  const [dadosProjeto, setDadosProjeto] = useState<any>([]);

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const contentCatalogoRef = useRef<any>(null);

  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };


  let params: any = {
    id: "",
    fecharComanda: false,
    abrirInserirAgendamento: false
  }
  params = useParams();

  if (location && location.state && location.state.id) {
    params.id = location.state.id;
    params.fecharComanda = location.state.fecharComanda;
  }


  if (props.id) {
    params = {
      id: props.id,
      fecharComanda: props.fecharComanda
    }
  }

  useEffect(() => {
    console.log("#########", params.id);
    if (params.id) {
      console.log("entrou no useEffect");
      carregarProjeto();
      // carregarEmpresa();

    }
  }, [params.id]);


  // useEffect(() => {
  //   console.log("recarregarCatalogo", recarregarCatalogo);
  //   if (recarregarCatalogo) {
  //     carregarEmpresa();
  //     setRecarregarCatalogo(false);
  //   }
  // }, [recarregarCatalogo]
  // );

  useEffect(() => {

    try {
      calcularSubtotal(valor, quantidade, unidadeMedida, largura, comprimento, desconto);
    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [largura, comprimento, quantidade, valor, desconto]);


  const calcularSubtotal = (
    valor: any,
    quantidade: any,
    unidadeMedida: any,
    largura?: any,
    comprimento?: any,
    desconto?: any
  ) => {
    try {
      const valorNumerico = parseFloat(valor?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o valor para número
      let quantidadeNumerica = parseFloat(quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a quantidade para número
      if (unidadeMedida === "m2") {
        let larg = parseFloat(largura?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a largura para número
        let comp = parseFloat(comprimento?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o comprimento para número
        quantidadeNumerica = larg * comp;

        if (largura && comprimento) {
          setQuantidade((larg * comp).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
      }
      let descontoNumerico = 0;
      if (desconto) {
        descontoNumerico = parseFloat(desconto.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o desconto para número
      }

      console.log("valorNumerico", valorNumerico);
      console.log("quantidadeNumerica", quantidadeNumerica);
      console.log("descontoNumerico", descontoNumerico);

      if (!valorNumerico || !quantidadeNumerica) {

        return;
      }

      // Calcula o valor total sem desconto
      let valorTotal = valorNumerico * quantidadeNumerica;
      // Aplica o desconto
      valorTotal -= (valorTotal * descontoNumerico / 100);

      // let valorTotalFormatado = (valorTotal.toLocaleString('pt-br', {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2
      // }));

      return valorTotal;
    } catch (error) {
      console.log("erro ao calcular", error);
    }
  };

  useIonViewDidEnter(() => {

    console.log("aaaaa***********************", params.id);
    setEsconderMenu(true);
    setNomeMenu("");

    if (params.fecharComanda) {
      setFecharComanda(params.fecharComanda);
    }

  });

  // function carregarEmpresa() {

  //   const consultar = async () => {

  //     requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
  //       method: empresaCarregar.method,
  //       headers: [
  //         ["token", window.localStorage.getItem("token")]
  //       ],
  //       credentials: 'include'
  //     },
  //       history,
  //       (response: any) => {
  //         // console.log(response);
  //         //setArea(response.Item.area);

  //         //setListaItemCatalogoProdutos(response.Item.catalogoProdutos || []);

  //         setDadosEmpresa(response.Item);
  //         setShowLoading(false);
  //       },
  //       (error: any) => {
  //         console.log(error);
  //         history.replace("/login/");
  //       });

  //   }

  //   consultar();
  // }

  function carregarProjeto() {

    const consultar = async () => {

      console.log("*********************************params.id", params.id);

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      setShowLoading(true);
      const resp = await fetch(urlBase.url + "/projetos/" + params.id.replaceAll(/#/g, "%23"),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        }).finally(() => {
          setShowLoading(false);
        });
      const response = await resp.json();

      if (response.Item !== undefined) {
        // console.log("***************************", response);
        setDadosProjeto(response.Item);
        setListaItemProdutos(response.Item.produtos || []);

        setValorTotalProdutos(response.Item.valorTotalProdutos?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }

      if (buscarInputRef.current) {
        buscarInputRef.current.setFocus();
      }
    }

    consultar();
  }




  function deletarItemProdutos(index: number) {
    // Filtra a lista de produtos, removendo o item com o índice fornecido
    const updatedList = listaItemProdutos.filter((_: any, i: any) => i !== index);

    // Atualiza a lista de produtos com o item removido
    setListaItemProdutos(updatedList);

    // Calcula a nova soma dos valores após a remoção
    const totalValor = updatedList.reduce((total: any, item: any) => {
      const itemValorNumerico = parseFloat(
        item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
      return total + itemValorNumerico;
    }, 0);

    // Exemplo: você pode armazenar a nova soma em outro estado ou usar conforme necessário
    console.log("Nova soma dos valores:", totalValor);

    setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));

  }


  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    // console.log('Before complete', listaItemProdutos);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaItemProdutos(event.detail.complete(listaItemProdutos));

    // After complete is called the items will be in the new order
    // console.log('After complete', listaItemProdutos);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemProdutos = async () => {
      const resp = await fetch(ordernarProjetosItemProdutosEmpresa.url.replace("{id}", params.id.replaceAll(/#/g, "%23")),
        {
          method: ordernarProjetosItemProdutosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'produtos': listaItemProdutos }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      // console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaItemProdutos(retorno.Item?.Attributes?.produtos || []);
      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemProdutos();

  }

  const loadItemProdutos2 = () => {

    if (listaItemProdutos == undefined) return;

    if (listaItemProdutos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup onIonItemReorder={handleReorder}>
          <IonGrid>
            {listaItemProdutos.map((value: any, index: any) => (
              <>

                <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                  <IonCol size="9">


                    {(value.descricao && Array.isArray(value.descricao)) ? (

                      <>
                        {(value.tipo === "pizzas") ? (
                          <div>{value.tamanho} {value.quantidadeSabores} SABORES</div>
                        ) : (
                          <div>{value.categoria}</div>
                        )}
                        {value.descricao.map((item: any, index: number) => (
                          <div key={"desc" + index}>
                            - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}</IonLabel>)
                          </div>
                        ))}
                      </>

                    ) : (
                      <div key={"desc" + index}>
                        {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</IonLabel>)

                      </div>
                    )}



                  </IonCol>


                  <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                    <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                  </IonCol>
                  <IonCol sizeSm='6'>
                    <IonLabel style={{ textAlign: "right", fontSize: "12px" }} >
                      <IonChip style={{ margin: 0, padding: '5px' }} disabled={!fecharComanda && (dadosProjeto.dataCupom)}>
                        <IonIcon size='large' color='danger' icon={removeCircle} onClick={() => {

                          // Faz uma cópia da lista atual de produtos
                          let updatedList = [...listaItemProdutos];

                          // Acessa o item que será atualizado
                          let itemToUpdate = { ...updatedList[index] };

                          // Formata a quantidade atual
                          const quantidadeFormatada = parseFloat(
                            itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );

                          if (quantidadeFormatada > 1) {
                            // Decrementa a quantidade
                            let quantidadeNova = quantidadeFormatada - 1;

                            // Formata o valor e calcula o novo valor com base na nova quantidade
                            const valorFormatado = parseFloat(
                              itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                            );

                            // Atualiza a quantidade e o valor do item
                            itemToUpdate.quantidade = quantidadeNova;
                            const valorFloat = quantidadeNova * valorFormatado;

                            itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            });

                            // Substitui o item atualizado na lista copiada
                            updatedList[index] = itemToUpdate;

                            let totalValor = updatedList.reduce((total: any, item: any) => {
                              const itemValorNumerico = parseFloat(
                                item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                              );
                              return total + itemValorNumerico;
                            }, 0);

                            setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }));

                            // Atualiza o estado com a nova lista
                            setListaItemProdutos(updatedList);

                          }
                          // else {
                          //   // Se a quantidade for 1, pode-se optar por remover o item completamente ou manter o valor como está.
                          //   // Se preferir remover o item:
                          //   updatedList.splice(index, 1);
                          //   setListaItemProdutos(updatedList);
                          // }

                        }}></IonIcon>
                        <IonLabel style={{ fontSize: '20px' }}>{value.quantidade.toLocaleString('pt-br', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })}</IonLabel>
                        <IonIcon size='large' color='success' icon={addCircle} onClick={() => {

                          // Faz uma cópia da lista atual de produtos
                          let updatedList = [...listaItemProdutos];

                          // Acessa o item que será atualizado
                          let itemToUpdate = { ...updatedList[index] };

                          // Formata e incrementa a quantidade
                          const quantidadeFormatada = parseFloat(
                            itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );

                          let quantidadeNova = quantidadeFormatada + 1;

                          // Formata o valor e calcula o novo valor com base na nova quantidade
                          const valorFormatado = parseFloat(
                            itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );

                          // Atualiza a quantidade e o valor do item
                          itemToUpdate.quantidade = quantidadeNova;
                          const valorFloat = quantidadeNova * valorFormatado;

                          itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });

                          // Substitui o item atualizado na lista copiada
                          updatedList[index] = itemToUpdate;

                          let totalValor = updatedList.reduce((total: any, item: any) => {
                            const itemValorNumerico = parseFloat(
                              item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                            );
                            return total + itemValorNumerico;
                          }, 0);

                          setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }));

                          // Atualiza o estado com a nova lista
                          setListaItemProdutos(updatedList);


                        }}></IonIcon>
                      </IonChip>
                    </IonLabel>
                  </IonCol>
                  <IonCol style={{ display: "flex", justifyContent: "flex-end" }} sizeSm='6'>
                    <IonButtons>
                      <IonButton disabled={!fecharComanda && (dadosProjeto.dataCupom)}
                        onClick={() => {
                          mostartAlerta({
                            backdropDismiss: false,
                            header: 'Remover item',
                            message: 'Deseja <strong>remover</strong>?',
                            buttons: [
                              {
                                text: 'Cancelar',
                                role: 'cancel',
                              },
                              {
                                text: 'Confirmar',
                                cssClass: 'primary',
                                handler: () => {
                                  deletarItemProdutos(index);
                                }
                              }
                            ],
                            onDidDismiss: (e) => console.log('did dismiss'),
                          });
                        }}
                      >
                        <IonIcon color="primary" icon={trash} />
                      </IonButton>
                    </IonButtons>
                  </IonCol>

                </IonRow>
              </>
            ))}
          </IonGrid>
        </IonReorderGroup>
      </>
    );
  }


  function cadastrarDireto(descricaoValue: any, categoriaValue: any,
    quantidadeValue: any, unidadeMedidaValue: any, valorValue: any, descontoValue: any,
    subtotalValue: any, indice: any, tipoValue: any, tamanhoValue: any, quantidadeSaboresValue: any) {

    console.log("indice", indice);
    if (indice != "") {
      const objectRequest = {
        tipo: tipoValue,
        tamanho: tamanhoValue,
        quantidadeSabores: quantidadeSaboresValue,
        descricao: descricaoValue,
        categoria: categoriaValue,
        quantidade: quantidadeValue,
        largura: largura,
        comprimento: comprimento,
        unidadeMedida: unidadeMedidaValue,
        valor: valorValue,
        desconto: descontoValue || 0,
        subtotal: subtotalValue,
        indice: indice
      }
      // console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        alterarProjetosItemProdutosEmpresa.url.replace("{id}", params.id.replaceAll("#", "%23")),
        {
          method: alterarProjetosItemProdutosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          // console.log(response);
          if (response) {
            setTextToast("Alteração realizada com sucesso.");
            setShowToast(true);

            setListaItemProdutos(response.message.Attributes.produtos || []);

            setValorTotalProdutos(response.message.Attributes.valorTotalProdutos.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setTeveAlteracao(true);
            setShowDetalhesAdicionar(false);

          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar a alteração.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar a alteração.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else {
      const objectRequest = {
        tipo: tipoValue,
        tamanho: tamanhoValue,
        quantidadeSabores: quantidadeSaboresValue,
        descricao: descricaoValue,
        categoria: categoriaValue,
        quantidade: quantidadeValue,
        largura: largura,
        comprimento: comprimento,
        unidadeMedida: unidadeMedidaValue,
        valor: valorValue,
        desconto: descontoValue || 0,
        subtotal: subtotalValue,
      }
      // console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        inserirProjetosItemProdutosEmpresa.url.replace("{id}", params.id.replaceAll("#", "%23")),
        {
          method: inserirProjetosItemProdutosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          // console.log(response);
          if (response) {
            setTextToast("Cadastro realizado com sucesso.");
            setShowToast(true);

            setListaItemProdutos(response.message.Attributes.produtos || []);

            setValorTotalProdutos(response.message.Attributes.valorTotalProdutos.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setTeveAlteracao(true);

          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);
          if (buscarInputRef.current) {
            buscarInputRef.current.setFocus();
          }
        });
    }


  }

  const handleSearchMaisComporPorBarra = (e: any) => {
    if ((window.localStorage.getItem("servicoPrincipal") == "Pedidos") && e.target.value == "/") {
      setShowDetalhesCompor(true);
    }
    else {
      handleSearch(e);
    }
  };

  // Função para buscar itens
  const handleSearch = (e: any) => {
    setBuscar(e.target.value);
    const searchTerm = e.target.value?.toLowerCase();
    if (searchTerm === "") {
      setFilteredItems([]);
      return;
    }

    const isInteger = /^\d+$/.test(searchTerm);

    const filtered = dadosEmpresa?.catalogoProdutos.filter((item: any) =>
      item.tipo === "simples" && (isInteger ? item.codigo === searchTerm : item.codigo.includes(searchTerm) || item.nome.toLowerCase().includes(searchTerm))
    );
    setFilteredItems(filtered);
  };

  const handleSelectItemDireto = (item: any) => {

    setBuscar("");
    setFilteredItems([]);
    let subTotal = calcularSubtotal(item.valor, "1", item.unidadeMedida, "0", "0", "0");

    console.log("**********", subTotal);
    cadastrarDireto(item.nome, item.categoria, "1", item.unidadeMedida, item.valor, "0", subTotal, "", "", "", "");
  };


  // async function closeModalAtualizarInformacoes() {
  //   setShowAtualizarInformacoes(false);
  // }

  // async function closeModalAtualizarInformacoesSucess() {
  //   carregarProjeto();
  //   setShowAtualizarInformacoes(false);
  //   setRecarregarProjeto(true);
  //   setShowLiberar(true);
  // }

  async function closeModalLiberal() {
    setShowLiberar(false);
  }


  async function closeModalLiberalSucess(projeto: any) {
    setShowLiberar(false);

    if (projeto) {
      setDadosProjeto(projeto);
      setRecarregarProjeto(true);
      if (!isPlatform('mobileweb') && isPlatform('mobile')) {
        await gerarCupom(projeto.pk);
      }
      else {
        await openNewTabWithHTML(projeto);
      }
      history.goBack();
    }

  }


  async function gerarCupom(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoading(true);
    const resp = await fetch(projetoGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarCupom.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoading(false);

  }


  const openNewTabWithHTML = async (projeto: any) => {
    let newWindow: any;
    newWindow = window.open('', '_blank');

    // Gera o conteúdo HTML dinamicamente
    const htmlContentTab = await montarCupomHtml(projeto);

    // Escreve o conteúdo HTML na nova aba
    newWindow.document.open();
    newWindow.document.write(htmlContentTab);
    newWindow.document.close();

    // Chama a função de impressão na nova aba
    setTimeout(function () {
      newWindow.print();
      newWindow.close();
    }, 300);
  };


  async function montarCupomHtml(projeto: any) {

    function removerAcentosECedilha(texto: any) {
      return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ç/g, 'c').replace(/Ç/g, 'C');
    }

    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, ''); // Remove tudo que não é dígito

      if (texto.length === 11) {
        // Máscara para CPF
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        // Máscara para CNPJ
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }

      return texto; // Retorna o texto sem máscara se não tiver 11 ou 14 dígitos
    }

    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';
      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });
      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }
      return linhas;
    }
    let dataCupom: any;
    if (projeto.dataCupom) {
      dataCupom = new Date(projeto.dataCupom);
      //dataCupom.setHours(dataCupom.getHours() - 3);
    }
    let html = `
      <!DOCTYPE html>
      <html lang="pt-BR">
         <head>
            <meta charset="UTF-8">
            <meta nomeProjeto="viewport" content="width=device-width, initial-scale=1.0">
            <title>CUPOM NÃO FISCAL</title>
            <style>
               body {
               font-family: 'Courier New', Courier, monospace;
               margin: 0;
               padding: 0;
               }
            <style>
               @media print {
               body {
               width: 370px; /* Define a largura da página como a largura do papel da impressora térmica */
               margin: 0;
               }
               .no-print {
               display: none; /* Esconde elementos que não devem aparecer na impressão */
               }
               }
               .bold {
               font-weight: bold;
               }
               .regular {
               font-size: 12pt;
               }
               .regular-itens {
               font-size: 12pt;
               }
               .table-container {
               width: 100%;
               max-width: 370px;
               margin: 0 auto;
               text-align: left;
               }
               table {
               width: 100%;
               border-collapse: collapse;
               }
               th, td {
               border: none;
               padding: 5px;
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               th {
               background-color: #f2f2f2;
               }
               .header, .footer {
               margin-top: 20px;
               }
               .footer {
               margin-top: 40px;
               }
               .separator {
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               /* CSS for the giant button */
               .print-button {
               display: block;
               width: 100%;
               padding: 20px;
               margin: 20px 0;
               background-color: #4CAF50; /* Green background */
               color: white;
               font-size: 24px;
               font-weight: bold;
               text-align: center;
               border: none;
               border-radius: 10px;
               cursor: pointer;
               }
               .print-button:hover {
               background-color: #45a049; /* Darker green on hover */
               }
               .close-button {
               position: absolute;
               top: 10px;
               right: 10px;
               padding: 10px 20px;
               background-color: #f44336; /* Red background */
               color: white;
               font-size: 16px;
               font-weight: bold;
               border: none;
               border-radius: 5px;
               cursor: pointer;
               }
               .close-button:hover {
               background-color: #d32f2f; /* Darker red on hover */
               }
            </style>
            <div class="table-container">
               `;
    if ((projeto.interno)) {
      html += `
               <div class="header bold">
                  <div>${projeto.clienteNome}</div>
               </div>
               `;
    }
    else {
      html += `
               <div class="header bold">
                  <div>${projeto.empresaNome}</div>
                  <div class="regular">CNPJ: ${aplicarMascaraCPFouCNPJ(projeto.empresaCnpj)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${projeto.empresaEndereco}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
                  <div class="regular">Tel: ${formataTelefone(projeto.empresaTelefone)}</div>
                  <div class="regular">
                     ${(dataCupom)
          ? `Data: ${dataCupom.toLocaleDateString('pt-BR')} Hora: ${dataCupom.toLocaleTimeString('pt-BR')}`
          : ""}
                  </div>
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">
                  <div>${projeto.clienteNome}</div>
               </div>
               <div class="header bold">
                  <div class="regular">Tel: ${formataTelefone(projeto.clienteTelefone)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${[projeto.endereco, projeto.complemento]
            .filter(Boolean)
            .join(', ') || ""}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">CUPOM NÃO FISCAL</div>
               `;
    }
    html += `
               <div class="separator">======================================</div>
               <div class="regular">${projeto.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}</div>
               <div class="separator">======================================</div>
               `;
    if (projeto.produtos && projeto.produtos.length > 0) {
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td colspan="2">QTDE&nbsp|&nbspDESCRIÇÃO</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        ${projeto.produtos.map((iv: any, index: any) => `
                        ${(Array.isArray(iv.descricao)) ? (
          iv.descricao.map((itemDesc: any, indexLinha: any) => `
                        ${indexLinha === 0 ?
              quebrarTextoEmLinhas(iv.tipo === "pizzas" ? `${iv.tamanho} ${iv.quantidadeSabores} SABORES (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})` : `${iv.categoria} (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha2) => `
                        <tr>
                           ${indexLinha2 === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
              : ''}
                        ${(itemDesc.descricao) && (
              quebrarTextoEmLinhas(`- ${itemDesc.descricao} (R$ ${itemDesc.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha) => `
                        <tr>
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')
            )}
                        `).join('')
        ) : (
          quebrarTextoEmLinhas(`${iv.descricao} (R$ ${iv.valor.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })})`, 30)
            .map((linha, indexLinha) => `
                        <tr>
                           ${indexLinha === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
        )}
                        <tr>
                           <td colspan="2" class="regular-itens" style="text-align: left;">VALOR: R$ ${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        `).join('')}
                     </tbody>
                  </table>
               </div>
               `;
      if (!(projeto.interno)) {
        html += `
               <div class="bold">TOTAL: R$ ${projeto.valorTotalProdutos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      }
    }
    if (projeto.servicos && projeto.servicos.length > 0) {
      if (projeto.produtos && projeto.produtos.length > 0) {
        html += `
               <div class="separator">**************************************</div>
               `;
      }
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td>#</td>
                           <td>DESCRIÇÃO</td>
                           <td>QTD</td>
                           <td>VALOR</td>
                        </tr>
                        ${projeto.servicos.map((iv: any, index: any) => `
                        <tr>
                           <td class="regular-itens">${quebrarTextoEmLinhas(`${iv.descricao} x ${iv.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 30)
          .map((linha, indexLinha) => `
                        <tr>
                           <td class="regular-itens">${indexLinha == 0 ? String(index + 1).padStart(2, '0') : "&nbsp"}</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')}</td> 
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td class="regular-itens" style="text-align: right;">${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>`).join('')}
                     </tbody>
                  </table>
               </div>
               <div class="separator">--------------------------------------</div>
               <div class="bold">TOTAL: R$ ${projeto.valorTotalServicos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.formaPagamento) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}</div>
               `;
    }
    if (projeto.troco) {
      html += `
               <div class="bold">TROCO: R$ ${projeto.troco?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.taxaEntrega && !projeto.interno) {
      html += `            
               <div class="bold">TAXA DE ENTREGA: +R$ ${projeto.taxaEntrega?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.desconto) {
      html += `            
               <div class="bold">DESCONTO: -R$ ${projeto.desconto?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.interno && projeto.taxaServico) {
      html += `            
               <div class="bold">TAXA: +R$ ${projeto.taxaServico?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
               `;
    }
    if (projeto.valorTotal && !(projeto.interno)) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">TOTAL ${projeto.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${projeto.valorTotal?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      const qrData = await QRCode.toDataURL(projeto.pk);
      html += `
               <div class="separator">.</div>
               <div>
                  <img src="${qrData}" alt="QR Code" style="width: 150px; height: 150px;"/>
               </div>
               `;
    }
    html += `
               <div class="separator">.</div>
               <div class="separator">.</div>
               <div class="separator">.</div>
            </div>
            </body>
      </html>
      `;
    return removerAcentosECedilha(html);
  }


  async function fecharInterno() {
    const objectRequest = {
    }
    requestService(alterarStatusProjeto.url.replaceAll("{id}", params.id.replaceAll(/#/g, "%23")) + "?alteracao=true", {
      method: alterarStatusProjeto.method,
      headers: [["token", localStorage.getItem("token") || ""]],
      credentials: 'include',
      body: JSON.stringify(objectRequest)
    },
      history,
      async (response: any) => {

        // console.log("response", response);
        setDadosProjeto(response);
        setRecarregarProjeto(true);

        if (!isPlatform('mobileweb') && isPlatform('mobile')) {
          await gerarCupom(response.pk);
        }
        else {
          await openNewTabWithHTML(response);
        }

        Toast.show({
          text: "Liberado com sucesso!",
          position: "center",
          duration: "long"
        });
        history.goBack();
      },
      (error: any) => {
        setTextToast(error.message);
        setShowToast(true);
      });
  }

  async function closeModalProdutosCompor() {
    setShowDetalhesCompor(false);
  }

  async function closeModalProdutosComporSuccess(retorno: any) {

    if (retorno && retorno.Attributes) {
      setListaItemProdutos(retorno.Attributes.produtos || []);

      setValorTotalProdutos(retorno.Attributes.valorTotalProdutos.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
    setShowDetalhesCompor(false);
    setTeveAlteracao(true);
  }



  // async function closeModalProdutosComporV2() {
  //   setShowDetalhesComporV2(false);
  // }

  // async function closeModalProdutosComporV2Success(retorno: any) {

  //   if (retorno && retorno.Attributes) {
  //     setListaItemProdutos(retorno.Attributes.produtos || []);

  //     setValorTotalProdutos(retorno.Attributes.valorTotalProdutos.toLocaleString('pt-br', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2
  //     }));
  //   }
  //   setShowDetalhesComporV2(false);
  //   setTeveAlteracao(true);
  // }


  async function closeModalProdutosAdicionar() {
    setShowDetalhesAdicionar(false);
  }

  async function closeModalProdutosAdicionarSuccess(retorno: any, fecharModal: boolean) {

    if (retorno && retorno.Attributes) {
      setListaItemProdutos(retorno.Attributes.produtos || []);

      setValorTotalProdutos(retorno.Attributes.valorTotalProdutos.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }

    if (fecharModal) {
      setShowDetalhesAdicionar(false);
    }
    setTeveAlteracao(true);
  }




  async function liberar() {
    if (dadosProjeto.interno) {
      mostartAlerta({
        backdropDismiss: false,
        header: 'Liberar ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
        message: 'Deseja <strong>liberar</strong>?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            cssClass: 'primary',
            handler: async () => {
              await fecharInterno();
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })
    }
    else {
      //history.push("../projetos/projetoscupomhtml", { dadosProjeto: dadosProjeto })
      setShowLiberar(true);
    }
  }

  const scrollToCatalogo = () => {
    contentCatalogoRef.current && contentCatalogoRef.current.scrollIntoView();
  };

  async function adicionarItem(produto: any) {

    if (produto) {

      let updatedList = [...listaItemProdutos, produto];

      setListaItemProdutos(updatedList);

      let totalValor = updatedList.reduce((total: any, item: any) => {
        const itemValorNumerico = parseFloat(
          item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
        );
        return total + itemValorNumerico;
      }, 0);

      setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));


    }
    scrollToCatalogo();
  }

  function avancar() {
    setShowLoading(true);

    let objRequest = {
      "pk": params.id.replaceAll(/#/g, "%23"),
      "produtos": listaItemProdutos

    };

    requestService(alterarProjeto.url, {
      method: alterarProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {


        if (response.message && response.message.Attributes) {
          history.goBack();
        }
      },
      (error: any) => {
        console.log(error);
      }).finally(() => {
        setShowLoading(false);
      });
  }

  return (

    <IonPage className="ProjetosProdutosMemory">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => {
              if (teveAlteracao) {
                setRecarregarProjeto(true);
              }
              history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Itens</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>
              {/* <IonItem lines="none" button disabled={(!fecharComanda && (dadosProjeto.dataCupom))}
                hidden={acessos?.configuracoes != 'edicao'}
                onClick={() => {
                  setSelecionado("");
                  setShowDetalhesAdicionar(true);
                  closePopover();

                }}>
                <IonLabel>Adicionar Produto</IonLabel>
                <IonIcon icon={checkmarkOutline} slot="start" />
              </IonItem> */}

              {/* <IonItem lines="none" button disabled={(!fecharComanda && (dadosProjeto.dataCupom))}
                hidden={acessos?.configuracoes != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos")}
                onClick={() => {
                  setSelecionado("");
                  setShowDetalhesCompor(true);
                  closePopover();
                }}>

                <IonLabel>Adicionar Produto</IonLabel>
                <IonIcon icon={addCircleOutline} slot="start" />
              </IonItem> */}

              {/* <IonItem lines="none" button disabled={(!fecharComanda && (dadosProjeto.dataCupom))}
                hidden={acessos?.configuracoes != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos")}
                onClick={() => {
                  setSelecionado("");
                  setShowDetalhesComporV2(true);
                  closePopover();
                }}>
                <IonLabel>Compor Produto</IonLabel>
                <IonIcon icon={checkmarkDoneOutline} slot="start" />
              </IonItem> */}

              {/* <IonItem lines="none" button
                hidden={acessos?.configuracoes != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}
                onClick={() => {
                  setShowLiberar(true);
                  // setShowAtualizarInformacoes(true);
                  closePopover();
                }}>
                <IonLabel>Fechar</IonLabel>
              </IonItem> */}

              {/* <IonItem lines="none" button hidden={acessos?.projetos != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} disabled={dadosProjeto.dataCupom}
                onClick={async () => {


                  await liberar();

                  closePopover();

                }}>
                <IonIcon icon={checkboxOutline} slot="start" />
                <IonLabel>{'Liberar ' + (window.localStorage.getItem("servicoTipo") || "Projeto")}</IonLabel>
              </IonItem> */}

              {/* <IonItem lines="none" button hidden={acessos?.projetos != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} disabled={!dadosProjeto.dataCupom}
                onClick={async () => {

                  if (dadosProjeto.dataCupom) {
                    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                      await gerarCupom(dadosProjeto.pk);
                    }
                    else {
                      await openNewTabWithHTML(dadosProjeto);
                    }
                  }
                  else {
                    setShowLiberar(true);
                  }
                  closePopover();

                }}>
                <IonIcon icon={printOutline} slot="start" />
                <IonLabel>Cupom</IonLabel>
              </IonItem> */}


              <IonItem lines="none" button hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}
                onClick={() => {
                  history.push("../produtoscatalogo", { recarregar: true })
                  closePopover();
                }}>
                <IonIcon icon={bookOutline} slot="start" />
                <IonLabel>Catálogo produtos</IonLabel>
              </IonItem>
              <IonItem lines="none" button hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}
                onClick={() => { history.push("../produtoscategoriasnovo"); closePopover(); }}>
                <IonIcon icon={listOutline} slot="start" />
                <IonLabel>Categorias produtos</IonLabel>
              </IonItem>


              <IonItem lines="none" button onClick={closePopover}>
                <IonIcon icon={closeOutline} slot="start" />
                <IonLabel>Cancelar</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>
        </IonToolbar>

        {/* <IonToolbar color="primary">

          <IonSearchbar disabled={!fecharComanda && (dadosProjeto.dataCupom)}
            onIonInput={handleSearchMaisComporPorBarra}
            placeholder="Digite o código, nome ou barra para compor"
            value={buscar} ref={buscarInputRef}
          />

        </IonToolbar> */}



      </IonHeader>

      <IonContent fullscreen={false}>

        {/* {((window.localStorage.getItem("servicoPrincipal") == "Pedidos")) ? (
          <>
       
            <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.projetos !== 'edicao'}>
              <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { setSelecionado(""); setShowDetalhesCompor(true); }} disabled={(!fecharComanda && (dadosProjeto.dataCupom))}>
                <IonIcon icon={add}></IonIcon>
              </IonFabButton>
            </IonFab>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" hidden={acessos?.projetos !== 'edicao'}>
              <IonFabButton color="tertiary" disabled={(dadosProjeto.dataCupom)}
                onClick={async () => { await liberar(); }}>
                <IonLabel>Liberar</IonLabel>
              </IonFabButton>
            </IonFab>
          </>
        ) : (

          <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.projetos !== 'edicao'}>
            <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { setSelecionado(""); setShowDetalhesAdicionar(true); }} disabled={(!fecharComanda && (dadosProjeto.dataCupom))}>
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>
        )} */}


        <IonGrid style={{ padding: "0px", margin: "0px" }} ref={contentCatalogoRef}>
          <IonRow>

            <IonCol style={{ padding: "0px", margin: "0px" }} sizeMd="8" size="12">
              <ProjetosProdutosComporNovoMemory
                empresa={dadosEmpresa}
                disabled={(!fecharComanda && (dadosProjeto.dataCupom))}
                adicionarItem={adicionarItem}
              />
            </IonCol>


            <IonCol style={{ padding: "0px", margin: "0px" }} sizeMd="4" size="12">
              <IonCard>
                <IonCardContent>

                  <IonGrid style={{ padding: "0px", margin: "0px" }}>

                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="8"><IonLabel><b>Lista de itens</b></IonLabel></IonCol>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="4"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotalProdutos}</b></IonLabel></IonCol>

                    </IonRow>

                  </IonGrid>

                  {loadItemProdutos2()}

                  {/* <IonGrid style={{ margin: "0px" }} hidden={selectedButton == "Comanda"}>
                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="12"><IonLabel><b>Pagamento</b></IonLabel></IonCol>
                    </IonRow>
                  </IonGrid>


                  <IonGrid style={{ padding: "0px", margin: "0px" }} hidden={selectedButton == "Comanda"}>
                    <IonRow>
                      <IonCol>
                        <IonList>
                          <IonItem lines="none">
                            <IonLabel position="stacked">Forma de pagamento</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={formaPagamento}
                              onIonChange={(e) => {
                                setFormaPagamento(e.detail.value);
                              }}
                            >
                              {opcoesPagamento.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao}>
                                  {opcao}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>

                          <IonItem lines="none" hidden={formaPagamento != "Dinheiro"}>
                            <IonLabel position="stacked">Troco</IonLabel>
                            <IonInput
                              type="tel"
                              maxlength={14}
                              placeholder="Digite o valor"
                              value={troco}
                              onKeyUp={onChangeValor}
                            />
                          </IonItem>

                          <IonItem lines="none">
                            <IonLabel position="stacked">Taxa de entrega</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={taxaEntrega}
                              onIonChange={(e) => setTaxaEntrega(e.detail.value)}
                            >
                              {opcoesTaxaEntrega.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao.value}>
                                  {opcao.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>

                          <IonItem lines="none">
                            <IonLabel position="stacked">Desconto</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={desconto}
                              onIonChange={(e) => setDesconto(e.detail.value)}
                            >
                              {opcoesDesconto.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao.value}>
                                  {opcao.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonList>
                      </IonCol>
                    </IonRow>
                  </IonGrid> */}

                  {/* <IonGrid style={{ padding: "0px", margin: "0px" }}>
                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderTopStyle: 'solid' }}>
                      <IonCol><IonLabel><b>Total</b></IonLabel></IonCol>
                      <IonCol><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotal}</b></IonLabel></IonCol>
                    </IonRow>
                  </IonGrid> */}


                  <IonToolbar>
                    <IonButton disabled={!fecharComanda && (dadosProjeto.dataCupom)}
                      onClick={async () => {
                        avancar();
                      }}
                      expand="block"
                      type="submit"
                    >
                      Registrar
                    </IonButton>
                  </IonToolbar>


                </IonCardContent>
              </IonCard>
            </IonCol>

          </IonRow>
        </IonGrid>



        {/* {filteredItems?.length > 0 && (
          <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: filteredItems.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
            {filteredItems.map((item: any, index: any) => (
              <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItemDireto(item)}>
                <IonLabel>{item.nome} (R$ {item.valor})</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )} */}

        {/* <IonGrid>
          <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
            <IonCol><IonLabel><b>Total</b></IonLabel></IonCol>
            <IonCol><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotalProdutos}</b></IonLabel></IonCol>
          </IonRow>
        </IonGrid>
        {loadItemProdutos2()} */}

        {/* <div hidden={dadosProjeto.dataCupom || acessos?.projetos != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '20px' }}>
          <IonButton disabled={dadosProjeto.dataCupom}
            onClick={async () => {


              if (dadosProjeto.interno) {
                mostartAlerta({
                  backdropDismiss: false,
                  header: 'Liberar ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
                  message: 'Deseja <strong>liberar</strong>?',
                  buttons: [
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                    },
                    {
                      text: 'Confirmar',
                      cssClass: 'primary',
                      handler: async () => {
                        await fecharInterno();
                      }
                    }
                  ],
                  onDidDismiss: (e) => console.log('did dismiss'),
                })
              }
              else {
                //history.push("../projetos/projetoscupomhtml", { dadosProjeto: dadosProjeto })
                setShowLiberar(true);
              }


            }}
            type="submit"
            style={{ margin: "0px", textAlign: "right" }}
          >
            Liberar {window.localStorage.getItem("servicoTipo") || "Projeto"}
          </IonButton>
        </div>

        <div hidden={!dadosProjeto.dataCupom || acessos?.projetos != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '20px' }}>
          <IonButton
            onClick={async () => {

              //history.push("../projetos/projetoscupomhtml", { dadosProjeto: dadosProjeto })

              if (dadosProjeto.dataCupom) {
                if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                  await gerarCupom(dadosProjeto.pk);
                }
                else {
                  await openNewTabWithHTML(dadosProjeto);
                }
              }
              else {
                setShowLiberar(true);
              }



            }}
            type="submit"
            style={{ margin: "0px", textAlign: "right" }}
          >
            {dadosProjeto.dataCupom ? "Cupom" : dadosProjeto.interno ? "Liberar " + (window.localStorage.getItem("servicoTipo") || "Projeto") : "Fechar " + (window.localStorage.getItem("servicoTipo") || "Projeto")}
          </IonButton>
        </div> */}

        {/* <IonButton slot="end" >Ordenar</IonButton> */}

        <IonModal onDidPresent={() => {
          if (buscarAdicionarProdutoInputRef.current) {
            buscarAdicionarProdutoInputRef.current.setFocus();
          }
        }} isOpen={showDetalhesAdicionar} onDidDismiss={() => { setShowDetalhesAdicionar(false); }}>
          <ProjetosProdutosAdicionar idprojeto={params.id} value={selecionado} closeAction={closeModalProdutosAdicionarSuccess} closeActionSimple={closeModalProdutosAdicionar} inputRefSearchAdicionar={buscarAdicionarProdutoInputRef}></ProjetosProdutosAdicionar>
        </IonModal>

        <IonModal // className="fullscreen-modal"
          // onDidPresent={() => {
          //   if (buscarComporProdutoInputRef.current) {
          //     buscarComporProdutoInputRef.current.setFocus();
          //   }
          // }}
          isOpen={showDetalhesCompor} onDidDismiss={() => { setShowDetalhesCompor(false); }}>
          <ProjetosProdutosComporNovo idprojeto={params.id} value={selecionado} empresa={dadosEmpresa} closeAction={closeModalProdutosComporSuccess} closeActionSimple={closeModalProdutosCompor}></ProjetosProdutosComporNovo>
        </IonModal>

        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[

            // {
            //   text: 'Adicionar Produto',
            //   icon: checkmarkOutline,
            //   handler: () => {
            //     setShowDetalhesAdicionar(true);
            //   }
            // },
            // {
            //   text: 'Compor Produto',
            //   icon: checkmarkDoneOutline,
            //   handler: () => {
            //     setShowDetalhesCompor(true);
            //   }
            // },
            // {
            //   text: 'Compor Produto',
            //   icon: fastFoodOutline,
            //   handler: () => {
            //     setShowDetalhesComporV2(true);
            //   }
            // },
            {
              text: 'Cancelar',
              icon: closeOutline,
              role: 'cancel',
              handler: () => {
                setShowActionSheet(false);
              }
            }
          ]}
        />

        {/* <IonModal onDidPresent={() => {
          if (inputProjetosAltRef.current) {
            inputProjetosAltRef.current.setFocus();
          }
        }} isOpen={showAtualizarInformacoes} onDidDismiss={() => { setShowAtualizarInformacoes(false); }}>
          <ProjetosAlterar projeto={dadosProjeto} inputRef={inputProjetosAltRef} closeAction={closeModalAtualizarInformacoesSucess} closeActionSimple={closeModalAtualizarInformacoes}></ProjetosAlterar>
        </IonModal> */}

        <IonModal isOpen={showLiberar} onDidDismiss={() => { closeModalLiberal(); }}>
          <ProjetosLiberar projetoid={params.id} closeAction={closeModalLiberalSucess} closeActionSimple={closeModalLiberal}></ProjetosLiberar>
        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default ProjetosProdutosMemory;