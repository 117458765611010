import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLoading, IonMenuButton, IonModal, IonPage, IonRow, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { appsOutline, calendarOutline, cashOutline, chatbubbles, chatbubblesOutline, flagOutline, notifications, trendingUpOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Autoplay, Navigation, Pagination, Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '@ionic/react/css/ionic-swiper.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Menu from '../../Components/Menu';
import Notificacoes from '../Notificacoes/Notificacoes';
import './Inicio.css';


type Props = {
  abrirMenu: boolean;
};

const Inicio: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [showLoadingPage, setShowLoadingPage] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);

  const { mensagens, setMensagens } = useContext(MessageContext);

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("");
  });


  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }


  return (
    <>
      <Menu id="main-content-inicio"></Menu>
      <IonPage id="main-content-inicio" className="Inicio">
        <IonHeader className="ion-no-border">
          <IonToolbar color="primary">
            <IonButtons slot="start">


              <IonMenuButton></IonMenuButton>
            </IonButtons>


            <IonButtons slot="end">
              <IonButton onClick={() => { setShowNotificacoes(true); }}>
                <IonIcon slot="icon-only" icon={notifications} />
                <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
              </IonButton>
            </IonButtons>

            <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
              <IonButton onClick={() => { history.push("./chat"); }}>
                <IonIcon slot="icon-only" icon={chatbubbles} />
                <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
              </IonButton>
            </IonButtons>

          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={false}>


          <div style={{ padding: '5px' }}>

            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '0px' }}>
              <div id='containerInicioCategorias' style={{ maxWidth: '1024px' }}>

                <IonGrid fixed={true} style={{ paddingTop: '12px', maxWidth : "690px" }}>

                  <IonRow>
                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.vendas != 'leitura' && acessos?.vendas != 'edicao')}>
                      <IonCard onClick={() => { history.push("./vendas") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={trendingUpOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Vendas</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Funil de vendas e orçamentos
                        </IonCardContent>
                      </IonCard>
                    </IonCol>

                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.caixa != 'leitura' && acessos?.caixa != 'edicao')}>
                      <IonCard onClick={() => { history.push("./caixa") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={cashOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Caixa</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Fechar {window.localStorage.getItem("MesasOuComandasOuCaixas") || "Mesas"}
                        </IonCardContent>
                      </IonCard>
                    </IonCol>

                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao')}>
                      <IonCard onClick={() => { history.push("./projetos") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={appsOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>{window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Crie e acompanhe {window.localStorage.getItem("servicoTipoPlural") || "Projetos"}
                        </IonCardContent>
                      </IonCard>
                    </IonCol>



                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao')}>
                      <IonCard onClick={() => { history.push("./agendamentoseventoscommenu") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={calendarOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Agenda</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Agende entregas, serviços e reuniões
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao')}>
                      <IonCard onClick={() => { history.push("./operacoes") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={flagOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Operações</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Acompanhe o dia a dia da equipe
                        </IonCardContent>
                      </IonCard>
                    </IonCol>


                    {/* <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao')}>
                      <IonCard onClick={() => { history.push("./financeiro") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={logoUsd} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Financeiro</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Receitas e despesas em um único lugar
                        </IonCardContent>
                      </IonCard>
                    </IonCol> */}
                    <IonCol size={"6"} size-md={"6"} size-lg={"6"}>
                      <a target='_blank' href={"https://api.whatsapp.com/send?phone=5511941744046"} style={{ textDecoration: 'none' }}>
                        <IonCard
                          // onClick={() => { history.push("./menuconfiguracoes") }}
                          style={{ width: '100%', maxWidth: '100%', height: "134px" }}>
                          <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>

                            <IonIcon color="primary" style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={chatbubblesOutline} />

                            <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Ajuda</IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent style={{ fontSize: "12px" }}>
                            Como podemos ajudar você?
                          </IonCardContent>
                        </IonCard>
                      </a>
                    </IonCol>


                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonGrid fixed={true} style={{ paddingTop: '0px', paddingBottom: '10px', paddingLeft: '18px', paddingRight: '18px' }}>
              <IonRow>
                <IonCol size={"12"} size-md={"12"} size-lg={"12"} style={{ padding: '0px' }}>
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true
                    }}
                    navigation={false}
                    modules={[Autoplay, Pagination, Navigation]}

                    onSwiper={(swiper) => {
                      setSwiperInstance(swiper);
                    }} >
                    <div>
                      <SwiperSlide>
                        <img src="https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/banners/banner1.png" alt="Banner 1" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src="https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/banners/banner2.png" alt="Banner 2" />
                      </SwiperSlide>
                      {/* Adicione mais slides conforme necessário */}
                    </div>
                  </Swiper>
                </IonCol>
              </IonRow>

            </IonGrid>
          </div>


          <div className="content">
            <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
              <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
            </IonModal>

          </div>

          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoadingPage}
            onDidDismiss={() => { setShowLoadingPage(false); }}
            spinner='circles'
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Inicio;