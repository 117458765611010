import { Toast } from '@capacitor/toast';
import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonTitle, IonToggle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import imageCompression from 'browser-image-compression';
import { add, arrowBack, close, eye, star, starOutline, trashOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alterarDetaquePortfolio, assinaUrl, enviarArquivoPortfolio, removerArquivoPortfolioCategoria, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './Portfolio.css';
import imgDefaultImage from './imgs/defaultImage.png';


type Props = {
  abrirMenu: boolean;
};

const Portfolio: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [showModalIncluirCategorias, setShowModalIncluirCategorias] = useState(false);
  const [fileDataURL, setFileDataURL] = useState<any>(null);
  const imageMimeType = /image\/(png|jpg|jpeg)/i;
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [destaquePortfolio, setDestaquePortfolio] = useState(false);
  const [arrayCategorias, setArrayCategorias] = useState<any>([]);
  const [fileArquivos, setFileArquivos] = useState<any>();
  const [descricaoArquivo, setDescricaoArquivo] = React.useState<any>();

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const descricaoInputRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
  });

  useEffect(() => {
    carregaCategoria();
  }, []);

  // Busca as categorias cadastradas para a empresa
  async function carregaCategoria() {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      setShowLoadingPage(true);

      const resp: Response = await fetch(urlBase.url + "/portfolio",
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      await resp.json().then(async (res) => {

        if (resp.status === 400 || resp.status === 401) {
          history.replace("/login/");
        }

        if (res) {

          let lista = res.Items.sort((a: any, b: any) => a.destaque - b.destaque).reverse();

          //let lista = (res.Items.sort((a: any, b: any) => (a.descricao.toLowerCase() > b.descricao.toLowerCase()) ? 1 : -1));


          setArrayCategorias(lista);
        }

      }).finally(() => {
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  // Efetua o registro da imagem no sistema
  async function enviarArquivo(url: any, fileName: any, fileType: any) {

    let objRequest;
    objRequest = {
      "url": url,
      "nome": fileName,
      "tipo": fileType,
      "descricao": descricaoArquivo,
      "destaque": destaquePortfolio,
      "opcao": "categoria"
    };

    requestService(enviarArquivoPortfolio.url, {
      method: enviarArquivoPortfolio.method,
      headers: [
        ["token", window.localStorage.getItem("token") || ""]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response.message) {
          Toast.show({
            text: "Enviado com sucesso",
            position: "center",
            duration: "long"
          });

          //carregaGaleria(categoriaPortfolio);
          carregaCategoria();
          DismissModalIncluirCategorias();
        }
      },
      (error: any) => {
        console.log(error);
        Toast.show({
          text: error.message,
          position: "center",
          duration: "long"
        });
      })
  }

  // Efetua a exclusao da Categoria
  async function removerCategoria(categoria: any) {

    const remover = async () => {

      setShowLoadingPage(true);

      const resp: Response = await fetch(removerArquivoPortfolioCategoria.url.replace("{id}", categoria.replaceAll(/#/g, "%23")),
        {
          method: removerArquivoPortfolioCategoria.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });

      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          Toast.show({
            text: res.message,
            position: "center",
            duration: "long"
          });
        }
        else {
          if (res) {
            Toast.show({
              text: res.message,
              position: "center",
              duration: "long"
            });

            carregaCategoria();
          }
        }
      }).finally(() => {
        setShowLoadingPage(false);
      });
    }

    remover();
  }

  // Valida se tamanho do arquivo está dentro dos limites estabelecidos e efetua o processo de inclusão da categoria
  const handleUpload = async (file: any) => {

    if (file.size < 5000000) {
      try {

        await AssinaUploadS3Arquivo(file);

      } catch (error) {
        console.log("error", error);
        Toast.show({
          text: "Ocorreu um erro ao adicionar um arquivo, tente novamente.",
          position: "center",
          duration: "long"
        });
      }
    }
    else {
      Toast.show({
        text: "Somente arquivos até 5 MB.",
        position: "center",
        duration: "long"
      });
    }
  }

  // Método que assina url para envio do arquivo e efetua a inclusao da categoria 
  async function AssinaUploadS3Arquivo(file: any) {
    const atualizar = async () => {
      // Split the filename to get the name and type
      let fileParts = file.name.split('.');
      let fileNameArquivo = fileParts[0];
      let fileTypeArquivo = fileParts[1];

      let params = {
        nome: fileNameArquivo,
        tipo: fileTypeArquivo
      }

      try {
        setShowLoadingPage(true);

        //Assina URL
        const resp = await fetch(assinaUrl.url.concat("?pasta=portfolio"),
          {
            method: assinaUrl.method,
            headers: [
              ["token", localStorage.getItem("token") || ""]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        // Assinado com sucesso, insere arquivo no S3
        if (response.Item) {
          const uploadToS3 = await fetch(response.Item.urlAssinado, {
            method: "PUT",
            headers: {
              "Content-Type": fileTypeArquivo,
            },
            body: file,
          });

          // Inserido com sucesso no S3, registra categoria no sistema
          if (uploadToS3.status == 200) {
            await enviarArquivo(response.Item.key, response.Item.fileName, fileTypeArquivo);
          }
          else {
            Toast.show({
              text: "Ocorreu um erro ao fazer o upload do arquivo.",
              position: "center",
              duration: "long"
            });
          }
        }

        //setShowLoadingPage(false);

      } catch (error) {
        console.log("erro", error);
        //setShowLoadingPage(false);
      }
    }

    atualizar().finally(() => { setShowLoadingPage(false) });
  };

  // Método que efetua a alteração se e a categoria é destaque 
  async function alterarDestaque(categoria: any) {

    const consultar = async () => {

      setShowLoadingPage(true);

      const resp: Response = await fetch(alterarDetaquePortfolio.url.replace("{id}", categoria.replaceAll(/#/g, "%23")),
        {
          method: alterarDetaquePortfolio.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });

      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          history.replace("/login/");
        }

        if (res) {
          carregaCategoria();
        }
      }).finally(() => {
        setShowLoadingPage(false);
      });
    }

    consultar();

  }

  // Metodo chamado quando é selecionado um arquivo para subida
  async function ChangeHandlerInputFile(e: any) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match(imageMimeType)) {
        alert("Tipo da imagem inválido");
        return;
      }
      //setFileArquivos(file);
      setFileArquivos(await ReduzirFoto(file));
    }
    else {
      alert("Arquivo não selecionado");
      return;
    }
  }

  // Metodo para reduzir tamanho de imagens
  async function ReduzirFoto(file: File) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
      initialQuality: 1
    }

    return await imageCompression(file, options);
  }

  // Utilizado para exibir a imagem na tela quando o arquivo é selecionado
  useEffect(() => {
    let fileReader: FileReader, isCancel = false;
    if (fileArquivos) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const result = e.target;
        if (result && !isCancel) {
          setFileDataURL(result.result);
        }
      }
      fileReader.readAsDataURL(fileArquivos);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [fileArquivos]);

  // Utilizado no dismiss do modal de incluir categorias
  function DismissModalIncluirCategorias() {
    //carregaCategoria();
    setShowModalIncluirCategorias(false);
    setFileArquivos(null);
    setFileDataURL(null);
    setDescricaoArquivo('');
    setDestaquePortfolio(false);
  }

  // Chamado pelo botao cadastrar do modal de cadastro de categoria
  function CadastrarCategoria() {
    if (!fileArquivos || !descricaoArquivo || descricaoArquivo.trim() == "") {
      Toast.show({
        text: "Todos os campos são obrigatórios.",
        position: "center",
        duration: "long"
      });

      return null;
    }
    else {
      handleUpload(fileArquivos).catch((e) => { console.log("cancelado", e); });
    }
  }

  return (
    <IonPage className="Portfolio">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          {/* <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={images} />
            </IonButton>
          </IonButtons> */}
          <IonTitle>Portfólio</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { setShowModalIncluirCategorias(true) }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab vertical="bottom" horizontal="end" slot="fixed" >
          <IonFabButton color="tertiary" onClick={() => {
            history.push("portfolioVisualizar");
          }} >
            <IonIcon color="light" icon={eye}></IonIcon>
          </IonFabButton>
        </IonFab>
        <div style={{ padding: '5px', paddingTop: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '50px' }}>
            <div id='containerPortifolioCategorias' style={{ maxWidth: '1024px' }}>
              <IonGrid fixed={true}>
                <IonRow>
                  {
                    arrayCategorias.map((value: any) => (
                      <IonCol size={value.destaque ? "12" : "6"} size-md={value.destaque ? "12" : "6"} size-lg={value.destaque ? "12" : "6"}>
                        <div className='fabCategoria' style={{ position: 'absolute', top: '-10px', left: '-5px' }} hidden={acessos?.configuracoes != 'edicao'}>
                          <IonFab slot="fixed" vertical="top" horizontal="start"><IonFabButton onClick={() => alterarDestaque(value.sk)} color="medium" size='small'><IonIcon color='primary' icon={value.destaque ? star : starOutline} /></IonFabButton></IonFab>
                        </div>
                        <div className='fabCategoria' style={{ position: 'absolute', bottom: '0px', right: '-5px' }} hidden={acessos?.configuracoes != 'edicao'}>
                          <IonFab slot="fixed" vertical="bottom" horizontal="end"><IonFabButton onClick={() => { removerCategoria(value.sk) }} color='medium' size='small'><IonIcon color='light' icon={trashOutline} /></IonFabButton></IonFab>
                        </div>
                        <IonCard onClick={() => { history.push("galeria", { categoria: btoa(value.sk), descricao: value.descricao }); }}
                          style={{ width: '100%', maxWidth: '1024px', height: value.destaque ? "350px" : "250px" }}>
                          <img style={{ objectFit: 'cover', width: '100%', height: value.destaque ? "350px" : "250px" }} src={value.urlFile}></img>
                          {/* <ProgressiveImage imgSrc={value.urlFile} previewSrc={DefaultImage} width={'100%'} height={value.destaque ? "350px" : "250px"} /> */}
                          <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}>
                            <IonCardContent style={{ textAlign: 'end', height: value.destaque ? "350px" : "250px", width: '100%', display: 'flex', alignItems: 'end', paddingBottom: '50px', justifyContent: 'right', color: '#fff', fontFamily: 'Open Sans', fontSize: '20px', fontWeight: 'bold', textShadow: '1px 1px 10px #000' }}>{value.descricao}</IonCardContent>
                          </div>
                        </IonCard>
                      </IonCol>
                    ))
                  }
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </div>

        {/*MODAL PARA CADASTRO DE CATEGORIAS*/}
        <IonModal onDidPresent={() => {
          if (descricaoInputRef.current) {
            descricaoInputRef.current.setFocus();
          }
        }} isOpen={showModalIncluirCategorias} onDidDismiss={() => { DismissModalIncluirCategorias() }}>
          <IonContent>
            <IonToolbar style={{ paddingTop: '40px', height: "90px" }} >
              <IonButtons slot="start">
                <IonButton onClick={() => { DismissModalIncluirCategorias() }}>
                  <IonIcon color='primary' slot="icon-only" icon={close} />
                </IonButton>
              </IonButtons>
              <IonTitle color="primary">Cadastro de Categoria</IonTitle>
            </IonToolbar>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
              <IonCard onClick={() => { setShowModalIncluirCategorias(true); }} style={{ maxWidth: '350px', width: '100%', minHeight: '100px' }}>
                <IonCardContent>
                  <IonItem lines='full'>
                    <IonLabel position='stacked'>Categoria</IonLabel>
                    <IonInput value={descricaoArquivo} ref={(ref) => descricaoInputRef.current = ref} maxlength={20} required autoCorrect='true' autoCapitalize='true' onIonInput={(e: any) => setDescricaoArquivo(e.target.value)} placeholder='Nome da categoria'></IonInput>
                  </IonItem>
                  <IonItem lines='full'>
                    <IonLabel>Destaque</IonLabel>
                    <IonToggle checked={destaquePortfolio} onIonChange={e => { setDestaquePortfolio(e.detail.checked); }} slot='end'></IonToggle>
                  </IonItem>
                  <IonItem lines='none'>
                    <input onChange={ChangeHandlerInputFile} type="file" accept="image/*" />
                  </IonItem>
                  <img style={{ objectFit: 'cover', height: '250px', width: '100%', borderRadius: '15px' }} src={fileDataURL || imgDefaultImage}></img>
                </IonCardContent>
              </IonCard>
            </div>
          </IonContent>
          <IonFooter>
            <IonToolbar>
              <IonButton expand='block' onClick={() => CadastrarCategoria()}>Cadastrar</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />
      </IonContent>
    </IonPage>
  );
};

export default Portfolio;