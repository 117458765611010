import { IonActionSheet, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonReorder, IonReorderGroup, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { add, addCircleOutline, arrowBack, bookOutline, closeOutline, ellipsisVertical, listOutline, pencil, reorderTwo, trash } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { alterarVendasItemProdutosEmpresa, empresaCarregar, inserirVendasItemProdutosEmpresa, ordernarVendasItemProdutosEmpresa, removerVendasItemProdutosEmpresa, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./VendasProdutos.css";

type Props = {
  id: any;
  nome: any;
};

export interface Opcao {
  descricao: string;
}

const VendasProdutos: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();
  const [id, setId] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [listaItemProdutos, setListaItemProdutos] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const [indice, setIndice] = useState("");
  const [descricao, setDescricao] = useState("");
  const [categoria, setCategoria] = useState("");
  const [quantidade, setQuantidade] = useState<any>();
  const [largura, setLargura] = useState<any>();
  const [comprimento, setComprimento] = useState<any>();
  const [unidadeMedida, setUnidadeMedida] = useState("");
  const [valor, setValor] = useState<any>();
  const [desconto, setDesconto] = useState<any>();
  const [subtotal, setSubtotal] = useState<any>();
  const [valorTotal, setValorTotal] = useState<any>();
  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [listaCategoria, setListaCategoria] = useState<any>([]);

  const [isDisabled, setIsDisabled] = useState(true);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { recarregarVenda, setRecarregarVenda } = useContext(MessageContext);

  const [teveAlteracao, setTeveAlteracao] = useState(false);
  const [mostartAlerta] = useIonAlert();

  const [editar, setEditar] = useState(false);

  const [showDetalhesCompor, setShowDetalhesCompor] = useState(false);
  const [area, setArea] = useState("");
  const [buscar, setBuscar] = useState("");
  const [listaItemCatalogoVendas, setListaItemCatalogoVendas] = useState<any>([]);

  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [listaCompor, setListaCompor] = useState<any>([]);
  const [showActionSheet, setShowActionSheet] = useState(false);

  const buscarInputRef = useRef<any>(null);
  const buscarProdutoSimplesInputRef = useRef<any>(null);
  const buscarComporProdutoInputRef = useRef<any>(null);


  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };

  let params: any = {
    id: "",
    abrirInserirAgendamento: false
  }
  params = useParams();

  if (location && location.state && location.state.id) {
    params.id = location.state.id;
  }


  if (props.id) {
    params = {
      id: props.id
    }
  }

  useEffect(() => {
    if (id != "") {
      console.log("entrou no useEffect");
      carregarVenda();
      carregarEmpresa();

    }
  }, [id]);


  useEffect(() => {

    try {
      calcularSubtotal(valor, quantidade, unidadeMedida, largura, comprimento, desconto);
    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [largura, comprimento, quantidade, valor, desconto]);

  const calcularSubtotal = (
    valor: any,
    quantidade: any,
    unidadeMedida: any,
    largura?: any,
    comprimento?: any,
    desconto?: any
  ) => {
    try {
      const valorNumerico = parseFloat(valor?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o valor para número
      let quantidadeNumerica = parseFloat(quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a quantidade para número
      if (unidadeMedida === "m2") {
        let larg = parseFloat(largura?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a largura para número
        let comp = parseFloat(comprimento?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o comprimento para número
        quantidadeNumerica = larg * comp;

        if (largura && comprimento) {
          setQuantidade((larg * comp).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
      }
      let descontoNumerico = 0;
      if (desconto) {
        descontoNumerico = parseFloat(desconto.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o desconto para número
      }

      console.log("valorNumerico", valorNumerico);
      console.log("quantidadeNumerica", quantidadeNumerica);
      console.log("descontoNumerico", descontoNumerico);

      if (!valorNumerico || !quantidadeNumerica) {
        setSubtotal("0,00");
        return;
      }

      // Calcula o valor total sem desconto
      let valorTotal = valorNumerico * quantidadeNumerica;
      // Aplica o desconto
      valorTotal -= (valorTotal * descontoNumerico / 100);

      let valorTotalFormatado = (valorTotal.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

      setSubtotal(valorTotalFormatado);

      return valorTotal;
    } catch (error) {
      console.log("erro ao calcular", error);
    }
  };

  useIonViewDidEnter(() => {

    console.log("***********************", params.id);
    setEsconderMenu(true);
    setNomeMenu("");
    if (params.id) {
      setId(params.id);
    }

  });

  function carregarEmpresa() {

    const consultar = async () => {

      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setArea(response.Item.area);
          setListaItemCatalogoVendas(response.Item.catalogoProdutos || []);
          setListaCategoria(response.Item.categoriaProdutos || []);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function carregarVenda() {

    const consultar = async () => {

      console.log("*********************************params.id", params.id);

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      setShowLoading(true);
      const resp = await fetch(urlBase.url + "/vendas/" + params.id.replaceAll(/#/g, '%23'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        }).finally(() => {
          setShowLoading(false);
        });
      const response = await resp.json();

      if (response.Item !== undefined) {
        console.log("***************************", response);
        setListaItemProdutos(response.Item.produtos || []);
        setValorTotal(response.Item.valorTotal?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
        setValorTotalProdutos(response.Item.valorTotalProdutos?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }

      if (buscarInputRef.current) {
        buscarInputRef.current.setFocus();
      }
    }

    consultar();
  }

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setValor(v);
  }

  function onChangeQuantidade(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setQuantidade(v);
  }

  function onChangeLargura(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setLargura(v);
  }

  function onChangeComprimento(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setComprimento(v);
  }


  function onChangeDesconto(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    // Remove qualquer coisa que não seja um dígito
    v = v.replace(/\D/g, '');

    // Limita o desconto a 100%
    if (parseInt(v) > 100) {
      v = '100';
    }

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setDesconto(v);
  }


  function deletarItemProdutos(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delItemProdutos = async () => {
      const resp = await fetch(removerVendasItemProdutosEmpresa.url.replace("{id}", id.replace(/#/g, "%23")),
        {
          method: removerVendasItemProdutosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno", retorno);

      if (retorno && retorno.Item?.Attributes) {
        console.log("retorno.Item?.Attributes", retorno.Item?.Attributes);
        setTextToast("Produto removido com sucesso.");
        setShowToast(true);
        setListaItemProdutos(retorno.Item?.Attributes?.produtos || []);
        setTeveAlteracao(true);

        setValorTotal(retorno.Item?.Attributes?.valorTotal.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
        setValorTotalProdutos(retorno.Item?.Attributes?.valorTotalProdutos.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }))
      }
      else {
        setTextToast("Ocorreu um erro ao efetuar a exclusão.");
        setShowToast(true);
      }
    }

    delItemProdutos();

  }


  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    // console.log('Before complete', listaItemProdutos);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaItemProdutos(event.detail.complete(listaItemProdutos));

    // After complete is called the items will be in the new order
    // console.log('After complete', listaItemProdutos);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemProdutos = async () => {
      const resp = await fetch(ordernarVendasItemProdutosEmpresa.url.replace("{id}", id.replace(/#/g, "%23")),
        {
          method: ordernarVendasItemProdutosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'produtos': listaItemProdutos }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaItemProdutos(retorno.Item?.Attributes?.produtos || []);
      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemProdutos();

  }


  const loadItemProdutos = () => {

    if (listaItemProdutos == undefined) return;

    if (listaItemProdutos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {listaItemProdutos.map((value: any, index: any) => (
            <IonItem lines="none" key={"itemlista" + value.descricao + index.toString()}>

              <div style={{ flex: 1, width: "100px" }} onClick={() => {
                setTextToast(value.categoria + ' - ' + value.descricao);
                setShowToast(true);
              }}>
                <IonLabel style={{ fontSize: "12px" }}>
                  {value.categoria}
                </IonLabel>

                <IonLabel style={{ fontSize: "12px" }}>
                  {value.descricao}
                </IonLabel>
              </div>
              <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>{value.valor.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</IonLabel>
              <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>{value.quantidade.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</IonLabel>
              <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "center", fontSize: "12px" }}>{value.unidadeMedida}</IonLabel>
              {/* <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>{value.desconto}%</IonLabel> */}
              <IonLabel style={{ textAlign: "right", fontSize: "12px" }}>{value.subtotal}</IonLabel>
              <IonLabel >  </IonLabel>


              <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonReorder slot="end"></IonReorder>
              </div>
              <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonButtons >


                  {/* <IonButton hidden={!isDisabled} onClick={() => {
                    if (value.obrigatorio == "sim") {
                      setTextToast("Campo Obrigatório.");
                    }
                    else {
                      setTextToast("Campo não Obrigatório.");
                    }
                    setShowToast(true);
                  }}>
                    <IonIcon color="primary" icon={value.obrigatorio == "sim" ? checkmarkCircle : checkmarkCircleOutline}></IonIcon>
                  </IonButton> */}


                  <IonButton hidden={!isDisabled || acessos?.vendas != 'edicao'}
                    onClick={() => {
                      setIndice(value.indice);
                      setDescricao(value.descricao);
                      setCategoria(value.categoria);


                      setQuantidade(value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }));

                      if (value.unidadeMedida == "m2") {
                        setLargura(value.largura.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));


                        setComprimento(value.comprimento.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));
                      }

                      setUnidadeMedida(value.unidadeMedida);

                      setValor(value.valor.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }));

                      setDesconto(value.desconto.toString());
                      setSubtotal(value.subtotal);

                      setEditar(true);
                      setShowDetalhes(true);

                    }}>
                    <IonIcon color="primary" icon={pencil} />
                  </IonButton>

                  <IonButton hidden={!isDisabled || acessos?.vendas != 'edicao'}
                    onClick={() => {
                      mostartAlerta({
                        backdropDismiss: false,
                        header: 'Remover item',
                        message: 'Deseja <strong>remover</strong>?',
                        buttons: [
                          {
                            text: 'Cancelar',
                            role: 'cancel',
                          },
                          {
                            text: 'Confirmar',
                            cssClass: 'primary',
                            handler: () => {
                              deletarItemProdutos(value.indice);
                            }
                          }
                        ],
                        onDidDismiss: (e) => console.log('did dismiss'),
                      })
                    }}>
                    <IonIcon color="primary" icon={trash} />
                  </IonButton>

                </IonButtons>

              </div>
            </IonItem>

          ))}
        </IonReorderGroup>
      </>
    );
  }

  const loadItemProdutosCompor = () => {

    if (listaCompor == undefined) return;

    if (listaCompor.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>
        {listaCompor.map((value: any, index: any) => (
          <IonItem lines="none" key={"itemlista" + value.descricao + index.toString()}>

            <div style={{ flex: 1, width: "100px" }} onClick={() => {
              setTextToast(value.categoria + ' - ' + value.descricao);
              setShowToast(true);
            }}>
              <IonLabel style={{ fontSize: "12px" }}>
                {value.categoria}
              </IonLabel>

              <IonLabel style={{ fontSize: "12px" }}>
                {value.descricao}
              </IonLabel>
            </div>
            <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>{value.valor.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}</IonLabel>
            <IonLabel >  </IonLabel>

            <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
              <IonButtons >

                <IonButton hidden={!isDisabled || acessos?.projetos != 'edicao'}
                  onClick={() => {
                    mostartAlerta({
                      backdropDismiss: false,
                      header: 'Remover item',
                      message: 'Deseja <strong>remover</strong>?',
                      buttons: [
                        {
                          text: 'Cancelar',
                          role: 'cancel',
                        },
                        {
                          text: 'Confirmar',
                          cssClass: 'primary',
                          handler: () => {
                            deletarItemCompor(index);
                          }
                        }
                      ],
                      onDidDismiss: (e) => console.log('did dismiss'),
                    })
                  }}>
                  <IonIcon color="primary" icon={trash} />
                </IonButton>

              </IonButtons>

            </div>
          </IonItem>

        ))}
      </>
    );
  }

  function deletarItemCompor(index: Number): void {
    const updatedList = listaCompor.filter((_: any, i: any) => i !== index);
    setListaCompor(updatedList);

    console.log("updatedList", updatedList);


    const concatenatedDescriptions = updatedList.map((item: { descricao: any; }) => item.descricao).join("/");

    const totalValor = updatedList.reduce((total: any, item: any) => {
      const itemValorNumerico = parseFloat(item.valor.toString().replace(/\./g, '').replace(/\,/g, '.'));
      return total + itemValorNumerico;
    }, 0);

    let valorFinal = totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    setSubtotal(valorFinal);

    setDescricao(concatenatedDescriptions);
    setCategoria("Compor");
    setQuantidade("1");
    setUnidadeMedida("unid");
    setValor(valorFinal);
    setDesconto("0");


  }

  function validarCampo(valor: any, mensagem: any) {
    if (!valor || valor === 0 || valor.trim() === "") {
      setTextToast(mensagem);
      setShowToast(true);
      return false;
    }
    return true;
  }

  function validarCampos(
    descricao: any,
    categoria: any,
    unidadeMedida: any,
    valor: any,
    desconto: any,
    subtotal: any,
    largura?: any,
    comprimento?: any,
    quantidade?: any
  ): boolean {
    if (
      !validarCampo(descricao, "O campo descrição é obrigatório.") ||
      !validarCampo(categoria, "O campo categoria é obrigatório.") ||
      !validarCampo(unidadeMedida, "O campo unidade de medida é obrigatório.") ||
      !validarCampo(valor, "O campo valor é obrigatório.") ||
      // !validarCampo(desconto, "O campo desconto é obrigatório.") ||
      !validarCampo(subtotal, "O campo subtotal é obrigatório.")
    ) {
      return false;
    }

    if (unidadeMedida === "m2") {
      if (
        !validarCampo(largura, "O campo largura é obrigatório.") ||
        !validarCampo(comprimento, "O campo comprimento é obrigatório.")
      ) {
        return false;
      }
    } else {
      if (!validarCampo(quantidade, "O campo quantidade é obrigatório.")) {
        return false;
      }
    }

    return true;
  }

  function cadastrar() {

    if (!validarCampos(descricao, categoria, unidadeMedida, valor, desconto, subtotal, largura, comprimento, quantidade)) {
      return false;
    }

    cadastrarDireto(descricao, categoria, quantidade, unidadeMedida, valor, desconto, subtotal, indice);


  }

  function cadastrarDireto(descricaoValue: any, categoriaValue: any, quantidadeValue: any, unidadeMedidaValue: any, valorValue: any, descontoValue: any, subtotalValue: any, indice: any) {

    console.log("indice", indice);
    if (indice != "") {
      const objectRequest = {
        descricao: descricaoValue,
        categoria: categoriaValue,
        quantidade: quantidadeValue,
        largura: largura,
        comprimento: comprimento,
        unidadeMedida: unidadeMedidaValue,
        valor: valorValue,
        desconto: descontoValue || 0,
        subtotal: subtotalValue,
        indice: indice
      }
      console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        alterarVendasItemProdutosEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
        {
          method: alterarVendasItemProdutosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          console.log(response);
          if (response) {
            setTextToast("Alteração realizada com sucesso.");
            setShowToast(true);

            setListaItemProdutos(response.message.Attributes.produtos || []);
            setValorTotal(response.message.Attributes.valorTotal.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setValorTotalProdutos(response.message.Attributes.valorTotalProdutos.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setTeveAlteracao(true);
            setShowDetalhes(false);

          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar a alteração.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar a alteração.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);
          // if (buscarInputRef.current) {
          //   buscarInputRef.current.setFocus();
          // }
        });
    } else {
      const objectRequest = {
        descricao: descricaoValue,
        categoria: categoriaValue,
        quantidade: quantidadeValue,
        largura: largura,
        comprimento: comprimento,
        unidadeMedida: unidadeMedidaValue,
        valor: valorValue,
        desconto: descontoValue || 0,
        subtotal: subtotalValue,
      }
      console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        inserirVendasItemProdutosEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
        {
          method: inserirVendasItemProdutosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          console.log(response);
          if (response) {
            setTextToast("Cadastro realizado com sucesso.");
            setShowToast(true);

            setListaItemProdutos(response.message.Attributes.produtos || []);
            setValorTotal(response.message.Attributes.valorTotal.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setValorTotalProdutos(response.message.Attributes.valorTotalProdutos.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setDescricao("");
            setValor("");
            setQuantidade("");
            setLargura("");
            setComprimento("");
            //setUnidadeMedida("");
            setTeveAlteracao(true);
            // setShowDetalhes(false);

          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);
          if (buscarInputRef.current) {
            buscarInputRef.current.setFocus();
          }
        });

    }
  }

  function readCategoria() {
    return listaCategoria.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value} value={value}>{value}</IonSelectOption>
    )
    )
  };

  const handleSearchMaisComporPorBarra = (e: any) => {
    if (e.target.value == "/") {
      resetFields();
      setListaCompor([]);
      setEditar(false);
      setShowDetalhesCompor(true);
    }
    else {
      handleSearch(e);
    }
  };


  // Função para buscar itens
  const handleSearch = (e: any) => {
    setBuscar(e.target.value);
    const searchTerm = e.target.value?.toLowerCase();
    if (searchTerm === "") {
      setFilteredItems([]);
      return;
    }

    const isInteger = /^\d+$/.test(searchTerm);

    const filtered = listaItemCatalogoVendas.filter((item: any) =>
      isInteger ? item.codigo === searchTerm : item.codigo.includes(searchTerm) || item.nome.toLowerCase().includes(searchTerm)
    );
    setFilteredItems(filtered);
  };

  const handleSelectItem = (item: any) => {
    setDescricao(item.nome);
    setCategoria(item.categoria);
    setQuantidade("1");
    setUnidadeMedida(item.unidadeMedida);
    setValor(item.valor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setDesconto("0");
    setBuscar("");
    setFilteredItems([]);
  };


  const handleSelectItemDireto = (item: any) => {

    setBuscar("");
    setFilteredItems([]);
    let subTotal = calcularSubtotal(item.valor, "1", item.unidadeMedida, "0", "0", "0");

    console.log("**********", subTotal);
    cadastrarDireto(item.nome, item.categoria, "1", item.unidadeMedida, item.valor, "0", subTotal, "");
  };

  const handleSelectItemCompor = (item: any) => {

    setBuscar("");
    setFilteredItems([]);

    const novoItem = {
      descricao: item.nome,
      quantidade: "1",
      unidadeMedida: item.unidadeMedida,
      valor: item.valor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      desconto: "0",
      categoria: item.categoria,
    };

    let updatedList = [...listaCompor, novoItem];
    setListaCompor(updatedList);

    const concatenatedDescriptions = updatedList.map(item => item.descricao).join("/");

    const totalValor = updatedList.reduce((total, item) => {
      const itemValorNumerico = parseFloat(item.valor.toString().replace(/\./g, '').replace(/\,/g, '.'));
      return total + itemValorNumerico;
    }, 0);

    let valorFinal = totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    setSubtotal(valorFinal);

    setDescricao(concatenatedDescriptions);
    setCategoria("Compor");
    setQuantidade("1");
    setUnidadeMedida("unid");
    setValor(valorFinal);
    setDesconto("0");

    if (buscarComporProdutoInputRef.current) {
      buscarComporProdutoInputRef.current.setFocus();
    }

  };

  const resetFields = () => {
    setBuscar("");
    setIndice("");
    setDescricao("");
    setCategoria("");
    setQuantidade("");
    setLargura("");
    setComprimento("");
    setUnidadeMedida("");
    setValor("");
    setDesconto("");
    setSubtotal("0,00");
  };

  return (

    <IonPage className="VendasProdutos">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => {
              if (teveAlteracao) {
                setRecarregarVenda(true);
              }
              history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Itens</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>
              <IonItem lines="none" button
                hidden={acessos?.configuracoes != 'edicao'}
                onClick={() => {

                  resetFields();
                  setEditar(false);
                  setShowDetalhes(true);
                  closePopover();

                }}>
                <IonIcon icon={addCircleOutline} slot="start" />
                <IonLabel>Adicionar Produto</IonLabel>
              </IonItem>

              {/* <IonItem lines="none" button
                hidden={acessos?.configuracoes != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}
                onClick={() => {
                  resetFields();
                  setListaCompor([]);
                  setEditar(false);
                  setShowDetalhesCompor(true);
                  closePopover();
                }}>
                <IonLabel>Compor Produto</IonLabel>
              </IonItem> */}

              {/* <IonItem lines="none" button
                hidden={acessos?.configuracoes != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}
                onClick={() => {
                  setShowAtualizarInformacoes(true);
                  closePopover();
                }}>
                <IonLabel>Fechar {window.localStorage.getItem("servicoTipo") || "Projeto"}</IonLabel>
              </IonItem> */}

              <IonItem lines="none" button hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}
                onClick={() => { history.push("../produtoscatalogo"); closePopover(); }}>
                <IonIcon icon={bookOutline} slot="start" />
                <IonLabel>Catálogo de produtos</IonLabel>
              </IonItem>
              <IonItem lines="none" button hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}
                onClick={() => { history.push("../produtoscategorias"); closePopover(); }}>
                <IonIcon icon={listOutline} slot="start" />
                <IonLabel>Categorias dos produtos</IonLabel>
              </IonItem>

              <IonItem lines="none" button onClick={closePopover}>
                <IonIcon icon={closeOutline} slot="start" />
                <IonLabel>Cancelar</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>

        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.projetos !== 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => 
            {
              resetFields();
              setEditar(false);
              setShowDetalhes(true);
            }}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonSearchbar
          onIonInput={handleSearchMaisComporPorBarra}
          placeholder="Digite o código, nome ou barra para compor"
          value={buscar} ref={buscarInputRef}
        />

        {filteredItems?.length > 0 && (
          <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: filteredItems.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
            {filteredItems.map((item: any, index: any) => (
              <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItemDireto(item)}>
                <IonLabel>{item.nome}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}

        <IonList style={{ margin: "0px", padding: "0px" }}>
          <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>

            <div style={{ flex: 1, width: "100px" }}>
              <IonLabel style={{ fontSize: "12px" }}>Descrição</IonLabel>
            </div>
            <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>Valor</IonLabel>
            <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>Quantidade</IonLabel>
            <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "center", fontSize: "12px" }}>Unidade de Medida</IonLabel>
            {/* <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>Desconto</IonLabel> */}
            <IonLabel style={{ textAlign: "right", fontSize: "12px" }}>Total</IonLabel>
            <IonLabel style={{ textAlign: "right" }}>
              <IonBadge hidden={acessos?.vendas != 'edicao'}
                onClick={() => setIsDisabled((current) => !current)}>
                <IonIcon color="medium" icon={reorderTwo}></IonIcon>
              </IonBadge>
            </IonLabel>
          </IonItem>

          {loadItemProdutos()}

          <IonItem lines="none" style={{ fontWeight: 'bold', borderTop: '1px solid #dddddd' }} hidden={listaItemProdutos.length === 0}>

            <div style={{ flex: 1, width: "100px" }}>
              <IonLabel style={{ fontSize: "12px" }}></IonLabel>
            </div>
            <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ fontSize: "12px" }}></IonLabel>
            <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ fontSize: "12px" }}></IonLabel>
            {/* <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ fontSize: "12px" }}></IonLabel> */}
            <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ fontSize: "12px" }}></IonLabel>
            <IonLabel style={{ textAlign: "right", fontSize: "12px" }}>{valorTotalProdutos}</IonLabel>
            <IonLabel style={{ textAlign: "right" }}>
            </IonLabel>
          </IonItem>


          {/* <IonFab horizontal="end" edge style={{ "top": "0px", "right": "-4px" }} hidden={acessos?.vendas != 'edicao'}>
            <IonFabButton size="small" color="tertiary" 
              <IonIcon color="light" icon={menu}></IonIcon>
            </IonFabButton>
          </IonFab> */}
        </IonList>


        {/* <IonButton slot="end" >Ordenar</IonButton> */}



        <IonModal onDidPresent={() => {
          if (buscarProdutoSimplesInputRef.current) {
            buscarProdutoSimplesInputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
                    setShowDetalhes(false);
                  }} />
                </IonButtons>
                <IonTitle>{editar ? "Editar" : "Novo"} produto</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>

              <IonSearchbar
                onIonInput={handleSearch}
                placeholder="Digite o código ou nome"
                value={buscar} ref={buscarProdutoSimplesInputRef}
              />

              <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: filteredItems.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
                {filteredItems.map((item: any, index: any) => (
                  <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItem(item)}>
                    <IonLabel>{item.nome}</IonLabel>
                  </IonItem>
                ))}
              </IonList>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList lines="full">
                      <IonItem>
                        <IonLabel position="stacked">Descrição*</IonLabel>
                        <IonInput
                          placeholder="Digite a descrição"

                          ref={(ref) => buscarInputRef.current = ref}
                          autoCorrect='true' autoCapitalize='true'
                          value={descricao}
                          required
                          onIonInput={(e: any) => setDescricao(e.target.value)}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position="stacked">Categoria*</IonLabel>
                        <IonSelect
                          value={categoria}
                          placeholder="Selecione"
                          onIonChange={e => { setCategoria(e.detail.value); }}
                        >
                          {readCategoria()}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="stacked">Valor*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite o valor"
                          value={valor}
                          onKeyUp={onChangeValor}
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Unidade de medida*</IonLabel>
                        <IonSelect
                          value={unidadeMedida}
                          placeholder="Selecione"
                          onIonChange={e => { setUnidadeMedida(e.detail.value); }}
                        >
                          <IonSelectOption key="kg" value="kg">Quilograma (kg)</IonSelectOption>
                          <IonSelectOption key="m" value="m">Metro (m)</IonSelectOption>
                          <IonSelectOption key="m2" value="m2">Metro (m²)</IonSelectOption>
                          <IonSelectOption key="unid" value="unid">Unidade (unid)</IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem hidden={unidadeMedida != "m2"}>
                        <IonLabel position="stacked">Largura*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite a largura"
                          value={largura}
                          onKeyUp={onChangeLargura}
                        />
                      </IonItem>
                      <IonItem hidden={unidadeMedida != "m2"}>
                        <IonLabel position="stacked">Comprimento*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite o comprimento"
                          value={comprimento}
                          onKeyUp={onChangeComprimento}
                        />
                      </IonItem>

                      <IonItem disabled={unidadeMedida == "m2"}>
                        <IonLabel position="stacked">Quantidade*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite a quantidade"
                          value={quantidade}
                          onKeyUp={onChangeQuantidade}
                        />
                      </IonItem>

                      {/* <IonItem>
                        <IonLabel position="stacked">Desconto(%)*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={3}
                          placeholder="Digite o desconto"
                          value={desconto}
                          onKeyUp={onChangeDesconto}
                        />
                      </IonItem> */}
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>
            <IonFooter className="ion-no-border" style={{ borderTop: '1px solid #ccc' }}>


              <IonList>
                <IonItem lines="none">
                  <IonLabel slot="start" style={{ fontWeight: 'bold' }}>Valor Total:</IonLabel>
                  <IonLabel slot="end">{subtotal || "0"}</IonLabel>
                </IonItem>


              </IonList>

              <IonToolbar>
                <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonModal onDidPresent={() => {
          if (buscarComporProdutoInputRef.current) {
            buscarComporProdutoInputRef.current.setFocus();
          }
        }} isOpen={showDetalhesCompor} onDidDismiss={() => {
          setShowDetalhesCompor(false);
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
                    setShowDetalhesCompor(false);
                  }} />
                </IonButtons>
                <IonTitle>Compor produto</IonTitle>


              </IonToolbar>
            </IonHeader>
            <IonContent>


              <IonSearchbar
                onIonInput={handleSearch}
                placeholder="Digite o código ou nome"
                value={buscar} ref={buscarComporProdutoInputRef}
              />

              <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: filteredItems.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
                {filteredItems.map((item: any, index: any) => (
                  <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItemCompor(item)}>
                    <IonLabel>{item.nome}</IonLabel>
                  </IonItem>
                ))}
              </IonList>



              <IonGrid>
                <IonRow>
                  <IonCol>

                    <IonList style={{ margin: "0px", padding: "0px" }}>
                      <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>

                        <div style={{ flex: 1, width: "100px" }}>
                          <IonLabel style={{ fontSize: "12px" }}>Descrição</IonLabel>
                        </div>
                        <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>Valor</IonLabel>
                        <IonLabel style={{ textAlign: "right" }}></IonLabel>
                      </IonItem>

                      {loadItemProdutosCompor()}

                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>
            <IonFooter className="ion-no-border" style={{ borderTop: '1px solid #ccc' }}>


              <IonList>
                <IonItem lines="none">
                  <IonLabel slot="start" style={{ fontWeight: 'bold' }}>Valor Total:</IonLabel>
                  <IonLabel slot="end">{subtotal || "0"}</IonLabel>
                </IonItem>


              </IonList>

              <IonToolbar>
                <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[

            {
              text: 'Adicionar Produto',
              icon: addCircleOutline,
              handler: () => {
                resetFields();
                setEditar(false);
                setShowDetalhes(true);
              }
            },
            // {
            //   text: 'Compor Produto',
            //   icon: checkmarkDone,
            //   handler: () => {
            //     resetFields();
            //     setListaCompor([]);
            //     setEditar(false);
            //     setShowDetalhesCompor(true);
            //   }
            // },
            {
              text: 'Cancelar',
              icon: closeOutline,
              role: 'cancel',
              handler: () => {
                setShowActionSheet(false);
              }
            }
          ]}
        />

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default VendasProdutos;