import { IonBadge, IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonLoading, IonMenuButton, IonModal, IonPage, IonRefresher, IonRefresherContent, IonToast, IonToolbar, isPlatform, useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import "./AgendamentosEventosComMenu.css";

import moment from 'moment';
import 'moment/locale/pt-br';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import './react-big-calendar.css';
//import 'react-big-calendar/lib/sass/styles';
import { Buffer } from 'buffer';
import dayjs from 'dayjs';
import { add, chatbubbles, checkmarkCircle, notifications, refresh } from 'ionicons/icons';
import { urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import AgendamentosNovo from '../AgendamentosNovo/AgendamentosNovo';
import Notificacoes from '../Notificacoes/Notificacoes';

type Props = {
  abrirMenu: boolean;
  // empresaid: string;
  onDismiss: () => void;
};

class CalendarEvent {
  title: string;
  allDay: boolean;
  color: string;
  start: Date;
  end: Date;
  desc: string;
  status: string;
  pk: string;
  confirmado: boolean;
  resourceId?: string;
  tooltip?: string;

  constructor(_title: string, _start: Date, _endDate: Date, _allDay?: boolean, _color?: string, _desc?: string, _status?: string, _pk?: string, _confirmado?: boolean, _resourceId?: string) {
    this.title = _title;
    this.allDay = _allDay || false;
    this.color = _color || '';
    this.start = _start;
    this.end = _endDate;
    this.desc = _desc || '';
    this.status = _status || '';
    this.pk = _pk || '';
    this.confirmado = _confirmado || false;
    this.resourceId = _resourceId;
  }

  getJson(): any {
    return JSON.stringify(this);
  }
}

const AgendamentosEventosComMenu: React.FC<Props> = (props: Props) => {

  const inputRef = useRef<any>(null);

  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState<any[]>([]);
  //const [mes, setMes] = useState<number>(new Date().getMonth());
  //const [ano, setAno] = useState<number>(new Date().getFullYear());
  const history = useHistory<any>();
  const location = useLocation<any>();
  const [hiddenBack, setHiddenBack] = useState(false);
  const [hiddenNext, setHiddenNext] = useState(false);
  const [eventoSelecionado, setEventoSelecionado] = useState<any>();


  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");



  const [showNovo, setShowNovo] = useState(false);


  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { loja, setLoja } = useContext(MessageContext);
  const [showModalEvento, setShowModalEvento] = useState(false);

  const { anoAgendamento, setAnoAgendamento } = useContext(MessageContext);
  const { mesAgendamento, setMesAgendamento } = useContext(MessageContext);
  const { recarregarAgendamento, setRecarregarAgendamento } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);

  const { mensagens, setMensagens } = useContext(MessageContext);


  const inputAgendamentoNovoRef = useRef<any>(null);

  // let params: any = {
  //   projetoid: "",
  //   empresaid: ""
  // }

  // params = useParams();

  // if (location && location.state && location.state.empresaid) {
  //   params.empresaid = location.state.empresaid;
  // }

  // if (props.empresaid) {
  //   params = {
  //     empresaid: props.empresaid
  //   }
  // }

  useEffect(() => {
    if (!anoAgendamento && !mesAgendamento) {
      setAnoAgendamento(new Date().getFullYear());
      setMesAgendamento(new Date().getMonth());
    }
  }, []
  );


  useEffect(() => {
    carregaAgendamentos(anoAgendamento || new Date().getFullYear(), mesAgendamento || new Date().getMonth());
  }, [loja]
  );


  useEffect(() => {

    console.log("mes", mesAgendamento);
    // if (params.empresaid) {
    // setAnoAgendamento(new Date().getFullYear());
    // setMesAgendamento(new Date().getMonth());
    carregaAgendamentos(anoAgendamento || new Date().getFullYear(), mesAgendamento || new Date().getMonth());
    // }
  }, [mesAgendamento, anoAgendamento]);


  useEffect(() => {
    if (recarregarAgendamento) {
      console.log("**********************************passou no recarregar");
      carregaAgendamentos(anoAgendamento || new Date().getFullYear(), mesAgendamento || new Date().getMonth());
      setRecarregarAgendamento(false);
    }
  }, [recarregarAgendamento]
  );

  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("Agenda");
    // if (params.abrirInserirAgendamento) {
    //   setShowModalEvento(true);
    // }

  });

  const carregaAgendamentos = async (anoParam: number, mesParam: number) => {

    const loadResult = await carregarAgendamentos(anoParam, mesParam);


    if (loadResult) {
      let contador = 0;

      let newEvents: any[] = [{}];

      loadResult.map((valor: any, index: any) => {
        contador++;


        let data: string = valor.dataAgendamento.substring(0, 10);
        let dataHoraComeco: Date = new Date(data + "T" + valor.horaAgendamento + ":00");
        let dataHoraTermino: Date = new Date(data + "T" + (valor.horaAgendamento2 ? valor.horaAgendamento2 : valor.horaAgendamento) + ":00");

        const newEvent: CalendarEvent = new CalendarEvent((valor.interno ? "interno;" : "não interno;")
          + (valor.confirmado ? "confirmado;" : "não confirmado;")
          + (valor.convidados && valor.convidados.length > 0 ? "convidados;" : "não convidados;")
          + valor.clienteNome + ": " + valor.descricaoAgendamento + " - " + valor.tipoAgendamento + " - " + valor.prestadorNome,
          dataHoraComeco,
          dataHoraTermino,
          false,
          valor.color,
          "",
          valor.status,
          valor.pk,
          valor.confirmado
        );

        newEvents.push(newEvent);
      });

      setEvents(newEvents);
      // console.log("newEvents", newEvents);

    }
  }


  function diasNoMes(mes: number, ano: number) {
    var data = new Date(ano, mes, 0);
    return data.getDate();
  }

  async function carregarAgendamentos(ano: number, mes: number) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }



      let dataFormatada = dayjs(ano + '-' + (mes + 1) + '-01').format('YYYY-MM-DD');
      let dataFormatada2 = dayjs(ano + '-' + (mes + 1) + '-' + diasNoMes(mes, ano)).format('YYYY-MM-DD');


      let urlFinal = urlBase.url + "/agendamentos/eventos?campo=data" +
        "&valor=" + dataFormatada +
        "&valor2=" + dataFormatada2 +
        "&lojas=" + Buffer.from(loja.replaceAll(/#/g, '%23')).toString('base64');


      setShowLoading(true);

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      let res = await resp.json();

      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }

      setShowLoading(false);

      if (res && res.Items) {
        return res.Items;
      }
      else {
        return null;
      }



    };
    return await consultar();
  }

  const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
  };

  function Event(event: any) {
    return (
      <span style={{ fontSize: "10px" }}>
        {/* <strong>
          <p style={{ fontSize: "10px" }}> */}
        {/* {event.confirmado ? <IonIcon color="success" icon={checkmarkCircle} /> : <IonIcon color="danger" icon={checkmarkCircle} />} */}
        {/* {event.confirmado ? "aaaaaaaaa ": "bbbb"} */}
        {/* {event.title.split(";")[0] == "interno" ? <IonIcon color="danger" icon={lockClosed} /> : <IonIcon color="success" icon={lockOpen} />} */}
        {event.title.split(";")[1] == "confirmado" ? <IonIcon color="success" icon={checkmarkCircle} /> : <IonIcon color="danger" icon={checkmarkCircle} />}
        {/* {event.title.split(";")[2] == "convidados" ? <IonIcon color="success" icon={people} /> : <IonIcon color="danger" icon={people} />} */}
        {event.title.split(";")[3].trim()}
        {/* </p>
        </strong> */}
        {/* {event.desc && (':  ' + event.desc)} */}
      </span>
    )
  }

  // function CustomToolbar() {
  //   return (
  //     <div className="toolbar-container">

  //       <div className="back-next-buttons">
  //         {/* <IonButton className="btn btn-back">
  //           <BackIcon/>
  //         </IonButton> */}
  //         {/* <label className='label-date'>Aug-Sept 2016</label> */}
  //       </div>

  //       <div className="filter-container">
  //         <ButtonGroup>
  //           <Button className="bg-filter-off"><span className="label-filter-off">Day</span></Button>
  //           <Button className="bg-filter-off"><span className="label-filter-off">Week</span></Button>
  //           <Button className="bg-filter-off"><span className="label-filter-off">Month</span></Button>
  //           <Button className="bg-filter-off"><span className="label-filter-off">Year</span></Button>
  //         </ButtonGroup>


  //       </div>
  //     </div >
  //   )
  // }

  const CustomToolbar = (toolbar: any) => {

    const goToAgendaView = () => {
      toolbar.onView('agenda');
      // setHiddenBack(true);
      // setHiddenNext(true);
    }

    const goToDayView = () => {
      toolbar.onView('day');
      // setHiddenBack(true);
      // setHiddenNext(true);
    }

    const goToWeekView = () => {
      toolbar.onView('week');
      // setHiddenBack(true);
      // setHiddenNext(true);
    }

    const goToMonthView = () => {
      toolbar.onView('month');
      // setHiddenBack(false);
      // setHiddenNext(false);
    }

    const goToBack = () => {
      //console.log("ano", toolbar.date.getYear());

      //setMes(toolbar.date.getMonth() - 1);


      //toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      //console.log("ano", toolbar.date.getYear());
      //setMes(toolbar.date.getMonth() + 1);

      //toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
      const now = new Date();
      console.log("now.getMonth()", now.getMonth());
      console.log("toolbar", toolbar.date.getMonth());
      setMesAgendamento(toolbar.date.getMonth());

      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
      );
    };

    return (
      <div className='toolbar-container'>
        <label className='label-date'>{label()}</label>

        <div className='back-next-buttons'>
          <IonButton className='btn-back' onClick={goToBack} hidden={hiddenBack}>&#8249;</IonButton>
          <IonButton className='btn-next' onClick={goToNext} hidden={hiddenNext}>&#8250;</IonButton>
        </div>

        <div className='back-next-buttons'>
          <IonButton className='btn-current' onClick={goToMonthView}>mês</IonButton>
          <IonButton className='btn-back' onClick={goToWeekView}>semana</IonButton>
          <IonButton className='btn-back' onClick={goToDayView}>dia</IonButton>
          <IonButton className='btn-current' onClick={goToAgendaView}>agenda</IonButton>
        </div>

      </div >
    );
  };


  async function closeModalNovo(pk: any) {
    carregaAgendamentos(anoAgendamento || new Date().getFullYear(), mesAgendamento || new Date().getFullYear());
    setShowNovo(false);
  }

  async function closeModalSimpleNovo() {
    setShowNovo(false);
  }


  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }


  function doRefresh(event: any) {
    carregaAgendamentos(anoAgendamento, mesAgendamento);
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }


  return (
    <>
      <IonPage className="AgendamentosEventosComMenu">
        <IonHeader className="ion-no-border">
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            {/* <IonButtons slot="start">
           
              {
                ((!isPlatform('mobileweb') && isPlatform('mobile'))) ?
                  (
                    < IonButton onClick={() => {
                      history.goBack();
                    }}>
                      <IonIcon slot="icon-only" icon={arrowBack} />
                    </IonButton>
                  ) :
                  (
                    <IonButtons slot="start">
                      <IonMenuButton></IonMenuButton>
                    </IonButtons>
                  )
              }
            </IonButtons> */}
            {/* <IonTitle hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))}>Agenda</IonTitle> */}


            <IonButtons slot="end">
              <IonButton onClick={() => { setShowNotificacoes(true); }}>
                <IonIcon slot="icon-only" icon={notifications} />
                <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
              </IonButton>
            </IonButtons>

            <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
              <IonButton onClick={() => { history.push("./chat"); }}>
                <IonIcon slot="icon-only" icon={chatbubbles} />
                <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
              </IonButton>
            </IonButtons>

          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={false}>


          <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.agenda != 'edicao'}>

            <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {

              setShowNovo(true);


            }} >
              <IonIcon color="light" icon={add}></IonIcon>
            </IonFabButton>




          </IonFab>


          {/* <IonFab vertical="bottom" horizontal="center" slot="fixed" style={{ "top": "20px" }} hidden={acessos?.agenda != 'edicao' || !(!isPlatform('mobileweb') && isPlatform('mobile'))}>

            <IonFabButton color="tertiary" onClick={() => {

              setShowNovo(true);


            }} >
              <IonIcon color="light" icon={add}></IonIcon>
            </IonFabButton>




          </IonFab> */}


          <IonFab vertical="top" horizontal="end" slot="fixed" style={{ "top": "68px" }} hidden={isPlatform("android") || isPlatform("ios")}>
            <IonFabButton color="tertiary" onClick={() => { carregaAgendamentos(anoAgendamento, mesAgendamento); }} >
              <IonIcon color="light" icon={refresh}></IonIcon>
            </IonFabButton>
          </IonFab>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>


          <Calendar
            style={{ "paddingTop": "10px" }}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            selectable={false}
            culture="pt-BR"
            views={
              {
                month: true,
                week: true,
                day: true,
                agenda: true
              }}
            // defaultView={Views.AGENDA}
            // timeslots={10}
            // min={new Date('2023, 1, 21, 08:00')}
            // max={new Date('2023, 1, 21, 20:00')}
            localizer={localizer}
            onNavigate={(newDate: Date) => {
              // console.log("newDate", newDate)
              // console.log("newDate.getFullYear()", newDate.getFullYear())
              setAnoAgendamento(newDate.getFullYear());
              setMesAgendamento(newDate.getMonth());
            }}
            onSelectEvent={event => {
              // console.log("event.pk", event.pk.replaceAll(/#/g, '%23'));
              // console.log("empresaid", loja.replaceAll(/#/g, '%23'));

              setEventoSelecionado(event);
              // não atualizar mais pois esta considerando o mês atual para carregar a pagina no didenter, problema é quando o usuário encerrar agendamento
              // setMes(-1);
              //setShowModalDetalhes(true);      
              history.push("./agendamentos/agendamentosdetalhes", { agendamentoid: event.pk.replaceAll(/#/g, '%23') });
            }}
            components={{
              event: Event,
              //toolbar: CustomToolbar
            }}
            //onSelectSlot={handleSelect}
            eventPropGetter={
              (event, start, end, isSelected) => {
                let newStyle = {
                  backgroundColor: "lightgrey",
                  color: 'black',
                  borderColor: "gray",
                  // borderRadius: "0px",
                  // border: "none",
                };

                let newStyleTransparente = {
                  backgroundColor: "lightgrey",
                  color: 'black',
                  borderColor: "gray",
                  // borderRadius: "0px",
                  // border: "none",
                  opacity: 0.5
                };


                if (event.color) {
                  newStyle.backgroundColor = event.color;
                  newStyleTransparente.backgroundColor = event.color;
                }

                return {
                  className: "",
                  style: event.status == "Agendado" ? newStyle : newStyleTransparente
                };
              }
            }
            messages={{
              next: ">",
              previous: "<",
              today: "Hoje",
              month: "Mês",
              week: "Semana",
              day: "Dia"
            }}
          />


          <div className="content">

            <IonModal onDidPresent={() => {
              if (inputAgendamentoNovoRef.current) {
                inputAgendamentoNovoRef.current.setFocus();
              }
            }} isOpen={showNovo} onDidDismiss={() => { setShowNovo(false); }}>
              <AgendamentosNovo inputRef={inputAgendamentoNovoRef} projetoid={null} closeAction={closeModalNovo} closeActionSimple={closeModalSimpleNovo}></AgendamentosNovo>
            </IonModal>

            <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
              <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
            </IonModal>


          </div>

          <IonToast
            isOpen={showToast}
            position="middle" //top, bottom, middle
            onDidDismiss={() => setShowToast(false)}
            message={textToast}
            duration={3000}
          />

          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => { setShowLoading(false); }}
            spinner='circles'
          />

        </IonContent>

      </IonPage>
    </>
  );


};

export default AgendamentosEventosComMenu;