import { IonCard, IonCardContent, IonContent, IonFab, IonFabButton, IonIcon, IonPage } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import './GaleriaFoco.css';


type Props = {
  abrirMenu: boolean;
};

const GaleriaFoco: React.FC<Props> = (props: Props) => {

  const location = useLocation();
  const history = useHistory();

  return (
    <IonPage>
      <div id="galeriaFocoFab" style={{ paddingTop: '100px', "background": "#ffffff" }} >
        <IonFab slot="fixed" vertical="top" horizontal="start" style={{ paddingTop: '40px' }}><IonFabButton size='small' onClick={() => history.goBack()}><IonIcon color='primary' icon={arrowBack}></IonIcon></IonFabButton></IonFab>
      </div>
      <IonContent>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', paddingBottom: '50px' }}>
          <div id='containerGaleriaFoco' style={{ maxWidth: '1024px' }}>
            <IonCard>
              <img src={location.state ? (location.state as { url: string, titulo: string }).url : ""}></img>
              <IonCardContent><h3>{location.state ? (location.state as { url: string, titulo: string }).titulo : ""}</h3></IonCardContent>
            </IonCard>
          </div>
        </div>
      </IonContent>

    </IonPage>
  );

};

export default GaleriaFoco;
