import { IonCard, IonCardContent, IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { trash } from 'ionicons/icons';
import React from 'react';

interface GridImagensProps { lista: any, history: any, excluirFoto: any, inibirExcluir: boolean }

export default class GridImagens extends React.Component<GridImagensProps> {
    render() {
        return this.props.lista.map((item: any) =>
            <div id='containerinterno'>
                <IonCard color='light'>
                    <div>
                        <div style={{ width: 'fit-content', height: 'fit-content' }} onClick={() => { { this.props.history.push("galeriafoco", { url: item.urlFile, titulo: item.descricao }) } }}>
                            <img src={item.urlFile} onClick={() => { { this.props.history.push("galeriafoco", { url: item.urlFile, titulo: item.descricao }) } }}></img>
                            {/* <ProgressiveImage imgSrc={item.urlFile} previewSrc={DefaultImage} width={'auto'} height={"auto"} /> */}
                        </div>
                        <div className='fabCategoria' style={{ position: 'relative' }} hidden={this.props.inibirExcluir}>
                            <IonFab slot="fixed" vertical="bottom" horizontal="end" hidden={this.props.excluirFoto ? false : true}>
                                <IonFabButton color='medium' size='small' onClick={() => { this.props.excluirFoto(item.sk) }}><IonIcon color='light' icon={trash} /></IonFabButton>
                            </IonFab>
                        </div>
                    </div>
                    <IonCardContent onClick={() => { this.props.history.push("galeriafoco", { url: item.urlFile, titulo: item.descricao }); }}><h3>{item.descricao}</h3></IonCardContent>
                </IonCard>
            </div>)
    }
}
