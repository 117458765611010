import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToast, IonToggle, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { addCircle, arrowBack } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { incluirProjeto, listaEquipePorPerfil, requestService } from '../../../../../Utils/Services';
import AppTypeaheadList from '../../../../../components/AppTypeaheadList';
import { MessageContext } from '../../../../../contexts/MessageContext';
import ArquitetosCad from '../ArquitetosCad/ArquitetosCad';
import Endereco from '../Endereco/Endereco';
import './ProjetosNovo.css';

type Props = {
  clienteid: any;
  inputRef: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const ProjetosNovo: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const { loja, setLoja } = useContext(MessageContext);
  const [lojas, setLojas] = useState<string>(loja);
  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);
  const { pkUsuario, setPkUsuario } = useContext(MessageContext);
  const [textoFiltro, setTextoFiltro] = useState<any>();
  const [textToast, setTextToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [mostartAlerta] = useIonAlert();

  const [nomeProjeto, setNomeProjeto] = useState<any>();
  const [arquiteto, setArquiteto] = useState<any>();
  const [listaCliente, setListaCliente] = useState<any>([]);
  const [listaFiltrada, setListaFiltrada] = useState<any>([]);
  const [listaArquiteto, setListaArquiteto] = useState<any>([]);
  const [checkedTemArquiteto, setCheckedTemArquiteto] = useState(false);

  const [showModalResponsaveis, setShowModalResponsaveis] = useState(false);
  const [responsaveis, setResponsaveis] = useState<any>([]);
  const [selectedResponsaveis, setSelectedResponsaveis] = useState<any>([]);
  const [selectedResponsaveisText, setSelectedResponsaveisText] = useState<string>('0 Responsáveis');

  const [showDetalhesEndereco, setShowDetalhesEndereco] = useState(false);
  const inputRefEndereco = useRef<any>(null);

  const [focusAppTypeaheadItemClientes1, setFocusAppTypeaheadItemClientes1] = useState(false);

  const [showDetalhesArquiteto, setShowDetalhesArquiteto] = useState(false);
  const inputDetalhesArquitetoRef = useRef<any>(null);

  const [pkCliente, setPkCliente] = useState("");
  const [nome, setNome] = useState("");
  // const [cpf, setCPF] = useState("");
  // const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [endereco, setEndereco] = useState("");
  const [enderecoLat, setEnderecoLat] = useState("");
  const [enderecoLng, setEnderecoLng] = useState("");
  const [complemento, setComplemento] = useState("");

  const campoTelefone = useRef<any>(null);

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);

  useEffect(() => {

    setFocusAppTypeaheadItemClientes1(true);

    if (window.localStorage.getItem("servicoPrincipal") == "Obras") {
      consultarArquiteto();
    }
    consultarUsuarios();

    buscarClientes();

  }, []);

  useIonViewDidEnter(() => {

  })


  function validarCampos() {

    if (nome.length == 0) {
      setTextToast("Campo Nome é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (telefone.length != 15 && telefone.length != 14) {
      setTextToast("Campo Telefone é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (endereco.length == 0) {
      setTextToast("Campo Endereço é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (checkedTemArquiteto && arquiteto.length === 0) {
      setTextToast("Campo Arquiteto é obrigatório.");
      setShowToast(true);
      return false;
    }

    // else if (enderecoLat.length == 0) {
    //   setTextToast("Selecione um endereço no mapa.");
    //   setShowToast(true);
    //   return false;
    // }
    // else if (enderecoLng.length == 0) {
    //   setTextToast("Selecione um endereço no mapa.");
    //   setShowToast(true);
    //   return false;
    // }
    return true;
  }

  function avancar(pularEndereco: boolean = false) {

    console.log("1");
    if (!validarCampos())
      return;

    console.log("2");

    setShowLoading(true);

    let objRequest = {
      "nomeProjeto": nomeProjeto,
      "nomeCliente": nome,
      "telefoneCliente": "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
      lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
        return lojas.includes(value.empresaLojaId);
      }),
      "pularEndereco": pularEndereco,
      "endereco": endereco || "",
      "complemento": complemento || "",
      "enderecoLat": enderecoLat,
      "enderecoLng": enderecoLng,
      "clienteid": pkCliente.replaceAll(/#/g, '%23'),
      "responsavelid": (checkedTemArquiteto && arquiteto.length > 0 ? arquiteto : ""),
      "empresaid": loja || "",
      "responsaveis": selectedResponsaveis,
      "interno": false
    };

    console.log("objRequest", objRequest);

    requestService(incluirProjeto.url, {
      method: incluirProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response.message) {


          setTextToast((window.localStorage.getItem("servicoTipo") || "Projeto") + " criado com sucesso!");
          setShowToast(true);

          if (response.message?.pk) { 
            props.closeAction(response.message?.pk);
            // history.push("projetos/projetosprodutos", { id: response.message?.pk.replaceAll(/#/g, "%23") })
          }
        }
      },
      (error: any) => { 
        console.log(error);
        mostartAlerta({
          backdropDismiss: false,
          header: 'Endereço com problema',
          message: error.message,

          buttons: [{
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            handler: () => {
              avancar(true);
            }
          }],
          onDidDismiss: () => { console.log('did dismiss'); }
        }).finally(() => {
          setShowLoading(false);
        });





      })



  }


  const formatData = (data: string[]) => {
    if (data.length === 1) {
      const responsavel = responsaveis.find((convidado: any) => convidado.pk === data[0])!;
      return responsavel.nome;
    }

    return `${data.length} Responsáveis`;
  };

  const responsavelSelectionChanged = (responsaveis: any[]) => {

    if (responsaveis.length > 0) {
      setSelectedResponsaveis(responsaveis);
      setSelectedResponsaveisText(formatData(responsaveis));
    }
    else {
      setSelectedResponsaveis([]);
      setSelectedResponsaveisText("0 Responsáveis");
    }
    setShowModalResponsaveis(false);
  };


  const buscarClientes = async () => {
    try {
      setShowLoading(true);
      let listaClientes = await buscarClientesDynamoDb();

      if (Array.isArray(listaClientes) && listaClientes.length > 0) {
        let lista = (listaClientes.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))

        console.log("lista", lista);
        setListaCliente(lista);

      }
    } catch (error: any) {
      console.error("Ocorreu um problema inesperado. Por favor, tente novamente mais tarde. Detalhes do erro: ", error);
      setTextToast(error);
      setShowToast(true);
    } finally {
      setShowLoading(false);
    }

  }

  const buscarClientesDynamoDb = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", "cliente"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let clientes = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (clientes.Items) {
        return clientes.Items;
      }
    }
  }

  function readArquiteto() {
    return listaArquiteto.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"arquiteto" + value.pk} value={value.pk}>{value.nome}</IonSelectOption>
    )
    )
  };

  const consultarUsuarios = async () => {

    let ret2 = await consultarUsuarioDynamoDb();
    console.log("ret2", ret2);

    let lista = (ret2.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
    setResponsaveis(lista);

    const responsavelSelecionado = lista.find((convidado: any) => convidado.pk === pkUsuario);

    console.log("responsavelSelecionado", responsavelSelecionado);

    if (responsavelSelecionado) {
      setSelectedResponsaveis([responsavelSelecionado.pk]);
      setSelectedResponsaveisText(responsavelSelecionado.nome);
    }
  }


  const consultarUsuarioDynamoDb = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", "usuario"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        return equipe.Items;
      }
    }
  }

  const consultarArquiteto = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", "arquiteto"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
        setListaArquiteto(lista);
      }
    }
  }

  async function closeModalEndereco(enderecoSelecionado: any, latitudeSelecionado: any, enderecoLngSelecionado: any) {
    console.log("enderecoSelecionado", enderecoSelecionado);
    console.log("latitudeSelecionado", latitudeSelecionado);
    console.log("enderecoLngSelecionado", enderecoLngSelecionado);

    setEndereco(enderecoSelecionado);
    setEnderecoLat(latitudeSelecionado);
    setEnderecoLng(enderecoLngSelecionado);

    setShowDetalhesEndereco(false);
  }

  async function closeModalSimpleEndereco() {
    setShowDetalhesEndereco(false);
  }

  async function closeModalArquiteto(pk: any) {
    setArquiteto(pk);
    setShowDetalhesArquiteto(false);
    consultarArquiteto();
  }

  async function closeModalSimpleArquiteto() {
    setShowDetalhesArquiteto(false);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();



    let val = e.target.value;

    if (val.length > 15) {
      val = val.substring(0, 15);
      setTelefone(val.substring(0, 15));
      return;
    }
    if (val.length < 15) {

      setTelefone(val
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})(\d+?)$/, "$1"));
    }
    else {

      setTelefone(val
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})(\d+?)$/, "$1"));
    }


  }

  function formatCPF(e: string) {
    let cpfval = e.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    console.log(cpfval);
    return cpfval;
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  const handleSelectItem = (item: any) => {
    setListaFiltrada([]);
    setTextoFiltro("");

    setNome(item.nome);
    // setCPF(formatCPF(item.cpf));
    setTelefone(formataTelefone(item.telefone));
    setEndereco(item.endereco);
    setEnderecoLat(item.enderecoLat);
    setEnderecoLng(item.enderecoLng);
    setComplemento(item.complemento);
    setPkCliente(item.pk);
    // if (item.dataAniversario) {
    //   setDataAniversario(item.dataAniversario);
    //   setDataAniversarioBr(new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
    //     Date.parse(item.dataAniversario)
    //   ));
    // }
    console.log("item", item);
  };

  return (
    <>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Criar {(window.localStorage.getItem("servicoTipo") || "Projeto")}</IonTitle>

        </IonToolbar>
        <div style={{ background: "var(--ion-color-primary)", padding: "0px", margin: "0px" }}>
          <IonSearchbar
            ref={(ref) => props.inputRef.current = ref}
            value={textoFiltro}
            onIonChange={(e) => {

              let lista = textoFiltro
                ? listaCliente.filter((value: any) =>
                  value.nome.toLowerCase().includes(textoFiltro?.toLowerCase())
                )
                : [];
              setListaFiltrada(lista);
              setTextoFiltro(e.detail.value)
            }}
            placeholder="Pesquisar nome"
          />
        </div>

        {listaFiltrada?.length > 0 && (
          <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: listaFiltrada.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
            {listaFiltrada.map((item: any, index: any) => (
              <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItem(item)}>
                <IonLabel>{item.nome}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}

      </IonHeader>
      <IonContent class="ion-padding">


        <IonList lines="full" class="ion-no-margin">

          <IonItem lines="none" hidden={(window.localStorage.getItem("identificacaoPorNumero") == "true") ? true : false}>
            <IonLabel>Nome</IonLabel>
            <IonInput placeholder="Nome" value={nomeProjeto}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setNomeProjeto(e.target.value)}></IonInput>
          </IonItem>

          {/* <IonItem lines="none">
            <IonLabel>Cliente*</IonLabel>
            <IonButtons slot="end" onClick={() => { setShowCriarCliente(!showCriarCliente) }}>
              <IonIcon color="tertiary" size='large' slot="icon-only" icon={showCriarCliente ? removeCircle : addCircle}></IonIcon>
            </IonButtons>





          </IonItem> */}


          <IonItem lines="none">
            <IonLabel position="stacked">Nome*</IonLabel>
            <IonInput placeholder="Digite o nome completo" value={nome} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
          </IonItem>


          <IonItem lines="none">
            <IonLabel position="stacked">Telefone*</IonLabel>
            <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} />
          </IonItem>

          {/* <IonItem lines="none">
                  <IonLabel>Interno</IonLabel>
                  <IonToggle slot='end' checked={checkedInterno} onIonChange={e => {
                    setCheckedInterno(e.detail.checked);
                  }} />
                </IonItem> */}



          {/* <IonItem lines="none">
                  <IonLabel position="stacked">CPF</IonLabel>
                  <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} />
                </IonItem>


                <IonItem lines="none">
                  <IonLabel position="stacked">E-mail</IonLabel>
                  <IonInput value={email} type='email' autoCorrect='true' placeholder="Digite o e-mail" onIonInput={(e: any) => setEmail(e.target.value)}></IonInput>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel position="stacked">Aniversário</IonLabel>
                  <IonInput value={dataAniversarioBr} placeholder="Selecione" onClick={e => { openPopoverCad(e); }}></IonInput>
                </IonItem> */}


          <IonItem lines="none">
            <IonLabel position="stacked">Endereço*</IonLabel>
            {/* <IonIcon slot="end" size='large' color='primary' icon={searchCircle} onClick={() => { setShowDetalhesEndereco(true); }}></IonIcon> */}
            <IonInput value={endereco} autoCorrect='true' autoCapitalize='true' placeholder="Ex: Avenida Paulista, 111, São Paulo, SP"
              onIonChange={(e) => { setEnderecoLat(""); setEnderecoLng(""); }}
              onIonInput={(e: any) => setEndereco(e.target.value)}></IonInput>
            <IonButton slot="end" style={{ textAlign: 'right' }} onClick={() => { setShowDetalhesEndereco(true); }}>Buscar</IonButton>
          </IonItem>

          {/* <IonItem lines="none">
                    <IonButton slot="end" style={{ textAlign: 'right' }} onClick={() => { setShowDetalhesEndereco(true); }}>Buscar</IonButton>
                  </IonItem> */}
          {/* <IonItem lines="none">
                    <IonLabel>Latitude*</IonLabel>
                    <IonInput placeholder="Selecione a Latitude" value={latitude}
                      autoCorrect='true' autoCapitalize='true'
                      required onIonInput={(e: any) => setLatitude(e.target.value)}></IonInput>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Longitude*</IonLabel>
                    <IonSelect value={longitude} placeholder="Selecione o Longitude" onIonChange={(e) => setLongitude(e.detail.value)}>
                      {longitudes.map((longitude) => (
                        <IonSelectOption key={longitude.sigla} value={longitude.sigla}>
                          {longitude.sigla}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem> */}
          <IonItem lines="full">
            <IonLabel>Complemento</IonLabel>
            <IonInput placeholder="Digite o Complemento" value={complemento}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setComplemento(e.target.value)}></IonInput>
          </IonItem>
          <IonItem lines="none" hidden={listaArquiteto.length === 0}>
            <IonLabel>Arquiteto</IonLabel>
            <IonButtons slot="end" onClick={() => { setShowDetalhesArquiteto(true) }}>
              <IonIcon color="tertiary" size='large' slot="icon-only" icon={addCircle}></IonIcon>
            </IonButtons>
            <IonToggle checked={checkedTemArquiteto} onIonChange={e => {
              setCheckedTemArquiteto(e.detail.checked);
            }} />

          </IonItem>

          <IonItem lines="full" hidden={!checkedTemArquiteto}>
            <IonSelect slot="end" value={arquiteto} placeholder="Selecione" onIonChange={e => { setArquiteto(e.detail.value); }}>
              {readArquiteto()}
            </IonSelect>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Responsáveis</IonLabel>
            <IonItem slot="end" lines="none" button={true} detail={false} onClick={e => { setShowModalResponsaveis(true); }}>
              {selectedResponsaveisText}
            </IonItem>
            <IonModal isOpen={showModalResponsaveis} onDidDismiss={() => setShowModalResponsaveis(false)} >
              <AppTypeaheadList
                title="Responsáveis"
                items={responsaveis}
                selectedItems={selectedResponsaveis}
                autoFocus={focusAppTypeaheadItemClientes1}
                onSelectionCancel={() => setShowModalResponsaveis(false)}
                onSelectionChange={responsavelSelectionChanged}
              />
            </IonModal>
          </IonItem>

        </IonList>

        <IonModal onDidPresent={() => {
          if (inputDetalhesArquitetoRef.current) {
            inputDetalhesArquitetoRef.current.setFocus();
          }
        }} isOpen={showDetalhesArquiteto} onDidDismiss={() => { setShowDetalhesArquiteto(false); }}>
          <ArquitetosCad usuario={null} inputRef={inputDetalhesArquitetoRef} closeAction={closeModalArquiteto} closeActionSimple={closeModalSimpleArquiteto}></ArquitetosCad>
        </IonModal>

        <IonModal
          onDidPresent={() => {
            if (inputRefEndereco.current) {
              inputRefEndereco.current.setFocus();
            }
          }} isOpen={showDetalhesEndereco} onDidDismiss={() => { setShowDetalhesEndereco(false); }}>
          <Endereco endereco={endereco} inputRef={inputRefEndereco} closeAction={closeModalEndereco} closeActionSimple={closeModalSimpleEndereco}></Endereco>
        </IonModal>



      </IonContent>
      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton expand="block" size='large' color="primary" onClick={() => { avancar(); }}>Registrar</IonButton>
        </IonToolbar>
      </IonFooter>

      < IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
      />


      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { setShowLoading(false); }}
        spinner='circles'
      />

    </>
  );
};

export default ({ clienteid, inputRef, closeAction, closeActionSimple }: { clienteid: any, inputRef: any, closeAction: Function, closeActionSimple: Function }) => (
  <ProjetosNovo clienteid={clienteid} inputRef={inputRef} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </ProjetosNovo>
)
