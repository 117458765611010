// import '@capacitor-community/http';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonListHeader, IonPage, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { albumsOutline, arrowBack, bookOutline, clipboardOutline, imagesOutline, informationCircleOutline, listOutline, settingsOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './MenuConfiguracoes.css';

type Props = {
    abrirMenu: boolean;
}

const MenuConfiguracoes: React.FC<Props> = (props: Props) => {

    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [textToast, setTextToast] = useState("");
    const [showToast2, setShowToast2] = useState(false);

    const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
    const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
    const { nomeMenu, setNomeMenu } = useContext(MessageContext);

    useIonViewDidEnter(() => {
        setEsconderMenu(true);
        setNomeMenu("");
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=''></IonBackButton>
                    </IonButtons>
                    {/* <IonButtons slot="start">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={settings} />
                        </IonButton>
                    </IonButtons> */}
                    <IonTitle slot="start">Configurações</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent className="conta">


                <div hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}>

                    <IonListHeader lines="full" >Empresa</IonListHeader>

                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button routerLink="/empresa/portfolio" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={imagesOutline} />
                        <IonLabel>Portfólio</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button routerLink="/empresa/contaalt" routerDirection="forward" detail >
                        <IonIcon color='medium' slot="start" icon={settingsOutline} />
                        <IonLabel>Configurações</IonLabel>
                    </IonItem>

                    <IonListHeader lines="full" hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}>Vendas</IonListHeader>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button onClick={() => {
                        history.push("/empresa/empresaworkflowvendacadastro", { empresaid: JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "" });
                    }} detail >
                        <IonIcon color='medium' slot="start" icon={settingsOutline} />
                        <IonLabel>Configurações Funil</IonLabel>
                    </IonItem>

                    <IonListHeader lines="full" hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}>{window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonListHeader>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button onClick={() => {
                        history.push("/empresa/empresaworkflow", { empresaid: JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "" });
                    }} detail >
                        <IonIcon color='medium' slot="start" icon={albumsOutline} />
                        <IonLabel>Modelo {window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo"}</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button onClick={() => {
                        history.push("/empresa/empresaworkflowcadastro", { empresaid: JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "" });
                    }} detail >
                        <IonIcon color='medium' slot="start" icon={settingsOutline} />
                        <IonLabel>Configurações {window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo"}</IonLabel>
                    </IonItem>



                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} lines="none" button routerLink="/empresa/produtoscatalogo" routerDirection="forward" detail >
                        <IonIcon color='medium' slot="start" icon={bookOutline} />
                        <IonLabel>Catálogo</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao' || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} lines="none" button routerLink="/empresa/produtoscategoriasnovo" routerDirection="forward" detail >
                        <IonIcon color='medium' slot="start" icon={listOutline} />
                        <IonLabel>Categorias</IonLabel>
                    </IonItem>


                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao' || (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} lines="none" button routerLink="/empresa/servicoscatalogo" routerDirection="forward" detail >
                        <IonIcon color='medium' slot="start" icon={bookOutline} />
                        <IonLabel>Catálogo</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao' || (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} lines="none" button routerLink="/empresa/servicoscategorias" routerDirection="forward" detail >
                        <IonIcon color='medium' slot="start" icon={listOutline} />
                        <IonLabel>Categorias</IonLabel>
                    </IonItem>

                    <IonListHeader lines="full" hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}>Operação</IonListHeader>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button routerLink="/empresa/operacoesalt" routerDirection="forward" detail >
                        <IonIcon color='medium' slot="start" icon={informationCircleOutline} />
                        <IonLabel>Instruções da operação</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button onClick={() => {
                        history.push("/empresa/formulariocadastro", { empresaid: JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "" });
                    }} detail >
                        <IonIcon color='medium' slot="start" icon={clipboardOutline} />
                        <IonLabel>Formulário</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button routerLink="/empresa/formulariocategorias" routerDirection="forward" detail >
                        <IonIcon color='medium' slot="start" icon={listOutline} />
                        <IonLabel>Categorias do formulário</IonLabel>
                    </IonItem>

                    <IonListHeader lines="full" hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}>Financeiro</IonListHeader>
                    <IonItem hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'} lines="none" button routerLink="/empresa/financeirocategorias" routerDirection="forward" detail >
                        <IonIcon color='medium' slot="start" icon={listOutline} />
                        <IonLabel>Categorias</IonLabel>
                    </IonItem>

                </div>

                <IonToast
                    isOpen={showToast}
                    position="middle" //top, bottom, middle
                    onDidDismiss={() => setShowToast(false)}
                    message={textToast}
                    duration={3000}
                />
            </IonContent>

        </IonPage>
    );
};

export default MenuConfiguracoes;
