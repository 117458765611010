import { PushNotifications } from '@capacitor/push-notifications';
import { Toast } from '@capacitor/toast';
import { IonAlert, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonModal, IonPage, IonPopover, IonRow, IonSearchbar, IonText, IonToast, IonToggle, IonToolbar, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { add, addCircleOutline, albumsOutline, calendarOutline, chatboxOutline, chatbubbles, checkmarkCircleOutline, closeOutline, documentOutline, ellipsisHorizontal, ellipsisVertical, folderOutline, listOutline, notifications, pencilOutline, powerOutline, printOutline, refresh, time } from 'ionicons/icons';
import 'moment/locale/pt-br';
import QRCode from 'qrcode';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { useHistory, useLocation } from 'react-router';
import { alterarStatusProjeto, finalizarProjeto, projetoGerarAcompanhamento, projetoGerarCupom, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import AgendamentosNovo from '../AgendamentosNovo/AgendamentosNovo';
import Notificacoes from '../Notificacoes/Notificacoes';
import ProjetosNovo from '../ProjetosNovo/ProjetosNovo';
import "./Projetos.css";
import iconMoto from "./imgs/moto.svg";


interface Item {
  id: string;
  name: string;
  dataCadastro: string;
  clienteNome: string;
  clienteInterno: boolean;
  bgColorPercentual: string;
  pk: string;
  gsi2pk: string;
  dataCupom: string;
  servicoPrincipal: string;
  layoutSimplificado: string;
  valorTotal: string;
  interno: boolean;
  produtos: Produto[];

  // Additional properties
  empresaNome: string;
  empresaCnpj: string;
  empresaEndereco: string;
  empresaTelefone: string;
  endereco: string;
  complemento: string;
  servicoTipo: string;
  nomeProjeto: string;
  valorTotalProdutos: string;
  formaPagamento: string;
  troco: string;
  taxaEntrega: string;
  desconto: string;
  taxaServico: string;

  // Missing properties
  numero: string; // Adicione esta linha
  clienteTelefone: string; // Adicione esta linha
}

interface Produto {
  descricao: string;
  quantidade: number;
  andamento: boolean;
  concluido: boolean;
}

interface Column {
  id: string;
  name: string;
  items: Item[];
  bgcolor: string;
}

type Props = {
  abrirMenu: boolean;
  empresaid: string;
  onDismiss: () => void;
};


const Projetos: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();

  const btnref = useRef<HTMLIonButtonElement>(null);

  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "");
  const { loja, setLoja } = useContext(MessageContext);
  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);
  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  // const [stateLastEvaluatedKey, setStateLastEvaluatedKey] = useState<any>();

  // const [stages, setStages] = useState<any>([]);
  // const [empresaid, setEmpresaid] = useState<string>("");

  const [mostartAlerta] = useIonAlert();

  const [alertConfirmar, setAlertConfirmar] = useState(false);
  const [showModalPermissao, setShowModalPermissao] = useState(false);
  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);
  // const [pkProjetoSelecionado, setPkProjetoSelecionado] = useState<string>("");
  // const [indiceSelecionado, setIndiceSelecionado] = useState<string>("");
  // const [indiceCardSelecionado, setIndiceCardSelecionado] = useState<number>(0);
  // const [statusSelecionado, setStatusSelecionado] = useState<string>("");
  // const [nomeSelecionado, setNomeSelecionado] = useState<string>("");
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const [columns, setColumns] = useState<Column[]>([]);
  const [baseColumns, setBaseColumns] = useState(columns);
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [autoReload, setAutoReload] = useState(false);
  // const [isDragging, setIsDragging] = useState(false);

  const [showActionSheet, setShowActionSheet] = useState(false);

  // const [showModalNovoDelivery, setShowModalNovoDelivery] = useState(false);
  const inputRefNovoDelivery = useRef<any>(null);

  const [showModalNovo, setShowModalNovo] = useState(false);
  const inputRefNovo = useRef<any>(null);

  // const [showModalNovoComanda, setShowModalNovoComanda] = useState(false);
  const inputRefNovoComanda = useRef<any>(null);

  const [showNovoAgendamento, setShowNovoAgendamento] = useState(false);
  const inputAgendamentoNovoRef = useRef<any>(null);

  // const [showLiberar, setShowLiberar] = useState(false);

  const [dadosProjeto, setDadosProjeto] = useState<any>();


  const toggleAutoReload = () => {
    setAutoReload((prev) => !prev);
  };

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);


  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };



  const [showPopoverCard, setShowPopoverCard] = useState(false);
  const [popoverCardEvent, setPopoverCardEvent] = useState(null);

  const openPopoverCard = (e: any) => {
    e.persist();
    setPopoverCardEvent(e);
    setShowPopoverCard(true);
  };

  const closePopoverCard = () => {
    setShowPopoverCard(false);
    setPopoverCardEvent(null);
  };




  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("Caixa");
    setShowLoading(true);
    carregarBoard().finally(() => {
      setShowLoading(false);
    });


  })

  // useEffect(() => {
  //   console.log("entrou useEffect mudou loja - projetos", loja);
  //   carregarBoard();
  // }, [loja]
  // );

  useEffect(() => {
    console.log("recarregarProjeto", recarregarProjeto);
    if (recarregarProjeto) {
      carregarBoard();
    }
  }, [recarregarProjeto]
  );


  useEffect(() => {
    console.log("entrou atualizar automatico");
    if (autoReload) {
      const interval = setInterval(() => {
        if (loja) {
          setSearchQuery("");
          carregarBoard();
        }
      }, 30000); //30 segundos = 30000 milissegundos

      // Limpeza do intervalo quando o componente for desmontado
      return () => clearInterval(interval);
    }
  }, [autoReload]);


  function solicitarAcesso(e: any) {
    e.target.disabled = true;

    PushNotifications.checkPermissions()
      .then((res) => {
        if (res.receive === 'granted') {
          console.log("sucesso push", res.receive);
          PushNotifications.register();
          setShowModalPermissao(false);
          if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {
            avancarNovoDelivery();
          }
          else {
            avancarNovoProjeto();
          }
        }
        else {
          if (res.receive === 'prompt') {
            PushNotifications.requestPermissions().then((res) => {
              if (res.receive === 'granted') {
                console.log("entrou chaveContato 2");
                PushNotifications.register();
                setShowModalPermissao(false);
                if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {
                  avancarNovoDelivery();
                }
                else {
                  avancarNovoProjeto();
                }
              }
              else {
                NativeSettings.open({
                  optionAndroid: AndroidSettings.ApplicationDetails,
                  optionIOS: IOSSettings.App
                })
                  .then((value) => {
                    console.log("saidamaxgeo3" + value.status);
                    PushNotifications.checkPermissions()
                      .then((res) => {
                        if (res.receive === 'granted') {
                          console.log("sucesso push", res.receive);
                          PushNotifications.register();
                          setShowModalPermissao(false);
                          if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {
                            avancarNovoDelivery();
                          }
                          else {
                            avancarNovoProjeto();
                          }
                        }
                        else {
                          console.log("saidamaxgeo4" + res.receive);
                          setTextToast("Para continuar você deve liberar a Localização do dispositivo");
                          setShowToast(true);
                        }
                      })
                      .catch(reason => {
                        console.log("saidamaxgeo4" + reason);
                        setTextToast("Para continuar você deve liberar a Localização do dispositivo");
                        setShowToast(true);
                      });
                  });
              }
            });
          }
          else {
            NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails,
              optionIOS: IOSSettings.App
            })
              .then((value) => {
                console.log("saidamaxgeo3" + value.status);
                PushNotifications.checkPermissions()
                  .then((res) => {
                    if (res.receive === 'granted') {
                      console.log("sucesso push", res.receive);
                      PushNotifications.register();
                      setShowModalPermissao(false);
                      if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {
                        avancarNovoDelivery();
                      }
                      else {
                        avancarNovoProjeto();
                      }
                    }
                    else {
                      console.log("saidamaxgeo4" + res.receive);
                      setTextToast("Para continuar você deve liberar a Localização do dispositivo");
                      setShowToast(true);
                    }
                  })
                  .catch(reason => {
                    console.log("saidamaxgeo4" + reason);
                    setTextToast("Para continuar você deve liberar a Localização do dispositivo");
                    setShowToast(true);
                  });
              });
          }
        }
      })
      .catch(error => {
        setTextToast("Para continuar você deve liberar acesso a notificação");
        setShowToast(true);
      })
      .finally(() => e.target.disabled = false);
  }



  const getColor = (percentual: any) => {
    const percent = parseFloat(percentual?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0);

    if (percent == 0) {
      return "rgba(255, 255, 255)";
    } else if (percent <= 33) {
      return "rgba(212, 37, 28, 0.5)"; // Vermelho com 70% de opacidade
    } else if (percent <= 66) {
      return "rgba(29, 52, 96, 0.5)"; // Azul com 70% de opacidade
    } else {
      return "rgba(51, 172, 27, 0.5)"; // Verde com 70% de opacidade
    }
  };

  const getColorLiberado = (dataCadastro: any) => {

    console.log("dataCadastro", dataCadastro);
    if (!dataCadastro) {
      return "rgba(255, 255, 255)";
    }

    // Convertendo a dataCadastro para um objeto de data
    const dataCadastrada = new Date(dataCadastro);
    const agora = new Date();

    // Calcula a diferença em milissegundos
    const diferencaMs = agora.getTime() - dataCadastrada.getTime();

    // Converte a diferença para minutos
    const diferencaMinutos = Math.floor(diferencaMs / 1000 / 60);

    // Verifica a diferença de tempo e retorna a cor correspondente
    if (diferencaMinutos > 60) {
      // Se passou mais de 60 minutos, retorna vermelho
      return "rgba(255, 0, 0, 0.1)";
    } else if (diferencaMinutos > 30) {
      // Se passou entre 30 e 60 minutos, retorna amarelo
      return "rgba(255, 255, 0, 0.1)";
    } else {
      // Se passou menos de 30 minutos, não retorna cor (ou retorna branco, conforme o exemplo anterior)
      return "rgba(255, 255, 255)";
    }
  };

  async function carregarBoard() {
    const consultar = async () => {
      //setShowLoading(true);

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let urlFinal = urlBase.url + "/projetos/painel?campo=data" +
        "&filtro=Ativo" +
        "&lojas=" + Buffer.from((loja || lojas).replaceAll(/#/g, '%23')).toString('base64');

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      let res = await resp.json();



      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }

      // if (res && res.LastEvaluatedKey) {
      //   setStateLastEvaluatedKey(res.LastEvaluatedKey);
      // }
      // else {
      //   setStateLastEvaluatedKey(null);
      // }

      if (res && res.Items) {

        let listaOrdenada = res.Items.sort((a: any, b: any) => (a.ordenacao > b.ordenacao) ? 1 : -1);

        let colunas: any[] = [];
        JSON.parse(window.localStorage.getItem("configWorkflow") || "[]").map((valor: any, index: any) => {

          let item: any[] = [];

          listaOrdenada.map((value: any, index: any) => {

            if (value.statusWorkflow == valor.status) {
              let task: any = {
                id: value.pk,
                "numero": value.numero,
                "clienteTelefone": value.clienteTelefone,
                "name": value.nomeProjeto,
                "pk": value.pk,
                "gsi2pk": value.gsi2pk,
                "dataCadastro": value.dataCadastro,
                "clienteNome": value.clienteNome,
                "clienteInterno": value.clienteInterno,
                "produtos": value.produtos,
                "bgColorPercentual":
                  (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") ?
                    getColorLiberado(value.dataCadastro)
                    : getColor(value.percentualTotalServicos),
                "dataCupom": value.dataCupom,
                "servicoPrincipal": value.servicoPrincipal,
                "layoutSimplificado": value.layoutSimplificado,
                // "status": value.status,
                "valorTotal": value.valorTotal,
                "interno": value.interno,
                "empresaNome": value.empresaNome,
                "empresaCnpj": value.empresaCnpj,
                "empresaEndereco": value.empresaEndereco,
                "empresaTelefone": value.empresaTelefone,
                "endereco": value.endereco,
                "complemento": value.complemento,
                "servicoTipo": value.servicoTipo,
                "nomeProjeto": value.nomeProjeto,
                "valorTotalProdutos": value.valorTotalProdutos,
                "formaPagamento": value.formaPagamento,
                "troco": value.troco,
                "taxaEntrega": value.taxaEntrega,
                "desconto": value.desconto,
                "taxaServico": value.taxaServico

              };


              // console.log("value.statusWorkflow", value.statusWorkflow);
              // console.log("value.nomeProjeto", value.nomeProjeto);
              // console.log("value.ordenacao", value.ordenacao);
              item.push(task);
            }

          })

          let column: any = {
            id: valor.indice,
            name: valor.status,
            bgcolor: "#f5f5f5",
            items: item
          };

          colunas.push(column);

        });

        //setStages(board);
        setColumns(colunas);
        setBaseColumns(colunas);

        // console.log("colunas", colunas);

        // console.log("*********searchQuery", searchQuery);
        // if (searchQuery != "") {
        //   filterList(searchQuery);
        // }
      }

      //setShowLoading(false);

    };
    return await consultar();
  }


  function checkPermissao() {
    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
      PushNotifications.checkPermissions()
        .then((res) => {
          if (res.receive === 'granted') {
            console.log("sucesso, buscar a localização e ir para pagina de seleção da loja", res.receive);
            PushNotifications.register();
            if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {
              avancarNovoDelivery();
            }
            else {
              avancarNovoProjeto();
            }
          }
          else {
            console.log('move para pagina de solicitação de aceeso a localizacao-' + res.receive);
            setShowModalPermissao(true);
          }
        })
        .catch(error => {
          console.log('move para pagina de solicitação de aceeso a localizacao-' + error);
          setShowModalPermissao(true);
        });
    }
    else {
      if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {
        avancarNovoDelivery();
      }
      else {
        avancarNovoProjeto();
      }
    }
  }

  function avancarPedidoInterno() {
    // setShowModalNovoComanda(true);
    history.push("projetos/projetosnovocomandanovo");

  }


  function avancarNovoDelivery() {

    // setShowModalNovoDelivery(true);
    history.push("projetos/projetosnovopedidovenda");
  }

  function avancarPedidoBalcao() {
    // setShowModalNovoComanda(true);
    history.push("projetos/projetosnovobalcaonovo");

  }

  function alterarStatus(pk: any, indice: any, status: any, indiceCard: any) {

    let params = {
      indice: indice,
      status: status,
      indiceCard: indiceCard
    };
    console.log("params", params);
    setShowLoading(true);
    requestService(alterarStatusProjeto.url.replaceAll("{id}", pk.replaceAll(/#/g, "%23")), {
      method: alterarStatusProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(params),
      credentials: 'include'
    },
      history,
      async (response: any) => {
        console.log(response);
        // if (response.message.Attributes) {
        //   let found = columns.find((obj: any) => {
        //     return obj.pk === pk;
        //   });

        //   console.log("response.message.Attributes", response.message.Attributes);

        //   found = response.message.Attributes;
        // }

        await carregarBoard();
      },
      (error: any) => {
        console.log(error);
        Toast.show({
          text: error.message,
          position: "center",
          duration: "long"
        });
      }).finally(() => {
        setShowLoading(false);
      });



  }


  function carregarDetalhes(projetoid: any) {
    history.push("projetos/projetosdetalhes", { projetoid: projetoid });
  }

  function tempoAndamento(dataCadastro: any) {
    if (!dataCadastro) {
      // Se não houver dataCadastro, retorna vazio
      return "";
    }

    // Convertendo a dataCadastro para um objeto de data
    const dataCadastrada = new Date(dataCadastro);
    const agora = new Date();

    // Calcula a diferença em milissegundos
    const diferencaMs = agora.getTime() - dataCadastrada.getTime();

    // Converte para unidades de tempo
    const minutos = Math.floor(diferencaMs / 1000 / 60) % 60; // Restante dos minutos
    const horas = Math.floor(diferencaMs / 1000 / 60 / 60) % 24; // Restante das horas
    const dias = Math.floor(diferencaMs / 1000 / 60 / 60 / 24); // Total de dias

    // Formatação da saída
    let resultado = "";

    if (dias > 0) {
      resultado += `${dias} ${dias === 1 ? "dia" : "dias"}`;
    }
    if (horas > 0) {
      resultado += `${dias > 0 ? ", " : ""}${horas} ${horas === 1 ? "hora" : "horas"}`;
    }
    if (minutos > 0) {
      resultado += `${dias > 0 || horas > 0 ? " e " : ""}${minutos} ${minutos === 1 ? "minuto" : "minutos"}`;
    }

    return resultado || "Agora mesmo";
  }


  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }



  // const onDragStart = () => {
  //   setIsDragging(true);
  // };

  const onDragEnd = (result: DropResult, provided: ResponderProvided): void => {
    //setIsDragging(false);

    const { source, destination, draggableId } = result;
    console.log("source", source);
    console.log("destination", destination);
    console.log("draggableId", draggableId);

    if (!destination) {
      return;
    }

    let sourceColumnItems: Item[] = [];
    let destinationColumnItems: Item[] = [];
    let draggedItem: Item | undefined;

    let sourceColumnId = 0;
    let destinationColumnId = 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].id === source.droppableId) {
        sourceColumnItems = columns[i].items;
        sourceColumnId = i;
      } else if (columns[i].id === destination.droppableId) {
        destinationColumnItems = columns[i].items;
        destinationColumnId = i;
      }
    }

    draggedItem = sourceColumnItems.find(item => item.id === draggableId);

    if (!draggedItem) {
      console.error('Dragged item not found');
      return;
    }

    const filteredSourceColumnItems = sourceColumnItems.filter(item => item.id !== draggableId);

    if (source.droppableId === destination.droppableId) {
      filteredSourceColumnItems.splice(destination.index, 0, draggedItem);

      const columnsCopy = [...columns];
      columnsCopy[sourceColumnId] = {
        ...columnsCopy[sourceColumnId],
        items: filteredSourceColumnItems,
      };
      setColumns(columnsCopy);
    } else {
      destinationColumnItems.splice(destination.index, 0, draggedItem);

      const columnsCopy = [...columns];
      columnsCopy[sourceColumnId] = {
        ...columnsCopy[sourceColumnId],
        items: filteredSourceColumnItems,
      };
      columnsCopy[destinationColumnId] = {
        ...columnsCopy[destinationColumnId],
        items: destinationColumnItems,
      };
      setColumns(columnsCopy);
    }

    // setPkProjetoSelecionado(draggableId)

    const found = JSON.parse(window.localStorage.getItem("configWorkflow") || "[]").find((obj: any) => {
      return obj.indice === destination.droppableId;
    });

    // setIndiceSelecionado(destination.droppableId);
    // setStatusSelecionado(found.status);

    // setIndiceCardSelecionado(destination.index);

    // setAlertConfirmar(true);


    alterarStatus(draggableId, destination.droppableId, found.status, destination.index);


  };



  const searchbarInput = (ev: any) => {
    const query = ev.target.value;
    setSearchQuery(query);
    filterList(query);
  };

  const filterList = (searchQuery: string | null | undefined) => {
    if (searchQuery === undefined || searchQuery === null) {
      setColumns([...baseColumns]); // Reset to original data
    } else {
      const normalizedQuery = searchQuery.toLowerCase();
      const newFilteredColumns = baseColumns.map((column: any) => {
        const filteredItems = column.items.filter((item: any) => {
          const numeroString = item.numero ? item.numero.toString() : "";
          const clienteTelefoneString = item.clienteTelefone ? item.clienteTelefone.toString().toLowerCase() : "";
          const clienteNomeString = item.clienteNome ? item.clienteNome.toString().toLowerCase() : "";
          const nameString = item.name ? item.name.toString().toLowerCase() : "";

          return (
            numeroString === normalizedQuery || // Match entire string for numero
            clienteTelefoneString.includes(normalizedQuery) ||
            clienteNomeString.includes(normalizedQuery) ||
            nameString.includes(normalizedQuery)
          );
        });

        return {
          ...column,
          items: filteredItems,
        };
      });

      setColumns(newFilteredColumns);
    }
  };



  // function getButtons() {

  //   let resultado: any = [
  //     {
  //       text: 'Cancelar',
  //       icon: closeOutline,
  //       role: 'cancel',
  //       handler: () => {
  //         console.log('Cancel clicked');
  //       }
  //     }
  //   ];

  //   if (acessos?.projetos == 'leitura' || acessos?.projetos == 'edicao') {




  //     if (window.localStorage.getItem("servicoPrincipal") == "Pedidos") {

  //       resultado.push(
  //         {
  //           text: 'Balcão',
  //           icon: addCircleOutline,
  //           handler: () => {
  //             avancarPedidoBalcao();
  //             setShowActionSheet(false);
  //           }
  //         }

  //       );

  //     }


  //     if (window.localStorage.getItem("servicoPrincipal") == "Vendas") {
  //       resultado.push(
  //         {
  //           text: 'Nova Venda',
  //           icon: appsOutline,
  //           handler: () => {
  //             avancarPedidoBalcao();
  //             setShowActionSheet(false);
  //           }
  //         }
  //       );

  //     }

  //     resultado.push(
  //       {
  //         text: (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") ? 'Delivery' : 'Criar ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
  //         icon: (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") ? iconMoto : appsOutline,
  //         handler: () => {
  //           checkPermissao();
  //           setShowActionSheet(false);
  //         }
  //       }
  //     );




  //     if (window.localStorage.getItem("servicoPrincipal") == "Pedidos") {

  //       resultado.push(
  //         {
  //           text: 'Mesa',
  //           icon: addCircleOutline,
  //           handler: () => {
  //             avancarPedidoInterno();
  //             setShowActionSheet(false);
  //           }
  //         }
  //       );
  //     }




  //   }
  //   return resultado;
  // }


  // async function closeModalNovoDelivery(enderecoSelecionado: any, latitudeSelecionado: any, longitudeSelecionado: any) {
  //   carregarBoard();
  //   setShowModalNovoDelivery(false);
  // }

  // async function closeModalSimpleNovoDelivery() {
  //   setShowModalNovoDelivery(false);
  // }

  async function closeModalNovo(enderecoSelecionado: any, latitudeSelecionado: any, longitudeSelecionado: any) {
    carregarBoard();
    setShowModalNovo(false);
  }

  async function closeModalSimpleNovo() {
    setShowModalNovo(false);
  }

  // async function closeModalNovoComanda(enderecoSelecionado: any, latitudeSelecionado: any, longitudeSelecionado: any) {
  //   carregarBoard();
  //   setShowModalNovoComanda(false);
  // }

  // async function closeModalSimpleNovoComanda() {
  //   setShowModalNovoComanda(false);
  // }


  async function closeModalNovoAgendamento(pk: any) {
    // consultarAgendamentos(idProjeto);
    setShowNovoAgendamento(false);
  }

  async function closeModalSimpleNovoAgendamento() {
    setShowNovoAgendamento(false);
  }

  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }

  // async function closeModalLiberalSucess(projeto: any) {
  //   setShowLiberar(false);


  //   console.log("response retorno liberar projeto", projeto);
  //   if (projeto) {
  //     setDadosProjeto(projeto);
  //     // nao funciona aqui
  //     //setRecarregarProjeto(true);
  //     await carregarBoard();
  //     if (!isPlatform('mobileweb') && isPlatform('mobile')) {
  //       await gerarCupom(projeto.pk);
  //     }
  //     else {
  //       await openNewTabWithHTML(projeto);
  //     }

  //     Toast.show({
  //       text: "Liberado com sucesso!",
  //       position: "center",
  //       duration: "long"
  //     });
  //   }
  // }

  // async function closeModalLiberal() {
  //   setShowLiberar(false);
  // }

  function carregarWorkflow(projetoid: any) {
    history.push("projetos/projetosworkflow", { projetoid: projetoid })
  }

  function carregarServicos() {
    history.push("projetos/projetosservicos", { id: dadosProjeto?.pk.replaceAll(/#/g, "%23") })

  }

  function carregarProdutos() {
    history.push("projetos/projetosprodutos", { id: dadosProjeto?.pk.replaceAll(/#/g, "%23") })

  }

  function avancarAgendamento(dadosProj: any) {
    setShowNovoAgendamento(true);
  }

  async function fecharInterno() {
    const objectRequest = {
    }
    requestService(alterarStatusProjeto.url.replaceAll("{id}", dadosProjeto.pk.replaceAll(/#/g, "%23")) + "?alteracao=true", {
      method: alterarStatusProjeto.method,
      headers: [["token", localStorage.getItem("token") || ""]],
      credentials: 'include',
      body: JSON.stringify(objectRequest)
    },
      history,
      async (response: any) => {

        // console.log("response", response);
        setDadosProjeto(response);
        // nao funciona aqui
        //setRecarregarProjeto(true);
        await carregarBoard();

        if (!isPlatform('mobileweb') && isPlatform('mobile')) {
          await gerarCupom(response.pk);
        }
        else {
          await openNewTabWithHTML(response);
        }

        Toast.show({
          text: "Liberado com sucesso!",
          position: "center",
          duration: "long"
        });
        // history.goBack();
      },
      (error: any) => {
        setTextToast(error.message);
        setShowToast(true);
      });
  }

  function getButtonsCard() {
    let resultado: any = [];



    if (acessos?.projetos == 'leitura' || acessos?.projetos == 'edicao') {
      resultado.push({
        text: "Detalhes",
        disabled: false,
        icon: listOutline,
        handler: () => {
          carregarDetalhes(dadosProjeto.pk);
        }
      });

      resultado.push({
        text: "Itens",
        disabled: false,
        icon: pencilOutline,
        handler: () => {
          (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") ?
            carregarProdutos() : carregarServicos()
        }
      });



      if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {

        // resultado.push({
        //   text: "Liberar",
        //   disabled: dadosProjeto && dadosProjeto.dataCupom,
        //   icon: checkboxOutline,
        //   handler: async () => {
        //     if (dadosProjeto.interno) {
        //       mostartAlerta({
        //         backdropDismiss: false,
        //         header: 'Liberar ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
        //         message: 'Deseja <strong>liberar</strong>?',
        //         buttons: [
        //           {
        //             text: 'Cancelar',
        //             role: 'cancel',
        //           },
        //           {
        //             text: 'Confirmar',
        //             cssClass: 'primary',
        //             handler: async () => {
        //               await fecharInterno();
        //             }
        //           }
        //         ],
        //         onDidDismiss: (e) => console.log('did dismiss'),
        //       })
        //     }
        //     else {
        //       //history.push("../projetos/projetoscupomhtml", { dadosProjeto: dadosProjeto })
        //       setShowLiberar(true);
        //     }
        //   }
        // });

        resultado.push({
          text: "Cupom",
          disabled: dadosProjeto && !dadosProjeto.dataCupom,
          icon: printOutline,
          handler: async () => {

            if (dadosProjeto.dataCupom) {
              // if (!isPlatform('mobileweb') && isPlatform('mobile')) {
              await gerarCupom(dadosProjeto.pk);
              // }
              // else {
              //   await openNewTabWithHTML(dadosProjeto);
              // }
            }
          }
        });
      }
      else {

        resultado.push({
          text: 'PDF',
          disabled: false,
          icon: documentOutline,
          handler: async () => {
            // setShowActionSheet(false);
            await gerarAcompanhamento(dadosProjeto?.pk);
          }
        });
      }



      if (!dadosProjeto?.layoutSimplificado) {
        resultado.push({
          text: window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo",
          disabled: false,
          icon: albumsOutline,
          handler: () => {
            console.log("dadosProjeto?.pk", dadosProjeto?.pk.replaceAll(/#/g, '%23'));
            carregarWorkflow(dadosProjeto?.pk.replaceAll(/#/g, '%23'));
          }
        });
      }

      resultado.push({
        text:
          "Chat",
        disabled: dadosProjeto?.interno,
        icon: chatboxOutline,
        handler: () => {
          history.push("projetos/mensagem", { projetoid: dadosProjeto.pk.replaceAll(/#/g, '%23'), projetonome: dadosProjeto.name });
        }
      });
    }

    if (acessos?.agenda == 'edicao') {

      resultado.push({
        text: "Agendar",
        disabled: dadosProjeto?.interno,
        icon: calendarOutline,
        handler: () => {
          avancarAgendamento(dadosProjeto);
        }
      });

    }



    if (acessos?.projetos == 'edicao') {

      // resultado.push({
      //   text: 'Avançar etapa',
      //   disabled: dadosProjeto?.status === "Ativo" ? false : true,
      //   icon: arrowForwardOutline,
      //   handler: () => {
      //     setProjetoId(dadosProjeto?.pk);
      //     setIndiceWorkflow(dadosProjeto?.indiceWorkflow);
      //     setStatusWorkflow(dadosProjeto?.statusWorkflow);
      //     setListaStatus(dadosProjeto?.configWorkflow);
      //     setShowWorkflowAvancar(true);
      //   }
      // });





      resultado.push({
        text: 'Encerrar',
        disabled: dadosProjeto?.interno,
        icon: powerOutline,
        handler: () => {
          setAlertConfirmar(true);
        }
      });
    }

    resultado.push({
      text: 'Cancelar',
      disabled: false,
      icon: closeOutline,
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
      }
    });

    return resultado;
  }


  async function gerarCupom(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoading(true);
    const resp = await fetch(projetoGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarCupom.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoading(false);

  }


  const openNewTabWithHTML = async (projeto: any) => {
    let newWindow: any;
    newWindow = window.open('', '_blank');

    // Gera o conteúdo HTML dinamicamente
    const htmlContentTab = await montarCupomHtml(projeto);

    // Escreve o conteúdo HTML na nova aba
    newWindow.document.open();
    newWindow.document.write(htmlContentTab);
    newWindow.document.close();

    // Chama a função de impressão na nova aba
    setTimeout(function () {
      newWindow.print();
      newWindow.close();
    }, 300);
  };


  async function montarCupomHtml(projeto: any) {

    function removerAcentosECedilha(texto: any) {
      return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ç/g, 'c').replace(/Ç/g, 'C');
    }

    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, ''); // Remove tudo que não é dígito

      if (texto.length === 11) {
        // Máscara para CPF
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        // Máscara para CNPJ
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }

      return texto; // Retorna o texto sem máscara se não tiver 11 ou 14 dígitos
    }

    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';
      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });
      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }
      return linhas;
    }
    let dataCupom: any;
    if (projeto.dataCupom) {
      dataCupom = new Date(projeto.dataCupom);
      //dataCupom.setHours(dataCupom.getHours() - 3);
    }
    let html = `
      <!DOCTYPE html>
      <html lang="pt-BR">
         <head>
            <meta charset="UTF-8">
            <meta nomeProjeto="viewport" content="width=device-width, initial-scale=1.0">
            <title>CUPOM NÃO FISCAL</title>
            <style>
               body {
               font-family: 'Courier New', Courier, monospace;
               margin: 0;
               padding: 0;
               }
            <style>
               @media print {
               body {
               width: 370px; /* Define a largura da página como a largura do papel da impressora térmica */
               margin: 0;
               }
               .no-print {
               display: none; /* Esconde elementos que não devem aparecer na impressão */
               }
               }
               .bold {
               font-weight: bold;
               }
               .regular {
               font-size: 12pt;
               }
               .regular-itens {
               font-size: 12pt;
               }
               .table-container {
               width: 100%;
               max-width: 370px;
               margin: 0 auto;
               text-align: left;
               }
               table {
               width: 100%;
               border-collapse: collapse;
               }
               th, td {
               border: none;
               padding: 5px;
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               th {
               background-color: #f2f2f2;
               }
               .header, .footer {
               margin-top: 20px;
               }
               .footer {
               margin-top: 40px;
               }
               .separator {
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               /* CSS for the giant button */
               .print-button {
               display: block;
               width: 100%;
               padding: 20px;
               margin: 20px 0;
               background-color: #4CAF50; /* Green background */
               color: white;
               font-size: 24px;
               font-weight: bold;
               text-align: center;
               border: none;
               border-radius: 10px;
               cursor: pointer;
               }
               .print-button:hover {
               background-color: #45a049; /* Darker green on hover */
               }
               .close-button {
               position: absolute;
               top: 10px;
               right: 10px;
               padding: 10px 20px;
               background-color: #f44336; /* Red background */
               color: white;
               font-size: 16px;
               font-weight: bold;
               border: none;
               border-radius: 5px;
               cursor: pointer;
               }
               .close-button:hover {
               background-color: #d32f2f; /* Darker red on hover */
               }
            </style>
            <div class="table-container">
               `;
    if ((projeto.interno)) {
      html += `
               <div class="header bold">
                  <div>${projeto.clienteNome}</div>
               </div>
               `;
    }
    else {
      html += `
               <div class="header bold">
                  <div>${projeto.empresaNome}</div>
                  <div class="regular">CNPJ: ${aplicarMascaraCPFouCNPJ(projeto.empresaCnpj)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${projeto.empresaEndereco}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
                  <div class="regular">Tel: ${formataTelefone(projeto.empresaTelefone)}</div>
                  <div class="regular">
                     ${(dataCupom)
          ? `Data: ${dataCupom.toLocaleDateString('pt-BR')} Hora: ${dataCupom.toLocaleTimeString('pt-BR')}`
          : ""}
                  </div>
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">
                  <div>${projeto.clienteNome}</div>
               </div>
               <div class="header bold">
                  <div class="regular">Tel: ${formataTelefone(projeto.clienteTelefone)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${[projeto.endereco, projeto.complemento]
            .filter(Boolean)
            .join(', ') || ""}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">CUPOM NÃO FISCAL</div>
               `;
    }
    html += `
               <div class="separator">======================================</div>
               <div class="regular">${projeto.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}</div>
               <div class="separator">======================================</div>
               `;
    if (projeto.produtos && projeto.produtos.length > 0) {
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td colspan="2">QTDE&nbsp|&nbspDESCRIÇÃO</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        ${projeto.produtos.map((iv: any, index: any) => `
                        ${(Array.isArray(iv.descricao)) ? (
          iv.descricao.map((itemDesc: any, indexLinha: any) => `
                        ${indexLinha === 0 ?
              quebrarTextoEmLinhas(iv.tipo === "pizzas" ? `${iv.tamanho} ${iv.quantidadeSabores} SABORES (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})` : `${iv.categoria} (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha2) => `
                        <tr>
                           ${indexLinha2 === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
              : ''}
                        ${(itemDesc.descricao) && (
              quebrarTextoEmLinhas(`- ${itemDesc.descricao} (R$ ${itemDesc.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha) => `
                        <tr>
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')
            )}
                        `).join('')
        ) : (
          quebrarTextoEmLinhas(`${iv.descricao} (R$ ${iv.valor.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })})`, 30)
            .map((linha, indexLinha) => `
                        <tr>
                           ${indexLinha === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
        )}
                        <tr>
                           <td colspan="2" class="regular-itens" style="text-align: left;">VALOR: R$ ${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        `).join('')}
                     </tbody>
                  </table>
               </div>
               `;
      if (!(projeto.interno)) {
        html += `
               <div class="bold">TOTAL: R$ ${projeto.valorTotalProdutos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      }
    }
    if (projeto.servicos && projeto.servicos.length > 0) {
      if (projeto.produtos && projeto.produtos.length > 0) {
        html += `
               <div class="separator">**************************************</div>
               `;
      }
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td>#</td>
                           <td>DESCRIÇÃO</td>
                           <td>QTD</td>
                           <td>VALOR</td>
                        </tr>
                        ${projeto.servicos.map((iv: any, index: any) => `
                        <tr>
                           <td class="regular-itens">${quebrarTextoEmLinhas(`${iv.descricao} x ${iv.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 30)
          .map((linha, indexLinha) => `
                        <tr>
                           <td class="regular-itens">${indexLinha == 0 ? String(index + 1).padStart(2, '0') : "&nbsp"}</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')}</td> 
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td class="regular-itens" style="text-align: right;">${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>`).join('')}
                     </tbody>
                  </table>
               </div>
               <div class="separator">--------------------------------------</div>
               <div class="bold">TOTAL: R$ ${projeto.valorTotalServicos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.formaPagamento) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}</div>
               `;
    }
    if (projeto.troco) {
      html += `
               <div class="bold">TROCO: R$ ${projeto.troco?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.taxaEntrega && !projeto.interno) {
      html += `            
               <div class="bold">TAXA DE ENTREGA: +R$ ${projeto.taxaEntrega?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.desconto) {
      html += `            
               <div class="bold">DESCONTO: -R$ ${projeto.desconto?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.interno && projeto.taxaServico) {
      html += `            
               <div class="bold">TAXA: +R$ ${projeto.taxaServico?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
               `;
    }
    if (projeto.valorTotal && !(projeto.interno)) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">TOTAL ${projeto.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${projeto.valorTotal?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      const qrData = await QRCode.toDataURL(projeto.pk);
      html += `
               <div class="separator">.</div>
               <div>
                  <img src="${qrData}" alt="QR Code" style="width: 150px; height: 150px;"/>
               </div>
               `;
    }
    html += `
               <div class="separator">.</div>
               <div class="separator">.</div>
               <div class="separator">.</div>
            </div>
            </body>
      </html>
      `;
    return removerAcentosECedilha(html);
  }

  async function gerarAcompanhamento(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoading(true);
    const resp = await fetch(projetoGerarAcompanhamento.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarAcompanhamento.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar PDF',
        message: '<strong>PDF</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoading(false);

  }

  function encerrar() {
    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    return fetch(finalizarProjeto.url.replace("{id}", dadosProjeto.pk.replaceAll(/#/g, "%23")),
      {
        method: finalizarProjeto.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
  }

  function avancarNovoProjeto() {

    setShowModalNovo(true);
  }

  function doRefresh(event: any) {
    carregarBoard();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  return (

    <IonPage className="Projetos">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton onClick={() => { setShowNotificacoes(true); }}>
              <IonIcon slot="icon-only" icon={notifications} />
              <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
            <IonButton onClick={() => { history.push("./chat"); }}>
              <IonIcon slot="icon-only" icon={chatbubbles} />
              <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>





              <IonItem lines="none" button hidden={acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao'}
                onClick={() => { checkPermissao(); closePopover(); }}>
                <IonIcon icon={addCircleOutline} slot="start" />
                <IonLabel>{'Criar ' + (window.localStorage.getItem("servicoTipo") || "Projeto")}</IonLabel>
              </IonItem>


              <IonItem lines="none" button onClick={() => { history.push("projetoshistorico"); closePopover(); }}>
                <IonIcon icon={folderOutline} slot="start" />
                <IonLabel>Histórico</IonLabel>
              </IonItem>

              <IonItem lines="none" button onClick={closePopover}>
                <IonIcon icon={closeOutline} slot="start" />
                <IonLabel>Cancelar</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>


        </IonToolbar>
        <IonToolbar color="primary">
          <IonGrid style={{ padding: "0px" }}>

            <IonRow class="ion-align-items-center">
              <IonCol>
                <IonSearchbar
                  onIonInput={searchbarInput}
                  placeholder={"Busque pelo Cliente ou " + (window.localStorage.getItem("servicoTipo") || "Projeto")}
                  value={searchQuery}
                />
              </IonCol>
              <IonCol size="auto" style={{ display: 'flex', alignItems: 'center' }}>
                <IonText style={{ fontSize: "12px", marginRight: '2px' }}>Auto</IonText>
                <IonToggle checked={autoReload} onIonChange={toggleAutoReload} />
              </IonCol>
            </IonRow>
          </IonGrid>

        </IonToolbar>

      </IonHeader>
      <IonContent>

        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.projetos != 'edicao'}>

          {/* <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => setShowActionSheet(true)} hidden={(acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao') || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos") && !(window.localStorage.getItem("servicoPrincipal") == "Vendas")}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton> */}

          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { checkPermissao(); }}>
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab vertical="top" horizontal="end" slot="fixed" hidden={isPlatform("android") || isPlatform("ios")}>
          <IonFabButton color="tertiary" onClick={() => { carregarBoard(); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>

        {/* <IonRefresher slot="fixed" onIonRefresh={doRefresh} disabled={isDragging}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}

        <IonAlert isOpen={alertConfirmar}
          onDidDismiss={() => setAlertConfirmar(false)}
          header={'Encerrar ' + window.localStorage.getItem("servicoTipo") || "Projeto"}
          message={'Deseja <strong>encerrar</strong>?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: async () => {
                setShowLoading(true);
                await encerrar().then(async res => {
                  console.log(res);

                  await carregarBoard();
                  // history.goBack();
                }).catch(() => console.log("Erro na chamada"))
                  .finally(() => {
                    setShowLoading(false);
                  });
              }
            }
          ]}
        />

        {/* <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={getButtons()}
        /> */}

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />




        <DragDropContext
          // onDragStart={onDragStart} 
          onDragEnd={onDragEnd}>
          <div style={{ display: 'flex', justifyContent: 'left', padding: '10px', overflowX: 'auto', maxHeight: '100%', overflowY: 'auto' }}>
            {columns.map((column: Column) => (
              <div key={column.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexShrink: 0 }}>
                <Droppable droppableId={column.id}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        backgroundColor: column.bgcolor,
                        width: (!isPlatform('mobileweb') && isPlatform('mobile')) ? 180 : 240,
                        height: 'fit-content',
                        padding: 2,
                        margin: 2,
                        borderRadius: 4,
                      }}
                    >

                      <p
                        id={column.name}
                        className="droppable"
                        style={{
                          border: "#000 1px dashed",
                          padding: "6px",
                          margin: "1px",
                          minHeight: '60px'
                        }}>
                        {column.name}
                      </p>

                      {/* <div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 16 }}>{column.name}</div> */}
                      <div>
                        {column.items.map((item: Item, index: number) => (
                          <Draggable draggableId={item.id} index={index} key={item.id}>
                            {(provided) => (
                              <div
                                // onClick={() => carregarDetalhes(item.pk.replaceAll(/#/g, '%23'), item.gsi2pk.replaceAll(/#/g, '%23'), false)}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                style={{
                                  ...provided.draggableProps.style,
                                  margin: '0 0 10px 0',
                                }}
                              >
                                <IonCard style={{ maxWidth: '400px', padding: '0px', margin: '6px', backgroundColor: item.bgColorPercentual }}>
                                  <div style={{ padding: "2px", margin: "2px", minHeight: '80px' }} >
                                    <IonCardContent
                                      style={{
                                        width: '100%',
                                        // display: 'flex',
                                        alignItems: 'center',
                                        color: '#000',
                                        fontSize: '10px',
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        position: 'relative',
                                      }}
                                    >

                                      <div style={{ position: 'absolute', top: '0', right: '0' }}>

                                        <div style={{ display: 'flex' }}>



                                          <IonButtons >
                                            <IonButton hidden={acessos?.projetos != 'edicao'}
                                              onClick={e => {
                                                setDadosProjeto(item);
                                                openPopoverCard(e);
                                              }}>

                                              {/* onClick={() => {
                                                carregarDetalhes(item.pk.replaceAll(/#/g, '%23'), item.gsi2pk.replaceAll(/#/g, '%23'), false)
                                              }}> */}
                                              <IonIcon color="primary" icon={ellipsisHorizontal} />
                                            </IonButton>
                                          </IonButtons>

                                          {/* {!item.clienteInterno && (

                                            <IonIcon color='primary' icon={iconMoto} size='small' />
                                          )} */}


                                        </div>
                                      </div>

                                      <div>
                                        <div style={{ textAlign: "center", display: 'block' }}>

                                          {!item.clienteInterno && (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") && (
                                            <IonIcon color='primary' icon={iconMoto} size='small' />
                                          )}
                                        </div>

                                        <div style={{ paddingTop: "10px", textAlign: "center", display: 'block' }}>
                                          <IonIcon icon={time} style={{ paddingRight: "4px" }}></IonIcon> {tempoAndamento(item.dataCadastro)}

                                        </div>
                                        <br />
                                        <div style={{ display: 'block', fontSize: "14px" }}>
                                          {window.localStorage.getItem("servicoTipo") || "Projeto"}: <b>{item.name}</b>
                                        </div>
                                        <div style={{ display: 'block', fontSize: "14px" }}>
                                          Cliente: <b>{item.clienteNome}</b>
                                        </div>


                                        <div hidden={!(item.produtos?.length > 0 && (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas"))}>

                                          {item.dataCupom && item.dataCupom !== "" ? (
                                            <div style={{ paddingTop: "10px" }}>
                                              <div style={{ fontWeight: 'bold', display: 'block' }}>
                                                Produtos
                                              </div>
                                              <div style={{ display: 'block' }}>
                                                {item.produtos?.map((produto: any, index: any) => (
                                                  <>

                                                    {Array.isArray(produto.descricao) ? (
                                                      <>
                                                        {(produto.tipo === "pizzas") ? (
                                                          <p style={{ fontWeight: "bold" }}>{produto.quantidade.toLocaleString('pt-br', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                          })} x {produto.tamanho} {produto.quantidadeSabores} SABORES</p>
                                                        ) : (
                                                          <p style={{ fontWeight: "bold" }}>{produto.quantidade.toLocaleString('pt-br', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                          })} x {produto.categoria}</p>
                                                        )}
                                                      </>

                                                    ) :
                                                      (
                                                        <p style={{ fontWeight: "bold" }} >{produto.quantidade.toLocaleString('pt-br', {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2
                                                        })} x {produto.descricao}</p>
                                                      )}

                                                    <div
                                                      key={index}
                                                      className="product-description"
                                                      style={{ textDecoration: produto.concluido ? "line-through" : "" }}
                                                    >

                                                      {(Array.isArray(produto.descricao)) && (
                                                        produto.descricao.map((itemDesc: any, index: any) => (
                                                          <div key={index}>
                                                            - {itemDesc.descricao}
                                                            {/* {itemDesc.descricao} x {itemDesc.quantidade.toLocaleString('pt-br', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                          })} */}
                                                          </div>
                                                        ))
                                                      )}


                                                    </div >
                                                  </>
                                                ))}
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              <div style={{ fontWeight: 'bold', display: 'block' }}>
                                                Montando {window.localStorage.getItem("servicoTipo") || "Projeto"}
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                        {!(window.localStorage.getItem("esconderValoresProjeto") === "true") && (
                                          <div style={{ paddingTop: "10px", display: 'block', fontSize: "14px" }}>
                                            Total: <b>R$ {item.valorTotal || "0,00"}</b>
                                          </div>
                                        )}

                                      </div>
                                    </IonCardContent>
                                  </div>
                                </IonCard>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>


        <IonPopover
          isOpen={showPopoverCard}
          event={popoverCardEvent}
          onDidDismiss={closePopoverCard}
        >
          <IonList>
            {getButtonsCard().map((button: any, index: number) => (
              <IonItem key={index} lines="none" disabled={button.disabled} button onClick={() => {
                if (button.handler) button.handler();
                closePopoverCard();
              }}>
                <IonIcon icon={button.icon} slot="start" />
                <IonLabel>{button.text}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonPopover>

        <IonModal isOpen={showModalPermissao} onDidDismiss={() => setShowModalPermissao(false)} initialBreakpoint={0.6} breakpoints={[0, 0.6]}>
          <IonContent className="ion-padding">
            <IonItem lines='none'>
              <IonLabel className="ion-text-wrap" style={{ marginTop: '30px', marginBottom: '30px', textAlign: 'center' }}><b>Acesso a envio de notificação</b></IonLabel>
            </IonItem>
            <IonItem lines='none'>
              <IonIcon src={checkmarkCircleOutline} slot='start'></IonIcon>
              <IonLabel className="ion-text-wrap" style={{ textAlign: 'justify' }}>Precisamos de <b>autorização para enviar notificação</b>, assim a cliente pode falar com você mais rapidamente e agilizar o atendimento.</IonLabel>
            </IonItem>
            <IonButton ref={btnref} style={{ marginTop: '30px', marginBottom: '30px' }} expand="block" color="primary" onClick={(e) => { solicitarAcesso(e); }}>Liberar</IonButton>
            <IonButton expand='block' fill="clear" color="tertiary" onClick={() => {
              setShowModalPermissao(false);
              if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {
                avancarNovoDelivery();
              }
              else {
                avancarNovoProjeto();
              }
            }}>Continuar sem ser notificado</IonButton>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
          <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
        </IonModal>


        {/* <IonModal isOpen={showLiberar} onDidDismiss={() => { setShowLiberar(false); }}>
          <ProjetosLiberar projetoid={dadosProjeto?.pk} closeAction={closeModalLiberalSucess} closeActionSimple={closeModalLiberal}></ProjetosLiberar>
        </IonModal> */}

        <IonModal onDidPresent={() => {
          if (inputAgendamentoNovoRef.current) {
            inputAgendamentoNovoRef.current.setFocus();
          }
        }} isOpen={showNovoAgendamento} onDidDismiss={() => { setShowNovoAgendamento(false); }}>
          <AgendamentosNovo inputRef={inputAgendamentoNovoRef} projetoid={btoa(dadosProjeto?.pk.replaceAll("%23", "#"))} closeAction={closeModalNovoAgendamento} closeActionSimple={closeModalSimpleNovoAgendamento}></AgendamentosNovo>
        </IonModal>


        {/* <IonModal className="fullscreen-modal" onDidPresent={() => {
          if (inputRefNovoDelivery.current) {
            inputRefNovoDelivery.current.setFocus();
          }
        }} isOpen={showModalNovoDelivery} onDidDismiss={() => setShowModalNovoDelivery(false)} >
          <ProjetosNovoPedidoVenda clienteid={null} inputRef={inputRefNovoDelivery} closeAction={closeModalNovoDelivery} closeActionSimple={closeModalSimpleNovoDelivery}></ProjetosNovoPedidoVenda>

        </IonModal> */}


        <IonModal onDidPresent={() => {
          if (inputRefNovo.current) {
            inputRefNovo.current.setFocus();
          }
        }} isOpen={showModalNovo} onDidDismiss={() => setShowModalNovo(false)} >
          <ProjetosNovo clienteid={null} inputRef={inputRefNovo} closeAction={closeModalNovo} closeActionSimple={closeModalSimpleNovo}></ProjetosNovo>

        </IonModal>



        {/* <IonModal onDidPresent={() => {
          if (inputRefNovoComanda.current) {
            inputRefNovoComanda.current.setFocus();
          }
        }} isOpen={showModalNovoComanda} onDidDismiss={() => setShowModalNovoComanda(false)} >
          <ProjetosNovoComanda clienteid={null} inputRef={inputRefNovoComanda} closeAction={closeModalNovoComanda} closeActionSimple={closeModalSimpleNovoComanda}></ProjetosNovoComanda>

        </IonModal> */}
      </IonContent>

    </IonPage>
  );


};

export default Projetos;
