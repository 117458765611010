import { IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonPopover, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, isPlatform, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import dayjs from 'dayjs';
import { arrowBack } from 'ionicons/icons';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useContext, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { useHistory } from 'react-router-dom';
import { listaEquipePorPerfil, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import ProjetosArquitetoTable from './ProjetosArquitetoTable';
import './RelatorioProjetosArquiteto.css';


type Props = {
  abrirMenu: boolean;
}

const RelatorioProjetosArquiteto: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [stateLastEvaluatedKey, setStateLastEvaluatedKey] = useState<any>();
  const [stateProjetosArquiteto, setStateProjetosArquiteto] = useState<any>([]);
  const [stateCarregado, setStateCarregado] = useState<boolean>(false);

  const popover1 = useRef<HTMLIonPopoverElement>(null);
  const popover2 = useRef<HTMLIonPopoverElement>(null);

  const [showModalDataInicio, setShowModalDataInicio] = useState(false);
  const [showModalDataFim, setShowModalDataFim] = useState(false);

  const [dataAgendamentoPeriodo1, setDataAgendamentoPeriodo1] = useState<any>("Selecione");
  const [dataAgendamentoPeriodo1Br, setDataAgendamentoPeriodo1Br] = useState<any>("Selecione");
  const [dataAgendamentoPeriodo2, setDataAgendamentoPeriodo2] = useState<any>("Selecione");
  const [dataAgendamentoPeriodo2Br, setDataAgendamentoPeriodo2Br] = useState<any>("Selecione");

  const [listaArquiteto, setListaArquiteto] = useState<any>([]);
  const [arquiteto, setArquiteto] = useState<any>();
  const [arquitetoNome, setArquitetoNome] = useState<any>();

  const [lojaSelecionadaId, setLojaSelecionadaId] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "");
  const [lojaSelecionadaNome, setLojaSelecionadaNome] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaNome || "");

  const [showLoading, setShowLoading] = useState(false);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarArquiteto();
  });

  const carregarArquiteto = async (empresaid?: any) => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", empresaid ? empresaid.replaceAll(/#/g, "%23") : lojaSelecionadaId.replaceAll(/#/g, "%23"));

    const resp = await fetch(montarUrl.replace("{perfil}", "arquiteto"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();

    console.log("equipe", equipe);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
        setListaArquiteto(lista);
      }
    }
  }


  function readArquiteto() {
    return listaArquiteto.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"arquiteto" + index} value={value.gsi5sk}>{value.nome}</IonSelectOption>
    )
    )
  };

  async function carregarProjetosArquitetoSegmentada() {
    const consultar = async () => {

      console.log(arquiteto);
      if (arquiteto == undefined || dataAgendamentoPeriodo1Br == "Selecione" || dataAgendamentoPeriodo2Br == "Selecione") {
        setTextToast("Todos os campos são obrigatórios.");
        setShowToast(true);
        return null;
      }

      let data1 = new Date(dataAgendamentoPeriodo1).getTime();
      let data2 = new Date(dataAgendamentoPeriodo2).getTime();

      var msDiff = data2 - data1;
      var msDiffDay = Math.floor(msDiff / (1000 * 60 * 60 * 24));

      if (msDiffDay < 0) {
        setTextToast("Data fim não pode ser menor que Data início.");
        setShowToast(true);
        return null;
      }
      else if (msDiffDay > 365) {
        setTextToast("Intervalo da data não pode ser maior que 365 dias.");
        setShowToast(true);
        return null;
      }

      setStateCarregado(true);
      setShowLoading(true);

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let dataFormatada = dayjs(dataAgendamentoPeriodo1).format('YYYY-MM-DD');
      let dataFormatada2 = dayjs(dataAgendamentoPeriodo2).format('YYYY-MM-DD');

      let urlFinal = urlBase.url + "/projetos/relatorioarquiteto?campo=data" +
        "&valor=" + dataFormatada +
        "&valor2=" + dataFormatada2 +
        "&pkarquiteto=" + arquiteto.replaceAll(/#/g, "%23") +
        "&lojas=" + Buffer.from(lojaSelecionadaId).toString('base64');

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      let res = await resp.json();

      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }

      if (res && res.LastEvaluatedKey) {
        setStateLastEvaluatedKey(res.LastEvaluatedKey);
      }
      else {
        setStateLastEvaluatedKey(null);
      }

      setShowLoading(false);
      if (res && res.Items) {
        return res.Items;
      }
      else {
        return null;
      }
    };
    return await consultar();
  }

  async function carregarDados(exportFunction?: any) {
    let loadResult = await carregarProjetosArquitetoSegmentada();
    let arrayProjetosArquiteto: any[] = [];


    if (window.localStorage.getItem("parceria")) {
      arrayProjetosArquiteto.push(['#', 'Nome', 'Status', 'Data', 'Cliente', 'Telefone', 'Valor', 'Avaliação', 'Comentário', 'Polo', 'Pontos', 'Parceria']);
    }
    else {
      arrayProjetosArquiteto.push(['#', 'Nome', 'Status', 'Data', 'Cliente', 'Telefone', 'Valor', 'Avaliação', 'Comentário']);
    }

    let contador = 0;

    if (loadResult) {
      loadResult.map((valor: any, index: any) => {
        contador++;

        let BRNumberCliente = "";
        if (valor.clienteTelefone?.length == 13) {
          BRNumberCliente = valor.clienteTelefone.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
          BRNumberCliente = "(" + BRNumberCliente[1] + ") " + BRNumberCliente[2] + "-" + BRNumberCliente[3];
        }

        if (window.localStorage.getItem("parceria")) {
          arrayProjetosArquiteto.push(
            [
              contador,
              valor.nomeProjeto || "-",
              valor.statusWorkflow || "-",
              dayjs(valor.dataCadastro).format('DD/MM/YYYY HH:mm') || "-",
              valor.clienteNome || "-",
              BRNumberCliente || "-",
              valor.valorTotal || "0,00",
              valor.avaliacaoCliente || "Não avaliado",
              valor.comentarioCliente || "Não avaliado",
              valor.polo || "-",
              valor.pontos || "-",
              valor.comissao || "-",
            ]);
        }
        else {
          arrayProjetosArquiteto.push(
            [
              contador,
              valor.nomeProjeto || "-",
              valor.statusWorkflow || "-",
              dayjs(valor.dataCadastro).format('DD/MM/YYYY HH:mm') || "-",
              valor.clienteNome || "-",
              BRNumberCliente || "-",
              valor.valorTotal || "0,00",
              valor.avaliacaoCliente || "Não avaliado",
              valor.comentarioCliente || "Não avaliado"
            ]);
        }


      });

      setStateProjetosArquiteto(arrayProjetosArquiteto);

      if (exportFunction) {
        exportFunction(arrayProjetosArquiteto);
      }
    }

  }

  async function gerarRelatorioPDF(arrayProjetosArquiteto: any) {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle: any = [{
      text: window.localStorage.getItem("servicoTipoPlural") || "Projetos",
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45]  // left, top, riight, bottom
    }];

    const reportDetails: any = [
      // { text: lojaSelecionadaNomeNome, style: 'subheader' }, //todo: colocar nome da empresa
      { text: lojaSelecionadaNome, style: 'subheader' },
      { text: arquitetoNome, style: 'subheader' },
      { text: 'Data início: ' + new Date(dataAgendamentoPeriodo1 || "").toLocaleString("pt-BR").substring(0, 10), style: 'subheader' },
      { text: 'Data fim: ' + new Date(dataAgendamentoPeriodo2 || "").toLocaleString("pt-BR").substring(0, 10), style: 'subheader' },
      {
        table: {
          body: arrayProjetosArquiteto

        },
        layout: {
          fillColor: function (rowIndex: any, node: any, columnIndex: any) {
            return (rowIndex == 0) ? '#ff7000' : (rowIndex % 2 === 0) ? '#CCCCCC' : null;
          }
        },
        style: 'padrao'
      }
    ];



    function rodape(currentPage: any, pageCount: any) {
      return [
        {
          text: currentPage + ' / ' + pageCount,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0]
        }
      ]
    }

    const docDefinitions: any = {
      pageSize: 'A4',

      pageOrientation: 'landscape',

      pageMargin: [15, 50, 15, 40],

      content: [reportDetails],
      footer: rodape,
      styles: {
        header: {
          fontSize: 10,
          bold: true
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        padrao: {
          fontSize: 8
        }
      }
    }

    pdfMake.createPdf(docDefinitions).open();
  }

  const openPopoverInicio = (e: any) => {
    popover1.current!.event = e;
    setShowModalDataInicio(true);
  };

  const openPopoverFim = (e: any) => {
    popover2.current!.event = e;
    setShowModalDataFim(true);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { setStateProjetosArquiteto([]); setStateCarregado(false); history.goBack(); }}><IonIcon slot="icon-only" icon={arrowBack} /></IonButton>
          </IonButtons>
          {/* <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={briefcase} />
            </IonButton>
          </IonButtons> */}
          <IonTitle slot="start">{window.localStorage.getItem("servicoTipoPlural") || "Projetos"} por Arquiteto</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <div style={{ textAlign: "center" }} hidden={stateCarregado}>


          <IonItem>
            <IonLabel>Data início</IonLabel>
            <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverInicio(e); }}>{dataAgendamentoPeriodo1Br}</IonLabel>
          </IonItem>

          <IonItem>
            <IonLabel>Data fim</IonLabel>
            <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverFim(e); }}>{dataAgendamentoPeriodo2Br}</IonLabel>
          </IonItem>

          <IonItem hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 1) ? false : true}>
            <IonLabel>Loja</IonLabel>
            <IonSelect name='Lojas' placeholder='Selecione as lojas' title='Lojas' value={lojaSelecionadaId} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => {
              setLojaSelecionadaId(e.detail.value);
              carregarArquiteto(e.detail.value);
            }} slot="end">
              {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                <IonSelectOption key={"lojaid" + valor.empresaLojaId} value={valor.empresaLojaId}>{valor.empresaLojaNome}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel>Arquiteto</IonLabel>
            <IonSelect slot="end" value={arquiteto} placeholder="Selecione" onIonChange={e => {
              setArquiteto(e.detail.value);

              console.log("chave projeto - gsi5sk", e.detail.value);

              console.log("listaArquiteto", listaArquiteto);
              let found;
              found = listaArquiteto.find((obj: any) => {
                return obj.gsi5sk === e.detail.value;
              });

              if (found) {
                setArquitetoNome(found.nome);
              }

            }}>
              {readArquiteto()}
            </IonSelect>
          </IonItem>

          <p></p>
          <IonButton onClick={async () => { await carregarDados() }} expand="block" type="submit" fill='solid' color='tertiary'>Buscar</IonButton>
        </div>
        <div style={{ textAlign: "center" }} hidden={!stateCarregado}>
          <IonItem lines='none'>
            <IonLabel style={{ textAlign: 'center' }}>{lojaSelecionadaNome}</IonLabel>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel style={{ textAlign: 'center' }}>{arquitetoNome}</IonLabel>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel style={{ textAlign: 'center' }}>Data início: {dayjs(dataAgendamentoPeriodo1).format("DD/MM/YYYY")}</IonLabel>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel style={{ textAlign: 'center' }}>Data fim: {dayjs(dataAgendamentoPeriodo2).format("DD/MM/YYYY")}</IonLabel>
          </IonItem>

          <p></p>
          <IonButton onClick={() => { setStateProjetosArquiteto([]); setStateCarregado(false); }} type="button" fill='solid' color='tertiary'>Limpar</IonButton>
          <IonButton hidden={isPlatform("android") || isPlatform("ios")} onClick={() => gerarRelatorioPDF(stateProjetosArquiteto)} type="button" fill='solid' color='tertiary'>Gerar PDF</IonButton>
          <CSVLink hidden={isPlatform("android") || isPlatform("ios")} data={stateProjetosArquiteto} separator={";"}><IonButton type="button" fill='solid' color='tertiary'>Gerar CSV</IonButton></CSVLink>

          <p></p>
          <ProjetosArquitetoTable model={stateProjetosArquiteto}></ProjetosArquitetoTable>
        </div>
        <p></p>

        <IonPopover ref={popover1}
          isOpen={showModalDataInicio}
          onDidDismiss={() => setShowModalDataInicio(false)} >
          <IonContent>
            <IonDatetime
              presentation='date'
              onIonChange={e => {
                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataAgendamentoPeriodo1Br(dataFormatada);
                  setDataAgendamentoPeriodo1(data);
                }
                //setShowModalDataInicio(false);
              }}></IonDatetime>
          </IonContent>
        </IonPopover >

        <IonPopover ref={popover2}
          isOpen={showModalDataFim}
          onDidDismiss={() => setShowModalDataFim(false)} >
          <IonContent>
            <IonDatetime
              presentation='date'
              onIonChange={e => {
                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataAgendamentoPeriodo2Br(dataFormatada);
                  setDataAgendamentoPeriodo2(data);
                }
                //setShowModalDataFim(false);
              }}></IonDatetime>
          </IonContent>
        </IonPopover >

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'

        />
      </IonContent>
    </IonPage>
  );
};

export default RelatorioProjetosArquiteto;