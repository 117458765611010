import { Toast } from '@capacitor/toast';
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPage, IonPopover, IonRow, IonTextarea, IonTitle, IonToast, IonToolbar, isPlatform, useIonAlert, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { addCircle, albumsOutline, alertCircle, arrowBack, arrowForwardOutline, calendarOutline, cardOutline, checkmarkCircle, clipboardOutline, closeOutline, documentOutline, ellipsisVertical, informationCircleOutline, listOutline, locationOutline, logoUsd, navigateOutline, notificationsOutline, pencilOutline, personOutline, phonePortraitOutline, powerOutline, pricetagOutline, printOutline, starOutline, trash, trendingUpOutline } from 'ionicons/icons';
import QRCode from 'qrcode';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { agendamentoProjetoCarregar, alterarStatusProjeto, finalizarProjeto, listaEquipePorPerfil, operacaoProjetoCarregar, projetoDetalheRegistrarArquivo, projetoGerarAcompanhamento, projetoGerarCupom, projetoRegistrarAnotacao, projetoRegistrarResponsavel, removerAnotacaoProjeto, removerArquivoDetalheProjeto, removerResponsavelProjeto, requestService, urlBase } from '../../../../../Utils/Services';
import AppTypeaheadItem from '../../../../../components/AppTypeaheadItem';
import { MessageContext } from '../../../../../contexts/MessageContext';
import AgendamentosNovo from '../AgendamentosNovo/AgendamentosNovo';
import ProjetosWorkflowAvancar from '../ProjetosWorkflowAvancar/ProjetosWorkflowAvancar';
import "./ProjetosDetalhes.css";
import maps from './imgs/maps.png';
import iconMoto from "./imgs/moto.svg";
import waze from './imgs/waze.png';

type Props = {
  abrirMenu: boolean;
  projetoid: string;
  esconderAgendamentos: boolean;
  esconderOperacoes: boolean;

  onDismiss: () => void;
};

const ProjetosDetalhes: React.FC<Props> = (props: Props) => {


  const history = useHistory<any>();
  const location = useLocation<any>();
  const [idProjeto, setIdProjeto] = useState("");
  const [dadosProjeto, setDadosProjeto] = useState<any>([]);
  const [temHoraFim, setTemHoraFim] = useState(false);
  // const [showActionSheet, setShowActionSheet] = useState(false);
  // const [showActionSheetLinhaDoTempo, setShowActionSheetLinhaDoTempo] = useState(false);
  const [alertConfirmar, setAlertConfirmar] = useState(false);
  const [showAtualizarInformacoes, setShowAtualizarInformacoes] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(false);

  const [listaAgendamentos, setListaAgendamentos] = useState<any>([]);
  const [listaOperacoes, setListaOperacoes] = useState<any>([]);

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  const contentAgendamentosRef = useRef<HTMLIonContentElement | null>(null);
  const contentOperacoesRef = useRef<HTMLIonContentElement | null>(null);

  const [showNovoAgendamento, setShowNovoAgendamento] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);

  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();

  const [showModalResponsaveis, setShowModalResponsaveis] = useState(false);
  const [responsaveis, setResponsaveis] = useState<any>([]);

  const [projetoId, setProjetoId] = useState("");
  const [indiceWorkflow, setIndiceWorkflow] = useState("");
  const [statusWorkflow, setStatusWorkflow] = useState("");
  const [listaStatus, setListaStatus] = useState<any>([]);
  const [showWorkflowAvancar, setShowWorkflowAvancar] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [showModalArquivos, setShowModalArquivos] = useState(false);
  const [fileArquivos, setFileArquivos] = useState<any>();
  const [textoArquivos, setTextoArquivos] = React.useState<string>();
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [caminhoParaExcluir, setCaminhoParaExcluir] = React.useState<string>();
  const [showAlertConfirmarExclusaoArquivo, setShowAlertConfirmarExclusaoArquivo] = useState(false);


  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };

  // const [showModalServicoProduto, setShowModalServicoProduto] = useState(false);

  // const [showLiberar, setShowLiberar] = useState(false);

  const scrollToAgendamentos = () => {
    contentAgendamentosRef.current && contentAgendamentosRef.current.scrollIntoView();
  };

  const scrollToOperacoes = () => {
    contentOperacoesRef.current && contentOperacoesRef.current.scrollIntoView();
  };

  const inputRef = useRef<any>(null);
  const inputAgendamentoNovoRef = useRef<any>(null);
  const inputProjetosAltRef = useRef<any>(null);

  useEffect(() => {
    console.log("idProjeto", idProjeto);
    if (idProjeto != "") {
      getProjeto();
    }
  }, [idProjeto]);

  useEffect(() => {
    console.log("recarregarProjeto", recarregarProjeto);
    if (recarregarProjeto) {
      getProjetoSimplificado();
      setRecarregarProjeto(false);
    }
  }, [recarregarProjeto]
  );

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    if (params.projetoid) {
      setIdProjeto(params.projetoid);
    }
  });

  const responsavelSelectionChanged = (responsavel: string) => {

    if (responsavel != "") {

      const responsavelJaExiste = dadosProjeto.responsaveis?.find((res: any) => res.pk === responsavel);

      if (responsavelJaExiste) {
        mostrarToast({
          duration: 2000,
          position: 'middle',
          buttons: [{ text: 'ok' }],
          message: 'Responsável já foi adicionado.'
        });
      }
      else {
        console.log("inserir", responsavel);





        setShowLoadingPage(true);
        let retorno = registrarResponsavel(dadosProjeto.pk.replace(/#/g, '%23'), responsavel.replace(/#/g, '%23'));
        if (retorno) {
          retorno?.then(async e => {
            let body = await e.json();
            if (e.status === 200) {

              const responsavelSelecionado = responsaveis.find((respon: any) => respon.pk === responsavel);
              console.log("responsavelSelecionado", responsavelSelecionado);


              if (dadosProjeto.responsaveis) {
                dadosProjeto.responsaveis?.push({ nome: responsavelSelecionado.nome, email: responsavelSelecionado.email, pk: responsavelSelecionado.pk, telefone: responsavelSelecionado.telefone });
              }
              else {
                dadosProjeto.responsaveis = [{ nome: responsavelSelecionado.nome, email: responsavelSelecionado.email, pk: responsavelSelecionado.pk, telefone: responsavelSelecionado.telefone }];
              }

              mostrarToast({
                duration: 2000,
                position: 'middle',
                buttons: [{ text: 'ok', handler: () => fecharToast() }],
                message: 'Sucesso na inclusão!'
              });
            }
            else {
              mostrarToast({
                position: 'middle',
                buttons: [{ text: 'ok', handler: () => fecharToast() }],
                message: 'Erro na inclusão!'
              });
            }
          }).catch((e) => {
            console.log(e);
            mostrarToast({
              position: 'middle',
              buttons: [{ text: 'ok', handler: () => fecharToast() }],
              message: 'Erro na inclusão!'
            });
          }).finally(() => {
            setShowLoadingPage(false);
          });
        }
        else {
          mostrarToast({
            duration: 2000,
            position: 'middle',
            buttons: [{ text: 'ok', handler: () => fecharToast() }],
            message: "Erro na inclusão!"
          });
          setShowLoadingPage(false);
        }

      }
    }
    setShowModalResponsaveis(false);
  };

  function avancarAgendamento(dadosProj: any) {
    setShowNovoAgendamento(true);
  }

  function carregarWorkflow(projetoid: any) {
    history.push("../projetos/projetosworkflow", { projetoid: projetoid })
  }


  let params: any = {
    projetoid: "",
    esconderAgendamentos: false,
    esconderOperacoes: false,
  }
  params = useParams();

  if (location && location.state && location.state.projetoid) {
    params.projetoid = location.state.projetoid;
    params.esconderAgendamentos = location.state.esconderAgendamentos;
    params.esconderOperacoes = location.state.esconderOperacoes;
  }



  if (props.projetoid) {
    params = {
      projetoid: props.projetoid,
      esconderAgendamentos: props.esconderAgendamentos,
      esconderOperacoes: props.esconderOperacoes,
    }
  }



  const getProjetoSimplificado = async () => {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", params.projetoid);

    setShowLoadingPage(true);
    const resp = await fetch(urlBase.url + "/projetos/" + params.projetoid.replaceAll(/#/g, '%23'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const operacao = await resp.json();
    //if (resp.status === 400 || resp.status === 401) history.replace("/login/");
    if (operacao.Item !== undefined) {

      setDadosProjeto(operacao.Item);
      // if (acessos?.agenda == 'leitura' || acessos?.agenda == 'edicao') {
      //   consultarAgendamentos(operacao.Item.pk);
      // }
      // if (acessos?.operacoes == 'leitura' || acessos?.operacoes == 'edicao') {
      //   consultarOperacoes(operacao.Item.pk);
      // }

      setTemHoraFim(operacao.Item.dataEncerramento ? true : false);

      console.log("dadosprojeto", operacao.Item);
    }

    setShowLoadingPage(false);

  }

  const getProjeto = async () => {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", params.projetoid);

    setShowLoadingPage(true);
    const resp = await fetch(urlBase.url + "/projetos/" + params.projetoid.replaceAll(/#/g, '%23'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const operacao = await resp.json();
    //if (resp.status === 400 || resp.status === 401) history.replace("/login/");
    if (operacao.Item !== undefined) {

      setDadosProjeto(operacao.Item);
      if (acessos?.agenda == 'leitura' || acessos?.agenda == 'edicao') {
        consultarAgendamentos(operacao.Item.pk);
      }
      if (acessos?.operacoes == 'leitura' || acessos?.operacoes == 'edicao') {
        consultarOperacoes(operacao.Item.pk);
      }

      setTemHoraFim(operacao.Item.dataEncerramento ? true : false);

      console.log("dadosprojeto", operacao.Item);
    }

    setShowLoadingPage(false);

  }

  function consultarAgendamentos(projetoid: any) {
    const consultarAgendamentoPorProjeto = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      const resp = await fetch(agendamentoProjetoCarregar.url.replace("{projetoid}", projetoid.replaceAll(/#/g, "%23")),
        {
          method: agendamentoProjetoCarregar.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      let projetos = await resp.json();

      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {

        if (projetos.Items) {
          setListaAgendamentos(projetos.Items);
        }

      }
    }
    consultarAgendamentoPorProjeto();

  }

  function consultarOperacoes(projetoid: any) {
    const consultarAgendamentoPorProjeto = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      console.log(operacaoProjetoCarregar.url.replace("{projetoid}", projetoid.replaceAll(/#/g, "%23")));
      const resp = await fetch(operacaoProjetoCarregar.url.replace("{projetoid}", projetoid.replaceAll(/#/g, "%23")),
        {
          method: operacaoProjetoCarregar.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      let projetos = await resp.json();

      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {

        if (projetos.Items) {
          setListaOperacoes(projetos.Items);
        }



      }
    }
    consultarAgendamentoPorProjeto();

  }

  function encerrar2() {
    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    return fetch(finalizarProjeto.url.replace("{id}", dadosProjeto.pk.replaceAll(/#/g, "%23")),
      {
        method: finalizarProjeto.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });
  }

  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  function formatarDataHoraCompleta(data: Date) {
    return adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + "/" + (data.getFullYear().toString()) + " " + ("0" + data.getHours().toString()).substr(-2) + ":" + ("0" + data.getMinutes().toString()).substr(-2);
  }

  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  async function gerarCupom(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoadingPage(true);
    const resp = await fetch(projetoGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarCupom.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoadingPage(false);

  }

  // async function gerarCupomPdf(projetoid: any) {


  //   var token: string = "";
  //   var tkn = localStorage.getItem("token");
  //   if (typeof (tkn) === "string") { token = tkn; }
  //   console.log("projetoid", projetoid);

  //   setShowLoadingPage(true);
  //   const resp = await fetch(projetoGerarCupomPdf.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
  //     {
  //       method: projetoGerarCupomPdf.method,
  //       headers: [
  //         ["token", token]
  //       ],
  //       credentials: 'include'
  //     });
  //   const result = await resp.json();
  //   if (result) {
  //     console.log("result", result);

  //     mostartAlerta({
  //       backdropDismiss: false,
  //       header: 'Gerar PDF',
  //       message: '<strong>Cupom PDF</strong> foi gerado com sucesso.',
  //       buttons: [
  //         {
  //           text: 'Cancelar',
  //           role: 'cancel',
  //         },
  //         {
  //           text: 'Visualizar',
  //           cssClass: 'primary',
  //           handler: () => {
  //             window.open(result, '_blank');
  //           }
  //         }
  //       ],
  //       onDidDismiss: (e) => console.log('did dismiss'),
  //     })

  //   }

  //   setShowLoadingPage(false);

  // }

  async function gerarAcompanhamento(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoadingPage(true);
    const resp = await fetch(projetoGerarAcompanhamento.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarAcompanhamento.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar PDF',
        message: '<strong>PDF</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoadingPage(false);

  }

  // function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  //   const index = arr.indexOf(value);
  //   if (index > -1) {
  //     arr.splice(index, 1);
  //   }
  //   return arr;
  // }


  async function funcRemoverResponsavel(pk: string, data: string) {

    console.log("remover", pk);
    console.log("remover", data);
    let resp = await fetch(removerResponsavelProjeto.url.replace("{id}", pk.replace(/#/g, "%23")).replace("{responsavel}", data.replace(/#/g, "%23")),
      {
        method: removerResponsavelProjeto.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });

    let retorno = await resp.json();

    console.log("retorno", retorno);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      console.log("dadosProjeto.responsaveis", dadosProjeto.responsaveis);

      var res = dadosProjeto.responsaveis.filter((p: any, i: any) => {
        if (p.pk == data) {
          dadosProjeto.responsaveis.splice(i, 1); //remove the mached object from the original array
          return p;
        }
      });
      console.log("res", res);

      //dadosProjeto.anotacoes.splice(retorno.data, 1);
    }
    return resp;
  }

  async function funcRemoverLoja(pk: string, data: string) {

    let resp = await fetch(removerAnotacaoProjeto.url.replace("{id}", pk.replace(/#/g, "%23")).replace("{anotacao}", data.replace(/#/g, "%23")),
      {
        method: removerAnotacaoProjeto.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });

    let retorno = await resp.json();

    console.log("retorno", retorno);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      console.log("dadosProjeto.anotacoes", dadosProjeto.anotacoes);

      var res = dadosProjeto.anotacoes.filter((p: any, i: any) => {
        if (p.data == data) {
          dadosProjeto.anotacoes.splice(i, 1); //remove the mached object from the original array
          return p;
        }
      });
      console.log("res", res);

      //dadosProjeto.anotacoes.splice(retorno.data, 1);
    }
    return resp;
  }

  const loadAnotacoes = () => {

    return dadosProjeto.anotacoes?.sort(function (a: any, b: any) {
      if (a.data < b.data) {
        return 1;
      }
      if (a.data > b.data) {
        return -1;
      }
      return 0;
    }).map((value: any) => (

      <IonItem lines="none">

        <IonLabel className="ion-text-wrap">
          <h3 style={{ "verticalAlign": "middle" }}><strong>{new Date(value.data).toLocaleDateString() + " " + new Date(value.data).toLocaleTimeString()}</strong></h3>
          <h3>{value.anotacao + (value.pessoa ? (' - ' + value.pessoa) : "")}</h3>
        </IonLabel>
        <IonIcon hidden={acessos?.projetos != 'edicao'} icon={trash} color="danger" style={{ fontSize: "20px" }} onClick={() =>
          mostartAlerta({
            backdropDismiss: false,
            header: 'Remover Anotação',
            message: 'Realizar <strong>remoção</strong>?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Remover',
                cssClass: 'primary',
                handler: () => {
                  setShowLoadingPage(true);
                  let retorno = funcRemoverLoja(dadosProjeto.pk, value.data);
                  if (retorno) {
                    retorno?.then(res => {
                      if (res.status === 200) {
                        console.log("res", res)
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Anotação removida com sucesso.'
                        });
                      }
                      else {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro ao tentar remover.'
                        });
                      }
                    }).catch(() => {
                      mostrarToast({
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Erro ao tentar remover.'
                      });
                    }).finally(() => {
                      setShowLoadingPage(false);
                    });
                  }
                  else {
                    mostrarToast({
                      position: 'middle',
                      buttons: [{ text: 'ok', handler: () => fecharToast() }],
                      message: 'Erro ao tentar remover.'
                    });
                    setShowLoadingPage(false);
                  }
                  return true;
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })
        }>
        </IonIcon>

      </IonItem>

    ))
  }

  const loadResponsaveis = () => {

    return dadosProjeto.responsaveis?.map((value: any) => (

      <IonItem lines="none">

        <IonLabel className="ion-text-wrap">
          <h3 style={{ "verticalAlign": "middle" }}>{value.nome}</h3>
        </IonLabel>
        <IonIcon hidden={acessos?.projetos != 'edicao'} icon={trash} color="danger" style={{ fontSize: "20px" }} onClick={() =>
          mostartAlerta({
            backdropDismiss: false,
            header: 'Remover Responsável',
            message: 'Realizar <strong>remoção</strong>?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Remover',
                cssClass: 'primary',
                handler: () => {
                  setShowLoadingPage(true);
                  console.log("remover");
                  let retorno = funcRemoverResponsavel(dadosProjeto.pk, value.pk);
                  if (retorno) {
                    retorno?.then(res => {
                      if (res.status === 200) {
                        console.log("res", res)
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Responsável removido com sucesso.'
                        });
                      }
                      else {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro ao tentar remover.'
                        });
                      }
                    }).catch(() => {
                      mostrarToast({
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Erro ao tentar remover.'
                      });
                    }).finally(() => {
                      setShowLoadingPage(false);
                    });
                  }
                  else {
                    mostrarToast({
                      position: 'middle',
                      buttons: [{ text: 'ok', handler: () => fecharToast() }],
                      message: 'Erro ao tentar remover.'
                    });
                    setShowLoadingPage(false);
                  }
                  return true;
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })
        }>
        </IonIcon>

      </IonItem>

    ))
  }

  const loadAgendamentos = () => {

    if (listaAgendamentos == undefined) return;

    if (listaAgendamentos.length === 0) {
      return (

        <IonItem lines="none" key={"semAgenda"}>

          <IonLabel className="ion-text-wrap">
            <h3 style={{ "verticalAlign": "middle" }}>Não existe nenhum agendamento</h3>
          </IonLabel>

        </IonItem>

      );
    }


    return listaAgendamentos.map((value: any, index: any) => (


      <IonItem disabled={params.esconderAgendamentos} lines="none" detail onClick={(e: any) => {

        history.push("../agendamentos/agendamentosdetalhes", { agendamentoid: value.pk, esconderProjeto: true })


      }} key={index}>
        <IonLabel className="ion-text-wrap">

          <h3>
            {/* {value.interno == true ? <IonIcon color="danger" icon={lockClosed} slot="start" /> : <IonIcon color="success" icon={lockOpen} slot="start" />} */}
            {value.confirmado == true ? <IonIcon color="success" icon={checkmarkCircle} /> : <IonIcon color="danger" icon={checkmarkCircle} />}
            {/* {value.convidados?.length > 0 ? <IonIcon color="success" icon={people} /> : <IonIcon color="danger" icon={people} />} */}
            <strong>{new Date(value.dataAgendamento).toLocaleString('pt-BR').substring(0, 10) + ' ' + value.horaAgendamento + ' - ' + value.status}</strong></h3>
          <h3>{value.descricaoAgendamento + ' - ' + value.tipoAgendamento + ' - ' + value.prestadorNome}</h3>
        </IonLabel>

      </IonItem>
    ));



  }

  const loadOperacoes = () => {

    if (listaOperacoes == undefined) return;

    if (listaOperacoes.length === 0) {
      return (
        <IonItem lines="none">

          <IonLabel className="ion-text-wrap">
            <h3 style={{ "verticalAlign": "middle" }}>Não existe nenhuma operação</h3>
          </IonLabel>

        </IonItem>
      );
    }

    return listaOperacoes.map((value: any, index: any) => (
      <IonItem disabled={params.esconderOperacoes} lines="none" detail onClick={(e: any) => {

        history.push("../operacoes/acompanhar", { estabelecimento: "A", codigoBarras: "B", operacaoid: value.pk.replace(/#/g, '%23'), esconderProjeto: true });

      }} key={index}>
        <IonLabel className="ion-text-wrap">
          <h3 style={{ "verticalAlign": "middle" }}><strong>{formatarDataHoraCompleta(new Date(value.dataInicio)) + " - " + value.status}  </strong></h3>
          <h3>{value.descricaoAgendamento + ' - ' + value.tipoOperacao + ' - ' + value.motoristaNome}</h3>
        </IonLabel>

      </IonItem>
    ));



  }

  // function getButtons() {
  //   let resultado: any = [
  //     {
  //       text: 'Cancelar',
  //       icon: closeOutline,
  //       role: 'cancel',
  //       handler: () => {
  //         console.log('Cancel clicked');
  //       }
  //     },

  //   ];

  //   if (acessos?.projetos == 'leitura' || acessos?.projetos == 'edicao') {

  //     if (!dadosProjeto.layoutSimplificado) {
  //       resultado.push(
  //         {
  //           text: window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo",
  //           icon: albumsOutline,
  //           handler: () => {
  //             console.log("dadosProjeto.pk", dadosProjeto.pk.replaceAll(/#/g, '%23'));
  //             //window.localStorage.setItem("configWorkflowProjeto", JSON.stringify(dadosProjeto.configWorkflow));
  //             carregarWorkflow(dadosProjeto.pk.replaceAll(/#/g, '%23'));
  //           }
  //         }
  //       )
  //     }
  //   }

  //   if (acessos?.projetos == 'edicao') {

  //     // resultado.push(
  //     //   {
  //     //     text: 'Editar',
  //     //     icon: create,
  //     //     handler: () => {
  //     //       setShowAtualizarInformacoes(true);
  //     //     }
  //     //   }
  //     // );

  //     resultado.push(
  //       {
  //         text: 'Avançar etapa',
  //         icon: arrowForwardOutline,
  //         handler: () => {
  //           setProjetoId(dadosProjeto.pk);
  //           setIndiceWorkflow(dadosProjeto.indiceWorkflow);
  //           setStatusWorkflow(dadosProjeto.statusWorkflow);
  //           setListaStatus(dadosProjeto.configWorkflow);
  //           setShowWorkflowAvancar(true);
  //         }
  //       }
  //     )
  //   }


  //   //   resultado.push(
  //   //     {
  //   //       text: 'Cupom TXT',
  //   //       icon: print,
  //   //       handler: async () => {
  //   //         setShowActionSheet(false);
  //   //         await gerarCupom(dadosProjeto.pk);
  //   //       }
  //   //     }
  //   //   );

  //   //   resultado.push(
  //   //     {
  //   //       text: 'Cupom PDF',
  //   //       icon: print,
  //   //       handler: async () => {
  //   //         setShowActionSheet(false);
  //   //         await gerarCupomPdf(dadosProjeto.pk);
  //   //       }
  //   //     }
  //   //   );
  //   // }


  //   if (acessos?.agenda == 'edicao') {

  //     resultado.push(
  //       {
  //         text: "Agendar entrega/serviço",
  //         icon: calendarOutline,
  //         handler: () => {
  //           avancarAgendamento(dadosProjeto);
  //         }
  //       })
  //   }

  //   if (acessos?.projetos == 'edicao') {


  //     if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {


  //       resultado.push(
  //         {
  //           text: dadosProjeto.dataCupom ? "Cupom" : "Liberar",
  //           icon: dadosProjeto.dataCupom ? printOutline : cashOutline,
  //           handler: () => {
  //             setShowLiberar(true);

  //           }

  //         }
  //       )
  //     }
  //     else {

  //       resultado.push(
  //         {
  //           text: 'PDF',
  //           icon: documentOutline,
  //           handler: async () => {
  //             setShowActionSheet(false);
  //             await gerarAcompanhamento(dadosProjeto.pk);
  //           }
  //         }
  //       );
  //     }


  //     resultado.push(
  //       {

  //         text: 'Encerrar ' + window.localStorage.getItem("servicoTipo") || "Projeto",
  //         icon: powerOutline,
  //         handler: () => {
  //           setAlertConfirmar(true);
  //         }

  //       }
  //     )

  //   }
  //   return resultado;
  // }


  // function getButtonsLinhaDoTempo() {
  //   let resultado: any = [
  //     {
  //       text: 'Cancelar',
  //       icon: closeOutline,
  //       role: 'cancel',
  //       handler: () => {
  //         console.log('Cancel clicked');
  //       }
  //     },

  //   ];

  //   if (acessos?.projetos == 'leitura' || acessos?.projetos == 'edicao') {

  //     if (!dadosProjeto.layoutSimplificado) {
  //       resultado.push(
  //         {
  //           text: window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo",
  //           icon: albumsOutline,
  //           handler: () => {
  //             console.log("dadosProjeto.pk", dadosProjeto.pk.replaceAll(/#/g, '%23'));
  //             //window.localStorage.setItem("configWorkflowProjeto", JSON.stringify(dadosProjeto.configWorkflow));
  //             carregarWorkflow(dadosProjeto.pk.replaceAll(/#/g, '%23'));
  //           }
  //         }
  //       )
  //     }
  //   }

  //   return resultado;
  // }

  // async function closeModalAtualizarInformacoes() {
  //   setShowAtualizarInformacoes(false);
  // }

  // async function closeModalAtualizarInformacoesSucess() {
  //   getProjeto();
  //   setShowAtualizarInformacoes(false);
  //   setRecarregarProjeto(true);
  //   setShowLiberar(true);
  // }


  // async function closeModalLiberal() {
  //   setShowLiberar(false);
  // }

  // async function closeModalLiberalSucess(projeto: any) {
  //   console.log("response retorno liberar projeto", projeto);
  //   if (projeto) {
  //     setDadosProjeto(projeto);
  //     // nao funciona aqui
  //     setRecarregarProjeto(true);

  //     if (!isPlatform('mobileweb') && isPlatform('mobile')) {
  //       await gerarCupom(projeto.pk);
  //     }
  //     else {
  //       await openNewTabWithHTML(projeto);
  //     }

  //     Toast.show({
  //       text: "Liberado com sucesso!",
  //       position: "center",
  //       duration: "long"
  //     });
  //   }
  // }


  async function closeModalNovoAgendamento(pk: any) {
    consultarAgendamentos(idProjeto);
    setShowNovoAgendamento(false);
  }

  async function closeModalSimpleNovoAgendamento() {
    setShowNovoAgendamento(false);
  }


  function registrarAnotacao(pk: any, mensagem: any) {
    if (!mensagem || mensagem.trim() == "") {
      return null;
    }

    let params = {
      mensagem: mensagem
    }
    return fetch(projetoRegistrarAnotacao.url.replaceAll("{pk}", pk.replaceAll(/#/g, "%23")),
      {
        method: projetoRegistrarAnotacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  function preencheArquivos(value: any) {

    return (
      (value.arquivos) ?
        (<>
          {value.arquivos.map((arq: any, index: any) => (

            <IonCol size="6" key={index} >

              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
                <div style={{ maxWidth: '350px', width: '100%' }}>
                  <a target='_blank' href={arq.split(";")[1]}>
                    {arq.split(";")[0]}
                  </a>

                  <IonButton hidden={acessos?.projetos != 'edicao'} style={{ margin: '10px 0 10px 0', fontWeight: 'normal', width: '100%' }} fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                    setCaminhoParaExcluir(arq); setShowAlertConfirmarExclusaoArquivo(true);
                  }}>Excluir</IonButton>
                </div>
              </div>
            </IonCol>
          ))}

        </>) :
        (
          <>
            {/* {preencheCard(value, index)} */}
          </>
        )
    )
  }

  const handleUpload = async (idProjeto: string) => {

    if (!fileArquivos || !textoArquivos || textoArquivos.trim() == "") {
      setAlertaPreenchimento(true);
      return null;
    }

    console.log("fileArquivos", fileArquivos);


    let file = fileArquivos.files[0];
    console.log("file", file);

    if (file.size < 15000000) {
      let fileParts = file.name.split('.');
      let fileNameArquivo = fileParts[0];
      let fileTypeArquivo = fileParts[1];
      console.log("Preparing the upload");
      console.log(fileParts);

      try {
        incluirAquivo(idProjeto, file, fileTypeArquivo, textoArquivos || "", "Arquivos");

      } catch (error) {
        console.log("error", error);
        Toast.show({
          text: "Ocorreu um erro ao adicionar um arquivo, tente novamente.",
          position: "center",
          duration: "long"
        });
      }

    }
    else {
      Toast.show({
        text: "Somente arquivos até 15 MB.",
        position: "center",
        duration: "long"
      });
    }
  }

  async function incluirAquivo(projetoid: string, file: any, fileTypeArquivo: any, nomeArquivo: string, tipoArquivo: string) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let params = {
        nome: nomeArquivo,
        campo: tipoArquivo,
        mensagem: fileTypeArquivo
      }

      try {
        setShowLoadingPage(true);

        const resp = await fetch(projetoDetalheRegistrarArquivo.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
          {
            method: projetoDetalheRegistrarArquivo.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        console.log("response", response);
        if (response.Item) {



          console.log("file.type", file.type);
          const uploadToS3 = await fetch(response.Item, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadToS3.status == 200) {

            Toast.show({
              text: "Arquivo foi adicionado com sucesso.",
              position: "center",
              duration: "long"
            });

            setTextoArquivos("");
            setFileArquivos("");
          }
          else {
            Toast.show({
              text: "Ocorreu um erro ao fazer o upload do arquivo.",
              position: "center",
              duration: "long"
            });

          }
        }
        setShowLoadingPage(false);

      } catch (error) {
        console.log("erro", error);
        setShowLoadingPage(false);
      }
    }

    atualizar();

  };

  function deletarArquivo(url: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    setShowLoadingPage(true);
    const delArquivo = async () => {
      const resp = await fetch(removerArquivoDetalheProjeto.url.replace("{id}", params.projetoid.replace(/#/g, "%23")),
        {
          method: removerArquivoDetalheProjeto.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'arquivo': url }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      //if (resp.status === 400 || resp.status === 401) history.replace("/login/");

      console.log("retorno", retorno);


      if (retorno.Item && retorno.Item.Attributes) {
        setDadosProjeto(retorno.Item.Attributes);
        setTemHoraFim(retorno.Item.Attributes.dataEncerramento ? true : false);
      }
      setShowLoadingPage(false);
    }

    delArquivo();


  }





  function registrarResponsavel(pk: any, responsavel: any) {
    if (!responsavel || responsavel.trim() == "") {
      return null;
    }

    let params = {
      responsavel: responsavel
    }
    return fetch(projetoRegistrarResponsavel.url.replaceAll("{pk}", pk.replaceAll(/#/g, "%23")),
      {
        method: projetoRegistrarResponsavel.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }






  const consultarResponsaveis = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", "usuario"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
        setResponsaveis(lista);
      }
    }
  }


  async function carregarResponsaveis() {
    await consultarResponsaveis();
    setShowModalResponsaveis(true);
  }

  async function closeModalWorkflowAvancar() {
    setShowWorkflowAvancar(false);
  }

  async function closeModalWorkflowAvancarSucess(dadosOperacao: any) {
    setTextToast("Etapa alterada com sucesso!");
    setShowToast(true);
    getProjeto();
    setShowWorkflowAvancar(false);
    setRecarregarProjeto(true);
  }

  function carregarServicos() {
    history.push("../projetos/projetosservicos", { id: dadosProjeto.pk.replaceAll(/#/g, "%23") })

  }

  function carregarProdutos() {
    history.push("../projetos/projetosprodutos", { id: dadosProjeto.pk.replaceAll(/#/g, "%23") })

  }

  // function carregarEncerrar() {
  //   history.push("projetoscupom", { projetoid: dadosProjeto.pk.replaceAll(/#/g, "%23") })
  // }

  function getButtons() {
    let resultado: any = [];

    if (acessos?.projetos == 'leitura' || acessos?.projetos == 'edicao') {
      if (!dadosProjeto.layoutSimplificado) {
        resultado.push({
          text: window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo",
          disabled: dadosProjeto.status === "Ativo" ? false : true,
          icon: albumsOutline,
          handler: () => {
            console.log("dadosProjeto.pk", dadosProjeto.pk.replaceAll(/#/g, '%23'));
            carregarWorkflow(dadosProjeto.pk.replaceAll(/#/g, '%23'));
          }
        });
      }
    }

    if (acessos?.projetos == 'edicao') {
      resultado.push({
        text: "Itens",
        disabled: dadosProjeto.status === "Ativo" ? false : true,
        icon: pencilOutline,
        handler: () => {
          (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") ?
            carregarProdutos() : carregarServicos()
        }
      });

      if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {

        // resultado.push({
        //   text: "Liberar",
        //   disabled: dadosProjeto && dadosProjeto.dataCupom,
        //   icon: checkboxOutline,
        //   handler: async () => {
        //     if (dadosProjeto.interno) {
        //       mostartAlerta({
        //         backdropDismiss: false,
        //         header: 'Liberar ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
        //         message: 'Deseja <strong>liberar</strong>?',
        //         buttons: [
        //           {
        //             text: 'Cancelar',
        //             role: 'cancel',
        //           },
        //           {
        //             text: 'Confirmar',
        //             cssClass: 'primary',
        //             handler: async () => {
        //               await fecharInterno();
        //             }
        //           }
        //         ],
        //         onDidDismiss: (e) => console.log('did dismiss'),
        //       })
        //     }
        //     else {
        //       //history.push("../projetos/projetoscupomhtml", { dadosProjeto: dadosProjeto })
        //       setShowLiberar(true);
        //     }
        //   }
        // });

        resultado.push({
          text: "Cupom",
          disabled: dadosProjeto && !dadosProjeto.dataCupom,
          icon: printOutline,
          handler: async () => {

            if (dadosProjeto.dataCupom) {
              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                await gerarCupom(dadosProjeto.pk);
              }
              else {
                await openNewTabWithHTML(dadosProjeto);
              }
            }
          }
        });
      }
      else {

        resultado.push({
          text: 'PDF',
          disabled: dadosProjeto.status === "Ativo" ? false : true,
          icon: documentOutline,
          handler: async () => {
            // setShowActionSheet(false);
            await gerarAcompanhamento(dadosProjeto.pk);
          }
        });
      }
    }

    if (acessos?.projetos == 'edicao') {

      resultado.push({
        text: 'Avançar',
        disabled: dadosProjeto.status === "Ativo" ? false : true,
        icon: arrowForwardOutline,
        handler: () => {
          setProjetoId(dadosProjeto.pk);
          setIndiceWorkflow(dadosProjeto.indiceWorkflow);
          setStatusWorkflow(dadosProjeto.statusWorkflow);
          setListaStatus(dadosProjeto.configWorkflow);
          setShowWorkflowAvancar(true);
        }
      });

    }

    if (acessos?.agenda == 'edicao') {
      resultado.push({
        text: "Agendar",
        disabled: dadosProjeto.status === "Ativo" ? false : true,
        icon: calendarOutline,
        handler: () => {
          avancarAgendamento(dadosProjeto);
        }
      });
    }

    if (acessos?.projetos == 'edicao') {

      resultado.push({
        text: 'Encerrar',
        disabled: dadosProjeto.status === "Ativo" ? false : true,
        icon: powerOutline,
        handler: () => {
          setAlertConfirmar(true);
        }
      });
    }

    resultado.push({
      text: 'Cancelar',
      disabled: false,
      icon: closeOutline,
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
      }
    });

    return resultado;
  }


  async function fecharInterno() {
    const objectRequest = {
    }
    requestService(alterarStatusProjeto.url.replaceAll("{id}", dadosProjeto.pk.replaceAll(/#/g, "%23")) + "?alteracao=true", {
      method: alterarStatusProjeto.method,
      headers: [["token", localStorage.getItem("token") || ""]],
      credentials: 'include',
      body: JSON.stringify(objectRequest)
    },
      history,
      async (response: any) => {

        // console.log("response", response);
        setDadosProjeto(response);
        setRecarregarProjeto(true);

        if (!isPlatform('mobileweb') && isPlatform('mobile')) {
          await gerarCupom(response.pk);
        }
        else {
          await openNewTabWithHTML(response);
        }

        Toast.show({
          text: "Liberado com sucesso!",
          position: "center",
          duration: "long"
        });
        // history.goBack();
      },
      (error: any) => {
        setTextToast(error.message);
        setShowToast(true);
      });
  }


  const openNewTabWithHTML = async (projeto: any) => {
    let newWindow: any;
    newWindow = window.open('', '_blank');

    // Gera o conteúdo HTML dinamicamente
    const htmlContentTab = await montarCupomHtml(projeto);

    // Escreve o conteúdo HTML na nova aba
    newWindow.document.open();
    newWindow.document.write(htmlContentTab);
    newWindow.document.close();

    // Chama a função de impressão na nova aba
    setTimeout(function () {
      newWindow.print();
      newWindow.close();
    }, 300);
  };


  async function montarCupomHtml(projeto: any) {

    function removerAcentosECedilha(texto: any) {
      return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ç/g, 'c').replace(/Ç/g, 'C');
    }

    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, ''); // Remove tudo que não é dígito

      if (texto.length === 11) {
        // Máscara para CPF
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        // Máscara para CNPJ
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }

      return texto; // Retorna o texto sem máscara se não tiver 11 ou 14 dígitos
    }

    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';
      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });
      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }
      return linhas;
    }
    let dataCupom: any;
    if (projeto.dataCupom) {
      dataCupom = new Date(projeto.dataCupom);
      //dataCupom.setHours(dataCupom.getHours() - 3);
    }
    let html = `
      <!DOCTYPE html>
      <html lang="pt-BR">
         <head>
            <meta charset="UTF-8">
            <meta nomeProjeto="viewport" content="width=device-width, initial-scale=1.0">
            <title>CUPOM NÃO FISCAL</title>
            <style>
               body {
               font-family: 'Courier New', Courier, monospace;
               margin: 0;
               padding: 0;
               }
            <style>
               @media print {
               body {
               width: 370px; /* Define a largura da página como a largura do papel da impressora térmica */
               margin: 0;
               }
               .no-print {
               display: none; /* Esconde elementos que não devem aparecer na impressão */
               }
               }
               .bold {
               font-weight: bold;
               }
               .regular {
               font-size: 12pt;
               }
               .regular-itens {
               font-size: 12pt;
               }
               .table-container {
               width: 100%;
               max-width: 370px;
               margin: 0 auto;
               text-align: left;
               }
               table {
               width: 100%;
               border-collapse: collapse;
               }
               th, td {
               border: none;
               padding: 5px;
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               th {
               background-color: #f2f2f2;
               }
               .header, .footer {
               margin-top: 20px;
               }
               .footer {
               margin-top: 40px;
               }
               .separator {
               text-align: left;
               margin: 0px;
               padding: 0px;
               }
               /* CSS for the giant button */
               .print-button {
               display: block;
               width: 100%;
               padding: 20px;
               margin: 20px 0;
               background-color: #4CAF50; /* Green background */
               color: white;
               font-size: 24px;
               font-weight: bold;
               text-align: center;
               border: none;
               border-radius: 10px;
               cursor: pointer;
               }
               .print-button:hover {
               background-color: #45a049; /* Darker green on hover */
               }
               .close-button {
               position: absolute;
               top: 10px;
               right: 10px;
               padding: 10px 20px;
               background-color: #f44336; /* Red background */
               color: white;
               font-size: 16px;
               font-weight: bold;
               border: none;
               border-radius: 5px;
               cursor: pointer;
               }
               .close-button:hover {
               background-color: #d32f2f; /* Darker red on hover */
               }
            </style>
            <div class="table-container">
               `;
    if ((projeto.interno)) {
      html += `
               <div class="header bold">
                  <div>${projeto.clienteNome}</div>
               </div>
               `;
    }
    else {
      html += `
               <div class="header bold">
                  <div>${projeto.empresaNome}</div>
                  <div class="regular">CNPJ: ${aplicarMascaraCPFouCNPJ(projeto.empresaCnpj)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${projeto.empresaEndereco}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
                  <div class="regular">Tel: ${formataTelefone(projeto.empresaTelefone)}</div>
                  <div class="regular">
                     ${(dataCupom)
          ? `Data: ${dataCupom.toLocaleDateString('pt-BR')} Hora: ${dataCupom.toLocaleTimeString('pt-BR')}`
          : ""}
                  </div>
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">
                  <div>${projeto.clienteNome}</div>
               </div>
               <div class="header bold">
                  <div class="regular">Tel: ${formataTelefone(projeto.clienteTelefone)}</div>
                  ${quebrarTextoEmLinhas(`Endereço: ${[projeto.endereco, projeto.complemento]
            .filter(Boolean)
            .join(', ') || ""}`, 35)
          .map(linha => `
                  <div class="regular">${linha}</div>
                  `).join('')}
               </div>
               <div class="separator">**************************************</div>
               <div class="header bold">CUPOM NÃO FISCAL</div>
               `;
    }
    html += `
               <div class="separator">======================================</div>
               <div class="regular">${projeto.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}</div>
               <div class="separator">======================================</div>
               `;
    if (projeto.produtos && projeto.produtos.length > 0) {
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td colspan="2">QTDE&nbsp|&nbspDESCRIÇÃO</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        ${projeto.produtos.map((iv: any, index: any) => `
                        ${(Array.isArray(iv.descricao)) ? (
          iv.descricao.map((itemDesc: any, indexLinha: any) => `
                        ${indexLinha === 0 ?
              quebrarTextoEmLinhas(iv.tipo === "pizzas" ? `${iv.tamanho} ${iv.quantidadeSabores} SABORES (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})` : `${iv.categoria} (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha2) => `
                        <tr>
                           ${indexLinha2 === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
              : ''}
                        ${(itemDesc.descricao) && (
              quebrarTextoEmLinhas(`- ${itemDesc.descricao} (R$ ${itemDesc.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha) => `
                        <tr>
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')
            )}
                        `).join('')
        ) : (
          quebrarTextoEmLinhas(`${iv.descricao} (R$ ${iv.valor.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })})`, 30)
            .map((linha, indexLinha) => `
                        <tr>
                           ${indexLinha === 0 ? `
                           <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                           ` : `
                           <td>&nbsp</td>
                           <td class="regular-itens">${linha}</td>
                           `}
                        </tr>
                        `).join('')
        )}
                        <tr>
                           <td colspan="2" class="regular-itens" style="text-align: left;">VALOR: R$ ${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                        <tr>
                           <td colspan="2">
                              <div class="separator">--------------------------------------</div>
                           </td>
                        </tr>
                        `).join('')}
                     </tbody>
                  </table>
               </div>
               `;
      if (!(projeto.interno)) {
        html += `
               <div class="bold">TOTAL: R$ ${projeto.valorTotalProdutos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      }
    }
    if (projeto.servicos && projeto.servicos.length > 0) {
      if (projeto.produtos && projeto.produtos.length > 0) {
        html += `
               <div class="separator">**************************************</div>
               `;
      }
      html += `
               <div class="table-container">
                  <table>
                     <tbody>
                        <tr>
                           <td>#</td>
                           <td>DESCRIÇÃO</td>
                           <td>QTD</td>
                           <td>VALOR</td>
                        </tr>
                        ${projeto.servicos.map((iv: any, index: any) => `
                        <tr>
                           <td class="regular-itens">${quebrarTextoEmLinhas(`${iv.descricao} x ${iv.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 30)
          .map((linha, indexLinha) => `
                        <tr>
                           <td class="regular-itens">${indexLinha == 0 ? String(index + 1).padStart(2, '0') : "&nbsp"}</td>
                           <td class="regular-itens">${linha}</td>
                        </tr>
                        `).join('')}</td> 
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">&nbsp</td>
                        <td class="regular-itens" style="text-align: right;">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td class="regular-itens" style="text-align: right;">${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>`).join('')}
                     </tbody>
                  </table>
               </div>
               <div class="separator">--------------------------------------</div>
               <div class="bold">TOTAL: R$ ${projeto.valorTotalServicos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.formaPagamento) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}</div>
               `;
    }
    if (projeto.troco) {
      html += `
               <div class="bold">TROCO: R$ ${projeto.troco?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.taxaEntrega && !projeto.interno) {
      html += `            
               <div class="bold">TAXA DE ENTREGA: +R$ ${projeto.taxaEntrega?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.desconto) {
      html += `            
               <div class="bold">DESCONTO: -R$ ${projeto.desconto?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
    }
    if (projeto.interno && projeto.taxaServico) {
      html += `            
               <div class="bold">TAXA: +R$ ${projeto.taxaServico?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
               `;
    }
    if (projeto.valorTotal && !(projeto.interno)) {
      html += `
               <div class="separator">**************************************</div>
               <div class="bold">TOTAL ${projeto.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${projeto.valorTotal?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
               `;
      const qrData = await QRCode.toDataURL(projeto.pk);
      html += `
               <div class="separator">.</div>
               <div>
                  <img src="${qrData}" alt="QR Code" style="width: 150px; height: 150px;"/>
               </div>
               `;
    }
    html += `
               <div class="separator">.</div>
               <div class="separator">.</div>
               <div class="separator">.</div>
            </div>
            </body>
      </html>
      `;
    return removerAcentosECedilha(html);
  }


  const getColor = (percentual: any) => {
    const percent = parseFloat(percentual?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0);
    if (percent <= 33) {
      return "rgba(212, 37, 28, 0.5)"; // Vermelho com 70% de opacidade
    } else if (percent <= 66) {
      return "rgba(29, 52, 96, 0.5)"; // Azul com 70% de opacidade
    } else {
      return "rgba(51, 172, 27, 0.5)"; // Verde com 70% de opacidade
    }
  };

  function carregarVenda(vendaid: any, empresaid: any) {
    history.push("../vendas/vendasdetalhes", { vendaid: vendaid, empresaid: empresaid })
  }

  return (

    <IonPage className="ProjetosDetalhes">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="dark" className="ion-text-wrap" style={{ "padding-left": "20px", "padding-top": "0px", "padding-bottom": "0px", "text-align": "left" }}>
            {dadosProjeto ? (window.localStorage.getItem("servicoTipo") || "Projeto") + " - " + dadosProjeto.nomeProjeto : ""}
          </IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>
          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>
              {getButtons().map((button: any, index: number) => (
                <IonItem key={index} lines="none" disabled={button.disabled} button onClick={() => {
                  if (button.handler) button.handler();
                  closePopover();
                }}>
                  <IonIcon icon={button.icon} slot="start" />
                  <IonLabel>{button.text}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonPopover>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} scrollEvents={true}>

        <div className="header">
          <div className="info">
            <IonButton onClick={scrollToAgendamentos} color="tertiary" hidden={acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao'}>
              Agenda
            </IonButton>
            <IonButton style={{ paddingLeft: "20px" }} onClick={scrollToOperacoes} color="tertiary" hidden={acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao'}>
              Operações
            </IonButton>
          </div>
        </div>
        <IonList>
          {/* <IonItem lines="none" >
            <IonButton slot="end" onClick={scrollToAgendamentos} color="tertiary" hidden={acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao'}>
              Agenda
            </IonButton>
            <IonButton onClick={scrollToOperacoes} color="tertiary" slot="end" hidden={acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao'}>
              Operações
            </IonButton>



          </IonItem> */}

          <IonListHeader lines="full">
            <IonLabel>{window.localStorage.getItem("servicoTipo") || "Projeto"}</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon icon={clipboardOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosProjeto && dadosProjeto.nomeProjeto ? dadosProjeto.nomeProjeto : dadosProjeto.numero}</h2>
            </div>
            {
              acessos?.vendas == 'leitura' || acessos?.vendas == 'edicao' ?
                dadosProjeto.vendaid ?
                  <IonIcon color="primary" icon={trendingUpOutline}
                    onClick={(e) => {
                      carregarVenda(dadosProjeto.vendaid.replaceAll(/#/g, '%23'), dadosProjeto.gsi2pk.replaceAll(/#/g, '%23'));
                    }} /> :
                  <></>
                : <></>
            }
          </IonItem>

          {/* <IonItem lines="none">
            <IonIcon icon={cubeOutline} slot="start" />
            <div className="contentList">
              <h1>Descrição</h1>
              <h2>{dadosProjeto && dadosProjeto.descricaoProjeto ? dadosProjeto.descricaoProjeto : "Sem descrição"}</h2>
            </div>
          </IonItem> */}

          <IonItem lines="none">
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Status</h1>
              <h2>{dadosProjeto.status}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={albumsOutline} slot="start" />
            <div className="contentList">
              <h1>{window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo"}</h1>
              <h2>{dadosProjeto ? dadosProjeto.statusWorkflow : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={locationOutline} slot="start" />
            <div className="contentList">
              <h1>Endereço</h1>
              <h2> {dadosProjeto
                ? [dadosProjeto.endereco, dadosProjeto.complemento]
                  .filter(Boolean)
                  .join(', ')
                : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={navigateOutline} slot="start" />
            <div className="contentList">
              {(dadosProjeto && dadosProjeto.enderecoLat && dadosProjeto.enderecoLng) ? (
                <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                  <a target='_blank' href={"https://www.google.com/maps/search/?api=1&query=" + (dadosProjeto ? dadosProjeto.enderecoLat + "," + dadosProjeto.enderecoLng + "&query_place_id=" : "")}><img src={maps} alt="maps" /></a>
                  <a target='_blank' href={"https://waze.com/ul?ll=" + (dadosProjeto ? dadosProjeto.enderecoLat + "%2C" + dadosProjeto.enderecoLng : "") + "&navigate=yes"}><img src={waze} alt="waze" /></a>
                </div>
              ) : (
                <h2 style={{ color: "red" }}><b>Sem Localização</b></h2>
              )}
            </div>
          </IonItem>

          {/* <IonItem lines="none">
            {dadosProjeto.interno == true ? <IonIcon color="danger" icon={lockClosed} slot="start" /> : <IonIcon color="success" icon={lockOpen} slot="start" />}
            <div className="contentList">
              <h1>Bloqueado</h1>
              <h2>{dadosProjeto ? (dadosProjeto.interno ? "sim" : "não") : ""}</h2>
            </div>
          </IonItem> */}





          <IonListHeader lines="full" hidden={window.localStorage.getItem("esconderValoresProjeto") == "true"}>
            <IonLabel style={{ color: dadosProjeto.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)", fontSize: '20px', fontWeight: 'bold' }}>Itens</IonLabel>
          </IonListHeader>

          {(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") ? (
            <>
              <IonItem
                hidden={window.localStorage.getItem("esconderValoresProjeto") == "true"}
                lines="none"
                button
                detail
                onClick={(e) => { carregarProdutos(); }}
                style={{ backgroundColor: '#f7f7f7', padding: '10px 15px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
              >
                <IonIcon src={listOutline} slot="start" style={{ color: dadosProjeto.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)", fontSize: '28px' }} />
                <div className="contentList" style={{ fontWeight: 'bold', fontSize: "24px", color: dadosProjeto.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)" }}>
                  <h1>Produtos</h1>
                  <h2>R$ {dadosProjeto && dadosProjeto.valorTotalProdutos ? dadosProjeto.valorTotalProdutos : "0,00"}</h2>
                </div>
              </IonItem>

              <IonListHeader lines="full">
                <IonLabel>Valores</IonLabel>
              </IonListHeader>

              <IonItem lines="none">
                <IonIcon icon={cardOutline} slot="start" />
                <div className="contentList">
                  <h1>Forma de pagamento</h1>
                  <h2>{dadosProjeto && dadosProjeto.formaPagamento ? dadosProjeto.formaPagamento : "Nenhum"}</h2>
                </div>
              </IonItem>

              <IonItem lines="none">
                <IonIcon icon={iconMoto} slot="start" />
                <div className="contentList">
                  <h1>Taxa de entrega</h1>
                  <h2>{dadosProjeto && dadosProjeto.taxaEntrega ? dadosProjeto.taxaEntrega : "0,00"}</h2>
                </div>
              </IonItem>

              <IonItem lines="none">
                <IonIcon icon={pricetagOutline} slot="start" />
                <div className="contentList">
                  <h1>Desconto</h1>
                  <h2>{dadosProjeto && dadosProjeto.desconto ? dadosProjeto.desconto : "0,00"}</h2>
                </div>
              </IonItem>

              <IonItem lines="none">
                <IonIcon icon={logoUsd} slot="start" />
                <div className="contentList" style={{ fontWeight: 'bold', fontSize: "24px" }}>
                  <h1>Valor total</h1>
                  <h2>{dadosProjeto && dadosProjeto.valorTotal ? dadosProjeto.valorTotal : "0,00"}</h2>
                </div>
              </IonItem>
            </>

          ) :

            (
              <IonItem
                hidden={window.localStorage.getItem("esconderValoresProjeto") == "true" || (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}
                lines="none"
                button
                detail
                onClick={(e) => { carregarServicos(); }}
                style={{ backgroundColor: '#f7f7f7', padding: '10px 15px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
              >
                <IonIcon src={listOutline} slot="start" style={{ color: dadosProjeto.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)", fontSize: '28px' }} />
                <div className="contentList" style={{ fontWeight: 'bold', fontSize: "24px", color: dadosProjeto.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)" }}>
                  <h1>Serviços</h1>
                  <h2>R$ {dadosProjeto && dadosProjeto.valorTotalServicos ? dadosProjeto.valorTotalServicos : "0,00"}</h2>
                </div>
              </IonItem>
            )}




          <IonListHeader lines="full" hidden={window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas"}>
            <IonLabel>Status</IonLabel>
          </IonListHeader>
          <IonItem lines="none" hidden={window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas"}>
            <div onClick={(e) => {
              carregarServicos();
            }} style={{
              position: "relative", height: "32px", width: "100%", backgroundColor: "#f5f5f5",
              borderRadius: "20px"
            }}>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  height: "100%",
                  width: `${parseFloat(dadosProjeto.percentualTotalServicos?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0)}%`,
                  backgroundColor: getColor(dadosProjeto.percentualTotalServicos),
                  zIndex: "1",
                  borderRadius: "20px",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "2"
                }}
              >
                {dadosProjeto.percentualTotalServicos || "0"}%
              </div>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Responsáveis</IonLabel>

            <IonItem hidden={acessos?.projetos != 'edicao'} lines="none" >
              <IonIcon src={addCircle} color="primary" onClick={async () => {


                await carregarResponsaveis();





              }}></IonIcon>
            </IonItem>
            <IonModal isOpen={showModalResponsaveis} onDidDismiss={() => setShowModalResponsaveis(false)} >
              <AppTypeaheadItem
                title="Responsáveis"
                items={responsaveis}
                selectedItem={""}
                onSelectionCancel={() => setShowModalResponsaveis(false)}
                onSelectionChange={responsavelSelectionChanged}
              />
            </IonModal>
          </IonListHeader>

          {loadResponsaveis()}


          <IonListHeader lines="full">
            <IonLabel>Empresa</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosProjeto && dadosProjeto.empresaNome ? dadosProjeto.empresaNome : ""}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div>
                <a target='_blank' href={'https://api.whatsapp.com/send?phone=' + dadosProjeto.empresaTelefone}>
                  <h2>{dadosProjeto && dadosProjeto.empresaTelefone ? formataTelefone(dadosProjeto.empresaTelefone) : ""}</h2>
                </a>
              </div>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Cliente</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosProjeto && dadosProjeto.clienteNome ? dadosProjeto.clienteNome : ""}</h2>
            </div>
          </IonItem>
          {/* 
          <IonItem lines="none">
            <IonIcon src={card} slot="start" />
            <div className="contentList">
              <h1>CPF</h1>
              <h2>{dadosProjeto && dadosProjeto.clienteCpf ? cpf_mask(dadosProjeto.clienteCpf) : ""}</h2>
            </div>
          </IonItem> */}

          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosProjeto ? dadosProjeto.clienteTelefone : "")}><h2>{dadosProjeto && dadosProjeto.clienteTelefone ? formataTelefone(dadosProjeto.clienteTelefone) : ""}</h2></a>
              </div>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={notificationsOutline} slot="start" />
            <div className="contentList">
              <h1>Enviar notificação?</h1>
              <h2>{dadosProjeto && dadosProjeto.enviarMsgCliente ? dadosProjeto.enviarMsgCliente : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={starOutline} slot="start" />
            <div className="contentList">
              <h1>Avaliação</h1>
              <h2>{dadosProjeto && dadosProjeto.avaliacaoCliente ? dadosProjeto.avaliacaoCliente : "Não avaliado"}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Comentário</h1>
              <IonTextarea readonly={true} style={{ "--background": "#eeeeee" }} rows={6} value={dadosProjeto && dadosProjeto.comentarioCliente ? dadosProjeto.comentarioCliente : "Não avaliado"}></IonTextarea>
            </div>
          </IonItem>


          <IonListHeader lines="full" hidden={!(dadosProjeto && dadosProjeto.responsavelNome)}>
            <IonLabel>Arquiteto</IonLabel>
          </IonListHeader>
          <IonItem lines="none" hidden={!(dadosProjeto && dadosProjeto.responsavelNome)}>
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosProjeto && dadosProjeto.responsavelNome ? dadosProjeto.responsavelNome : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none" hidden={!(dadosProjeto && dadosProjeto.responsavelNome)}>
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosProjeto ? dadosProjeto.responsavelTelefone : "")}><h2>{dadosProjeto && dadosProjeto.responsavelTelefone ? formataTelefone(dadosProjeto.responsavelTelefone) : ""}</h2></a>
              </div>

            </div>
          </IonItem>
          <IonItem lines="none" hidden={!(dadosProjeto && dadosProjeto.responsavelNome)}>
            <IonIcon src={notificationsOutline} slot="start" />
            <div className="contentList">
              <h1>Enviar notificação?</h1>
              <h2>{dadosProjeto && dadosProjeto.enviarMsgResposavel ? dadosProjeto.enviarMsgResposavel : ""}</h2>
            </div>
          </IonItem>


          <IonListHeader lines="full">
            <IonLabel>Arquivos</IonLabel>
            <IonItem lines="none" hidden={acessos?.projetos != 'edicao'}>
              <IonIcon src={addCircle} color="primary" onClick={async () => { setShowModalArquivos(true) }}></IonIcon>
            </IonItem>
          </IonListHeader>




          <IonList lines="full" class="ion-no-margin">
            <IonGrid>
              <IonRow>

                {preencheArquivos(dadosProjeto)}
              </IonRow>
            </IonGrid>
          </IonList>

          <div hidden={acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao'}>
            <IonListHeader lines="full" ref={contentAgendamentosRef}>
              <IonLabel>Agenda</IonLabel>
            </IonListHeader>

            {loadAgendamentos()}
          </div>
          <div hidden={acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao'}>
            <IonListHeader lines="full" ref={contentOperacoesRef}>
              <IonLabel>Operações</IonLabel>
            </IonListHeader>

            {loadOperacoes()}
          </div>


          <IonListHeader lines="full">
            <IonLabel>Anotações</IonLabel>
            <IonItem hidden={acessos?.projetos != 'edicao'} lines="none" >
              <IonIcon src={addCircle} color="primary" onClick={async () => {






                mostartAlerta({
                  backdropDismiss: false,
                  header: 'Registrar anotação no projeto',
                  message: 'Digite a anotação abaixo',
                  inputs: [
                    {
                      name: 'mensagem',
                      type: 'textarea',
                      id: 'name2-id',
                      placeholder: 'Digite aqui a anotação',
                    }],
                  buttons: ['Cancel', {
                    text: 'Incluir', handler: (d) => {
                      setShowLoadingPage(true);

                      console.log("d.mensagem", d.mensagem);
                      let retorno = registrarAnotacao(dadosProjeto.pk.replace(/#/g, '%23'), d.mensagem);
                      if (retorno) {
                        retorno?.then(async e => {
                          let body = await e.json();
                          if (e.status === 200) {
                            if (dadosProjeto.anotacoes) {
                              dadosProjeto.anotacoes?.push({ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa });
                            }
                            else {
                              dadosProjeto.anotacoes = [{ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa }];
                            }

                            mostrarToast({
                              duration: 2000,
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Sucesso na inclusão!'
                            });
                          }
                          else {
                            mostrarToast({
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro na inclusão!'
                            });
                          }
                        }).catch((e) => {
                          console.log(e);
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro na inclusão!'
                          });
                        }).finally(() => {
                          setShowLoadingPage(false);
                        });
                        return true;
                      }
                      else {
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Digite uma anotação para incluir.'
                        });
                        setShowLoadingPage(false);
                        return false;
                      }
                    }
                  }],
                  onDidDismiss: (e) => { console.log('did dismiss'); }
                })







              }}></IonIcon>
            </IonItem>

          </IonListHeader>

          {loadAnotacoes()}

        </IonList>

        {/* <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={getButtons()}

        /> */}

        {/* <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheetLinhaDoTempo}
          onDidDismiss={() => setShowActionSheetLinhaDoTempo(false)}
          cssClass='my-custom-class'
          buttons={getButtonsLinhaDoTempo()}
        /> */}

        <IonAlert isOpen={alertConfirmar}
          onDidDismiss={() => setAlertConfirmar(false)}
          header={'Encerrar ' + window.localStorage.getItem("servicoTipo") || "Projeto"}
          message={'Deseja <strong>encerrar</strong>?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: async () => {
                setShowLoadingPage(true);
                await encerrar2().then(res => {
                  console.log(res);
                  setRecarregarProjeto(true);
                  history.goBack();
                }).catch(() => console.log("Erro na chamada"))
                  .finally(() => {
                    setShowLoadingPage(false);
                  });
              }
            }
          ]}
        />

        <IonModal onDidPresent={() => {
          if (inputAgendamentoNovoRef.current) {
            inputAgendamentoNovoRef.current.setFocus();
          }
        }} isOpen={showNovoAgendamento} onDidDismiss={() => { setShowNovoAgendamento(false); }}>
          <AgendamentosNovo inputRef={inputAgendamentoNovoRef} projetoid={btoa(idProjeto.replaceAll("%23", "#"))} closeAction={closeModalNovoAgendamento} closeActionSimple={closeModalSimpleNovoAgendamento}></AgendamentosNovo>
        </IonModal>

        {/* <IonModal onDidPresent={() => {
          if (inputProjetosAltRef.current) {
            inputProjetosAltRef.current.setFocus();
          }
        }} isOpen={showAtualizarInformacoes} onDidDismiss={() => { setShowAtualizarInformacoes(false); }}>
          <ProjetosAlterar projeto={dadosProjeto} inputRef={inputProjetosAltRef} closeAction={closeModalAtualizarInformacoesSucess} closeActionSimple={closeModalAtualizarInformacoes}></ProjetosAlterar>
        </IonModal> */}

        {/* <IonModal isOpen={showLiberar} onDidDismiss={() => { setShowLiberar(false); }}>
          <ProjetosLiberar projetoid={params.projetoid} closeAction={closeModalLiberalSucess} closeActionSimple={closeModalLiberal}></ProjetosLiberar>
        </IonModal> */}

        <IonModal isOpen={showWorkflowAvancar} onDidDismiss={() => { setShowWorkflowAvancar(false); }}>
          <ProjetosWorkflowAvancar projetoid={projetoId} statusWorkflow={statusWorkflow} indiceWorkflow={indiceWorkflow} listaStatus={listaStatus} closeAction={closeModalWorkflowAvancar} alterarAction={closeModalWorkflowAvancarSucess}></ProjetosWorkflowAvancar>
        </IonModal>

        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showModalArquivos} onDidDismiss={() => { getProjeto(); setShowModalArquivos(false); setTextoArquivos(''); setFileArquivos(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Adicionar Arquivos</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalArquivos(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonItem lines="none">
              <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
              <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
            </IonItem>
            <IonItem lines="none" >
              <IonLabel className="ion-text-wrap" position="stacked">Arquivo:</IonLabel>
              <IonTextarea rows={1} maxlength={20} value={textoArquivos} ref={(ref) => inputRef.current = ref} autoCorrect='true' autoCapitalize='true' placeholder="Nome do arquivo" onIonChange={e => setTextoArquivos(e.detail.value!)}></IonTextarea>
            </IonItem>
            <IonItem lines="none" >
              <input ref={(ref) => { setFileArquivos(ref); }} type="file" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*" />
            </IonItem>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' onClick={() => { handleUpload(params.projetoid).catch((e) => { console.log("cancelado"); }); }}>Adicionar Arquivo</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        {/* <IonModal isOpen={showModalServicoProduto} onDidDismiss={() => setShowModalServicoProduto(false)} initialBreakpoint={0.2} breakpoints={[0, 0.2]}>
          <IonContent className="ion-padding">
            <IonItem lines='none'>
              <IonLabel className="ion-text-wrap" style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center' }}><b>Items {window.localStorage.getItem("servicoTipo") || "Projeto"}</b></IonLabel>
            </IonItem>
            <div style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>

              <IonChip color="primary"
                onClick={(e) => {
                  setShowModalServicoProduto(false); carregarServicos();
                }}>
                <IonIcon icon={listOutline}></IonIcon>
                <IonLabel style={{ height: "20px" }}>Serviços</IonLabel>
              </IonChip>
              <IonChip color="primary"
                onClick={(e) => {
                  setShowModalServicoProduto(false); carregarProdutos();
                }}>
                <IonIcon icon={listOutline} style={{ height: "20px" }}></IonIcon>
                <IonLabel style={{ height: "20px" }}>Produtos</IonLabel>
              </IonChip>

            </div>
          </IonContent>
        </IonModal> */}


        <IonAlert isOpen={showAlertConfirmarExclusaoArquivo}
          onDidDismiss={() => setShowAlertConfirmarExclusaoArquivo(false)}
          header={'Confirmar Exclusão'}
          message={'Você <strong>confirma</strong> excluir esse arquivo?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                deletarArquivo(caminhoParaExcluir);
                setShowAlertConfirmarExclusaoArquivo(false);
              }

            }
          ]}
        />



        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>

      {/* <IonFooter className="ion-no-border" hidden={(temHoraFim)}>
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheet(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter> */}

      {/* <IonFooter className="ion-no-border" hidden={(!temHoraFim)}>
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheetLinhaDoTempo(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter> */}
    </IonPage>
  );


};

export default ProjetosDetalhes;